import React, { useEffect, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { getApi, postApi } from '../service/adminService'
import Loader from '../../components/Loader/loader'
import PaginationComponent from '../../PaginationComponent'
import Search from '../../components/Search/Search'

const EWAplateformcharges = () => {

    // const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [ValidPagination, setValidPagination] = useState(false)
    const [dataArray, setDataArray] = useState([]);
    const [plandetail, setPlandetail] = useState([])
    const [search, setSearch] = useState('');
    // const [searchpage, setSearchpage] = useState('');
    // const [endDate, setendDate] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    // const [pagesCheck, setpagesCheck] = useState(1);
    // const [detail, setDetail] = useState([]);
    const [input, setInput] = useState(true);
    const [planID, setPlanID] = useState({})
    const [planIDtype, setPlanIDtype] = useState({})
    // const [Platform, setPlatform] = useState(true);
    const [Type, setType] = useState()
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [searchcheck, setsearchcheck] = useState(false)
    const [timevalue, settimevalue] = useState("")
    const [timevalue1, settimevalue1] = useState("")
    const [searchcheck1, setsearchcheck1] = useState(false)
    const [searchcheck2, setsearchcheck2] = useState(false)
    const [timevalue2, settimevalue2] = useState("")
    const [searchcheck3, setsearchcheck3] = useState(false)
    const [timevalue3, settimevalue3] = useState("")
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;


    const GetEmployerEWADetail = async (token) => {
        // const response = await GetEmployerEWAcharges(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/employer-ewa-charges", { page: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            settimevalue("")
            settimevalue1("")
            settimevalue2("")
            settimevalue3("")
            // setTimeout(() => {
            setDataArray(response.data);
            setTotalPagesCount(response.count);
            settotalItems(response.data)
            // setloader(false)
            // }, 2000);
            // setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        GetEmployerEWADetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    async function apiCall(val, id,type) {
        try {
            // console.log(val)
            val = Number(Number(val).toFixed(2))

            // await UpdateEmployerCommission(admin.admin_token, id, val, Type)
            await postApi("/update-employer-ewa-charges", {
                user_id: id,
                ewa_txn_charge: val,
                ewa_txn_charge_type: type
            })
            GetEmployerEWADetail(admin.admin_token);
        } catch (e) {
            console.log(e);
        }
    }

    const plateformcharges = async (value, txn_charges, user_id, charge_type) => {
        // const data = {
        //     "user_id": user_id,
        //     "platform_fee": value,
        //     "ewa_txn_charge": txn_charges,
        //     "ewa_txn_charge_type": charge_type
        // }

        // const response = await UpdateEmployerPlatformCommission(admin.admin_token, data)
        // console.log(value, "______________________________________________")
        const response = await postApi("/update-employer-ewa-charges", {
            user_id: user_id,
            plan_id: value,
            ewa_txn_charge: txn_charges,
            ewa_txn_charge_type: charge_type
        })

        // console.log(response)
        if (response.status == true) {
            GetEmployerEWADetail(admin.admin_token);
        }
    }

    useEffect(() => {
        let clear = setTimeout(() => {
            apiCall(input.val, input.id, input.type)
        }, 1000)
        return (
            () => {
                clearTimeout(clear)
            }
        )
    }, [input])

    const updateCommission = async (val, id,type) => {
        setInput({ val, id ,type})
    }

    const handleChangeQueryBuilders = async (e, val, id) => {
        setType(e)
        setInput({ val, id ,type:e})
    }

    const GetEWAPlan = async (token, pageNumber, filterVlu) => {
        // const response = await Getcities(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/ewa-plan-list");
        console.log("fdgdgdgddfgdg", response)
        if (response.status) {
            setPlandetail(response.data.rows);

        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        GetEWAPlan()
    }, [])

    useEffect(()=>{
        setPlanID({})
        setPlanIDtype({})
    },[dataArray])
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Merchant Commission({totalPagesCount})</h4> */}

                                <div className="page-leftheader">
                                    {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                    <h4 className="page-title mb-0 text-primary">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a>Manage EWA Plans</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Employer EWA{/* ({totalPagesCount}) */}</li>
                                            </ol>
                                        </nav>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {/* <span onChange={(e) => { setsearchcheck(true); setsearchcheck1(true); setsearchcheck2(true); setsearchcheck3(true) }}>
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                        </span> */}
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search}
                                                onChange={(e) => { setSearch(e.target.value); setpagesCount('1'); setValidPagination(!ValidPagination); setsearchcheck(true); setsearchcheck1(true); setsearchcheck2(true); setsearchcheck3(true) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Employer Details</th>
                                                        <th className="font-weight-bold">Pay Cycle</th>
                                                        <th className="font-weight-bold">EWA Plan List</th>
                                                        <th className="font-weight-bold">Commission
                                                            <span data-bs-toggle="tooltip" data-bs-placement="top" title="(Amount per TXN OR % per TXN)">
                                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                                            </span></th>
                                                        <th className="font-weight-bold " > TXN Type</th>
                                                        {/* <th></th><th></th><th></th> */}
                                                    </tr>
                                                </thead>
                                                {/* {loader == true ? <tbody>
                                                    <td />
                                                    <div >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <> */}
                                                <tbody>
                                                    {dataArray.length > 0 && dataArray.map((option, index) => {
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        const { plan_id, user_id, company_name, pay_cycle, full_name, email, platform_fee, mobile_number, ewa_txn_charge, ewa_txn_charge_type } = option;
                                                        const check = option.ewa_txn_charge_type == 'fixed' ? "$" + ewa_txn_charge : ewa_txn_charge + "%"
                                                        // console.log(platform_fee)
                                                        function truncateText(text, maxLength) {
                                                            if (text.length > maxLength) {
                                                                return text.substring(0, maxLength) + '...';
                                                            } else {
                                                                return text;
                                                            }
                                                        }
                                                        return (
                                                            <tr key={serial_num} >
                                                                <td><Link to={{ pathname: `${prefix}/employer_detail/${user_id}`, state: { user_id } }}  >
                                                                    <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{company_name ? company_name : ""}
                                                                        <br /><span className="content_fix" style={{ textTransform: 'none' }}>{email ? email : ""}</span>
                                                                        <br />{mobile_number ? mobile_number : ""}</span>
                                                                </Link>
                                                                </td>
                                                                <td>{pay_cycle != null ? pay_cycle : "NA"}
                                                                    {/* {console.log(searchcheck2, timevalue2, "3333333333")} */}
                                                                </td>
                                                                {/* {search  ? */}
                                                                 <td> <div className="input-group"
                                                                    style={{ maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >
                                                                    {/* {console.log(plan_id, planID, "1111111111111")} */}
                                                                    <select className="form-control mb-0" name="loan_type"
                                                                         value={planID[index]?.plan ? planID[index]?.plan :plan_id ? plan_id : ""} onChange={(e) => {planID[index]={}; planID[index].plan=e.target.value; setPlanID({...planID}); plateformcharges(e.target.value, ewa_txn_charge, user_id, ewa_txn_charge_type) }}>
                                                                        <option value="">-- No Plan Selected -- </option>
                                                                        {plandetail.map(plandata => (<option value={plandata.id}>{truncateText(plandata.title, 30)}</option>))}
                                                                    </select></div></td>  
                                                                    {/* :""} */}
                                                                    {/* {search.length==0 ?  <td> <div className="input-group"
                                                                        style={{ maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >
                                                                        {console.log(planID, timevalue2, "222222222222")}
                                                                        <select className="form-control mb-0" name="loan_type"
                                                                            value={planID[index] ? planID[index] :plan_id ? plan_id : ""} onChange={(e) => {planID[index]=e.target.value; setPlanID({...planID});  plateformcharges(e.target.value, ewa_txn_charge, user_id, ewa_txn_charge_type); }}>
                                                                            <option value="">-- No Plan Selected -- </option>
                                                                            {plandetail.map(plandata => (<option value={plandata.id}>{truncateText(plandata.title, 30)}</option>))}
                                                                        </select>
                                                                    </div></td>:""} */}
                                                                {/* {searchcheck1 && timevalue1 == "" ?
                                                                    <td><input type="number" className="form-control"

                                                                        value={ewa_txn_charge ? ewa_txn_charge : ""}
                                                                        onChange={(e) => { updateCommission(e.target.value, option.user_id); setsearchcheck1(false) }} /></td> : */}
                                                                        {console.log({index}, ewa_txn_charge_type,"ewa_txn_charge_typeewa_txn_charge_type")}
                                                                    <td><input type="number" className="form-control"
                                                                         value={(planID[index]?.commission || planID[index]?.commission=="") ? planID[index]?.commission : ewa_txn_charge ? ewa_txn_charge : ""}
                                                                        onChange={(e) => {planID[index]={}; planID[index].commission=e.target.value; setPlanID({...planID}); updateCommission(e.target.value, option.user_id,ewa_txn_charge_type) }}
                                                                    /></td>
                                                                    {/* } */}

                                                              
                                                                    <td> 
                                                                        {/* {console.log([index],"rrr", planIDtype[index]?.type ,"ttt", ewa_txn_charge_type ,"yyyy", ewa_txn_charge_type)} */}
                                                                        <select className='form-control font-weight-bold'
                                                                       value={planIDtype[index]?.type ? planIDtype[index]?.type : ewa_txn_charge_type ? ewa_txn_charge_type : ""}
                                                                        onChange={(e) => { planIDtype[index]={}; planIDtype[index].type=e.target.value; setPlanIDtype({...planIDtype}); handleChangeQueryBuilders(e.target.value, ewa_txn_charge, user_id); }} >
                                                                        <option value="percent" >Percent</option>
                                                                        <option value="fixed">Fixed Amount</option>
                                                                    </select></td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={9} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                                {/* </>} */}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default EWAplateformcharges
