import React, { useEffect, useRef, useState } from 'react'
import { getApi } from '../service/adminService';
import PaginationComponent from '../../PaginationComponent';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader/loader';
import { Image } from 'antd';
import Search from '../../components/Search/Search';


const LenderInterest = (props) => {
    const [loader, setloader] = useState(true)
    const [pagesCount, setpagesCount] = useState(1);
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [search, setSearch] = useState('');
    const [pagesCheck, setpagesCheck] = useState(1);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [detail, setDetail] = useState([]);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [recordCount, setRecordCount] = useState(0)
    const [ValidPagination, setValidPagination] = useState(false)

    // const employer = JSON.parse(localStorage.getItem("admin"));
    // const token = employer.admin_token;


    const getLenderInterest = async (token, pageNumber, filterVlu, employee) => {
        // const response = await GetLenderInterest(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/lender-interest", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        // console.log("EployeeViews", response)
        if (response.status) {
            setTimeout(() => {
                settotalItems(response.data)
                setTotalPagesCount(response.AllLender);
                setDataArray(response.data)
                setRecordCount(response.AllLender)
                setloader(false)
            }, 2000);
            // setViewEmployer(response.data);
            setloader(true)
        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getLenderInterest(admin.admin_token, pagesCount, search)
    }, [pagesCheck, search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getLenderInterest(admin.admin_token, pagesCheck, search)
    }

    // const getDetail = async (user_id) => {
    //     const response = await GetLenderDetail(admin.admin_token, user_id)
    //     console.log('response getDetail', response);
    //     if (response.data) {
    //         setDetail(response.data);
    //     } else {
    //         props.toast.error(response.message);
    //     }
    // }


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Lender Interest{recordCount ? '(' + recordCount + ')' : ''}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Logo</th>
                                                        <th className="font-weight-bold">Lender  {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Address {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                        <th className="font-weight-bold">City  {sortedBy == "country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }}></i>}</th>
                                                        <th className="font-weight-bold">Total Disbursed Amount {sortedBy == "total_disbursed" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_disbursed') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_disbursed') }}></i>}</th>
                                                        <th className="font-weight-bold">Total Pending Interest  {sortedBy == "total_pending_intrest" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_pending_intrest') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_pending_intrest') }}></i>}</th>
                                                        <th className="font-weight-bold">Paid Interest  {sortedBy == "total_intrest" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_intrest') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_intrest') }}></i>}</th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> : <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            // let Capitalize = (option) => {
                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                            // }
                                                            // let result = option.logo.replace(/lender-logo-/g, "logo/");
                                                            //  var NewLogo = option.logo.replace("/lender-logo-", "/logo/");
                                                            var num2 = parseFloat(option.total_intrest).toFixed(2)
                                                            // var NewText = option.logo.replace("lender-logo-", "logo/");

                                                            return (
                                                                <tr key={serial_num} >

                                                                    {/* {option.logo != null ?
                                                                        <td><span className="font-weight-normal1">
                                                                            <img style={{ width: 50, height: 50, borderRadius: '8px' }} src={option.logo} alt='' /></span></td>
                                                                        : <td><span className="font-weight-normal1" >
                                                                            <img style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></td>
                                                                    } */}
                                                                    <td>
                                                                        <span className="font-weight-normal1" >
                                                                            <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo != null ? option.logo : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Link className="text-primary" to={prefix + `/lenderdetail/${option.user_id}`}>
                                                                            {/* <Link to="" onClick={() => getDetail(option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" > */}
                                                                            <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{option.company_name ? option.company_name : "--"}<br />{option.mobile_number}</span>
                                                                        </Link></td>
                                                                    <td><span className="font-weight-normal1">{option?.address ? option?.address : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.country == null ? "Null" : <span>{option.country}</span>}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.total_disbursed ? "$" + option.total_disbursed : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.total_pending_intrest ? "$" + option.total_pending_intrest : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.total_intrest ? num2 : "--"}</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>}
                                                    </tbody></>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                    {/* <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Lender Information</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                        <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail ? detail.mini_loan_range : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail ? detail.max_loan_range : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            }
        </>
    )
}

export default LenderInterest
