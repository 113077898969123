import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import PaginationComponent from '../../PaginationComponent';
import Search from '../../components/Search/Search';
import { TimeZone } from '../../components/TimeZone/TimeZone';

const Loanapplication = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [adminData, setDsaData] = useState(localStorage.getItem("admin"));
    const [token, setToken] = useState(JSON.parse(adminData)?.admin_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    // const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [employeeDataID, setemployeeDataID] = useState("")
    const [lenderselect, setLenderselect] = useState()
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const [employerData, setemployerData] = useState([]);
    const tableRef = useRef(null);
    const [refresh, setRefresh] = useState(true)
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [sortedBy, setSortedBy] = useState("customer_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [detail, setDetail] = useState([]);
    const [multippleArray, setMultippleArray] = useState([]);

    const navigate = useNavigate();
    const ViewLoanCases = async (token) => {
        // search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}
        // const response = await GetLoanCasesDetail(token, search, pagesCount, sortedBy, orderBy, employeeDataID)
        const response = await getApi(`/get-loan-application`, { lender_id: employeeDataID, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })

        // console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases(token, search, pagesCount)

    }, [search, pagesCount, refresh, sortedBy, orderBy, employeeDataID])

    const getEmployeesDatas = async (token) => {
        const response = await getApi('/get-lender-list');
        // console.log(response.data)
        if (response.status) {
            setemployerData(response.data);

        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getEmployeesDatas(token);
    }, []);

    const handleChangeQuery = (e) => {
        console.log(e.target.value)
        setemployeeDataID(e.target.value)

    }

    const getDetail = async (user_id) => {

        // const response = await GetLenderDetail(token, user_id)

        const response = await getApi(`/get-lender/${user_id}`)
        console.log('response getDetail', response);
        if (response.data) {
            setDetail(response.data);

        } else {
            toast.error(response.message);
        }
    }
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // ViewInvestment(token, search, pagesCount )
    }
    const selectTransactions = async (e, id) => {
        // console.log(id)
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);


        // //console.log(rowIndex)
        if (rowIndex > -1) {

            checkArray.splice(rowIndex, 1);

        } else {
            checkArray.push(id);

        }

        //console.log(checkArray)

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }


    }

    // function handleCheckClick() {
    //     if (isChecked == false) {
    //         let newdata = dataArray.map(v => parseInt(v.user_loan_id)).join(',')
    //         let arrayData = newdata.split(',').map(Number);



    //         setMultippleArray((old_data) => [...arrayData]);
    //         setIsChecked(true);
    //     } else {
    //         setIsChecked(false);
    //         setMultippleArray([]);
    //     }
    //     setDataArray((old_data) => [...old_data]);
    // }

    const checheked = (item) => {
        //console.log(item)
        //console.log(multippleArray.includes(item))
        return multippleArray.includes(item) ? 1 : 0;
    }

    const singlelenderassign = (e) => {
        // console.log(e, "______________________________")
        setMultippleArray([e])
    }

    const AssignLender = async (e) => {
        // const data = JSON.stringify({
        //     user_loan_ids: multippleArray,
        //     lender_id: e.target.value
        // })

        // const response = await UpdateAssignLender(token, data)
        const response = await postApi("/assign-loan-to-lender", {
            user_loan_ids: multippleArray,
            lender_id: e.target.value
        })

        console.log(response)
        if (response.status == true) {
            toast.success(response.message)
            setMultippleArray([])
            ViewLoanCases(token, search, pagesCount)

            // getUsermanager(employer.employer_token);
        } else {
            console.log(response.message)
        }
    }

    const handleSelectQuery = (e) => {
        // console.log(e.target.value)
        setLenderselect(e.target.value)

    }
    // console.log(multippleArray)
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage Loan {Count ? "(" + Count + ")" : ""}</h4>
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">

                                    <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {multippleArray.length > 0 ?
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <div className="form-group">
                                        <label className="form-label">Assign Lender</label>
                                        <div className="row g-xs">
                                            <div className="wd-200 mg-b-30">
                                                <div className="input-group">
                                                    <select className="form-control mb-0" name="loan_type" /* value={queryFilter.loan_type}*/ onChange={(e) => AssignLender(e)} >
                                                        <option value="">-- Assign Lender --</option>
                                                        {employerData.map(item => (<option key={item.id} value={item.user_id}>{item.company_name ? item.company_name : "NA"} -- {item.mobile_number ? item.mobile_number : ""} </option>))}
                                                        {employerData.length == 0 &&
                                                            <option className='text-bold'>No Employer Available</option>

                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />}

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1') }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Search props={(search, setSearch)} /> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search Lender Wise </label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="loan_type" /* value={queryFilter.loan_type}*/ onChange={(e) => handleChangeQuery(e)} >
                                                    <option value="">-- Search Lender --</option>
                                                    {employerData.map(item => (<option key={item.id} value={item.user_id}>{item.company_name ? item.company_name : "NA"} -- {item.mobile_number ? item.mobile_number : ""} </option>))}
                                                    {employerData.length == 0 &&
                                                        <option className='text-bold'>No Employer Available</option>

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th />
                                                        {/* {dataArray.length > 0 &&
                                                            <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>} */}
                                                        <th className="font-weight-bold">Borrower Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Lender Detail {sortedBy == "lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }}> </i>}</th>
                                                        <th className="font-weight-bold">Loan Type  {sortedBy == "loan_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }}> </i>}</th>
                                                        <th className="font-weight-bold">Loan Status  {sortedBy == "loan_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                        <th className="font-weight-bold">Action </th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { lender_id, user_loan_id, loan_status, loan_type, last_name, first_name, mobile_number } = option;



                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {lender_id == null ? <td><input type="checkbox" name={option.user_loan_id} checked={checheked(option.user_loan_id) === 1 ? true : false} onChange={(event) => selectTransactions(event, option.user_loan_id)} /></td> : <td />}
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/applicant_details/${user_loan_id}`, state: { user_loan_id } }}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}  {last_name ? last_name : ""}<br />{mobile_number}</span></Link></td>
                                                                        <td><Link to="" onClick={() => getDetail(lender_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" >
                                                                            <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{option.lenderData?.company_name ? option.lenderData?.company_name : "--"} <br /> {option.lenderData?.full_name ? option.lenderData?.full_name : ""}<br />{option.lenderData?.mobile_number ? option.lenderData?.mobile_number : ""}</span></Link></td>
                                                                        <td>{option.loan_type ? loan_type : "-"}</td>
                                                                        <td><span className="font-weight-normal1"> {option?.loan_status ? loan_status?.status_name : "- -"} </span></td>
                                                                        <td><span className="font-weight-normal1">{/* {timezone} */}{TimeZone(option.createdAt).timeZone}</span></td>
                                                                        <td><Link className="btn btn-primary" to={{ pathname: `${prefix}/applicant_details/${user_loan_id}`, state: { user_loan_id } }}>
                                                                            <span className="font-weight-normal1">Borrower Details</span></Link>&nbsp;
                                                                            {lender_id == null && <button className='btn btn-primary' onClick={() => singlelenderassign(option.user_loan_id)}>Assign Lender</button>}
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}


                        </div>
                    </div>
                    {/* Lender detail start */}
                    <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Lender Information</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    {/* <h6>Lender Information</h6> */}
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                        <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14"> ${detail ? detail.mini_loan_range : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">${detail ? detail.max_loan_range : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Lender detail end */}
                </div>

            }
        </>
    )
}

export default Loanapplication
