import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { getApi, postApi } from '../../../service/employService';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HandleDeleteJobType, HandleDeleteSubJobType, HandleDeleteSubJobTypeLevel2 } from '../../../employerComponent/jobType/DeletejobType';
import { HandleDeleteJobLocation, HandleDeleteSubJobLocation } from '../../../employerComponent/Location/DeleteLocation';

const Jobtypelocation = () => {
    const [newJobType, setNewJobType] = useState('')
    const [newJobTypeLimit, setNewJobTypeLimit] = useState('')
    const [jobType, setJobType] = useState([])
    const [editJobTypeID, setEditJobTypeID] = useState('')
    const [editJobType, setEditJobType] = useState('')
    const [editJobTypeLimit, setEditJobTypeLimit] = useState('')
    const [OpenEditJobType, setOpenEditJobType] = useState(false)
    const [addNewJobLocation, setAddNewJobLocation] = useState('')
    const [addNewJobLocationPremium, setAddNewJobLocationPremium] = useState('')
    const [locationID, SetLocationID] = useState('')
    const [addSubLocationOpenModals, setAddSubLocationOpenModals] = useState(false)
    const [sublocation, setSublocation] = useState('')
    const [sublocationPremuim, setSublocationPremuim] = useState('')
    const [location, setLocation] = useState([])
    const [editSubLocationID, setEditSubLocationID] = useState('')
    const [OpenSubLocationModals, setOpenSubLocationModals] = useState(false)
    const [editSubLocation, setEditSubLocation] = useState('')
    const [editSubLocationPremuim, setEditSubLocationPremuim] = useState('')
    const [oPenEditLocationModal, setOPenEditLocationModal] = useState(false)
    const [editLocationbyId, setEditLocationbyId] = useState('')
    const [editLocation, setEditLocation] = useState('')
    const [editLocationPremium, setEditLocationPremium] = useState('')
    const [manageSubjobType, setManageSubjobType] = useState({});
    const [subJobTypeId, setSubJobTypeId] = useState(null);
    const [manageSubjobTypeLevel2, setManageSubjobTypeLevel2] = useState({});
    const [subJobTypeIdLevel2, setSubJobTypeIdLevel2] = useState(null);
    const [manageSubjobTypeLevel3, setManageSubjobTypeLevel3] = useState({});
    const [subJobTypeIdLevel3, setSubJobTypeIdLevel3] = useState(null);
    const [checkStatus, setCheckStatus] = useState()
    const HandleModalForSubJobTyperef = useRef()
    const HandleModalForSubJobTyperefLevel2 = useRef()
    const HandleModalForSubJobTyperefLevel3 = useRef()
    const [subjobtypeName, setSubjobtypeName] = useState()
    const [subjobtypeLimit, setSubjobtypeLimit] = useState()
    const [L2SubjobtypeName, setL2SubjobtypeName] = useState()
    const [L2SubjobtypeLimit, setL2SubjobtypeLimit] = useState()


    const addNewJobType = async (jobName) => {
        try {
            const response = await postApi("/add-job-type", {
                type: jobName,
                limit: newJobTypeLimit
            })

            if (response.status == true) {

                toast.success(response.message);
                GetJobType()
                setNewJobType('')
                setNewJobTypeLimit('')
                // navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/employees');
            } else {
                // setNextModal(nextModal + 1);
                toast.error(response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const editOldJobType = async (jobName, Limit) => {
        try {
            const response = await postApi(`/edit-job-type/${editJobTypeID}`, {
                job_type: jobName,
                limit: Limit
            })

            if (response.status) {
                toast.success(response.message)
                setOpenEditJobType(false)
                setEditJobTypeID('')
                GetJobType()
            }
        } catch (error) {
            console.log(error)
        }
    }


    const addJobLocation = async (locationName, premiumValue) => {
        try {
            const response = await postApi("/add-job-location", {
                location: locationName,
                premium_value: premiumValue ? premiumValue : 0

            })

            if (response.status == true) {
                GetTypeLocation();
                toast.success(response.message);

                setAddNewJobLocation('')
                setAddNewJobLocationPremium('')
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addSubJobLocation = async (location, premiumValue) => {
        try {
            const response = await postApi('/add-sub-job-location', {
                job_location_id: locationID,
                sub_location: location,
                premium_value: premiumValue ? premiumValue : 0
            })

            if (response.status == true) {
                GetTypeLocation();
                toast.success(response.message);
                setAddSubLocationOpenModals(false)
                setSublocation('')
                setSublocationPremuim('')
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const HandleEditSubLocation = async (sublocation, premiumValue) => {
        try {
            const response = await postApi(`/edit-sub-job-location/${editSubLocationID}`, {
                "sub_location": sublocation,
                "premium_value": premiumValue == "" ? 0 : premiumValue

            })

            if (response.status == true) {
                GetTypeLocation();
                toast.success(response.message);
                setOpenSubLocationModals(false)
                setEditSubLocation('')
                setEditSubLocationPremuim('')
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }


    const HandleEditLocation = async (sublocation, premiumValue) => {
        try {
            const response = await postApi(`/edit-job-location/${editLocationbyId}`, {
                job_location: sublocation,
                premium_value: premiumValue == "" ? "0" : premiumValue
            })

            if (response.status == true) {
                GetTypeLocation();
                toast.success(response.message);
                setOPenEditLocationModal(false)
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const GetJobType = async () => {
        try {
            const response = await getApi("/get-job-type");
            if (response.status) {
                setJobType(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const GetTypeLocation = async () => {
        try {

            const response = await getApi("/get-job-location");
            if (response.status) {
                setLocation(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }


    useEffect(() => {
        GetTypeLocation();
        GetJobType();
    }, []);

    const AddForm = useFormik({
        initialValues: {
            sub_job_type: "",
            sub_l2_job_type: subjobtypeName ? subjobtypeName : '',
            sub_l2_limit: subjobtypeLimit ? subjobtypeLimit : "",
            sub_l3_job_type: L2SubjobtypeName ? L2SubjobtypeName : "",
            sub_l3_limit: L2SubjobtypeLimit ? L2SubjobtypeLimit : ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({}),
        onSubmit: async values => {
            const response = await postApi("/business-detail-update", {
                company_name: values.business_name,
                mobile_number: values.mobile_number,
                employer_country: values.employer_country,
                address: values.business_location,
                business_type: values.businessType,
                employee_count_upto: values.employee_total_count
            })
            if (response.status == true) {
                toast.success(response.message);
            } else {
                console.log('inside abhi', response);
            }
        }

    });

    const handleManageSubjobType = (id) => {
        setManageSubjobType(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeId(id);
    };

    const handleCloseSubjobType = (id) => {
        setManageSubjobType(prevState => ({
            ...prevState,
            [id]: false
        }));
    };


    const AddSubJobType = async () => {
        try {
            if (AddForm.values.sub_job_type && AddForm.values.sub_limit) {
                const response = await postApi('/add-sub-job-type', {
                    job_id: subJobTypeId,
                    sub_job_type: AddForm.values.sub_job_type,
                    sub_limit: AddForm.values.sub_limit
                })
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperef.current.click()
                    AddForm.setFieldValue('sub_job_type', "")
                    AddForm.setFieldValue('sub_limit', "")
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const handleManageSubjobTypeLevel2 = (id, data) => {
        setCheckStatus(data)
        setManageSubjobTypeLevel2(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeIdLevel2(id);

    };

    const handleCloseSubjobTypeLevel2 = (id) => {
        AddForm.setFieldValue('sub_l2_job_type', "")
        AddForm.setFieldValue('sub_l2_limit', "")
        setManageSubjobTypeLevel2(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const handleManageSubjobTypeLevel3 = (id) => {
        setManageSubjobTypeLevel3(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeIdLevel3(id);
    };

    const handleCloseSubjobTypeLevel3 = (id) => {
        setManageSubjobTypeLevel3(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const AddSubJobTypeLevel2 = async () => {
        try {

            if (AddForm.values.sub_l2_job_type && AddForm.values.sub_l2_limit) {
                const data = {
                    sub_job_id: subJobTypeIdLevel2,
                    sub_l2_job_type: AddForm.values.sub_l2_job_type,
                    sub_l2_limit: AddForm.values.sub_l2_limit
                };
                const data1 = {
                    // sub_job_id: subJobTypeIdLevel2,
                    sub_job_type: AddForm.values.sub_l2_job_type,
                    sub_limit: AddForm.values.sub_l2_limit
                };

                const endpoint = checkStatus === 'Add' ? '/add-l2-sub-job-type' : `/edit-sub-job-type/${subJobTypeIdLevel2}`;
                const payload = checkStatus === 'Add' ? data : data1;
                const response = await postApi(endpoint, payload);
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperefLevel2.current.click()
                    AddForm.setFieldValue('sub_l2_job_type', "")
                    AddForm.setFieldValue('sub_l2_limit', "")
                    setSubJobTypeId('')
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const AddSubJobTypeLevel3 = async () => {
        try {
            if (AddForm.values.sub_l3_job_type && AddForm.values.sub_l3_limit) {
                const response = await postApi(`/edit-l2-sub-job-type/${subJobTypeIdLevel3}`, {
                    sub_l2_job_type: AddForm.values.sub_l3_job_type,
                    sub_l2_limit: AddForm.values.sub_l3_limit
                })
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperefLevel3.current.click()
                    // AddForm.setFieldValue('sub_job_type', "")
                    // AddForm.setFieldValue('sub_limit', "")
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header pt-2">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">Manage Job Type and Location</h4>
                </div>
            </div>
            <div className="row">

                <div className="col-md-6 row">
                    <div className="col-md-5">
                        <label>Add Job Type </label>
                        <input
                            type="text"
                            value={newJobType}
                            className="form-control m-0 me-2"
                            placeholder="Enter New Job"
                            onChange={e => setNewJobType(e.target.value)}
                        />
                    </div>

                    <div className="col-md-5">
                        <label>Add Limit </label>
                        <input
                            type="number"
                            value={newJobTypeLimit}
                            className="form-control"
                            placeholder="Enter Employee Limit"
                            onChange={e => setNewJobTypeLimit(e.target.value)}
                        />
                    </div>
                    <div className='col-md-2' style={{ marginTop: '30px' }}>
                        <button
                            type='submit'
                            className="btn btn-primary"
                            onClick={e => {
                                addNewJobType(newJobType)
                            }}
                            disabled={newJobType.replace(/\s/g, "").length == 0}
                        >Add
                        </button>
                    </div>
                </div>

                <div className="col-md-6 row">

                    <div className="col-md-5">
                        <label>Add Location </label>
                        <input
                            type="text"
                            value={addNewJobLocation}
                            className="form-control"
                            placeholder="New Location"
                            onChange={e => setAddNewJobLocation(e.target.value)}
                        />
                    </div>

                    <div className="col-md-5">
                        <label>Location Premium </label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">$</span>
                            <input
                                type="text"
                                value={addNewJobLocationPremium}
                                className="form-control"
                                placeholder="Premium Value"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={e => {
                                    if (e.target.value === '' || /^\d{0,5}$/.test(e.target.value)) {
                                        setAddNewJobLocationPremium(e.target.value);
                                    }
                                }}
                            // onChange={e => {
                            //     setAddNewJobLocationPremium(e.target.value)
                            // }}
                            />

                        </div>
                    </div>
                    <div className='col-md-2' style={{ marginTop: '30px' }}>
                        <button
                            type='submit'
                            className="btn btn-primary"
                            onClick={e => {
                                addJobLocation(addNewJobLocation, addNewJobLocationPremium)
                            }}
                            disabled={addNewJobLocation.replace(/\s/g, "").length == 0}
                        >Add
                        </button>
                    </div>
                </div>

                {/* <div className="col-md-6">
                    <div className="jobtypeBox">
                        <h6 className='text-bold'>Job Type</h6>
                        <ul className="jobtypeUl">
                            {(jobType && jobType.length > 0) && jobType.map((data, index) => {
                                return (
                                    <>
                                        <li key={index}>
                                            {data?.job_type}
                                            <div className='d-flex'>
                                                <span
                                                    className='me-2'
                                                    onClick={() => {
                                                        setEditJobTypeID(data?.id);
                                                        setEditJobType(data?.job_type);
                                                        setOpenEditJobType(!OpenEditJobType)
                                                    }} > <i className='fas fa-edit text-dark'></i></span>

                                                <span onClick={() => HandleDeleteJobType(data?.id)}><i className='fas fa-trash text-danger'></i></span>
                                            </div>
                                        </li>
                                    </>
                                )
                            })}
                            {jobType.length == 0 &&
                                <span className='text-success'>No Data Available</span>}

                        </ul>
                    </div>
                </div> */}



                <div className="col-md-6">
                    <div className="jobtypeBox">
                        <span className='text-bold'>Job Type</span>
                        <div className="accordion" id="accordionExample1">
                            {(jobType && jobType.length > 0) ? <>
                                {jobType.map((data, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header d-flex align-items-center justify-content-between" id={`heading1${index}`}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse1${index}`} aria-expanded="true" aria-controls={`collapse1${index}`}>
                                                <div>
                                                    {data?.job_type}<br />
                                                    Employee Limit: {data?.limit ? data?.limit : "0"}
                                                </div>
                                            </button>

                                            <small /*  className="d-flex align-items-center" */>
                                                <div className='m-1' style={{ fontSize: 'small' }}
                                                    onClick={() => handleManageSubjobType(data?.id)}
                                                                                    /* onClick={() => { setSubJObTypeId(data?.id); setmanageSubjobType(!manageSubjobType) }} */>
                                                    <i className='fas fa-plus text-success' title="Add"></i>
                                                </div>
                                                <div className='m-1' style={{ fontSize: 'small' }}
                                                    onClick={() => { HandleDeleteJobType(data?.id, GetJobType) }}
                                                // onClick={() => HandleDeleteJobType(data?.id)}
                                                >
                                                    <i className='fas fa-trash text-danger' title="Delete"></i>
                                                </div>
                                                <div className='m-1' style={{ fontSize: 'small' }}
                                                    onClick={() => {
                                                        setEditJobTypeID(data?.id);
                                                        setEditJobType(data?.job_type);
                                                        setEditJobTypeLimit(data?.limit);
                                                        setOpenEditJobType(!OpenEditJobType)
                                                    }}
                                                >
                                                    <i className='fas fa-edit text-dark' title="Edit"></i>
                                                </div>
                                            </small>
                                        </h2>
                                        {manageSubjobType[data?.id] &&
                                            <div className='row'>
                                                <div className='col-md-5 m-2'>
                                                    <input type="text" className="form-control" placeholder="Sub Job Type" {...AddForm.getFieldProps("sub_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                </div>
                                                <div className='col-md-4 m-2'>
                                                    <input type="number" className="form-control" placeholder="Emp Limit" {...AddForm.getFieldProps("sub_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                </div>
                                                <div className='col-md-1 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                    {AddForm.values.sub_job_type && AddForm.values.sub_limit ?

                                                        <div type="submit" className='fas fa-plus btn btn-primary'
                                                            onClick={() => AddSubJobType()}
                                                        ></div>
                                                        :
                                                        <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                    <button ref={HandleModalForSubJobTyperef} className='fa fa-close btn btn-danger ms-1'
                                                        onClick={() => handleCloseSubjobType(data?.id)}
                                                    ></button>
                                                </div></div>
                                        }
                                        <div id={`collapse1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading1${index}`} data-bs-parent="#accordionExample1">
                                            <div className="accordion-body" style={{ padding: '10px 0px 0px 10px' }}>
                                                <div className="accordion" id="accordionExample2">
                                                    <div className="accordion-item">

                                                        {data?.subJobType && data.subJobType.map((Subjobtype, index) => {
                                                            return (
                                                                <>
                                                                    <h2 className="accordion-header d-flex align-items-center justify-content-between" id={`heading2${index}`}>
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse2${index}`} aria-expanded="true" aria-controls={`collapse2${index}`}>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className='text-primary'>{Subjobtype?.sub_job_type}<br />
                                                                                    Employee Limit: {Subjobtype?.sub_limit}</span>
                                                                            </div>
                                                                        </button>
                                                                        <div /* className='d-flex align-items-center' */>
                                                                            <div className='m-1' style={{ fontSize: 'small' }}
                                                                                onClick={() => handleManageSubjobTypeLevel2(Subjobtype?.id, "Add")}
                                                                            ><i className='fas fa-plus text-success'></i></div>
                                                                            <div className='m-1' title="Delete" style={{ fontSize: 'small' }}
                                                                                onClick={() => HandleDeleteSubJobType(Subjobtype?.id, GetJobType)}
                                                                            ><i className='fas fa-trash text-danger'></i></div>
                                                                            <div className='m-1' title="Edit" style={{ fontSize: 'small' }}
                                                                                onClick={() => {
                                                                                    handleManageSubjobTypeLevel2(Subjobtype?.id, "Edit");
                                                                                    setSubjobtypeName(Subjobtype?.sub_job_type); setSubjobtypeLimit(Subjobtype?.sub_limit)
                                                                                }}
                                                                            ><i className='fas fa-edit text-dark'></i></div>
                                                                        </div>
                                                                    </h2>

                                                                    {manageSubjobTypeLevel2[Subjobtype?.id] &&
                                                                        <div className='row'>
                                                                            <div className='col-md-5 m-2'>
                                                                                <input type="text" className="form-control" placeholder="Sub Job Type" {...AddForm.getFieldProps("sub_l2_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                            </div>
                                                                            <div className='col-md-4 m-2'>
                                                                                <input type="number" className="form-control" placeholder="Emp Limit" {...AddForm.getFieldProps("sub_l2_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                            </div>
                                                                            <div className='col-md-1 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                                                {AddForm.values.sub_l2_job_type && AddForm.values.sub_l2_limit ?

                                                                                    <div type="submit" className='fas fa-plus btn btn-primary'
                                                                                        onClick={() => AddSubJobTypeLevel2()}></div>
                                                                                    :
                                                                                    <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                                                <button ref={HandleModalForSubJobTyperefLevel2} className='fa fa-close btn btn-danger ms-1'
                                                                                    onClick={() => handleCloseSubjobTypeLevel2(Subjobtype?.id)}
                                                                                ></button>
                                                                            </div></div>
                                                                    }
                                                                    <div id={`collapse2${index}`} className="accordion-collapse collapse" aria-labelledby={`heading2${index}`} data-bs-parent="#accordionExample2">
                                                                        <div className="accordion-body">
                                                                            {Subjobtype.subL2JobType && Subjobtype.subL2JobType.map((LevelTwo, index) => {
                                                                                // console.log("dhffgfdperthdj5289720957", LevelTwo)
                                                                                return (
                                                                                    <>
                                                                                        <div key={index} className='d-flex justify-content-between' style={{ padding: '0px 0px 0px 30px' }}>

                                                                                            <span className='text-success'>{LevelTwo?.sub_l2_job_type}<br />
                                                                                                Employee Limit: {LevelTwo?.sub_l2_limit}</span>
                                                                                            <div /* className='d-flex' */>

                                                                                                <div className='m-1' style={{ fontSize: 'small' }} title="Delete"
                                                                                                    onClick={() => HandleDeleteSubJobTypeLevel2(LevelTwo?.id, GetJobType)}
                                                                                                > <i className='fas fa-trash text-danger me-2'></i></div>
                                                                                                <div className='m-1' style={{ fontSize: 'small' }} title="Edit"
                                                                                                    onClick={() => {
                                                                                                        handleManageSubjobTypeLevel3(LevelTwo?.id, "Edit");
                                                                                                        setL2SubjobtypeName(LevelTwo?.sub_l2_job_type); setL2SubjobtypeLimit(LevelTwo?.sub_l2_limit)
                                                                                                    }}
                                                                                                ><i className='fas fa-edit text-dark'></i></div></div>
                                                                                        </div>
                                                                                        {manageSubjobTypeLevel3[LevelTwo?.id] &&
                                                                                            <div className='row'>
                                                                                                <div className='col-md-5 mt-1'>
                                                                                                    <input type="text" className="form-control" placeholder="Sub Job Type"  {...AddForm.getFieldProps("sub_l3_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                </div>
                                                                                                <div className='col-md-4 mt-1'>

                                                                                                    <input type="number" className="form-control" placeholder="Emp Limit"  {...AddForm.getFieldProps("sub_l3_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                </div>
                                                                                                <div className='col-md-3 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                                                                    {AddForm.values.sub_l3_job_type && AddForm.values.sub_l3_limit ?

                                                                                                        <div type="submit" className='fas fa-plus btn btn-primary'
                                                                                                            onClick={() => AddSubJobTypeLevel3()} ></div> :
                                                                                                        <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                                                                    <button ref={HandleModalForSubJobTyperefLevel3} className='fa fa-close btn btn-danger ms-1' onClick={() => handleCloseSubjobTypeLevel3(LevelTwo?.id)}></button>
                                                                                                </div></div>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                            )}




                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}</> :
                                <div >No Job Type</div>
                            }
                        </div>
                    </div>
                </div>











                <div className="col-md-6">
                    <div className="jobtypeBox">
                        <h6 className=''>Location</h6>

                        <div className="accordion" id="accordionExample">
                            {(location && location.length > 0) && location.map((item, index) => {
                                return (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header d-flex align-items-center justify-content-between" id={`heading${index}`}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                <div>
                                                    {item.job_location}
                                                    <br />
                                                    <span className='text-success'>Premium value: ${item?.premium_value ? item?.premium_value : '0'}</span>
                                                </div>
                                            </button>
                                            <small>
                                                <div className="m-1" style={{ fontSize: 'small' }}
                                                    onClick={() => { SetLocationID(item?.id); setAddSubLocationOpenModals(true) }}
                                                ><i className="fas fa-add text-success"></i></div>
                                                <div className='m-1' style={{ fontSize: 'small' }}
                                                    onClick={() => HandleDeleteJobLocation(item?.id, GetTypeLocation)}
                                                ><i className='fas fa-trash text-danger' /></div>
                                                <div className="m-1" style={{ fontSize: 'small' }}
                                                    onClick={() => {
                                                        setEditLocationbyId(item?.id); setEditLocation(item?.job_location);
                                                        setEditLocationPremium(item?.premium_value); setOPenEditLocationModal(true)

                                                    }}
                                                ><i className="fas fa-edit text-primary"></i></div>

                                            </small>
                                        </h2>
                                        <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                {item?.subLocations.map((view, id) => {
                                                    return (
                                                        <div key={id} className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                            {view?.sub_location !== "" ? (
                                                                <>{view.sub_location}: &nbsp;
                                                                </>
                                                            ) : (
                                                                <span className='text-primary'>"No Data"</span>
                                                            )}
                                                            {view?.premium_value ? "$" + view.premium_value : "$0"}
                                                            <div className='d-flex '>
                                                                <span className='fas fa-trash text-danger mx-2'
                                                                    onClick={() => HandleDeleteSubJobLocation(view?.id, GetTypeLocation)}
                                                                />
                                                                <span className='fas fa-edit text-dark'
                                                                    onClick={() => { setEditSubLocationID(view?.id); setEditSubLocationPremuim(view?.premium_value); setEditSubLocation(view?.sub_location); setOpenSubLocationModals(true) }}
                                                                > </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {location.length == 0 &&
                                <span className='text-success'>No Data Available</span>}
                        </div>



                    </div>
                </div>
            </div>


            {/******************************* Edit Job Type Modal Start *********************/}
            {OpenEditJobType &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Job type</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Job Type</label>
                                            <input
                                                type="text"
                                                value={editJobType}
                                                className="form-control m-0 me-2"
                                                placeholder="Enter New Job"
                                                onChange={e => setEditJobType(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Employee Limit</label>
                                            <input
                                                type="number"
                                                value={editJobTypeLimit}
                                                className="form-control m-0 me-2"
                                                placeholder="Enter New Job"
                                                onChange={e => setEditJobTypeLimit(e.target.value)}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpenEditJobType(false)
                                        }}
                                    >Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={e => {
                                            editOldJobType(editJobType, editJobTypeLimit)
                                        }}
                                        disabled={editJobType.replace(/\s/g, "").length == 0}
                                    >Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }

            {/******************************* Edit Job Type Modal End *********************/}

            {/******************************* Add Sub Location Modal Start *********************/}

            {addSubLocationOpenModals &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Add Sub-Location</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <input
                                        type="text"
                                        value={sublocation}
                                        className="form-control mt-2 me-2"
                                        placeholder="Enter Sub Location"
                                        onChange={e => setSublocation(e.target.value)}
                                    />

                                    <input
                                        type="text"
                                        value={sublocationPremuim}
                                        className="form-control me-2"
                                        placeholder="Enter Premium Value"
                                        // onChange={e => setSublocationPremuim(e.target.value)}
                                        onChange={e => {
                                            if (e.target.value === '' || /^\d{0,5}$/.test(e.target.value)) {
                                                setSublocationPremuim(e.target.value);
                                            }
                                        }}
                                    />

                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setAddSubLocationOpenModals(false)
                                        }}
                                    >Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={e => {
                                            addSubJobLocation(sublocation, sublocationPremuim)
                                        }}
                                        disabled={sublocation.replace(/\s/g, "").length == 0}
                                    >Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            {/******************************* Add Sub Location Modal End *********************/}

            {/******************************* Edit Sub Location Modal Start ******************/}

            {OpenSubLocationModals &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Add Sub-Location</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <input
                                        type="text"
                                        value={editSubLocation}
                                        className="form-control mt-2 me-2"
                                        placeholder="Enter Sub Location"
                                        onChange={e => setEditSubLocation(e.target.value)}
                                    />

                                    {/* <input
                                        type="text"
                                        value={editSubLocationPremuim}
                                        className="form-control me-2"
                                        placeholder="Enter Premium Value"
                                        onChange={e => {
                                             if (e.target.value === '' || /^\d{0,5}$/.test(e.target.value)) {
                                            setEditSubLocationPremuim(e.target.value);
                                             }
                                        }}


                                    /> */}
                                    <input
                                        type="number"
                                        value={editSubLocationPremuim}
                                        className="form-control me-2"
                                        placeholder="Enter Premium Value"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value === '' || value.replace(/\s/g, "").length > 0) {
                                                setEditSubLocationPremuim(value);
                                            }
                                        }}
                                    />

                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpenSubLocationModals(false)
                                        }}
                                    >Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={e => {
                                            HandleEditSubLocation(editSubLocation, editSubLocationPremuim)
                                        }}
                                        disabled={editSubLocation.replace(/\s/g, "").length == 0}
                                    >Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }

            {/******************************* Edit Sub Location Modal End ******************/}

            {/******************************* Edit Location Modal Satrt ******************/}

            {oPenEditLocationModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Location</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <input
                                        type="text"
                                        value={editLocation}
                                        className="form-control mt-2 me-2"
                                        placeholder="Enter Location"
                                        onChange={e => setEditLocation(e.target.value)}
                                    />

                                    {/* <input
                                        type="number"
                                        value={editLocationPremium}
                                        className="form-control me-2"
                                        placeholder="Enter Premium Value"
                                        onChange={e =>
                                            setEditLocationPremium(e.target.value)}
                                    /> */}

                                    <input
                                        type="number"
                                        value={editLocationPremium}
                                        className="form-control me-2"
                                        placeholder="Enter Premium Value"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value === '' || value.replace(/\s/g, "").length > 0) {
                                                setEditLocationPremium(value);
                                            }
                                            // if (e.target.value === '' || /^\d{0,5}$/.test(e.target.value)) {
                                            //     setEditLocationPremium(e.target.value);
                                            // }
                                        }}
                                    />


                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOPenEditLocationModal(false)
                                        }}
                                    >Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={e => {
                                            HandleEditLocation(editLocation, editLocationPremium)
                                        }}
                                        disabled={editLocation.replace(/\s/g, "").length == 0}
                                    >Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }

            {/******************************* Edit Location Modal End ******************/}

        </div>
    )
}

export default Jobtypelocation