import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import { GetEmployeeTransactionDetail, getApi } from '../../service/adminService'
import Loader from '../../../components/Loader/loader'
import { TimeZone } from '../../../components/TimeZone/TimeZone'
import PaginationComponent from '../../../PaginationComponent'

const TxnDetail = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const admin = JSON.parse(localStorage.getItem("admin"));


    const getEmployeeTransactionDetail = async (token) => {
        // const response = await GetEmployeeTransactionDetail(token, id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/borrower-transaction", { c_id: id, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        getEmployeeTransactionDetail(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">

                    <h6 className="page-title mb-0 text-primary">Borrower TXN Detail</h6>

                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-borrower-transactions?c_id=${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN ID   {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                            <th className="font-weight-bold">Paid Amount {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }}></i>} </th>
                                            <th className="font-weight-bold">Settled Amount {sortedBy == "settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('settled_amount') }}></i>}</th>
                                            <th className="font-weight-bold">Merchant Comm {sortedBy == "mrch_commission" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }}></i>}</th>
                                            <th className="font-weight-bold">Lender Comm {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }}></i>}</th>
                                            <th className="font-weight-bold">Thirdroc Income {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }}></i>}</th>
                                            <th className="font-weight-bold">TXN Charges {sortedBy == "txn_charge" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_charge') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_charge') }}></i>}</th>
                                            {/* <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }}></i>}</th> */}
                                            <th className="font-weight-bold">TXN From  {sortedBy == "txn_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_from') }}></i>} </th>
                                            {/* <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} </th> */}
                                            <th className="font-weight-bold">TXN Date   {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { txn_id, paid_amount, settled_amount, comment, txn_from, status, updated_at } = data;

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td><span className="font-weight-normal1"> {txn_id ? txn_id : "NA"} </span>
                                                                <span>
                                                                    <CopyToClipboard text={txn_id} >
                                                                        <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td>{data.paid_amount ? "$" + paid_amount : "NA"}</td>
                                                            <td>{data.settled_amount ? "$" + settled_amount : "NA"}</td>
                                                            {/* <td>{data.ewaSummary == null ? (data?.bnplSummary?.mrch_commission ? "$"+ data?.bnplSummary?.mrch_commission : "NA") : (data?.ewaSummary?.mrch_commission ? "$"+ data?.ewaSummary?.mrch_commission : "NA")}</td> */}
                                                            {data.walletSummary != null ? <td>{data?.walletSummary ? "$" + data.walletSummary?.mrch_commission : "-"}</td> :
                                                                <td>{data.ewaSummary == null ? (data.bnplSummary?.mrch_commission ? "$" + data.bnplSummary?.mrch_commission : "-") : (data.ewaSummary?.mrch_commission ? "$" + data.ewaSummary?.mrch_commission : "-")}</td>
                                                            }
                                                            <td>{data.ewaSummary == null ? (data.bnplSummary?.lender_commission_amount ? "$" + data.bnplSummary?.lender_commission_amount : "-") : "-"}</td>
                                                            {data.walletSummary != null ? <td>{data?.walletSummary ? "$" + data.walletSummary?.thirdroc_income : "-"}</td> :
                                                                <td>{data.ewaSummary == null ? (data.bnplSummary?.thirdroc_income ? "$" + data.bnplSummary?.thirdroc_income : "-") : (data.ewaSummary?.thirdroc_income ? "$" + data.ewaSummary?.thirdroc_income : "-")}</td>}
                                                            {data.walletSummary != null ? <td>{data?.walletSummary ? "$" + data.walletSummary?.txn_charge : "-"}</td> :
                                                                <td>{data.bnplSummary == null ? "$" + data.ewaSummary?.txn_charge : "-"}</td>}
                                                            {/* <td>{data.comment ? comment : "NA"}</td> */}
                                                            <td>{data.txn_from ? txn_from : "NA"}</td>
                                                            {/* <td>{data.status ? status : "NA"}</td> */}
                                                            <td>{TimeZone(updated_at).timeZone}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default TxnDetail
