import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Image } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import Loader from '../../components/Loader/loader'
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../PaginationComponent';
import { getApi, postApi } from '../service/adminService';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Limitfilter from '../../components/Limitfilter/Limitfilter';
import Search from '../../components/Search/Search';

const TransferRequest = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const S3_prefix = process.env.REACT_APP_S3_BUCKET_URL
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Entrieslimit, setEntrieslimit] = useState("10")
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const [disable, setDisable] = useState(false);
    const [sortedBy, setSortedBy] = useState("desc")
    const [orderBy, setOrderBy] = useState('id')
    const [imageUpload, setimageUpload] = useState(null)
    const [customer_id, setCustomer_id] = useState()
    const [totalItems, settotalItems] = useState(0);
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)

    const admin = JSON.parse(localStorage.getItem("admin"));

    const getTransferRequest = async (token) => {
        // const response = await GetTransferRequest(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/user-bank_tranfer_request", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit });

        // console.log("fdgdgdgddfgdg", response.data.length)
        if (response.status == 'success') {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.Allplans);
                setCount(response.Allplans);
                settotalItems(response.Allplans)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }


    useEffect(() => {
        // setpagesCount(1);
        getTransferRequest(admin.admin_token);

    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit]);

    const Form = useFormik({
        initialValues: {
            id: customer_id,
            payment_mode: "",
            comment: "",
            status: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            comment: yup.string().required('Please Add a Comment'),
            // payment_mode: yup.string().required('Please add a payment mode '),
            status: yup.string().required('Please Add a Status Mode '),
        }),

        onSubmit: async (values, { resetForm }) => {
            // console.log(multippleArray, typeof (multippleArray), "??????????????????????##############3")
            const formData = new FormData();
            formData.append("id", customer_id);
            formData.append("payment_mode", Form.getFieldProps('payment_mode').value);
            formData.append("comment", Form.getFieldProps('comment').value);
            formData.append("status", Form.getFieldProps('status').value);
            formData.append("image", imageUpload);
            // console.log(multippleArray)            
            /* UpdateTransferStatus(admin.admin_token, formData) */
            postApi("/update-transfer-status", formData, true).then((res) => {
                if (res.status == true) {
                    getTransferRequest(admin.admin_token);
                    toast.success(res.message);
                    ref.current.click()
                    resetForm({ values: "" });
                    setimageUpload("");
                }
            }).catch((err) => {

            })
        }
    })
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }


    const handleInputChange = (event) => {
        Form.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }
    const copiedInfo = () => {
        toast.success("Copied")
    }



    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Transfer Request  {totalItems ? '(' + totalItems + ')' : ''}  </h4>

                            </div>
                            {/* <div className='page-rightheader'>
                                <button className='btn btn-primary' data-bs-target="#add_settlement"
                                    data-bs-toggle="modal">+ Add Settlement</button>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap"/*  ref={tableRef} */>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">TXN Docs </th>
                                                        <th className="font-weight-bold">TXN ID  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                                        <th className="font-weight-bold">Customer Details  {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Total Amount  {sortedBy == "total_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Actual Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">TXN Charges  {sortedBy == "txn_charge" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }}></i>}</th>
                                                        <th className="font-weight-bold">Acc Details   {sortedBy == "account_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('account_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('account_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Account No.  {sortedBy == "account_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('account_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('account_number') }}></i>}</th>
                                                        <th className="font-weight-bold">Swift Code  {sortedBy == "swift_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('swift_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('swift_code') }}></i>}</th>
                                                        <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>}</th>
                                                        <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                                        <th className="font-weight-bold">Action/Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                        <th className="font-weight-bold">Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { amount, customer_id, full_name, mobile_number, txn_id, account_name, account_number, comment, payment_mode, total_amount, bank_name, account_type, txn_docs, txn_charge, swift_code, status, created_at } = option;


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {option.txn_docs != null ?
                                                                            <td><span className="font-weight-normal1">
                                                                                <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={option.txn_docs} alt='' /></span></td>
                                                                            : <td><span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></span></td>
                                                                        }
                                                                        <td><span className="font-weight-normal1"> {txn_id ? txn_id : "--"} </span>
                                                                            <span>
                                                                                <CopyToClipboard text={txn_id} >
                                                                                    <button className='btn btn-primary btn-sm ms-0' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                                </CopyToClipboard>
                                                                            </span>
                                                                        </td>
                                                                        <td><Link to={{ pathname: `${prefix}/employeedetails/${customer_id}`, state: { customer_id } }} >
                                                                            <span className='font-weight-normall text-primary'>{full_name ? full_name : "--"}<div>
                                                                                {mobile_number ? mobile_number : ""}</div></span></Link></td>
                                                                        <td><span className="font-weight-normal1"> {total_amount ? "$" + total_amount : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1 text-success"> {amount ? "$" + amount : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1">{txn_charge ? "$" + txn_charge : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1 ">{account_name ? account_name : "--"}
                                                                            <br />{bank_name ? bank_name : ""}
                                                                            <br />{account_type ? account_type : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{account_number ? account_number : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1"><span className="content_fix" >{swift_code ? swift_code : "--"}</span> </span></td>
                                                                        <td><span className="font-weight-normal1">{payment_mode ? payment_mode : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1">{comment ? comment : "--"} </span></td>
                                                                        {/* <td><span className="font-weight-normal1">{status ? status : "NA"} </span></td> */}
                                                                        <td><span className='font-weight-normal1'>
                                                                            {status === "IN_PROCESS" ?
                                                                                <button className='btn btn-primary'
                                                                                    data-bs-target="#add_settlement"
                                                                                    onClick={() => setCustomer_id(option.id)}
                                                                                    data-bs-toggle="modal">Update
                                                                                </button> : <span>{status == "SUCCESS" ? <span className="text-success" style={{ fontSize: "medium" }}>Transferred</span>
                                                                                    : <span className="text-danger" style={{ fontSize: "medium" }}>Failed</span>}</span>}
                                                                        </span>
                                                                        </td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(created_at).timeZone}</span></td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>

                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Update Request</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                {/* <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                    <h4> {totalAmoumt ? `Total: ${"$" + totalAmoumt.toFixed(2)}` : ""}</h4>
                                </div> */}
                                <div className="modal-body application-modal">

                                    <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                        {Form.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    <select className="form-control" name="payment_mode"
                                                        {...Form.getFieldProps("payment_mode")}
                                                        placeholder="Enter Payment Mode"  >
                                                        <option value="">Select Payment Mode</option>
                                                        <option value="cash" >Cash</option>
                                                        <option value="cheque">Cheque</option>
                                                        <option value="bank transfer">Bank Transfer</option>
                                                    </select>
                                                    {/* {Form.touched.payment_mode && Form.errors.payment_mode ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.payment_mode}</div> : ''} */}
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" /* onKeyUp={(e) => handleInputChange(e)} */ placeholder='comment' {...Form.getFieldProps("comment")} className="form-control" />
                                                    {Form.touched.comment && Form.errors.comment ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.comment}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <select className="form-control" name="status"
                                                        {...Form.getFieldProps("status")}
                                                        placeholder="Select Status"  >
                                                        <option value="null">Select Status</option>
                                                        <option value="FAILED" >Cancelled</option>
                                                        <option value="SUCCESS">Success</option>
                                                    </select>
                                                    {Form.touched.status && Form.errors.status ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.status}</div> : ''}
                                                </div>
                                                <div className="input-group file-browser mb-5">
                                                    <input type="file" onKeyUp={(e) => handleInputChange(e)} className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                                </div>
                                                {/* {Form.touched.image && Form.errors.image ?
                                                 <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.image}</div> : ''} */}
                                            </div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true" /* disabled={!disable ? true : false} */>
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }

        </>
    )
}

export default TransferRequest


