import React from 'react'
import Header from './header'
import Footer from './footer'

const EWA = () => {
    return (
        <>
            <Header />

            <section className="section-padding section-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 margin-md-60">
                            <h1 className="display-3 mb-0 fweight">Earned Wage Access with Thirdroc</h1>
                            <p className="mb-3">Flexible wages empower employees and increase their loyalty to employers. Early access to earned pay boosts financial control and retention.</p>
                        </div>
                        <div className="col-lg-5 offset-lg-1"><img src="website/assets/img/ewa-banner.png" alt="" className="" /></div>
                    </div>
                </div>
            </section>


            <section className="services bg-gradient--light p-top-100 p-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="icon-boxes icon-box--sixteen col-lg-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox">
                                        <img src="website/assets/img/service7.png" alt="" />
                                        <h6>Help your staff save <br /> money</h6>
                                        <p>By providing early access to wages, employers can deter employees from resorting to payday loans or high-interest credit cards.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox">
                                        <img src="website/assets/img/service8.png" alt="" />
                                        <h6>Zero cost to <br />Employers</h6>
                                        <p>Unlock the potential of your employees by offering costfree financial wellness solutions that empower them to succeed- at no cost to you!</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox">
                                        <img src="website/assets/img/service9.png" alt="" />
                                        <h6>Empower your staff to <br /> budget</h6>
                                        <p>By providing employees with enhanced financial visibility, they gain the ability to effectively budget for the month and strategically plan for the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section-bg p-top-100 p-bottom-20">
                <div className="icon-boxes icon-box--four">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="icon-box-four d-flex mideelHeadigBox">
                                    <div className="box-content text-center">
                                        <h6>Easy Setup</h6>
                                        <p>Get instant access to our Workwell program for your employees with our state-of-theart software built using modern technologies</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="icon-box-four d-flex mideelHeadigBox">
                                    <div className="box-content text-center">
                                        <h6>Employee Training and Communication</h6>
                                        <p>We can provide you with the necessary tools and training you need to effectively communicate and roll out these services</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="icon-box-four d-flex mideelHeadigBox">
                                    <div className="box-content text-center">
                                        <h6>Continuous Support and Maintenance</h6>
                                        <p>Our team is always available to provide ongoing support and maintenance to ensure that your program is running seamlessly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="p-top-80 p-bottom-80 bg-white">
                <div className="accordion-styles accordion--two EWAPage">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-5">
                                    <div className="divider text-center">
                                        <h1>Frequently Asked Questions</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 margin-md-60">
                                <div className="accordion accordion_four" id="accordion_4_2">
                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading1">
                                            <h6 className="mb-0">
                                                <a href="#" className="" data-toggle="collapse" data-target="#accordion_4_2_collapse1" aria-expanded="true" aria-controls="accordion_4_2_collapse1">
                                                    What is earned wage access?
                                                </a>
                                            </h6>
                                        </div>

                                        <div id="accordion_4_2_collapse1" className="collapse show" aria-labelledby="accordion_4_2_heading1" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>Earned wage access is a financial benefit that allows employees to access a portion of their earned wages before payday. It can help employees avoid high-interest loans and overdraft fees, and improve their financial stability.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading2">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse2" aria-expanded="false" aria-controls="accordion_4_2_collapse2">
                                                    How does earned wage access work?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse2" className="collapse" aria-labelledby="accordion_4_2_heading2" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>Employers partner with earned wage access providers to offer the benefit to their employees. Employees can
                                                    typically access up to a certain percentage of their earned wages for a small fee, which is deducted from
                                                    their next paycheck. The process is quick, easy, and can be done through a mobile app.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading3">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse3" aria-expanded="false" aria-controls="accordion_4_2_collapse3">
                                                    What is BNPL?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse3" className="collapse" aria-labelledby="accordion_4_2_heading3" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>Buy Now Pay Later (BNPL) is a payment option that allows customers to purchase items and pay for them in installments, typically over 6-8 weeks. BNPL providers often partner with retailers to offer this payment
                                                    option at checkout.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading4">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse4" aria-expanded="false" aria-controls="accordion_4_2_collapse4">
                                                    How does BNPL work?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse4" className="collapse" aria-labelledby="accordion_4_2_heading4" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>When a customer selects BNPL at checkout, they are typically required to provide some personal and financial information for approval. If approved, the purchase is completed and the customer is able to pay for
                                                    the item in installments without paying interest.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading5">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse5" aria-expanded="false" aria-controls="accordion_4_2_collapse5">
                                                    What is ThirdRoc's credit scoring process?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse5" className="collapse" aria-labelledby="accordion_4_2_heading5" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>ThirdRoc's credit scoring process is a data-driven approach to assessing credit risk for individuals and
                                                    businesses. We use a variety of factors, including payment history, credit utilization, and length of credit
                                                    history, to generate a credit score.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading6">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse6" aria-expanded="false" aria-controls="accordion_4_2_collapse6">
                                                    How does ThirdRoc's credit scoring process differ from traditional credit scoring methods?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse6" className="collapse" aria-labelledby="accordion_4_2_heading6" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>ThirdRoc's credit scoring process is designed to be more comprehensive and inclusive than traditional credit
                                                    scoring methods. We consider a broader range of factors, including financial wellness and income stability, to provide a more accurate and holistic assessment of credit risk.</p>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <img src="website/assets/img/access.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <Footer />
        </>
    )
}

export default EWA