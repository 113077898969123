import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import moment from 'moment';
import { deleteApi, getApi, GetPermission, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import PaginationComponent from '../../../../PaginationComponent';


const Employeeshift = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [employee, setEmployee] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    // const [sortedBy, setSortedBy] = useState("updatedAt")
    // const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const gridRef = useRef();
    const ref3 = useRef()
    const refCreateModalClose = useRef()
    const [ID, setId] = useState({})
    const [shiftStartTime, setshiftStartTime] = useState("")
    const [shiftEndTime, setShiftEndTime] = useState("")
    const [shiftName, setShiftName] = useState("")
    const [breakDuration, setBreakDuration] = useState("")
    const [breakTime, setBreakTime] = useState("")
    const [breakDurationCount, setBreakDurationCount] = useState([
        {
            key: '15min',
            value: 15
        },
        {
            key: '30min',
            value: 30
        },
        {
            key: '45min',
            value: 45
        },
        {
            key: '1hour',
            value: 60
        },

    ]);
    const navigate = useNavigate();
    const [permission_data, setPermission_data] = useState([])
    const [complianceDetail, setComplianceDetail] = useState([])
    const [restInterval, setRestInterval] = useState('')

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const GetShiftDetail = async (token) => {
        const response = await getApi("/get-all-shift",);
        console.log("🚀  file: epmloyer.js:25  GetShiftDetail ~ response:", response)
        try {
            if (response.status == true) {
                setTimeout(() => {
                    setEmployee(response.data);
                    setDataArray(response.data.rows);
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    const [employee_view, setemployee_view] = useState(false)
    const [employee_upload_bulk, setemployee_upload_bulk] = useState(false)
    const [employee_add, setemployee_add] = useState(false)
    const [employee_edit, setemployee_edit] = useState(false)
    const [employee_delete, setemployee_delete] = useState(false)
    useEffect(() => {
        if (role) {
            // console.log(role, "role")
            const options = role;
            // console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                // console.log(option, "option")
                if (option.key == "employee_view") {
                    setemployee_view(true);
                }
                if (option.key == "employee_upload_bulk") {
                    setemployee_upload_bulk(true);
                }
                if (option.key == "employee_add") {
                    setemployee_add(true);
                }
                if (option.key == "employee_edit") {
                    setemployee_edit(true);
                }
                if (option.key == "employee_delete") {
                    setemployee_delete(true);
                }
            });
        }
    }, [role]);

    const onBtnExport = useCallback(() => {
        gridRef.dataArray.exportDataAsCsv();
    }, []);


    useEffect(() => {
        // setpagesCount(1);
        GetShiftDetail(employer.employer_token);
    }, []);

    const AddForm = useFormik({
        initialValues: {
            shift_name: "",
            shift_start_time: "",
            shift_end_time: "",
            break_minutes: "",
            break_start_time: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            shift_name: yup.string().required('Please Enter Shift Name'),
            shift_start_time: yup.string().required('Please Enter Shift Start Time'),
            // shift_end_time: yup.string().required('Please Enter Shift End Time'),
            shift_end_time: yup.string()
                .required('Please Enter Shift End Time')
                .test('max-time', `Shift end time cannot be more than ${complianceDetail} hours`, function (value) {
                    const complianceValue = complianceDetail * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }),
            break_minutes: yup.string().required('Please select Break Duration'),
            break_start_time: yup.string().required('Please select Break Start Time')
                .test('interval-time', `Break Start Time should be within ${restInterval} hours from start time`, function (value) {
                    const complianceValue = restInterval * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }),

        }),
        onSubmit: async (values, { resetForm }) => {
            // console.log('values', values);
            const response = await postApi('/add-bulk-shift', {
                bulkShift: [
                    {
                        shift_name: values.shift_name,
                        shift_start_time: values.shift_start_time,
                        shift_end_time: values.shift_end_time,
                        break_minutes: values.break_minutes,
                        break_start_time: values.break_start_time
                    }
                ]
            })

            // console.log(response)
            if (response.status == true) {
                toast.success(response.message);
                resetForm({ values: '' });
                refCreateModalClose.current.click()
                GetShiftDetail(employer.employer_token);
            } else if (response.status == false) {
                toast.error(response.message);
            }
        }
    });
    const EditForm = useFormik({
        initialValues: {
            shift_name: shiftName ? shiftName : "",
            shift_start_time: shiftStartTime ? shiftStartTime : "",
            shift_end_time: shiftEndTime ? shiftEndTime : "",
            break_minutes: breakDuration ? breakDuration : "",
            break_start_time: breakTime ? breakTime : "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            shift_name: yup.string().required('Please Enter Shift Name'),
            shift_start_time: yup.string().required('Please Enter Shift Start Time'),
            // shift_end_time: yup.string().required('Please Enter Shift End Time'),           
            shift_end_time: yup.string()
                .required('Please Enter Shift End Time')
                .test('max-time', `Shift end time cannot be more than ${complianceDetail} hours`, function (value) {
                    const complianceValue = complianceDetail * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }),
            break_minutes: yup.string().required('Please select Break Duration'),
            // break_start_time: yup.string().required('Please select Break Start Time')
            break_start_time: yup.string().required('Please select Break Start Time')
                .test('interval-time', `Break Start Time should be within ${restInterval} hours maximum`, function (value) {
                    const complianceValue = restInterval * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }),

        }),
        onSubmit: async values => {
            const response = await postApi(`/edit-shift/${ID}`, {
                shift_name: values.shift_name,
                shift_start_time: values.shift_start_time,
                shift_end_time: values.shift_end_time,
                break_start_time: values.break_start_time,
                break_minutes: values.break_minutes
            })

            if (response.status == true) {
                toast.success(response.message);
                ref3.current.click()
                GetShiftDetail(employer.employer_token);
                window.$('#assign-rm').modal('hide')
            } else if (response.status == false) {
                toast.error(response.message);
                window.$('#assign-rm').modal('hide')
            }
        }
    });



    const adjustTime = (timeStr, minutes) => {
        let [hours, oldMinutes] = timeStr.split(':').map(Number);
        let newMinutes = oldMinutes + minutes;
        if (newMinutes >= 60) {
            newMinutes -= 60;
            hours++;
        } else if (newMinutes < 0) {
            newMinutes += 60;
            hours--;
        }

        // Ensure hours stay within 0-23 range
        hours = (hours + 24) % 24;

        // Format the adjusted time string
        return `${hours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    }

    const getTimeInMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const handleBreakTime = (e) => {
        const minTime = EditForm.values.shift_start_time;
        const maxTime = EditForm.values.shift_end_time;
        const adjustedMinTime = adjustTime(minTime, 1);
        const adjustedMaxTime = adjustTime(maxTime, -(EditForm.values.break_minutes));

        const { value } = e.target;
        const [hours, minutes] = value.split(':').map(Number);
        const selectedTimeInMinutes = hours * 60 + minutes;
        const minTimeInMinutes = getTimeInMinutes(adjustedMinTime);
        const maxTimeInMinutes = getTimeInMinutes(adjustedMaxTime);
        if (selectedTimeInMinutes < minTimeInMinutes || selectedTimeInMinutes > maxTimeInMinutes) {
            EditForm.setFieldValue('break_start_time', "");
            EditForm.setFieldValue('finderror', "Break Start Time Should be between Shift Start and End Time");
        } else {
            EditForm.setFieldValue('finderror', "");

            EditForm.setFieldValue('break_start_time', value);
        }
    }

    const handleendshifttime = (event) => {
        const { value } = event.target;
        const startTime = EditForm.values.shift_start_time;
        const [selectedHours, selectedMinutes] = value.split(':').map(Number);
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const selectedTotalMinutes = selectedHours * 60 + selectedMinutes;
        const startTotalMinutes = startHours * 60 + startMinutes;
        let endTotalMinutes = selectedTotalMinutes;
        if (endTotalMinutes < startTotalMinutes) {
            endTotalMinutes += 24 * 60;
        }
        if (endTotalMinutes - startTotalMinutes >= 61 && endTotalMinutes < 24 * 60) {
            EditForm.setFieldValue("shift_end_time", value);
        } else {
            EditForm.setFieldValue("shift_end_time", "");
        }
    }

    const handleBreakTimeCreate = (e) => {
        const minTime = AddForm.values.shift_start_time;
        const maxTime = AddForm.values.shift_end_time;
        const adjustedMinTime = adjustTime(minTime, 1);
        const adjustedMaxTime = adjustTime(maxTime, -(AddForm.values.break_minutes));

        const { value } = e.target;
        const [hours, minutes] = value.split(':').map(Number);
        const selectedTimeInMinutes = hours * 60 + minutes;
        const minTimeInMinutes = getTimeInMinutes(adjustedMinTime);
        const maxTimeInMinutes = getTimeInMinutes(adjustedMaxTime);
        if (selectedTimeInMinutes < minTimeInMinutes || selectedTimeInMinutes > maxTimeInMinutes) {
            AddForm.setFieldValue('break_start_time', "");
            AddForm.setFieldValue('finderror', "Break Start Time Should be between Shift Start and End Time");
        } else {
            AddForm.setFieldValue('finderror', "");

            AddForm.setFieldValue('break_start_time', value);
        }
    }

    const handleendshifttimeCreate = (event) => {
        const { value } = event.target;
        const startTime = AddForm.values.shift_start_time;
        const [selectedHours, selectedMinutes] = value.split(':').map(Number);
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const selectedTotalMinutes = selectedHours * 60 + selectedMinutes;
        const startTotalMinutes = startHours * 60 + startMinutes;
        let endTotalMinutes = selectedTotalMinutes;
        if (endTotalMinutes < startTotalMinutes) {
            endTotalMinutes += 24 * 60;
        }
        if (endTotalMinutes - startTotalMinutes >= 61 && endTotalMinutes < 24 * 60) {
            AddForm.setFieldValue("shift_end_time", value);
        } else {
            AddForm.setFieldValue("shift_end_time", "");
        }
    }

    const HandleDeleteShift = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteApi(`/delete-shift/${id}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Shift has been deleted Successfully.',
                        'success'
                    )
                    GetShiftDetail(employer.employer_token);
                    console.log(response.message)
                    toast.success(response.message)
                }
                else {
                    toast.error(response.message)
                }
            }
        })
    }

    useEffect(() => {
        if (EditForm.values.shift_start_time > EditForm.values.break_start_time ||
            EditForm.values.shift_end_time < EditForm.values.break_start_time
        ) {
            EditForm.setFieldValue('break_start_time', '')
            // EditForm.setFieldValue('break_minutes', '')
        }
        // EditForm.setErrors({})
        // EditForm.setTouched({})

    }, [EditForm.values.shift_start_time, EditForm.values.shift_end_time])

    useEffect(() => {
        AddForm.setFieldValue('break_start_time', '')
        AddForm.setFieldValue('break_minutes', '')
        AddForm.setErrors({})
        AddForm.setTouched({})

    }, [AddForm.values.shift_start_time, AddForm.values.shift_end_time])

    const customOffcanvasStyle = {
        width: "350px",
        height: "400px",
        marginLeft: 'auto',
        // position: "fixed",
        bottom: "20px",
        right: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        overflow: "hidden",
        backgroundColor: "white",
        zIndex: 1050,
        transition: "transform 0.3s ease-in-out"


    };

    const customOffcanvasHeader = {
        backgroundColor: "#0f2f82", // Header background color
        color: "white", // Header text color
        padding: "10px", // Padding
        borderBottom: "1px solid #ddd" // Border
    };

    const customOffcanvasBody = {
        padding: "2px", // Padding
        overflowY: "auto", // Enable scrolling

    };

    const closeButtonStyle = {
        position: "absolute",
        color: 'white',
        top: "3px",
        right: "10px",
        background: "transparent",
        border: "none",
        fontSize: "25px",
        cursor: "pointer"
    };

    const GetcomplianceDetail = async () => {
        try {
            const response = await getApi("/compliance-list");
            if (response.status) {
                // console.log(response.data)
                const dataFilter = response.data.filter(Item => Item.compliance_type == "daily_hours")
                const dataFilter1 = response.data.filter(Item => Item.compliance_type == "interval_for_rest")
                setComplianceDetail(dataFilter[0].compliance_value);
                setRestInterval(dataFilter1[0].compliance_value)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    useEffect(() => {
        GetcomplianceDetail()
    }, [])


    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "Employee" }, second: { name: "Employee Shift" } }} />
                            </h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {(employee_upload_bulk || user_type == "employer") && (<button type="button" className="btn btn-primary btn-pill mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap"><i className="fa fa-plus me-2"></i> Create Shift </button>)}
                                {/* {(user_type == "employer" || employee_add) && (<button type="button" className="btn btn-primary btn-pill mt-3"><i className="fa fa-plus me-2"></i> Assign Shift</button>)} */}
                                {/* {(user_type == "employer" || employee_add) && (<button type="button" className="btn btn-primary btn-pill mt-3" ><i className="fa fa-plus me-2"></i> Add People</button>)} */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            {/* <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {/**************  Create Shift Start Modal *******************/}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <i className='fas fa-user m-1'></i>
                                    <h5 className="modal-title" id="exampleModalLabel">Create Shift</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={AddForm.handleSubmit}>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label className="form-label">Shift Name  {AddForm.values.shift_name && <i className="fa-solid fa-circle-check text-primary"></i>}</label>
                                            <input type="text" className="form-control" placeholder="Enter Shift Name" {...AddForm.getFieldProps("shift_name")} />
                                            {AddForm.touched.shift_name && AddForm.errors.shift_name ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_name}</div> : ''}
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="form-label">Start Shift {AddForm.values.shift_start_time && <i className="fa-solid fa-circle-check text-primary"></i>}</label>
                                                    <input type="time" className="form-control" {...AddForm.getFieldProps("shift_start_time")} />
                                                    {AddForm.touched.shift_start_time && AddForm.errors.shift_start_time ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_start_time}</div> : ''}
                                                </div>
                                                <div className="col">
                                                    <label className="form-label">End Shift  {AddForm.values.shift_end_time && <i className="fa-solid fa-circle-check text-primary"></i>}</label>
                                                    <input type="time" className="form-control" {...AddForm.getFieldProps("shift_end_time")} onChange={handleendshifttimeCreate} />
                                                    {AddForm.touched.shift_end_time && AddForm.errors.shift_end_time ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_end_time}</div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        {AddForm.values.shift_end_time && AddForm.values.shift_end_time &&
                                            <div className="form-group">
                                                <label className="form-label">Break Duration  {AddForm.values.break_minutes && <i className="fa-solid fa-circle-check text-primary"></i>}</label>
                                                <div className="btnstorage">
                                                    {breakDurationCount.map(option => (
                                                        <div key={option} style={{ display: 'inline-block', marginRight: '10px' }}>
                                                            <input
                                                                className="checkbox-tools"
                                                                type="radio"
                                                                name="break_minutes"
                                                                id={`tool-${option.value}`}
                                                                value={option.value}
                                                                checked={AddForm.values.break_minutes === option.value}
                                                                onChange={() => AddForm.setFieldValue('break_minutes', option.value)}
                                                                onBlur={AddForm.handleBlur}
                                                            />
                                                            <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option.value}`}>{option.key}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                {AddForm.touched.break_minutes && AddForm.errors.break_minutes ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.break_minutes}</div> : ''}
                                                {AddForm.values.break_minutes && (
                                                    <div className="row">
                                                        <div className="col">
                                                            <label className="form-label">Break Start Time {AddForm.values.break_start_time && <i className="fa-solid fa-circle-check text-primary"></i>}</label>
                                                            <input type="time" className="form-control" {...AddForm.getFieldProps("break_start_time")}
                                                                onChange={handleBreakTimeCreate} />
                                                            {AddForm.touched.break_start_time && AddForm.errors.break_start_time ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.break_start_time}</div> : ''}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" ref={refCreateModalClose} className="btn btn-dark" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-primary">Create Shift</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                    {/*************  Create Shift End Modal ***********************/}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Shift Name  </th>
                                                    <th className="font-weight-bold">Start Time </th>
                                                    <th className="font-weight-bold">End Time  </th>
                                                    <th className="font-weight-bold">Total Time </th>
                                                    <th className="font-weight-bold">Break Duration </th>
                                                    <th className="font-weight-bold">Break Start Time  </th>
                                                    {/* <th className="font-weight-bold">Working Shift  </th> */}
                                                    <th className="font-weight-bold">Action</th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader

                                                    /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 &&
                                                            dataArray.map((Item, i) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                                const { shift_name, shift_start_time, shift_end_time, break_minutes, break_start_time } = Item;
                                                                const shiftStartTime = new Date(`2000-01-01T${Item.shift_start_time}`);
                                                                const shiftEndTime = new Date(`2000-01-01T${Item.shift_end_time}`);

                                                                // Calculate total minutes
                                                                let totalMinutes = (shiftEndTime - shiftStartTime) / (1000 * 60);

                                                                // Adjust for overnight shifts
                                                                if (totalMinutes < 0) {
                                                                    totalMinutes += 24 * 60;
                                                                }

                                                                // Calculate hours and minutes
                                                                const totalHours = Math.floor(totalMinutes / 60);
                                                                const remainingMinutes = totalMinutes % 60;
                                                                return (
                                                                    <tr key={serial_num}>
                                                                        <td> <span className="font-weight-normal1">{Item?.shift_name ? shift_name : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{Item?.shift_start_time ? shift_start_time : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{Item?.shift_end_time ? shift_end_time : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{totalHours} hours {remainingMinutes} minutes </span></td>
                                                                        <td><span className="font-weight-normal1">{Item?.break_minutes ? break_minutes + " min" : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{Item?.break_start_time ? break_start_time : ""}</span></td>
                                                                        {(employee_edit || user_type == "employer") && (
                                                                            <td> <Link to="" className="btn btn-sm btn-outline-primary me-1 fas fa-edit" onClick={() => {
                                                                                setId(Item?.id); setShiftName(Item?.shift_name); setshiftStartTime(Item?.shift_start_time); setShiftEndTime(Item?.shift_end_time); setBreakDuration(Item?.break_minutes)
                                                                                setBreakTime(Item?.break_start_time);
                                                                            }}
                                                                                data-bs-target="#assign-rm" data-bs-toggle="modal"
                                                                            > </Link>
                                                                                <Link to=''
                                                                                    className="btn btn-sm btn-outline-danger fa fa-trash"
                                                                                    onClick={() => HandleDeleteShift(Item?.id)}></Link>

                                                                            </td>
                                                                        )}

                                                                    </tr>
                                                                )
                                                            })
                                                        } </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-11">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {/* <button class="btn btn-primary" type="button"
                                style={{ height: '30px' }}
                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasChat" aria-controls="offcanvasChat">?</button> */}

                            <button className="btn btn-primary" style={{ height: '30px' }} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">?</button>
                        </div>   </div>
                </div >
            }
            {/************************************* Edit Employee Start **********************************/}

            <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog" style={{ display: 'block !important' }}>
                <div className="modal-dialog" role="document" style={{ overflowY: 'initial !important' }}>
                    <div className="modal-content">
                        <div className="modal-header"> <h6 className="modal-title">Edit Shift</h6>
                            <button ref={ref3} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                        </div>

                        <div className="modal-body" >
                            <form className="needs-validation" id='registerForm' onSubmit={EditForm.handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label">Shift Name</label>
                                    <input type="text" className="form-control" placeholder="Enter Shift Name" {...EditForm.getFieldProps("shift_name")} />
                                    {EditForm.touched.shift_name && EditForm.errors.shift_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.shift_name}</div> : ''}
                                </div>
                                <div className="form-group row">
                                    <div className="col">
                                        <label className="form-label">Start Shift</label>
                                        <input type="time" className="form-control" {...EditForm.getFieldProps("shift_start_time")} />
                                        {EditForm.touched.shift_start_time && EditForm.errors.shift_start_time ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.shift_start_time}</div> : ''}
                                    </div>
                                    <div className="col">
                                        <label className="form-label">End Shift</label>
                                        <input type="time" className="form-control" min="10:00" {...EditForm.getFieldProps("shift_end_time")} onChange={handleendshifttime} />
                                        {EditForm.touched.shift_end_time && EditForm.errors.shift_end_time ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.shift_end_time}</div> : ''}
                                    </div>
                                </div>

                                {EditForm.values.shift_start_time && EditForm.values.shift_end_time &&
                                    <div className="form-group">
                                        <label className="form-label">Break Duration</label>
                                        <div className="btnstorage">
                                            {breakDurationCount.map(option => {
                                                return (
                                                    <div key={option} style={{ display: 'inline-block', marginRight: '10px' }}>
                                                        <input
                                                            className="checkbox-tools"
                                                            type="radio"
                                                            name="break_minutes"
                                                            id={`tool-${option.value}`}
                                                            value={option.value}
                                                            checked={EditForm.values.break_minutes == option.value}
                                                            onChange={() => EditForm.setFieldValue('break_minutes', option.value)}
                                                            onBlur={EditForm.handleBlur}
                                                        />
                                                        <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option.value}`}>{option.key}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {EditForm.touched.break_minutes && EditForm.errors.break_minutes ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.break_minutes}</div> : ''}
                                        {EditForm.values.break_minutes && (
                                            <div className="form-group">
                                                <label className='form-label'>Break Start Time</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    {...EditForm.getFieldProps("break_start_time")}
                                                    onChange={handleBreakTime}
                                                    onBlur={EditForm.handleBlur}
                                                />
                                                {EditForm.touched.break_start_time && EditForm.errors.break_start_time ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.break_start_time}</div> : ''}
                                            </div>
                                        )}
                                    </div>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Cancel</button>
                                    <button type='submit' className='btn btn-primary'>Edit Shift</button>
                                </div>
                            </form>


                        </div>

                    </div>
                </div>
            </div>
            {/************************************* Edit Employee End ************************************/}



            {/************************************* Shift creation detail Modal Start ************************************/}
            <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom"
                style={customOffcanvasStyle}
                aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-header"
                    style={customOffcanvasHeader}>
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Shift Helper</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small"
                    style={customOffcanvasBody}>
                    <ol style={{ paddingLeft: '1.5rem' }}>
                        <li>Create new shifts by clicking on the "Create Shifts" button</li>
                        <li>In Create Shift, enter any friendly shift name</li>
                        <li>Click on the clock icon under "Start Shift" to select the starting time of the shift or enter the time from your keyboard manually</li>
                        <li>Click on the clock icon under "End Shift" to select the ending time of the shift or enter the time from your keyboard manually</li>
                        <li>Break Duration field will appear the moment you enter End Shift timings</li>
                        <li>Select the duration of the break from 15 minutes, 30 minutes, 45 minutes, or 1 hour. When you select, the Break Start Time field will appear for you to select the time when the break should start</li>
                        <li>Finally, click on the "Create Shifts" button to create a new shift</li>
                    </ol>
                </div>
                <button style={closeButtonStyle} data-bs-dismiss="offcanvas" aria-label="Close">&times;</button>
            </div>
            {/************************************* Shift creation detail Modal End ************************************/}

        </>
    )
}

export default Employeeshift