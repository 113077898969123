import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { Image, Switch } from 'antd';
import PaginationComponent from '../../PaginationComponent';
import { deleteApi, getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import Swal from 'sweetalert2';
import Search from '../../components/Search/Search';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Employer = (props) => {
    const ref = useRef()
    const [extendPlanDate, setExtendPlanDate] = useState('')
    const [counterid, setcounterid] = useState();
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [ValidPagination, setValidPagination] = useState(false)
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate()
    const [uploadImage, setUploadImage] = useState('')
    const [employerID, setEmployerID] = useState('')
    const [currentPlanDate, setCurrentPlanDate] = useState('')
    const employer = JSON.parse(localStorage.getItem("admin"));
    const token = employer.admin_token;
    const [multippleArray, setMultippleArray] = useState([]);
    const [isChecked, setIsChecked] = useState(false);


    const getEmployeesData = async (token) => {
        // /get-employer-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderBy}
        // const response = await GetEmployers(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/get-employer-list", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        // console.log(response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllEmployer);
                settotalItems(response.AllEmployer)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message === 'Session expired') {
            localStorage.removeItem("admin");
            navigate(prefix + "/login")
        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getEmployeesData(token, pagesCount, search);
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getEmployeesData(token, pagesCount, search );
    }

    const form = useFormik({
        initialValues: {
            user_id: "",
            new_password: "",
            confirm_password: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: yup.string().required('Please Enter New Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
            const response = await postApi("/changeUserPasswordByAdmin", {
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            });

            resetForm({ values: '' });
            ref.current.click()
            if (response.status == false) {
                toast.error(response.message);
                ref.current.click()
            }
            if (response.status == true) {
                toast.success(response.message);
                ref.current.click()

            } else {

                console.log("#@#@#1234", response);
            }

        }
    });
    const [values, setValues] = React.useState({
        new_password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        new_password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',

    }

    const eyeBtnStyle2 = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle3 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',
    }
    const [toggle, setToggle] = useState(true)
    const [toggleforNFC, settoggleforNFC] = useState(true)
    const Registration = async (id, e) => {
        // let data = {
        //     "id": id,
        //     "status": e == true ? '1' : '0',
        // }
        try {
            // `/verify/${data.id}`
            // const total = await changeStatus(token, data)
            const total = await getApi(`/verify/${id}`)

            console.log('changeStatus', total)
            //   changeStatusToast();
        } catch (error) {
            console.log(error)
        }
    }

    const NFCStatus = async (id, e) => {
        // let data = {
        //     "id": id,
        //     "status": e == true ? '1' : '0',
        // }
        try {
            // const total = await changeNFCStatus(token, data)
            const total = await getApi(`/nfc-toggle/${id}`)
            console.log('changeStatus', total)
            if (total.status == true) {
                toast.success(total.message)
            }
            //   changeStatusToast();
        } catch (error) {
            console.log(error)
        }
    }

    const GPSStatus = async (id) => {
        console.log(id)
        try {
            // const total = await changeNFCStatus(token, data)
            const total = await getApi(`/update-gps-status/${id}`)
            if (total.status == true) {
                toast.success(total.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleNavigate = (id) => {
        navigate(`${prefix}/editemployer/` + id.user_id, { state: id })
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deleteEmployer(token, data)
                const response = await deleteApi(`/delete-employer/${data}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'User Account has been deleted Successfully.',
                        'success'
                    )
                    getEmployeesData();
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    const AddForm = useFormik({
        initialValues: {
            extended_date: /* currentPlanDate ? currentPlanDate : */ "",
            comment: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            extended_date: yup.string().required('Please select Date'),

        }), onSubmit: async (values, { resetForm }) => {

            const formData = new FormData();
            formData.append("extended_date", AddForm.getFieldProps('extended_date').value);
            formData.append("comment", AddForm.getFieldProps('comment').value);
            formData.append("image", uploadImage);
            postApi(`/extend-time-period/${employerID}`, formData, true).then((res) => {
                if (res.status == true) {
                    getEmployeesData();
                    setUploadImage('')
                    setExtendPlanDate(false)
                    resetForm({ values: "" })
                    toast.success(res.message);

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    })

    const today = new Date();
    const minDate = today.toISOString().split('T')[0];

    // console.log(minDate, "******************")
    const selectTransactions = async (e, id) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);
        if (rowIndex > -1) {

            checkArray.splice(rowIndex, 1);

        } else {
            checkArray.push(id);

        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }


    }

    function handleCheckClick() {
        if (isChecked == false) {
            let newdata = dataArray.map(v => parseInt(v.user_id)).join(',')
            let arrayData = newdata.split(',').map(Number);
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            setIsChecked(false);
            setMultippleArray([]);
        }
        setDataArray((old_data) => [...old_data]);
    }

    const checheked = (item) => {
        return multippleArray.includes(item) ? 1 : 0;
    }

    // console.log(multippleArray,dataArray, "************")
    const [errorModal, seterrorModal] = useState(false)
    const [PFdetail, setPFdetail] = useState([])


    const ViewPF = async () => {
        try {
            const response = await getApi('/get-pf-plan');
            if (response.status) {
                setPFdetail(response.data.rows)

            } else {
                console.log(response)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        ViewPF()
    }, [])

    const [updatedStatus, setUpdatedStatus] = useState([])
    const [responseMessage, setResponseMessage] = useState('')

    const AssignPF = async (id) => {
        try {
            const response = await postApi('/assign-pf-plan', {
                employersArray: multippleArray,
                pfPlanId: id
            })
            if (response.status) {
                console.log(response)
                // toast.success(response.message)
                getEmployeesData()
                setUpdatedStatus(response?.data)
                setResponseMessage(response?.message)
                seterrorModal(true)
                setMultippleArray([])
            } else {
                console.log(response)
                toast.error(response.message)
            }
        } catch (error) {
            console.log(error)
        }

    }

    // console.log(multippleArray)

    return (
        <>
            {!showAddNewCases &&
                <div>
                    {errorModal ? <>
                        <div class="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Status {updatedStatus.length > 0 && <span className='text-primary' style={{ fontSize: '13px', float: 'end' }}> {" - UnAssigned Employer Count: " + updatedStatus.length}</span>}</h1>
                                        <button /* ref={handleModal} */ style={{ display: 'none' }} className="fas fa-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body" style={{ textTransform: 'none', maxHeight: '400px', overflowY: 'auto' }}>
                                        {updatedStatus.length > 0 ? <div class="card" style={{ width: "30rem" }}>
                                            <div class="card-header d-flex" style={{ justifyContent: "space-between" }}>
                                                Employee Detail
                                                {/* <span>Country</span> */}
                                            </div>
                                            <ul class="list-group list-group-flush">
                                                {updatedStatus?.map((Item, index) => {
                                                    return (
                                                        <>
                                                            <li class="list-group-item" style={{ borderBottom: '20px' }} >
                                                                <div className='d-flex'>
                                                                    <div className='me-2'>
                                                                        <p className='m-0 fw-bold text-wrap'>Name</p>
                                                                        <p className='m-0 fw-bold text-wrap'>Email</p>
                                                                        <p className='m-0 fw-bold text-wrap'>Mobile No.</p>
                                                                        <p className='m-0 fw-bold text-wrap'>Company Name</p>
                                                                        <p className='m-0 fw-bold text-wrap'>Country</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='m-0 text-wrap'>: {Item?.full_name ? Item.full_name : "--"}</p>
                                                                        <p className='m-0 text-wrap'>: {Item?.email ? Item.email : "--"} </p>
                                                                        <p className='m-0 text-wrap'>: {Item?.mobile_number ? Item.mobile_number : "--"}</p>
                                                                        <p className='m-0 text-wrap'>: {Item?.company_name ? Item.company_name : "--"}</p>
                                                                        <p className='m-0 text-wrap'>:  {Item?.country ? Item.country : "--"}</p>
                                                                    </div>
                                                                </div>

                                                            </li>

                                                        </>
                                                    )
                                                })}

                                            </ul>
                                        </div> : <div>{responseMessage}</div>}
                                    </div>
                                    <div class="modal-footer">
                                        <button className='btn btn-primary' onClick={() => seterrorModal(false)}>Acknowledge</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </> :
                        <div className="main-container container-fluid px-0">
                            <div className="page-header mb-3">
                                <div className="page-leftheader">
                                    <h4 className="page-title mb-0 text-primary">Employer Reports {totalItems ? '(' + totalItems + ')' : ''}</h4>
                                </div>
                                {/* <AssignToLender show={show} handleShow={handleShow} handleClose={handleClose} /> */}
                                <div className="page-rightheader">
                                    <div className="btn-list">
                                        <Link to={prefix + "/employer/trash"} className="btn btn-primary btn-pill mt-2" ><i className="fa fa-trash "></i> Recover</Link>
                                        <Link to={prefix + "/addemployer"} className="btn btn-primary btn-pill mt-2" ><i className="fa fa-plus me-2"></i> Add Employer</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    {multippleArray.length > 0 && <div className="form-group">
                                        <label className="form-label">Assign PF Plan</label>
                                        <select className='form-control' onChange={(e) => AssignPF(e.target.value)}>
                                            <option value="">Please Select PF</option>
                                            {PFdetail.length > 0 && PFdetail.map((Items, index) => {
                                                return (
                                                    <>
                                                        <option key={index} value={Items.id}>{Items.title}</option>
                                                    </>
                                                )
                                            })}
                                        </select></div>}</div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>
                                                            <th className="font-weight-bold">Logo</th>
                                                            <th className="font-weight-bold">Employer {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Authorize Person Detail {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Country {sortedBy == "Country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }}></i>}</th>
                                                            <th className="font-weight-bold">PF Plan  {sortedBy == "pf_plan_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('pf_plan_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('pf_plan_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Plan Valid Till {sortedBy == "valid_till" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('valid_till') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('valid_till') }}></i>}</th>
                                                            <th className="font-weight-bold">Need NFC Tags</th>
                                                            <th className="font-weight-bold">NFC Status</th>
                                                            <th className="font-weight-bold">Active/Inactive</th>
                                                            <th className="font-weight-bold">Actions</th>
                                                            {/* <th></th> */}
                                                        </tr>
                                                    </thead>
                                                    {loader == true ? <tbody>
                                                        <td /><td /><td />
                                                        <div>
                                                            <Loader /> </div> </tbody> :
                                                        <>
                                                            <tbody>
                                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    const date = new Date();
                                                                    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

                                                                    return (
                                                                        <tr key={serial_num}>
                                                                            <td><input type="checkbox" name={option.user_id} checked={checheked(option.user_id) === 1 ? true : false} onChange={(event) => selectTransactions(event, option.user_id)} /></td>
                                                                            <td><span className="font-weight-normal1" ><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo != null ? option.logo : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></span></td>
                                                                            <td> <Link className="text-primary" to={prefix + `/employer_detail/${option.user_id}`}><span className="font-weight-normal1" >{option.company_name !== null ? option.company_name : ""}<br />{option.mobile_number}<br /><span className='customtextformat'>{option.email}</span></span></Link></td>
                                                                            <td><span className="font-weight-normal1"> Name: {option.full_name ? option.full_name : "--"}<br />
                                                                                <span className="content_fix" >Email: <a href={option.website} target="blank" style={{ color: "#0f2f82" }} >
                                                                                    <span className='customtextformat'> {option.website ? option.website : "--"}</span></a>
                                                                                    <br />Address: {option.address ? option.address : "--"}</span></span></td>
                                                                            {/* <td className='font-weight-normal1'><span className="content_fix" ><a href={option.website} target="blank" style={{ color: "#0f2f82" }} ><span className='customtextformat'>{option.website ? option.website : ""}</span></a></span></td> */}
                                                                            <td className='font-weight-normal1'>{option?.country ? option?.country : "--"}</td>
                                                                            <td className='font-weight-normal1'>{option?.pf_plan_name ? option?.pf_plan_name : "--"}</td>
                                                                            <td><span className="font-weight-normal1">{formattedDate > option?.valid_till ? <span className='badge rounded-pill bg-danger'> {option?.valid_till}</span> : <span className='badge rounded-pill bg-success'> {option?.valid_till}</span>}</span></td>
                                                                            <td>{option.gps_attendance_status == '1' &&
                                                                                <div className="col-auto ms-auto d-print-none">
                                                                                    <Switch checkedChildren="YES" onClick={() => GPSStatus(option.user_id)} unCheckedChildren="NO" defaultChecked />
                                                                                </div>}
                                                                                {option.gps_attendance_status == '0' &&
                                                                                    <div className="col-auto ms-auto d-print-none">
                                                                                        <Switch checkedChildren="YES" onClick={() => GPSStatus(option.user_id)} unCheckedChildren="NO" />
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            <td>{option.is_nfc_active == '1' &&
                                                                                <div className="col-auto ms-auto d-print-none">
                                                                                    <Switch checkedChildren="Active" onClick={(e) => NFCStatus(option.user_id, e)} unCheckedChildren="Inactive" defaultChecked onChange={(e) => settoggleforNFC(e)} />
                                                                                </div>}
                                                                                {option.is_nfc_active == '0' &&
                                                                                    <div className="col-auto ms-auto d-print-none">
                                                                                        <Switch checkedChildren="Active" onClick={(e) => NFCStatus(option.user_id, e)} unCheckedChildren="Inactive" onChange={(e) => settoggleforNFC(e)} />
                                                                                    </div>}
                                                                            </td>
                                                                            <td>{option.is_verified == '1' &&
                                                                                <div className="col-auto ms-auto d-print-none">
                                                                                    <Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive" defaultChecked onChange={(e) => setToggle(e)} />
                                                                                </div>}
                                                                                {option.is_verified == '0' &&
                                                                                    <div className="col-auto ms-auto d-print-none"><Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive" onChange={(e) => setToggle(e)} /></div>}
                                                                            </td>
                                                                            {/* <td><span className="font-weight-normal1"><Link to={prefix + `/allemployee_of_employer/${option.user_id}`}  ><i className="fa fa-eye me-2 btn btn-primary" /></Link></span>
                                                                           <i className="fa fa-edit me-2 btn btn-primary" onClick={() => handleNavigate(option)}></i>
                                                                            <span onClick={() => { setcounterid(option.user_id) }} type="button" className='btn btn-primary' data-bs-target="#add_settlement" data-bs-toggle="modal" ><i className="fas fa-lock-open " /></span>&nbsp;&nbsp;
                                                                            <button type="button" className='btn btn-primary'><i className="fas fa-trash " onClick={() => { handleDelete(option.user_id) }} /></button>
                                                                        </td> */}
                                                                            <td>
                                                                                <div className="dropdown-action">
                                                                                    <button className="btn view-pro dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                        Action
                                                                                        <span className="caret"></span>
                                                                                    </button>
                                                                                    <ul className="dropdown-menu actionfix" aria-labelledby="dropdownMenu1">
                                                                                        <li><Link to={prefix + `/allemployee_of_employer/${option.user_id}`}  > <span className="font-weight-normal1">View </span></Link></li>
                                                                                        <li><span onClick={() => handleNavigate(option)} className="font-weight-normal1" style={{ marginLeft: '17px', cursor: 'pointer', fontSize: 'small' }}>Edit</span></li>
                                                                                        <li><Link><span className='font-weight-normal1' onClick={() => { handleDelete(option.user_id) }} >Delete</span></Link></li>
                                                                                        <li><Link to={prefix + `/plan_history/${option.user_id}`}  > <span className="font-weight-normal1">Plan History</span></Link></li>
                                                                                        <li><Link><span className='font-weight-normal1' onClick={() => { setExtendPlanDate(true); setEmployerID(option?.user_id) }} >Extend Plan</span></Link></li>
                                                                                        <li><Link><span onClick={() => { setcounterid(option.user_id) }} className='font-weight-normal1' data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</span></Link></li>
                                                                                        <li><Link to={prefix + `/sub-employer_detail/${option.user_id}`}  > <span className="font-weight-normal1">View Sub-Employer</span></Link></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {dataArray.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                                    </tr>}
                                                            </tbody></>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {extendPlanDate &&
                                    <>
                                        <div class="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <form onSubmit={AddForm.handleSubmit}>
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Extend Plan</h1>
                                                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                                        </div>
                                                        <div class="modal-body">
                                                            <div className='row'>
                                                                <div className='col-md-12 mb-3'>
                                                                    <label>Extend Till</label>
                                                                    <input
                                                                        type="date"
                                                                        min={minDate}
                                                                        className="form-control m-0 me-2"
                                                                        placeholder="Enter Plan End Date"
                                                                        {...AddForm.getFieldProps("extended_date")}
                                                                    />
                                                                    {AddForm.touched.extended_date && AddForm.errors.extended_date ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.extended_date}</div> : ''}
                                                                </div>
                                                                <div className='col-md-12 mb-3'>
                                                                    <label>Comment</label>
                                                                    <textarea
                                                                        type="text"

                                                                        className="form-control m-0 me-2"
                                                                        placeholder="Enter Comment here..."
                                                                        {...AddForm.getFieldProps("comment")}
                                                                    />
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <label>Select File</label>
                                                                    <input
                                                                        type="file"
                                                                        onChange={(e) => setUploadImage(e.target.files[0])}
                                                                        // {...AddForm.getFieldProps("image")}
                                                                        className="form-control m-0 me-2"
                                                                        placeholder="Select Images"
                                                                    // onChange={e => setExtendPlanDate(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button
                                                                type="button"
                                                                class="btn btn-dark"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => {
                                                                    setExtendPlanDate(false)
                                                                }}
                                                            >Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                class="btn btn-primary"
                                                                // onClick={e => {
                                                                //     HandleUpdatePlanDate(extendPlanDate)
                                                                // }}
                                                                disabled={AddForm?.values?.extended_date.length == 0}
                                                            >Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-backdrop fade show"></div>
                                    </>
                                }
                                {/* Change Password Start */}
                                <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content modal-content-demo">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Change Password</h3>
                                                <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                            </div>

                                            <div className="modal-body application-modal">

                                                <form className="row" onSubmit={form.handleSubmit}>

                                                    <div className="row">
                                                        <div className="row g-xs">

                                                            <div className="col-12">
                                                                {/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
                                                                <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                                                    name="password" {...form.getFieldProps("new_password")} />
                                                                {!values.showPassword ?
                                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                    :
                                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                }
                                                                {form.touched.new_password && form.errors.new_password ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="row g-xs">
                                                            <div className="col-12">
                                                                {/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
                                                                <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                                                    name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

                                                                {!valuesConfP.showConfPassword ?
                                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                    :
                                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                }
                                                                {form.touched.confirm_password && form.errors.confirm_password ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-footer mt-2">
                                                        <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                                            Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Change Password End */}
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>}
                </div>

            }
        </>
    )
}


export default Employer;
