import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumbsecond } from '../../../../components'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { postApi } from '../../../service/employService';
import Loader from '../../../../components/Loader/loader';
import moment from 'moment';
import toast from 'react-hot-toast';

const PaySlip = () => {
    const location = useLocation();
    const { state } = location
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const handleModal = useRef()
    const navigate = useNavigate()
    const [handleButton, setHandlebutton] = useState(false)
    const [viewEmployeeDetail, setViewEmployeeDetail] = useState('')
    const [message, setmessage] = useState('')

    const UpdateDeductionAmount = async () => {
        try {
            const response = await postApi("/employee-salary-slip", {
                customer_id: state.from,
                deduction_amount: state.data
            })
            if (response.status) {
                setHandlebutton(true)
                setViewEmployeeDetail(response.data)
                setmessage(response.message)
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (state?.data && state?.data) {
            UpdateDeductionAmount()
        }
    }, [])

    const HandleModalCheck = () => {
        handleModal.current.click()
        navigate('/employer/employees')
    }

    const Date = moment().format('YYYY-MM-DD')

    const totalDeductions = viewEmployeeDetail?.deductionArray?.reduce((acc, curr) => acc + parseFloat(curr.deductionAmount), 0);
    const netPay = viewEmployeeDetail?.totalNormalPay - (totalDeductions ? totalDeductions : 0);

    const FinalPay = netPay - viewEmployeeDetail?.totalFnpfDeduction

    console.log(FinalPay, "************", viewEmployeeDetail?.totalFnpfDeduction)

    const GenerateBulkPayslip = async () => {
        try {
            const response = await postApi("/bulk-employee-payslip-generate", {
                employeesArray: [{
                    customer_id: state.from,
                    deductionArray: state.data
                }]
            })
            if (response.status) {
                console.log(response)
                toast.success(response.message)
                navigate('/employer/employees')
            } else {
                console.log(response)
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>

            {handleButton ?
                <>
                    {Object.keys(viewEmployeeDetail).length == 0 ?
                        <>
                            <div class="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Attendace Not Found</h1>
                                            <button ref={handleModal} style={{ display: 'none' }} className="fas fa-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body" style={{ textTransform: 'none' }}>
                                            {message}
                                        </div>
                                        <div class="modal-footer">
                                            <button className='btn btn-primary' onClick={() => HandleModalCheck()}>Go Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"></div>
                        </>
                        :
                        <div className="main-container container-fluid px-0">
                            <div className="page-header my-3 py-2">
                                <div className="page-leftheader">
                                    <h4 className="page-title mb-0 text-primary">
                                        <Breadcrumbsecond order={{ first: { name: "Employees", path: `${prefix}/employees` }, second: { name: "Payslip" } }} />
                                    </h4>

                                </div>
                                <button className='btn btn-primary' onClick={() => GenerateBulkPayslip()} >Generate Payslip</button>
                            </div>
                            <div className="payslip">
                                <div className="salary-header">
                                    <div className="logo-group">
                                        {/* <img src="/assets/img/Evergreen-logo.jpg" alt="Logo 1" className="logo main-logo" /> */}
                                        <img src="/assets/img/wormald-logo.jpg" alt="" className="logo ms-2" />
                                        {/* <div className="nested-logos d-flex justify-content-center">
                                            <img src="/assets/img/wormald-logo.jpg" alt="Logo 2" className="logo ms-2" />
                                            <img src="/assets/img/gueadforce-logo.jpg" alt="Logo 3" className="logo ms-2" />
                                            <img src="/assets/img/wormald-2-logo.jpg" alt="Logo 4" className="logo ms-2" />
                                        </div> */}
                                    </div>
                                    <div className="title-group">
                                        <h3>Payslip</h3>
                                    </div>
                                    <div className="page-info ms-5">
                                        <span>Page: 1</span><br />
                                        <span>PAYID: 36086</span><br />
                                        <span>Printed Date: {Date ? Date : "--"}</span>
                                    </div>
                                </div>
                                <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                    <div className="employee-details">
                                        <span><strong>Employee ID:</strong> {viewEmployeeDetail?.employeeDetail?.employee_code}</span><br />
                                        <span><strong>Employee Name:</strong> {viewEmployeeDetail?.employeeDetail?.first_name}  {viewEmployeeDetail?.employeeDetail?.last_name}
                                        </span><br />
                                        <span><strong>TIN:</strong> {viewEmployeeDetail?.employeeDetail?.tin}</span><br />
                                        <span><strong>FNPF No:</strong> {viewEmployeeDetail?.employeeDetail?.fnpf} </span>
                                    </div>

                                    <div className="employee-details">
                                        <span><strong>Month:</strong> {moment().format("MMMM YYYY")} </span><br />
                                        {/* <span><strong>End Date:</strong> 15-10-2023</span><br /> */}
                                        <span><strong>Normal Pay:</strong> {viewEmployeeDetail?.totalNormalPay ? viewEmployeeDetail?.totalNormalPay.toFixed(2) : "--"}</span><br />

                                    </div>
                                </div>

                                <div className="payment-details">
                                    <table className="details">
                                        <tr>
                                            <th>Type</th>
                                            <th>Description</th>
                                            <th>Hours</th>
                                            <th>Rate</th>
                                            <th>Premium</th>
                                            <th>Amount</th>
                                            <th>Department</th>
                                        </tr>
                                        <tr>
                                            <td>Timesheet</td>
                                            <td>Normal Pay</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.totalNTHours ? viewEmployeeDetail?.employeeDetail?.totalNTHours : "--"}</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.NT_per_hrs_wages}</td>
                                            <td></td>
                                            <td>{viewEmployeeDetail?.totalNormalPay ? viewEmployeeDetail?.totalNormalPay.toFixed(2) : "--"}</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>Timesheet</td>
                                            <td>Public Holiday</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>--</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>Premium</td>
                                            <td>Premium Gross Value</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>--</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>sickleave</td>
                                            <td>Sick Leave Gross Value</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>--</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>
                                        </tr>

                                        {viewEmployeeDetail?.deductionArray?.length > 0 && viewEmployeeDetail?.deductionArray.map((Items, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>Fixed Deductions</td>
                                                        <td>{Items.deductionName ? Items.deductionName : "--"}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{Items.deductionAmount ? "-" + Items.deductionAmount : "--"}</td>
                                                        <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>

                                                    </tr>
                                                </>
                                            )
                                        })}
                                        <tr>
                                            <td>fnpf</td>
                                            <td>fnpf</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{viewEmployeeDetail?.totalFnpfDeduction ? "-" + viewEmployeeDetail?.totalFnpfDeduction : '--'}</td>
                                            <td>{viewEmployeeDetail?.employeeDetail?.departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>NETPAY</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{FinalPay ? FinalPay.toFixed(2) : "--"}</td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </div>

                                <div className="salary-footer">
                                    <p><strong>Method of Payment:</strong> --</p>
                                    <p><strong>Bank:</strong> --</p>
                                    <p><strong>Bank Account Name:</strong>--
                                        {/* <br />Powered by E-Business */}
                                    </p>
                                    <p><strong>Bank Account Number:</strong>--</p>
                                </div>
                            </div>

                        </div>}
                </>
                :
                <div style={{ marginLeft: '400px', marginTop: '200px' }}><Loader /></div>}
        </>
    );
}

export default PaySlip