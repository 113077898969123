import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useFormik } from 'formik'
import Swal from 'sweetalert2';
import { deleteApi, getApi, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import { TimeZone } from '../../../../components/TimeZone/TimeZone';

const ManageLeave = () => {
    const employer = JSON.parse(localStorage.getItem("employer"))
    const [loader, setloader] = useState(true)
    const [leave_types, setLeave_types] = useState([
        {
            "leave_name": "BL",
            "leave_count": 0,
        },
        {
            "leave_name": "SL",
            "leave_count": 0,
        },
        {
            "leave_name": "AL",
            "leave_count": 0,
        }
    ])



    const GetLeaveDetail = async () => {
        try {
            const response = await getApi("/get-leave-types");
            console.log('response', response);

            // if (response.status) {
            // 	if (response.data.count > 0) {
            // 		setLeave_types(response.data.rows);
            // 	}
            // }
            if (response.status == true) {
                setTimeout(() => {
                    setLeave_types(response.data.rows);
                    setloader(false)
                }, 2000);
                setloader(true)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetLeaveDetail();
    }, []);

    const form = useFormik({
        initialValues: {
            leavesArray: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            leavesArray: yup.string()
                .matches(/[a-zA-Z0-9]/, 'Leaves Must Contain At Least One Alphanumeric Character')
                .test('noOnlySpaces', 'Leaves cannot be empty or contain only spaces', value => {
                    return value && value.trim() !== '';
                })
                .required('Please Enter Leaves Title')
        }),
        onSubmit: async (values, { resetForm }) => {
            const response = await postApi("/add-leave-type", {
                leavesArray: [
                    {
                        "leave_name": values.leavesArray,
                        "leave_count": 0,
                    },
                ],
            })
            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                resetForm({ values: '' });
                // navigate(prefix + '/view_department')
                GetLeaveDetail(employer.employer_token);


            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
                resetForm({ values: '' });
            }
        }
    })

    const AddLeave = async (updatedArray) => {
        // console.log(datatype)
        try {
            const response = await postApi("/add-leave-type", {
                leavesArray: updatedArray

            })
            if (response.status == true) {
                console.log(response)
                GetLeaveDetail();
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        // AddLeave();
    }, [leave_types])


    const handleDelete = (id) => {
        // console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const response = await deleteDeparment(employer.employer_token, id)
                const response = await deleteApi(`/delete-leave/${id}`)

                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Leaves has been deleted Successfully.',
                        'success'
                    )
                    GetLeaveDetail();
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    // console.log("****************************", leave_types, "****************************")

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        <Breadcrumbsecond order={{ first: { name: "Employee" }, second: { name: "Manage Leaves" } }} />
                    </h4>
                </div>
            </div>
            <div className="main-container container-fluid px-0">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" >
                        <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                            <div className="row">

                                <div className="form-group">
                                    <label className="form-label">Leave Name</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Leaves Name" {...form.getFieldProps("leavesArray")} />
                                            <button type="submit" className="btn btn-primary ">Add</button>
                                        </div>
                                        {form.touched.leavesArray && form.errors.leavesArray ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.leavesArray}</div> : ''}
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {/* <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword"
                                     value={search} onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold">Leave Name  </th>
                                                <th className="font-weight-bold">Leave Count  </th>
                                                <th className="font-weight-bold">Created Date </th>
                                                <th className="font-weight-bold">Action </th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader />
                                            </div> </tbody> : <>
                                            <tbody>
                                                {leave_types.length > 0 && leave_types.map((option, index) => {
                                                    // let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    return (
                                                        <tr key={index} >
                                                            <td><span className="font-weight-normal1">{option.leave_name ? option.leave_name : "-"}</span></td>
                                                            {/* <td>{option?.leave_count ? option.leave_count : "--"}</td> */}
                                                            {/* <td><input
                                                                type='text'
                                                                value={option.leave_count}
                                                                onChange={(e) => {
                                                                    let inputValue = e.target.value;
                                                                    AddLeave()
                                                                    // Validate input to allow only numbers with a maximum length of 2
                                                                    if (inputValue === '' || /^\d{0,2}$/.test(inputValue)) {
                                                                        // If the input is empty or a valid number with maximum length 2, update the state
                                                                        const parsedValue = inputValue === '' ? '' : parseInt(inputValue);
                                                                        setLeave_types(
                                                                            leave_types.map(item =>
                                                                                item.leave_name === option.leave_name ?
                                                                                    { ...item, leave_count: parsedValue } : item
                                                                            )
                                                                        );
                                                                    }
                                                                }} /></td> */}
                                                            <td>
                                                                <input
                                                                    type='text'
                                                                    defaultValue={option.leave_count}
                                                                    className='form-control'
                                                                    maxLength={2}

                                                                    onBlur={(e) => {
                                                                        let inputValue = e.target.value;
                                                                        if (inputValue === '' || /^\d{0,2}$/.test(inputValue)) {
                                                                            const parsedValue = inputValue === '' ? '' : parseInt(inputValue);

                                                                            const updatedLeaves = leave_types.map(item => item.leave_name === option.leave_name ? { ...item, leave_count: parsedValue } : item)

                                                                            AddLeave(updatedLeaves)
                                                                        }

                                                                    }}
                                                                />
                                                            </td>

                                                            {/* // type="number" className='form-control'
                                                            //     defaultValue={option?.leave_count}
                                                            //     onBlur={(e) => updateLeavetype(e.target.value, option?.id)}  */}

                                                            <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                            <td>
                                                                {/* <button type="button" className="btn btn-primary"
                                                            data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.site_id); setSite_Name(option.nfc_site_name) }}  ><i className="fas fa-edit " /></button> &nbsp;&nbsp; */}
                                                                <button type="button" className="btn btn-md btn-outline-danger me-1 fa fa-trash"
                                                                    onClick={() => { handleDelete(option.id) }}
                                                                ></button></td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {leave_types.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ManageLeave