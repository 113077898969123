import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Link, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { Image } from 'antd';
import { getApi } from '../../../service/employService';
import { TimeZone } from '../../../../components/TimeZone/TimeZone';

const Viewemployeedetail = () => {
    const [infodata, setinfodata] = useState([])
    const params = useParams();
    const id = params.id;
    const location = useLocation()
    const { state } = location;
    const [bank, setBank] = useState([])
    const [employ, setEmploy] = useState([])
    const [income, setIncome] = useState([])
    const [loan, setLoan] = useState([])
    const [pay, setPay] = useState([])
    const [photo, setPhoto] = useState([])
    const [signature, setSignature] = useState([])
    const [tin, setTin] = useState([])
    const getEmployeesData = async () => {
        // const response = await GetEmployeesDetails(token, id);
        const response = await getApi(`/get-employeebyid/${id}`);
        if (response.status == true) {
            setinfodata(response.data);
            setBank(response.document.BANK_STATEMENTS)
            setEmploy(response.document.EMPLOYMENT_LETTER)
            setIncome(response.document.INCOME)
            setLoan(response.document.LOAN_STATEMENTS)
            setPay(response.document.PAYSLIPS)
            setPhoto(response.document.PHOTO_ID)
            setSignature(response.document.SIGNATURE)
            setTin(response.document.TIN_LETTER)
        } else {
            console.log("get employees data response", response);
        }
    }
    useEffect(() => {
        getEmployeesData();
        ViewEmployeeAdditionalInfo()
        ViewEmployeeDocuments()
    }, [])

    const [viewAdditionalInfo, setViewAdditionalInfo] = useState({})

    const ViewEmployeeAdditionalInfo = async () => {
        try {
            const response = await getApi(`/view_additional_information/${id}`)
            if (response.status) {
                // console.log("first")
                setViewAdditionalInfo(response.data)

            } else {
                console.log(response.status)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const [documents, setDocuments] = useState([])

    const ViewEmployeeDocuments = async () => {
        try {
            const response = await getApi(`/employee-all-doc/${id}`)
            if (response.status) {
                console.log("first**********************", response)
                // setViewAdditionalInfo(response.data)
                setDocuments(response)

            } else {
                console.log(response.status)
            }
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4">

                <h4 className="page-title mb-0 text-primary">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link style={{ color: "GrayText" }} className='breadcrumbslink' to="/employer/employees" >Employee</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Employee Details</li>
                        </ol>
                    </nav>
                </h4>

                <div style={{ width: "100%" }}>
                    <div className="col-md-12 tabsbtm">
                        <Tabs
                            defaultActiveKey={state?.data === 'Documents' ? "documents" : "personal"}
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="personal" title="Personal Information">
                                {/* <Personalinfo /> */}
                                <div className="main-container container-fluid px-0 ">
                                    <div className="page-header my-3 py-2">
                                        <div className="modal-body application-modal px-0">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="tabsiconBox">
                                                        <div className="main-content-body main-content-body-contacts">
                                                            <h6>Employee Information</h6>
                                                            <div className='btn_group' style={{}}>
                                                            </div>
                                                            <div className="main-contact-info-body">
                                                                <div className="media-list p-0">
                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                                                <div className="w-70"> <label>First Name : </label> <span className="font-weight-normal1 fs-14">{infodata.first_name ? infodata.first_name : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                                                <div className="w-70"> <label>Middle Name : </label> <span className="font-weight-normal1 fs-14">{infodata.middle_name ? infodata.middle_name : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-tie"></i> </div>
                                                                                <div className="w-70"> <label>Last Name:</label> <span className="font-weight-normal1 fs-14">{infodata.last_name ? infodata.last_name : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">

                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                <div className="w-70"> <label>Personal Email :</label> <span className="font-weight-normal1 fs-14">{infodata.personal_email ? infodata.personal_email : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-adn"></i> </div>
                                                                                <div className="w-70"> <label>Age :</label> <span className="font-weight-normal1 fs-14">{infodata.age ? infodata.age : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Residential Address :</label>
                                                                                    <span className="font-weight-normal1 fs-14">{infodata.residental_address ? infodata.residental_address : '--'}
                                                                                        <br /> {infodata.period ? infodata.period : ''}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                                <div className="w-70"> <label>Postal Address :</label> <span className="font-weight-normal1 fs-14">{infodata.postal_address ? infodata.postal_address
                                                                                    : 'NA'} <br /> {infodata.postal_address !== null ? infodata.postal_period + "s" : '--'}
                                                                                </span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-address-card"></i></div>
                                                                                <div className="w-70">
                                                                                    <label>Previous Address :</label>
                                                                                    <span className="font-weight-normal1 fs-14">{infodata.previus_address ? infodata.previus_address
                                                                                        : 'NA'}<br /> {infodata.postal_address !== null ? infodata.previous_period + "s" : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                <div className="w-70"> <label>Work Email :</label> <span className="font-weight-normal1 fs-14">{infodata.work_email ? infodata.work_email : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">

                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                <div className="w-70"> <label>Telephone(H) :</label> <span className="font-weight-normal1 fs-14">{infodata.telephone_home ? infodata.telephone_home : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone-alt"></i> </div>
                                                                                <div className="w-70"> <label>Telephone(W) :</label> <span className="font-weight-normal1 fs-14">{infodata.telephone_work ? infodata.telephone_work : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Marrital Status :</label> <span className="font-weight-normal1 fs-14">{infodata.martial_status ? infodata.martial_status : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-file"></i> </div>
                                                                                <div className="w-70"> <label>Tin :</label> <span className="font-weight-normal1 fs-14">{infodata.tin ? infodata.tin : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>FNPF: </label> <span className="font-weight-normal1 fs-14">{infodata.fnpf ? infodata.fnpf : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-address-book"></i> </div>
                                                                                <div className="w-70"> <label>Living House Status :</label> <span className="font-weight-normal1 fs-14">{infodata.notliving_house ? infodata.notliving_house : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">

                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-address-book"></i> </div>
                                                                                <div className="w-70"> <label>Previous Office Address :</label> <span className="font-weight-normal1 fs-14">{infodata.previus_office_address ? infodata.previus_office_address : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-piggy-bank"></i> </div>
                                                                                <div className="w-70"> <label>Bank Name :</label> <span className="font-weight-normal1 fs-14">{infodata.bank_name ? infodata.bank_name : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Branch :</label> <span className="font-weight-normal1 fs-14">{infodata.branch ? infodata.branch : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Account Name :</label> <span className="font-weight-normal1 fs-14">{infodata.account_name ? infodata.account_name : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" > <i className="fas fa-house-user"></i> </div>
                                                                                <div className="w-70"> <label>Account No. :</label> <span className="font-weight-normal1 fs-14">{infodata.account_number ? infodata.account_number : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" > <i className="fas fa-dollar"></i> </div>
                                                                                <div className="w-70"> <label>Deduction Amount Limit :</label> <span className="font-weight-normal1 fs-14">{infodata.deduction_amount_limit ? "$" + infodata.deduction_amount_limit : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>No of Dependent :</label> <span className="font-weight-normal1 fs-14">{infodata.dependent_no ? infodata.dependent_no : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" > <i className="fas fa-house-user"></i> </div>
                                                                                <div className="w-70"> <label>Occupation. :</label> <span className="font-weight-normal1 fs-14">{infodata.occupation ? infodata.occupation : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" > <i className="fas fa-user-check"></i> </div>
                                                                                <div className="w-70"> <label>Current Employer :</label> <span className="font-weight-normal1 fs-14">{infodata.current_employer ? infodata.current_employer : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-shield"></i> </div>
                                                                                <div className="w-70"> <label>Previous Employer :</label> <span className="font-weight-normal1 fs-14">{infodata.previus_employer ? infodata.previus_employer : '--'} <br />
                                                                                    {infodata.previous_employer_period !== null ? infodata.previous_employer_period + "s" : ''}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-address-book"></i> </div>
                                                                                <div className="w-70"> <label>Previous office Address :</label> <span className="font-weight-normal1 fs-14">
                                                                                    {infodata.previus_office_address ? infodata.previus_office_address + "s" : 'NA'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dolly-flatbed"></i> </div>
                                                                                <div className="w-70"> <label>Mortgage Balance :</label> <span className="font-weight-normal1 fs-14">{infodata.mortgage_balance ? "$" + infodata.mortgage_balance : '--'}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                                                <div className="w-70"> <label>Mortgaged :</label> <span className="font-weight-normal1 fs-14">{infodata.mortgaged ? "$" + infodata.mortgaged : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home	"></i> </div>
                                                                                <div className="w-70"> <label>Own House Estimated Value :</label> <span className="font-weight-normal1 fs-14">{infodata.house_est_value ? "$" + infodata.house_est_value : '--'}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center"></div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="income" title="Income"  >
                                {/* <Income /> */}
                                <div style={{ marginTop: "30px" }}>
                                    <h6>Income</h6>
                                    <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                        <div className="media-list p-0">

                                            <div className="media py-4 mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                                        <div className="w-70"> <label>Annual Salary : </label> <span className="font-weight-normal1 fs-14">{infodata.annual_salary ? "$" + infodata.annual_salary : 'NA'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                        <div className="w-70"> <label>Other Income : </label> <span className="font-weight-normal1 fs-14">{infodata.other_income ? "$" + infodata.other_income : 'NA'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                        <div className="w-70"> <label>Total Monthly Income:</label> <span className="font-weight-normal1 fs-14">{infodata.monthly_income ? "$" + infodata.monthly_income : 'NA'}</span> </div>
                                                    </div>
                                                    {/* <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                <div className="w-70"> <label>Default Credit Account :</label> <span className="font-weight-normal1 fs-14">
                                    {infodata.default_credit_account !== null && 0 ? "$" + infodata.default_credit_account : ''}</span> </div>
                            </div> */}
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="assets" title="Assets">
                                {/* <Assets /> */}
                                <div style={{ marginTop: "30px" }}>
                                    <h6>Assets</h6>
                                    <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                        <div className="media-list p-0">

                                            <div className="media py-4 mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house"></i> </div>
                                                        <div className="w-70"> <label>House Value : </label> <span className="font-weight-normal1 fs-14">{infodata.house_value ? "$" + infodata.house_value : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-car"></i> </div>
                                                        <div className="w-70"> <label>Car Value : </label> <span className="font-weight-normal1 fs-14">{infodata.car_value ? "$" + infodata.car_value : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-landmark"></i> </div>
                                                        <div className="w-70"> <label>Land Value :</label> <span className="font-weight-normal1 fs-14">{infodata.land_value ? "$" + infodata.land_value : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Life Policy :
                                                        </label> <span className="font-weight-normal1 fs-14">{infodata.life_policy ? infodata.life_policy : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-credit-card"></i> </div>
                                                        <div className="w-70"> <label>Debtors :</label> <span className="font-weight-normal1 fs-14">{infodata.debtors ? infodata.debtors : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                        <div className="w-70">
                                                            <label>Personal Effects :</label>
                                                            <span className="font-weight-normal1 fs-14"> {infodata.personal_effects ? "$" + infodata.personal_effects : '--'} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                        <div className="w-70"> <label>Available Cash :</label> <span className="font-weight-normal1 fs-14">{infodata.cash_at_hand ? "$" + infodata.cash_on_hand : '--'} </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i></div>
                                                        <div className="w-70">
                                                            <label>Cash At Bank :</label>
                                                            <span className="font-weight-normal1 fs-14">{infodata.cash_at_bank ? "$" + infodata.cash_at_bank : '--'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-toolbox"></i> </div>
                                                        <div className="w-70"> <label>Total Asset :</label> <span className="font-weight-normal1 fs-14"> {infodata.total_asset ? "$" + infodata.total_asset : '--'} </span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="liabilities" title="Liabilities">
                                {/* <Liabilities /> */}
                                <div style={{ marginTop: "30px" }}>
                                    <h6>Liabilities</h6>
                                    <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                        <div className="media-list p-0" >

                                            <div className="media py-4 mt-0" >
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                        <div className="w-70"> <label>Mortgage Loan Balance : </label> <span className="font-weight-normal1 fs-14">{infodata.mortgage_loan_balnce ? "$" + infodata.mortgage_loan_balnce : 'NA'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                                        <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">{infodata.life_insurance ? "$" + infodata.life_insurance : 'NA'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Home Loan :</label> <span className="font-weight-normal1 fs-14">{infodata.home_loan ? "$" + infodata.home_loan : 'NA'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                                        <div className="w-70"> <label>Other Loan Balance :</label> <span className="font-weight-normal1 fs-14">{infodata.otherloan_balance ? "$" + infodata.otherloan_balance : 'NA'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-location-arrow"></i> </div>
                                                        <div className="w-70"> <label>Total Liabilities :</label> <span className="font-weight-normal1 fs-14">{infodata.total_outgoings ? "$" + infodata.total_outgoings : 'NA'}</span> </div>

                                                    </div>


                                                    <div className="d-flex align-items-center">
                                                        {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                <div className="w-70"> <label>Mortgage Balance :</label> <span className="font-weight-normal1 fs-14">${infodata ? infodata.mortgage_balance : ''}</span> </div> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="monthly" title="Monthly Cost">
                                {/* <Monthlycost /> */}
                                <div className="tabsiconBox" style={{ marginTop: "30px" }}>
                                    <h6>Monthly Cost</h6>

                                    <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                        <div className="media-list p-0">

                                            <div className="media py-2 mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home"></i> </div>
                                                        <div className="w-70"> <label>Rent : </label> <span className="font-weight-normal1 fs-14"><b>{infodata.rent ? "$" + infodata.rent : 'NA'}</b></span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-lightbulb"></i> </div>
                                                        <div className="w-70"> <label>Electricity : </label> <span className="font-weight-normal1 fs-14"><b>{infodata.electricity ? "$" + infodata.electricity : 'NA'}</b></span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-2 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                        <div className="w-70"> <label>Telephone Cost :</label> <span className="font-weight-normal1 fs-14">{infodata.bill_for_phone ? "$" + infodata.bill_for_phone : 'NA'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-water"></i> </div>
                                                        <div className="w-70"> <label>Water :
                                                        </label> <span className="font-weight-normal1 fs-14">{infodata.bill_for_water ? "$" + infodata.bill_for_water : 'NA'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                        <div className="w-70"> <label>House Keeping :</label> <span className="font-weight-normal1 fs-14"><b>{infodata.house_keeping ? "$" + infodata.house_keeping : 'NA'}</b></span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-book-reader"></i> </div>
                                                        <div className="w-70">
                                                            <label>School Fees :</label>
                                                            <span className="font-weight-normal1 fs-14"><b>{infodata.school_fees ? "$" + infodata.school_fees : 'NA'}</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-gas-pump"></i> </div>
                                                        <div className="w-70"> <label>Travelling/Petrol/Bus :</label> <span className="font-weight-normal1 fs-14"><b>{infodata.travelling ? "$" + infodata.travelling : '--'}</b></span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-tv"></i></div>
                                                        <div className="w-70">
                                                            <label>Entertainment/Video :</label>
                                                            <span className="font-weight-normal1 fs-14"><b>{infodata.entertainment ? "$" + infodata.entertainment : '--'}</b>  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="material-icons mt-1">&#xe8a1; </i></div>
                                                        <div className="w-70"> <label>Total Hire Purchase Payments :</label> <span className="font-weight-normal1 fs-14">{infodata.hire_payments ? "$" + infodata.hire_payments : 'NA'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="documents" title="Documents">
                                {/* <Documents /> */}
                                <div style={{ marginTop: "30px" }}>
                                    {/* <h6>Documents</h6> */}
                                    {/* <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                        <div className="media-list p-0">
                                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                                <div className="col">
                                                    <div className="card  " style={{ border: "1px solid silver" }}>
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Tin Number</h3>
                                                        </div>
                                                        {tin.map((Tin, index) => {
                                                            return (
                                                                <>
                                                                    {(Tin?.file_type === '.pdf' || Tin?.file_type === '.docx') ? (
                                                                        <a href={Tin.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Tin ? Tin.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                 />
                                                                    )}
                                                                   
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card " style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Signature</h3>
                                                        </div>
                                                        {signature.map((Signature, index) => {
                                                            return (
                                                                <>
                                                                    {Signature?.file_type === '.pdf' || Signature?.file_type === '.docx' ? (
                                                                        <a href={Signature.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Signature ? Signature.doc_file : ''}
                                                                          
                                                                            style={{ width: '70px', height: '100px', border: "1px solid silver" }}
                                                                            className="card-img-top m-1" alt="..." />
                                                                    )}
                                                                   
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                                <div className="col">
                                                    <div className="card  " style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Photo ID</h3>
                                                        </div>
                                                        {photo.map((Photo, index) => {
                                                            return (
                                                                <>
                                                                    {(Photo?.file_type === '.pdf' || Photo?.file_type === '.docx') ? (
                                                                        <a href={Photo.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Photo ? Photo.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                />
                                                                    )}
                                                                    
                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card" style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Pay Slip</h3>
                                                        </div>
                                                        {pay.map((Pay, index) => {
                                                            return (
                                                                <>
                                                                    {(Pay?.file_type === '.pdf' || Pay?.file_type === '.docx') ? (
                                                                        <a href={Pay.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Pay ? Pay.doc_file : ''}
                                                                           style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                    )}
                                                                    
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                                <div className="col">
                                                    <div className="card  " style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Income Statement</h3>
                                                        </div>
                                                        {income.map((Income, index) => {
                                                            return (
                                                                <>
                                                                    {(Income?.file_type === ('.pdf' || Income?.file_type === '.docx')) ? (
                                                                        <a href={Income.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Income ? Income.doc_file : ''}
                                                                            style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                                        />)}
                                                                   
                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card " style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Loan Statement</h3>
                                                        </div>
                                                        {loan.map((Loan, index) => {
                                                            return (
                                                                <>
                                                                    {(Loan?.file_type === ('.pdf' || Loan?.file_type === '.docx')) ? (
                                                                        <a href={Loan.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Loan ? Loan.doc_file : ''}
                                                                          style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                    )}

                                                                    
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                                <div className="col">
                                                    <div className="card  " style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Bank Statement</h3>
                                                        </div>
                                                        {bank.map((Bank, index) => {
                                                            return (
                                                                <>
                                                                    {(Bank?.file_type === '.pdf' || Bank?.file_type === '.docx') ? (
                                                                        <a href={Bank.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Bank ? Bank.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                              />
                                                                    )}
                                                                    
                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card" style={{ border: "1px solid silver" }} >
                                                        <div className="card-body" style={{ background: '#4239a4' }}>
                                                            <h3 className="card-title text-light ">Employment Letter</h3>
                                                        </div>
                                                        {employ.map((Employ, index) => {
                                                            return (
                                                                <>
                                                                    {(Employ?.file_type === '.pdf' || Employ?.file_type === '.docx') ? (
                                                                        <a href={Employ.doc_file} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        <Image src={Employ ? Employ.doc_file : ''}
                                                                           
                                                                            style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                    )}

                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                        <div className="accordion">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button d-flex justify-content-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        <span className='text-primary fw-bold'>"EWA Document"</span>
                                                                    </button>
                                                                </h2>
                                                                {/* <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"> */}
                                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                                                                    <div className="accordion-body">
                                                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                                <tr>
                                                                                    <th className="font-weight-bold">Documents Type  </th>
                                                                                    <th className="font-weight-bold">Doc File </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <>
                                                                                <tbody>
                                                                                    {documents?.ewaDocs?.length > 0 && documents?.ewaDocs.map((option, i) => {
                                                                                        const { type } = option;
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td><span className="font-weight-normal1">{option.type ? type.replace(/_/g, " ") : "-"}</span></td>
                                                                                                <td><span className="font-weight-normal1">
                                                                                                    <a href={option.doc_file} download > <img
                                                                                                        src="/assets/img/pdf.jpeg"
                                                                                                        style={{ width: '50px', height: '35px', border: '1px solid silver' }}
                                                                                                        className="card-img-top m-1"
                                                                                                        alt="PDF"
                                                                                                    />
                                                                                                    </a></span></td>
                                                                                            </tr>)
                                                                                    })
                                                                                    } </tbody>
                                                                                <tbody>
                                                                                    {documents?.ewaDocs?.length == 0 &&
                                                                                        <tr>
                                                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                                                        </tr>
                                                                                    }
                                                                                </tbody> </>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion mt-4">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne">
                                                                        <span className='text-primary d-flex justify-content-center fw-bold'>{"On-boarding Document"}</span>
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne">
                                                                    {/* <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"> */}
                                                                    <div className="accordion-body">
                                                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                                <tr>
                                                                                    <th className="font-weight-bold">Documents Type  </th>
                                                                                    <th className="font-weight-bold">Doc File </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <>
                                                                                <tbody>
                                                                                    {
                                                                                        Object.keys(documents?.onBoardingdoc || {}).some(docType => documents.onBoardingdoc[docType].length > 0) ?
                                                                                            Object.keys(documents.onBoardingdoc).map((docType, i) => {
                                                                                                return documents.onBoardingdoc[docType].length > 0 &&
                                                                                                    documents.onBoardingdoc[docType].map((doc, j) => {
                                                                                                        return (
                                                                                                            <tr key={`${i}-${j}`}>
                                                                                                                <td><span className="font-weight-normal1">{doc.doc_type.replace(/_/g, " ")}</span></td>
                                                                                                                <td><span className="font-weight-normal1">
                                                                                                                    {doc?.file_type != ".pdf" ? <Image
                                                                                                                        src={doc.doc_file}
                                                                                                                        style={{ width: '50px', height: '35px', border: '1px solid silver' }}
                                                                                                                        className="card-img-top m-1"
                                                                                                                        alt="..."
                                                                                                                    /> :
                                                                                                                        <a href={doc.doc_file} download >
                                                                                                                            <img
                                                                                                                                src="/assets/img/pdf.jpeg"
                                                                                                                                style={{ width: '50px', height: '35px', border: '1px solid silver' }}
                                                                                                                                className="card-img-top m-1"
                                                                                                                                alt="PDF"
                                                                                                                            />
                                                                                                                        </a>}
                                                                                                                </span></td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })/*  :
                                                                                                    <tr key={i}>
                                                                                                        <td colSpan="2" className="text-center">No data available for {docType}</td>
                                                                                                    </tr> */
                                                                                            }) :
                                                                                            <tr>
                                                                                                <td colSpan="2" className="text-center">No data available</td>
                                                                                            </tr>
                                                                                    } </tbody>

                                                                            </>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion mt-4">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne">
                                                                        <span className='text-primary d-flex justify-content-center fw-bold'>{"Pay Slip Document"}</span>                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne2" className="accordion-collapse collapse" aria-labelledby="headingOne">
                                                                    {/* <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"> */}
                                                                    <div className="accordion-body">
                                                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                                <tr>
                                                                                    <th className="font-weight-bold">Month  </th>
                                                                                    <th className="font-weight-bold">Created Date </th>
                                                                                    <th className="font-weight-bold">Updated Date </th>
                                                                                    <th className="font-weight-bold">Doc File </th>
                                                                                </tr>
                                                                            </thead>

                                                                            <>
                                                                                <tbody>
                                                                                    {documents?.paySlipData?.length > 0 && documents?.paySlipData.map((option, i) => {
                                                                                        const { month } = option;
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td><span className="font-weight-normal1">{option.month ? month : "-"}</span></td>
                                                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                                                <td><span className="font-weight-normal1">{TimeZone(option.updated_at).timeZone}</span></td>
                                                                                                <td><span className="font-weight-normal1">
                                                                                                    <a href={option.payslip_doc} download > <img
                                                                                                        src="/assets/img/pdf.jpeg"
                                                                                                        style={{ width: '50px', height: '35px', border: '1px solid silver' }}
                                                                                                        className="card-img-top m-1"
                                                                                                        alt="PDF"
                                                                                                    />
                                                                                                    </a></span></td>
                                                                                            </tr>)
                                                                                    })
                                                                                    } </tbody>

                                                                                <tbody>
                                                                                    {documents?.paySlipData?.length == 0 &&
                                                                                        <tr>
                                                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                                                        </tr>
                                                                                    }
                                                                                </tbody> </>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="additionalInfo" title="Additional Info"  >
                                {/* <Personalinfo /> */}
                                <div className="main-container container-fluid px-0 ">
                                    <div className="page-header my-3 py-2">
                                        <div className="modal-body application-modal px-0">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="tabsiconBox">
                                                        <div className="main-content-body main-content-body-contacts">
                                                            <h6>Employee Additional Information</h6>
                                                            <div className='btn_group' style={{}}>
                                                            </div>
                                                            <div className="main-contact-info-body">
                                                                <div className="media-list p-0">
                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-id-card"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF Member:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_member ? viewAdditionalInfo?.fnpf_member : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-barcode"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF Code:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_code ? viewAdditionalInfo?.fnpf_code : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF First Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_firstname ? viewAdditionalInfo?.fnpf_firstname : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-tag"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF Other Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_othernames ? viewAdditionalInfo?.fnpf_othernames : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-dollar-sign"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>YTD FNPF:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.ytd_fnpf ? "$" + viewAdditionalInfo?.ytd_fnpf : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-briefcase"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF Emp Cont Till Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_employers_contribution_till_date ? "$" + viewAdditionalInfo?.fnpf_employers_contribution_till_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-coins"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>FNPF Cont Till Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fnpf_total_till_date ? "$" + viewAdditionalInfo?.fnpf_total_till_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-id-badge"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Security Licence:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.security_licence ? viewAdditionalInfo?.security_licence : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-calendar-alt"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Security Licence Expiry:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.security_licence_expiry ? viewAdditionalInfo?.security_licence_expiry : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-id-card"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Full Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.full_name ? viewAdditionalInfo?.full_name : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-camera"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Photo:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.photo ? viewAdditionalInfo?.photo : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-venus-mars"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Gender:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.gender ? viewAdditionalInfo?.gender : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-male"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Fathers Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.fathers_name ? viewAdditionalInfo?.fathers_name : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-ruler-vertical"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Height:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.height ? viewAdditionalInfo?.height : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-weight"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Weight:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.weight ? viewAdditionalInfo?.weight : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-female"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Spouse:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.spouse ? viewAdditionalInfo?.spouse : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-briefcase"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Spouse Occupation:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.spouse_occupation ? viewAdditionalInfo?.spouse_occupation : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-car"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Transport Mode:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.transport_mode ? viewAdditionalInfo?.transport_mode : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-road"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Street:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.street ? viewAdditionalInfo?.street : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-city"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Suburb:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.suburb ? viewAdditionalInfo?.suburb : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-shield"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Emergency Contact:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.emergency_contact ? viewAdditionalInfo?.emergency_contact : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-phone"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Emergency Number:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.emergency_number ? viewAdditionalInfo?.emergency_number : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-sitemap"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Management:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.management ? viewAdditionalInfo?.management : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-calendar-alt"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Start Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.start_date ? viewAdditionalInfo?.start_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-calendar-check"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Finish Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.finish_date ? viewAdditionalInfo?.finish_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-clock"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Years Service:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.years_service ? viewAdditionalInfo?.years_service : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-briefcase"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Employment Type:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.employment_type ? viewAdditionalInfo?.employment_type : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-file-contract"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Contract:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.contract ? viewAdditionalInfo?.contract : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-tag"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Job Role:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.job_role ? viewAdditionalInfo?.job_role : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-cog"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>System Role:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.system_role ? viewAdditionalInfo?.system_role : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-check"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Status:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.status ? viewAdditionalInfo?.status : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-id-card"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Jointcard:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.jointcard ? viewAdditionalInfo?.jointcard : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-id-card"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Identification No. :</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.identification_num ? viewAdditionalInfo?.identification_num : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-calendar-plus"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Incremental Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.incremental_date ? viewAdditionalInfo?.incremental_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-industry"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Sector:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.sector ? viewAdditionalInfo?.sector : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-running"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Hobbies:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.hobbies ? viewAdditionalInfo?.hobbies : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-building"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Previous Employers Tin:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.previous_employers_tin ? viewAdditionalInfo?.previous_employers_tin : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-dollar-sign"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Total Paid Till Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.total_paid_till_date ? "$" + viewAdditionalInfo?.total_paid_till_date : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-graduate"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Qualifications:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.qualifications ? viewAdditionalInfo?.qualifications : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-certificate"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Certifications:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.certifications ? viewAdditionalInfo?.certifications : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-university"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Tertiary:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.tertiary ? viewAdditionalInfo?.tertiary : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-school"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>High School:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.high_school ? viewAdditionalInfo?.high_school : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-smoking"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Smoker:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.smoker ? viewAdditionalInfo?.smoker : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-notes-medical"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Health Report:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.health_report ? viewAdditionalInfo?.health_report : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-user-shield"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Familycare Leave Available:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.familycare_leave_available ? viewAdditionalInfo?.familycare_leave_available : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-baby"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Paternity Leave Available:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.paternity_leave_available ? viewAdditionalInfo?.paternity_leave_available : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-stethoscope"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Sick Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_sick_leave ? viewAdditionalInfo?.ytd_sick_leave : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-calendar-check"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Annual Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_annual_leave ? viewAdditionalInfo?.ytd_annual_leave : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-church"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Bereavement Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.ytd_bereavement_leave ? viewAdditionalInfo?.ytd_bereavement_leave : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-hands-helping"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Familycare Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.ytd_familycare_leave ? viewAdditionalInfo?.ytd_familycare_leave : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-baby-carriage"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Paternity Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_paternity_leave ? viewAdditionalInfo?.ytd_paternity_leave : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1">
                                                                                    <i className="fas fa-baby"></i>
                                                                                </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Maternity Leave:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_maternity_leave ? viewAdditionalInfo?.ytd_maternity_leave : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-cube"></i> </div> {/* Changed from fa-user to fa-cube */}
                                                                                <div className="w-70">
                                                                                    <label>Cycle Value:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.cyclevalue ? "$" + viewAdditionalInfo?.cyclevalue : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-credit-card"></i> </div> {/* Changed from far fa-user-circle to far fa-credit-card */}
                                                                                <div className="w-70">
                                                                                    <label>Payment Method:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.payment_method ? viewAdditionalInfo?.payment_method : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-university"></i> </div> {/* Changed from fas fa-user-tie to fas fa-university */}
                                                                                <div className="w-70">
                                                                                    <label>Bank Code:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.bank_code ? viewAdditionalInfo?.bank_code : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-university"></i> </div> {/* Changed from fas fa-user-tie to fas fa-university */}
                                                                                <div className="w-70">
                                                                                    <label>Second Bank Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.second_bankname ? viewAdditionalInfo?.second_bankname : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-address-card"></i> </div> {/* Changed from fa-user to fa-address-card */}
                                                                                <div className="w-70">
                                                                                    <label>Second Account Number:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.second_accountnumber ? viewAdditionalInfo?.second_accountnumber : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-id-badge"></i> </div> {/* Changed from far fa-user-circle to fas fa-id-badge */}
                                                                                <div className="w-70">
                                                                                    <label>Second Account Name:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.second_accountname ? viewAdditionalInfo?.second_accountname : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-coins"></i> </div> {/* Changed from fas fa-user-tie to fas fa-coins */}
                                                                                <div className="w-70">
                                                                                    <label>Second Amount:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.second_amount ? "$" + viewAdditionalInfo?.second_amount : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-calendar-check"></i> </div> {/* Changed from fas fa-user-tie to fas fa-calendar-check */}
                                                                                <div className="w-70">
                                                                                    <label>Total Bonuspaid Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.total_bonuspaid_to_date ? "$" + viewAdditionalInfo?.total_bonuspaid_to_date : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-calendar-alt"></i> </div> {/* Changed from fa-user to fas fa-calendar-alt */}
                                                                                <div className="w-70">
                                                                                    <label>Completed Pay Period:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.completed_pay_period ? viewAdditionalInfo?.completed_pay_period : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-money-bill-alt"></i> </div> {/* Changed from far fa-user-circle to far fa-money-bill-alt */}
                                                                                <div className="w-70">
                                                                                    <label>Annual Income Plus Bonus:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.annual_income_plus_bonus ? "$" + viewAdditionalInfo?.annual_income_plus_bonus : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-coins"></i> </div> {/* Changed from fas fa-user-tie to fas fa-coins */}
                                                                                <div className="w-70">
                                                                                    <label>Ytd Gross:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_gross ? "$" + viewAdditionalInfo?.ytd_gross : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-wallet"></i> </div> {/* Changed from fas fa-user-tie to fas fa-wallet */}
                                                                                <div className="w-70">
                                                                                    <label>Ytd Income Tax Withheld:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_income_tax_withheld ? "$" + viewAdditionalInfo?.ytd_income_tax_withheld : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar-sign"></i> </div> {/* Changed from fa fa-dollar to fas fa-dollar-sign */}
                                                                                <div className="w-70">
                                                                                    <label>Annual Income Plus Bonus:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.annual_income_plus_bonus ? "$" + viewAdditionalInfo?.annual_income_plus_bonus : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar-sign"></i> </div> {/* Changed from fa fa-dollar to fas fa-dollar-sign */}
                                                                                <div className="w-70"> <label>Ytd Gross:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_gross ? "$" + viewAdditionalInfo?.ytd_gross : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar-sign"></i> </div> {/* Changed from fa fa-dollar to fas fa-dollar-sign */}
                                                                                <div className="w-70"> <label>Ytd Income Tax Withheld:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.ytd_income_tax_withheld ? "$" + viewAdditionalInfo?.ytd_income_tax_withheld : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar-sign"></i> </div> {/* Changed from fa fa-dollar to fas fa-dollar-sign */}
                                                                                <div className="w-70">
                                                                                    <label>Ytd Srt Tax Withheld:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.ytd_srt_tax_withheld ? "$" + viewAdditionalInfo?.ytd_srt_tax_withheld : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar-sign"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Ytd Ecal Tax Withheld:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.ytd_ecal_tax_withheld ? "$" + viewAdditionalInfo?.ytd_ecal_tax_withheld : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-map"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Non Resident:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.non_resident ? viewAdditionalInfo?.non_resident : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-clock"></i>  </div>
                                                                                <div className="w-70"> <label>Time Group:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.time_group ? viewAdditionalInfo?.time_group : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-map-marker-alt"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Current Sites:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.current_sites ? viewAdditionalInfo?.current_sites : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-globe"></i> </div> {/* Changed from fa fa-user to fas fa-user */}
                                                                                <div className="w-70">
                                                                                    <label>Other Approved Sites:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.other_approved_sites ? viewAdditionalInfo?.other_approved_sites : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-handshake"></i> </div> {/* Changed from fa fa-user-circle to far fa-user-circle */}
                                                                                <div className="w-70">
                                                                                    <label>Contracted Client:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.contracted_client ? viewAdditionalInfo?.contracted_client : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-calendar-times"></i> </div> {/* Changed from fa fa-user-tie to fas fa-user-tie */}
                                                                                <div className="w-70"> <label>Termination Date:</label>
                                                                                    <span className="font-weight-normal1 fs-14">{viewAdditionalInfo?.termination_date ? viewAdditionalInfo?.termination_date : '--'}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-comment"></i> </div> {/* Changed from fa fa-user-tie to fas fa-user-tie */}
                                                                                <div className="w-70">
                                                                                    <label>Remarks:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.remarks ? viewAdditionalInfo?.remarks : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-clipboard"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Logs:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        {viewAdditionalInfo?.log ? viewAdditionalInfo?.log : '--'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-image"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>Photo:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        <Image src={viewAdditionalInfo?.data_photo ? viewAdditionalInfo?.data_photo : '--'}
                                                                                            style={{ width: '50px', height: '50px', border: "1px solid silver" }}
                                                                                            className="card-img-top m-1" alt="..." />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-file"></i> </div>
                                                                                <div className="w-70">
                                                                                    <label>CV:</label>
                                                                                    <span className="font-weight-normal1 fs-14">
                                                                                        <Image src={viewAdditionalInfo?.data_cv ? viewAdditionalInfo?.data_cv : '--'}
                                                                                            style={{ width: '50px', height: '50px', border: "1px solid silver" }}
                                                                                            className="card-img-top m-1" alt="..." />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Viewemployeedetail
