import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Breadcrumbsecond } from '../../components';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import PaginationComponent from '../../PaginationComponent';
import { getApi } from '../service/adminService';
import { Image } from 'antd';
import Search from '../../components/Search/Search';


const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Lendertrash = (props) => {
    console.log(props)
    const [detail, setDetail] = useState([]);
    const [loading, setloading] = useState(false);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [count, setCount] = useState('')
    const [ValidPagination, setValidPagination] = useState(false)
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const { id } = useParams();

    const getLendertrashData = async () => {
        // const response = await GetLendertrashData(token, id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/delete-lenders-list", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log(response);
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllLender);
                setCount(response.AllLender)
                //    settotalItems(response.data)
                setloader(false)
            }, 2000);
            setloader(true)

        } else {
            console.log("get employees Trash data response", response);
        }
    }

    useEffect(() => {
        getLendertrashData(token, search, pagesCount);
    }, [search, pagesCount, orderBy, sortedBy]);

    const handleStore = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Restore it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const response = await RecoverLender(token, data)
                const response = await getApi(`/restore-lender/${data}`)

                if (response?.status) {
                    Swal.fire(
                        'Restore!',
                        'User Account has been Restore Successfully.',
                        'success'
                    )
                    getLendertrashData(token, search, pagesCount);

                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    const getDetail = async (user_id) => {
        setloading(true)

        // const response = await GetLenderDetail(token, user_id)
        const response = await getApi(`/get-lender/${user_id}`)

        console.log('response getDetail', response);
        if (response.data) {
            setDetail(response.data);
            setloading(false)
        } else {
            setloading(false)
            props.toast.error(response.message);
        }

    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    // console.log(loader, "loader")

    return (
        <>
            <>
                <Toaster position="top-right" reverseOrder={false} />
                {!showAddNewCases &&
                    <div>
                        <div className="main-container container-fluid px-0">
                            <div className="page-header mb-3">
                                <div className="page-leftheader">
                                    {/* <h4 className="page-title mb-0 text-primary">Employer - { dataArray && dataArray.length>0 ?dataArray[0].current_employer : ""}({count == "" ? 0 : count})</h4> */}
                                    <Breadcrumbsecond order={{ first: { name: "Lender", path: `${prefix}/lender` }, second: { name: `All Trash Lenders ${count && `(${count})`}` } }} />
                                </div>

                            </div>
                            <div className="row">
                                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <div className="form-group">
                                        <label className="form-label">Search</label>
                                        <div className="row g-xs">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th className="font-weight-bold">Logo</th>
                                                            <th className="font-weight-bold">Lender Detail {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Authorize Person Name {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Website {sortedBy == "website" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }}></i>}</th>
                                                            <th className="font-weight-bold">Address  {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                            <th className="font-weight-bold">Created At {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                            <th className="font-weight-bold">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    {loader == true ? <tbody>
                                                        <td />
                                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                            <Loader /> </div> </tbody> :
                                                        <>
                                                            <tbody>
                                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    return (
                                                                        <tr key={serial_num} >
                                                                            <td>
                                                                                <span className="font-weight-normal1" >
                                                                                    <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo != null ? option.logo : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                                </span>
                                                                            </td>
                                                                            <td> <Link className="text-primary" onClick={() => getDetail(option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal"><span className="font-weight-normal1" >{
                                                                                option.company_name !== null ? option.company_name : ""}<br />{option.mobile_number}<br />{option.email}</span></Link>
                                                                            </td>
                                                                            <td><span className="font-weight-normal1">{option.full_name ? option.full_name : ""}</span></td>
                                                                            <td className='font-weight-normal1'><span className="content_fix" ><a href={option.website} target="blank" style={{ color: "#0f2f82" }} >{option.website ? option.website : ""}</a></span></td>
                                                                            <td className='font-weight-normal1'><span className="content_fix" >{option.address ? option.address : ""}</span></td>
                                                                            <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                            <td><span className='btn btn-primary ' onClick={() => { handleStore(option.user_id) }} >Restore</span></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {dataArray.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={9} className='text-center'>No Data Available</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                            <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content modal-content-demo">
                                        <div className="modal-header"> <h6 className="modal-title">Lender Information</h6>
                                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                        <div className="modal-body application-modal">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="">
                                                        <div className="main-content-body main-content-body-contacts">
                                                            {/* <h6>Lender Information</h6> */}
                                                            <div className="main-contact-info-header bg-contacthead pb15">
                                                                <div className="media">
                                                                    <div className="media-body text-white ml-0">
                                                                        <h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
                                                                        <p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                            </div>
                                                            <div className="main-contact-info-body">
                                                                <div className="media-list p-0">
                                                                    <div className="media py-4 mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                                <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                                <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                                <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14"> ${detail ? detail.mini_loan_range : ''}</span> </div>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">${detail ? detail.max_loan_range : ''}</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="media py-4 border-top mt-0">
                                                                        <div className="media-body">
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure : ''} Months</span> </div>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                                <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure : ''} Months</span> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        </>
    )
}



export default Lendertrash;




