import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { deleteApi, getApi, postApi } from '../service/adminService';
import { Image } from 'antd';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Resizer from "react-image-file-resizer";
import Search from '../../components/Search/Search';

const Managepromotion = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Count, setCount] = useState();

    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef();
    const ref = useRef()
    const [disable, setDisable] = useState(false);
    const [sortedBy, setSortedBy] = useState("")
    const [orderBy, setOrderBy] = useState('desc')
    const [imageUpload, setimageUpload] = useState()
    const [titles, setTitles] = useState()
    const [ID, setID] = useState()
    const [disamount, setdisAmount] = useState()
    const [distype, setdisType] = useState()
    const [maxamount, setmaxAmount] = useState()
    const [availlimit, setavailLimit] = useState()
    const [redirect_url, setredirect_url] = useState()
    const [promotion_type, setpromotion_type] = useState()
    const [selectedImage, setSelectedImage] = useState();
    const [image, setImage] = useState(null)
    const [highlights, sethighlights] = useState()
    const [merchant_name, setmerchant_name] = useState()
    const [description, setdescription] = useState()
    const [term_and_condition, setterm_and_condition] = useState()
    const [start_date, setstart_date] = useState()
    const [end_date, setend_date] = useState()
    const [merchantdetail, setMerchantDetail] = useState([])
    const [merchantvalidate, setmerchantvalidate] = useState()
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [ValidPagination, setValidPagination] = useState(false)

    const PromotionDetail = async (token) => {
        // `/promotion-banner-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`
        // const response = await Promotiondetail(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/promotion-banner-list", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        // console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.count);
                setCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        PromotionDetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    const [showFullDescription, setShowFullDescription] = useState(false);

    const getMerchantsData = async (token) => {
        // `/get-merchant-list`
        // const response = await GetMerchant(token);
        const response = await getApi("/get-merchant-list");
        // console.log("fdgdgdgddfgdg", response)
        if (response?.status == true) {
            setMerchantDetail(response.data);

        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        getMerchantsData(admin.admin_token);
    }, []);
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
        return `${year}-${month}-${day}`;
    }

    const validateEndDate = () => {
        const startDate = new Date(Form.values.start_date);
        const endDate = new Date(Form.values.end_date);
        if (startDate > endDate) {
            Form.setFieldError("end_date", "End date must be greater than start date");
        } else {
            Form.setFieldError("end_date", "");
        }
    }

    function getMinimumEndDate() {
        const startDate = new Date(Form.values.start_date);
        const minimumEndDate = new Date(startDate);
        minimumEndDate.setDate(startDate.getDate());

        const year = minimumEndDate.getFullYear();
        let month = minimumEndDate.getMonth() + 1;
        let day = minimumEndDate.getDate();

        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                345,
                180,
                ["JPEG", "png", "jpg"],
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                345,
                180
            );
        });
    const image_select = async (e) => {
        try {
            const file = e.target.files[0];
            const base64String = await resizeFile(file); // Assuming resizeFile returns a Base64 string
            const blob = dataURItoBlob(base64String); // Convert Base64 string to Blob
            console.log(blob); // Now, you have the Blob object in 'blob'
            setimageUpload(blob);
            Form.setFieldValue('image', e.target.files[0]);
        } catch (error) {
            console.log(error);
        }
    }

    // Helper function to convert Base64 string to Blob
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab)
            ;

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    const [inputFields, setInputFields] = useState([{ value: "" }]);
    const [termsFields, setTermsFields] = useState([
        { value: "" },
    ]);

    const addInputField = () => {
        setInputFields([...inputFields, { value: "" }]);
    };

    const addTermsField = () => {
        setTermsFields([...termsFields, { value: "" }]);
    };
    const handleTermsChanges = (index, event) => {
        const newInputFields = [...termsFields];
        newInputFields[index].value = event.target.value;
        setTermsFields(newInputFields);
    };

    const removeTermsField = (index) => {
        const newTermsFields = [...termsFields];
        newTermsFields.splice(index, 1);
        setTermsFields(newTermsFields);
    };

    const removeInputField = (index) => {
        const newInputFields = [...inputFields];
        newInputFields.splice(index, 1);
        setInputFields(newInputFields);
    };

    const handleInputChanges = (index, event) => {
        const newInputFields = [...inputFields];
        newInputFields[index].value = event.target.value;
        setInputFields(newInputFields);
    };

    const Merchantvalidates = (e) => {
        console.log(e)
        setmerchantvalidate(e)
        Form.setFieldValue('merchant_id', e)
    }


    const Form = useFormik({
        initialValues: {
            title: "",
            // promotion_type: "",
            // redirect_url: "",
            highlights: "",
            discount_amount: "",
            discount_type: "",
            max_amount: "",
            avail_limit: "",
            merchant_id: "",
            start_date: "",
            end_date: "",
            description: "",
            term_and_condition: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Please Add Title').max(40, "Title must contain max 40 Characters"),
            // highlights: yup.string().required('Please Add Highlights'),
            discount_amount: yup.string().required('Please Add Discount Amount'),
            discount_type: yup.string().required('Please Select Discount Type'),
            max_amount: yup.string().required('Please Add Max Amount'),
            avail_limit: yup.string().required('Please Add Avail Limit'),
            merchant_id: yup.string().required('Please Select Merchant Name'),
            start_date: yup.string().required('Please Select Start Date'),
            end_date: yup.string().required('Please Select End Date'),
            description: yup.string().required('Please Add Description').min(100, "Description must contain min 100 Characters").max(150, "Description must contain max 150 Characters"),
            // term_and_condition: yup.string().required('Please Add T&C').min(100, "T&C' must contain min 100 Characters").max(150, "T&C' must contain max 150 Characters"),
            // promotion_type: yup.string().required('Please Select Promotion Type'),
            // redirect_url: yup.string().required('Please Add Promotion URL ').matches(
            //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            //     'Enter correct url!'
            // )
            //     .required('Please Enter Valid Promotion URL'),
            // image: yup.string().required('Please Select Image')
        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("title", Form.getFieldProps('title').value);
            const highlightsData = inputFields.map((obj) => ({ value: obj.value }));
            formData.append('highlights', JSON.stringify(highlightsData));
            // formData.append("highlights", Form.getFieldProps('highlights').value);
            formData.append("discount_amount", Form.getFieldProps('discount_amount').value)
            formData.append("discount_type", Form.getFieldProps('discount_type').value)
            formData.append("max_amount", Form.getFieldProps('max_amount').value)
            formData.append("avail_limit", Form.getFieldProps('avail_limit').value)
            formData.append("merchant_id", merchantvalidate)
            formData.append("start_date", Form.getFieldProps('start_date').value);
            formData.append("end_date", Form.getFieldProps('end_date').value);
            formData.append("description", Form.getFieldProps('description').value);
            const termsData = termsFields.map((obj) => ({ value: obj.value }));
            formData.append('term_and_condition', JSON.stringify(termsData));
            // formData.append("term_and_condition", Form.getFieldProps('term_and_condition').value);
            // formData.append("promotion_type", Form.getFieldProps('promotion_type').value);
            // formData.append("redirect_url", Form.getFieldProps('redirect_url').value);
            formData.append("image", imageUpload);
            /* Addpromotion(admin.admin_token, formData) */
            postApi("/add-promotion", formData, true).then((res) => {
                console.log(res)
                if (res.status == true) {
                    const Sendnotification = async () => {
                        // const body = JSON.stringify({
                        //     title: values.title,
                        //     body: values.description
                        // });
                        // const res = await sendnotification(admin.admin_token, body)
                        const res = await postApi(`/banner-notification`, {
                            title: values.title,
                            body: values.description
                        })
                        console.log(res)
                    }
                    Sendnotification()
                    setimageUpload()
                    toast.success(res.message);
                    PromotionDetail(admin.admin_token);
                    ref.current.click()
                    setmerchantvalidate("")

                    resetForm({ values: "" })
                }
            }).catch((err) => {

            })
        }
    })
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const handleInputChange = (event) => {
        Form.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }


    const handleDelete = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deletePromotion(admin.admin_token, id)
                const response = await deleteApi(`/delete-promotion-banner/${id}`)

                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'User Account has been deleted Successfully.',
                        'success'
                    )
                    PromotionDetail(admin.admin_token);
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }


    // const imageChange = (e) => {
    //     setImage(e.target.files[0])
    //     setSelectedImage(e.target.files[0])
    //     FormEdit.setFieldValue('image', e.target.files[0].name)
    // };
    const resizeFiles = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                345,
                180,
                ["JPEG", "png", "jpg"],
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                345,
                180
            );
        });
    const imageChange = async (e) => {
        // setImage(e.target.files[0])
        setSelectedImage(e.target.files[0])
        // FormEdit.setFieldValue('image', e.target.files[0].name)
        try {
            const file = e.target.files[0];
            const base64String = await resizeFiles(file);
            const blob = dataURItoBlobs(base64String);
            console.log(blob);
            setImage(blob);
            FormEdit.setFieldValue('image', e.target.files[0].name)
        } catch (error) {
            console.log(error);
        }
    };

    function dataURItoBlobs(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }
    const role_id = highlights;
    const editterms = term_and_condition
    // const [permissions, setPermissions] = useState([]);
    // const [edittermsandcondtion, setEdittermsandcondtion] = useState([])
    const [edittermsandcondtionupdate, setEdittermsandcondtionupdate] = useState([{ value: "" }]);
    const [editHeighlight, setEditHeighlight] = useState([{ value: "" }]);
    // for Edit Highlights Start
    useEffect(() => {
        if (role_id?.length > 0) {
            const parsedPermissions = JSON.parse(role_id);
            // setPermissions(parsedPermissions);
            setEditHeighlight(parsedPermissions);
        }
    }, [role_id]);
    const addHeighlightField = () => {
        setEditHeighlight([...editHeighlight, { value: "" }]);
    };

    const handleHeighlightChanges = (index, event) => {
        const newInputFields = [...editHeighlight];
        newInputFields[index].value = event.target.value;
        setEditHeighlight(newInputFields);
    };

    const removeEditHeighlightField = (index) => {
        const newTermsFields = [...editHeighlight];
        newTermsFields.splice(index, 1);
        setEditHeighlight(newTermsFields);
    };

    // for Edit Highlights End

    // for Edit Terms and Condition Start

    useEffect(() => {
        if (editterms?.length > 0) {
            const parsedPermissions = JSON.parse(editterms);
            // setEdittermsandcondtion(parsedPermissions);
            setEdittermsandcondtionupdate(parsedPermissions);
        }
    }, [editterms]);

    const addTermsandcondtionField = () => {
        setEdittermsandcondtionupdate([...edittermsandcondtionupdate, { value: "" }]);
    };

    const handleTermsandcondtionChanges = (index, event) => {
        const newInputFields = [...edittermsandcondtionupdate];
        newInputFields[index].value = event.target.value;
        setEdittermsandcondtionupdate(newInputFields);
    };

    const removeEditTermsandcondtionField = (index) => {
        const newTermsFields = [...edittermsandcondtionupdate];
        newTermsFields.splice(index, 1);
        setEdittermsandcondtionupdate(newTermsFields);
    };
    const validateEndDates = () => {
        const startDate = new Date(FormEdit.values.start_date);
        const endDate = new Date(FormEdit.values.end_date);
        if (startDate > endDate) {
            FormEdit.setFieldError("end_date", "End date must be greater than start date");
        } else {
            FormEdit.setFieldError("end_date", "");
        }
    }

    function getMinimumEndDates() {
        const startDate = new Date(FormEdit.values.start_date);
        const minimumEndDate = new Date(startDate);
        minimumEndDate.setDate(startDate.getDate());

        const year = minimumEndDate.getFullYear();
        let month = minimumEndDate.getMonth() + 1;
        let day = minimumEndDate.getDate();

        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    }
    // console.log(merchant_name, "_________________________")

    // const handlenmerchantname = (e) => {
    //     console.log(e)
    //     setmerchant_name(e)
    // }

    useEffect(() => {
        // console.log(merchant_name)
    }, [merchant_name])


    const FormEdit = useFormik({

        initialValues: {
            title: titles,
            highlights: highlights,
            discount_amount: disamount,
            discount_type: distype,
            max_amount: maxamount,
            avail_limit: availlimit,
            merchant_id: merchant_name,
            description: description,
            term_and_condition: term_and_condition,
            start_date: start_date,
            end_date: end_date,
            image: image,
            // redirect_url: redirect_url,
            // promotion_type: promotion_type

        },
        enableReinitialize: true,

        validationSchema: yup.object({
            title: yup.string().required('Please Enter Title').max(40, "Title must contain max 40 Characters"),
            // highlights: yup.string().required('Please Add Highlights'),
            discount_amount: yup.string().required('Please Add Discount Amount'),
            discount_type: yup.string().required('Please Select Discount Type'),
            max_amount: yup.string().required('Please Add Max Amount'),
            avail_limit: yup.string().required('Please Add Avail Limit'),
            merchant_id: yup.string().required('Please Select Merchant'),
            description: yup.string().required('Please Add Description').min(100, "Description must contain min 100 Characters").max(150, "Description must contain max 150 Characters"),
            // term_and_condition: yup.string().required('Please Add T&C').min(100, "T&C' must contain min 100 Characters").max(150, "T&C' must contain max 150 Characters"),
            start_date: yup.string().required('Please Select Start Date'),
            end_date: yup.string().required('Please Select End Date'),
            // promotion_type: yup.string().required('Please Select Promotion Type'),
            // redirect_url: yup.string().required('Please add Promotion URL ').matches(
            //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            //     'Enter correct url!'
            // )
            //     .required('Please Enter Valid Promotion URL')
            // image: yup.string().required('Please Select Image'),

        }),

        onSubmit: async values => {
            const formData = new FormData();
            formData.append("title", FormEdit.getFieldProps('title').value);
            // formData.append("promotion_type", FormEdit.getFieldProps('promotion_type').value);
            // formData.append("redirect_url", FormEdit.getFieldProps('redirect_url').value);                      
            const EditHeighlight = editHeighlight.map((obj) => ({ value: obj.value }));
            formData.append('highlights', JSON.stringify(EditHeighlight));
            // formData.append("highlights", FormEdit.getFieldProps('highlights').value);
            formData.append("discount_amount", FormEdit.getFieldProps('discount_amount').value);
            formData.append("discount_type", FormEdit.getFieldProps('discount_type').value);
            formData.append("max_amount", FormEdit.getFieldProps('max_amount').value);
            formData.append("avail_limit", FormEdit.getFieldProps('avail_limit').value);
            formData.append("merchant_id", FormEdit.getFieldProps('merchant_id').value);
            formData.append("description", FormEdit.getFieldProps('description').value);
            // formData.append("term_and_condition", FormEdit.getFieldProps('term_and_condition').value);        
            const EditTermsandCondition = edittermsandcondtionupdate.map((obj) => ({ value: obj.value }));
            formData.append('term_and_condition', JSON.stringify(EditTermsandCondition));
            formData.append("start_date", FormEdit.getFieldProps('start_date').value);
            formData.append("end_date", FormEdit.getFieldProps('end_date').value);
            formData.append("image", image);
            // 
            // const response = await EditPromotionDetail(admin.admin_token, formData, ID)
            const response = await postApi(`/edit-promotion/${ID}`, formData, true)

            if (response.status === true) {
                toast.success(response.message);
                tableRef.current.click()
                PromotionDetail(admin.admin_token);

            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage Promotion  {Count ? "(" + Count + ")" : ""} </h4>
                            </div>
                            <div className='page-rightheader'>
                                <button className='btn btn-primary' data-bs-target="#add_settlement"
                                    data-bs-toggle="modal"> Add Banner</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap"/*  ref={tableRef} */>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Image  </th>
                                                        <th className="font-weight-bold">Merchant {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Title  {sortedBy == "title" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }}></i>}</th>
                                                        <th className="font-weight-bold">Highlights  {sortedBy == "highlights" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('highlights') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('highlights') }}></i>}</th>
                                                        <th className="font-weight-bold">Dis Amount  {sortedBy == "discount_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('discount_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('discount_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Dis Type  {sortedBy == "discount_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('discount_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('discount_type') }}></i>}</th>
                                                        <th className="font-weight-bold">Max Amount  {sortedBy == "max_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('max_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('max_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Limit Per User  {sortedBy == "avail_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('avail_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('avail_limit') }}></i>}</th>
                                                        <th className="font-weight-bold">Description  {sortedBy == "description" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('description') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('description') }}></i>}</th>
                                                        <th className="font-weight-bold">T&C  {sortedBy == "term_and_condition" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('term_and_condition') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('term_and_condition') }}></i>}</th>
                                                        <th className="font-weight-bold">Start Date  {sortedBy == "start_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('start_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('start_date') }}></i>}</th>
                                                        <th className="font-weight-bold">End Date  {sortedBy == "end_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('end_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('end_date') }}></i>}</th>
                                                        {/* <th className="font-weight-bold">Promotion Type  {sortedBy == "promotion_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('promotion_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('promotion_type') }}></i>}</th>
                                                        <th className="font-weight-bold">Promotion URL  {sortedBy == "redirect_url" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('redirect_url') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('redirect_url') }}></i>}</th> */}
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                        <th className="font-weight-bold"> Actions</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td /><td /><td /><td />
                                                    <div>
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { id, image, title, discount_type, discount_amount, max_amount, promotion_type, avail_limit, redirect_url, highlights, description, term_and_condition, start_date, end_date } = option;
                                                                var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                const toggleDescription = () => {
                                                                    setShowFullDescription(!showFullDescription);
                                                                };

                                                                const initialDescription = option.description || "-";
                                                                const maxDescriptionWidth = "200px";
                                                                const highlight = JSON.parse(option?.highlights);
                                                                const termcondition = JSON.parse(option?.term_and_condition)
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td>
                                                                            <span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.image != null ? option.image : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                            </span>
                                                                        </td>
                                                                        <td><Link className="text-primary" to={prefix + `/merchantdetail/${option.merchant_id}`}><span className="font-weight-normal1">{option?.merchantDetail?.company_name ? option?.merchantDetail?.company_name : "--"} </span></Link></td>
                                                                        <td><span className="font-weight-normal1">{option.title ? title : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">
                                                                            {/* {highlight?.map((data) => data?.value)} */}
                                                                            <ul style={{ listStyleType: "none" }}>
                                                                                {highlight.length > 0 && JSON.parse(highlight).map((event, index) => (
                                                                                    <li key={index}>{event?.value ? event.value : "--"}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </span></td>
                                                                        {/* <td><span className="font-weight-normal1">{option.highlights ? highlights : "-"} </span></td> */}
                                                                        <td><span className="font-weight-normal1">{option.discount_amount ? "$" + discount_amount : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.discount_type ? discount_type : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.max_amount ? "$" + max_amount : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.avail_limit ? avail_limit : "-"} </span></td>
                                                                        <td><span className="tableDescriptionWidth font-weight-normal1">  {/* content_fix */} {option?.description ? description : "-"}</span></td>
                                                                        <td><span className="tableDescriptionWidth font-weight-normal1">
                                                                            <ul style={{ listStyleType: "none" }}>
                                                                                {termcondition.length > 0 && JSON.parse(termcondition).map((termconditions, index) => (
                                                                                    <li key={index}>{termconditions?.value ? termconditions?.value : "--"}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </span></td>
                                                                        {/* <td><span className="font-weight-normal1" style={{maxWidth:"10px"}}>{option.description ? description : "NA"} </span></td> */}
                                                                        {/* <td><span className="font-weight-normal1">{option.promotion_type ? promotion_type : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">
                                                                            <a href={option.redirect_url} target="blank" style={{ color: "#0f2f82" }} >
                                                                                {option.redirect_url ? redirect_url : "NA"}</a> </span></td> */}
                                                                        {/* <td><span className="content_fix font-weight-normal1">{option?.term_and_condition ? term_and_condition : "-"} </span></td> */}
                                                                        <td><span className="font-weight-normal1">{option.start_date ? start_date : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.end_date ? end_date : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{timezone} </span></td>
                                                                        <td><button type="button" className="btn btn-primary" data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(id); setTitles(title); setImage(image); setpromotion_type(promotion_type); setredirect_url(redirect_url); sethighlights(highlights); setdescription(description); setterm_and_condition(term_and_condition); setstart_date(start_date); setend_date(end_date); setdisAmount(discount_amount); setdisType(discount_type); setmaxAmount(max_amount); setavailLimit(avail_limit); setmerchant_name(option?.merchant_id) }} ><i className="fas fa-edit " /></button> &nbsp;&nbsp;
                                                                            <button type="button" className='btn btn-danger' onClick={() => { handleDelete(option.id) }} ><i className="fas fa-trash " /></button></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>


                    {/* Add Banner modal start */}
                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Add Banner</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body ">
                                    <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                        {Form.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Title:</label>
                                                            <input type="text" placeholder='Enter Promotion Title' /* minLength={60} */ {...Form.getFieldProps("title")} className="form-control" />
                                                            {Form.touched.title && Form.errors.title ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.title}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">Max Amount :</label>
                                                            <input type="number" placeholder='Enter Max Amount' {...Form.getFieldProps("max_amount")} className="form-control" />
                                                            {Form.touched.max_amount && Form.errors.max_amount ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.max_amount}</div> : ''}
                                                        </div>
                                                        {/* <div className="col-6">
                                                            <label className="form-label">Highlights:</label>
                                                            <input type="text" placeholder='Enter Highlights'  {...Form.getFieldProps("highlights")} className="form-control" />
                                                            {Form.touched.highlights && Form.errors.highlights ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.highlights}</div> : ''}
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Discount Amount :</label>
                                                            <input type="number" placeholder='Enter Discount Amount' {...Form.getFieldProps("discount_amount")} className="form-control" />
                                                            {Form.touched.discount_amount && Form.errors.discount_amount ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.discount_amount}</div> : ''}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="form-label">Discount Type :</label>
                                                            <select className="form-control" name="discount_type"
                                                                {...Form.getFieldProps("discount_type")}
                                                                placeholder="Enter Discount Type"  >
                                                                <option value="null">Select Discount Type</option>
                                                                <option value="fixed" >Fixed</option>
                                                                <option value="percentage">Percentage</option>
                                                            </select>
                                                            {Form.touched.discount_type && Form.errors.discount_type ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.discount_type}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">

                                                        <div className="col-6">
                                                            <label className="form-label">Limit Per User :</label>
                                                            <input type="number" placeholder='Enter Limit Per User' {...Form.getFieldProps("avail_limit")} className="form-control" />
                                                            {Form.touched.avail_limit && Form.errors.avail_limit ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.avail_limit}</div> : ''}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="form-label">Merchant Name :</label>
                                                            <select className="form-control" name='merchant_id' placeholder="Select Merchant Name"
                                                                onChange={(e) => Merchantvalidates(e.target.value)} /* {...Form.getFieldProps("merchant_id")} */>
                                                                <option value="null"> --Select Merchant-- </option>
                                                                {merchantdetail.length > 0 && merchantdetail.map((merchant, index) => {
                                                                    return (
                                                                        <option key={index} value={merchant.user_id}>{merchant.company_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {Form.touched.merchant_id && Form.errors.merchant_id ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.merchant_id}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Start Date:</label>
                                                            <input type="date" placeholder='Select Start Date' {...Form.getFieldProps("start_date")} className="form-control" min={getCurrentDate()} />
                                                            {Form.touched.start_date && Form.errors.start_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.start_date}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">End Date:</label>
                                                            <input type="date" placeholder='Select End Date' {...Form.getFieldProps("end_date")} className="form-control" min={getMinimumEndDate()} onBlur={validateEndDate} />
                                                            {Form.touched.end_date && Form.errors.end_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.end_date}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 ">
                                                    <label className="form-label">Description :</label>
                                                    <textarea placeholder='Enter Description' rows="3"/*  maxLength={100} */   {...Form.getFieldProps("description")} className="form-control" />
                                                    {Form.touched.description && Form.errors.description ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.description}</div> : ''}
                                                </div>
                                                {/* <div className="col-12">
                                                    <label className="form-label">Terms and Condition :</label>
                                                    <textarea placeholder='Enter Terms and Condition'  maxlength="200"  {...Form.getFieldProps("term_and_condition")} className="form-control" />
                                                    {Form.touched.term_and_condition && Form.errors.term_and_condition ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.term_and_condition}</div> : ''}
                                                </div> */}

                                                {/* <div className="col-12">
                                                    <label className="form-label">Promotion Type :</label>
                                                    <select className="form-control" name="promotion_type"
                                                        {...Form.getFieldProps("promotion_type")}
                                                        placeholder="Enter Payment Mode"  >
                                                        <option value="null">Select Promotion Type</option>
                                                        <option value="referral" >Referral</option>
                                                        <option value="offer">Offer</option>
                                                    </select>
                                                    {Form.touched.promotion_type && Form.errors.promotion_type ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.promotion_type}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">URL :</label>
                                                    <input type="text" placeholder='Enter Promotion URL' {...Form.getFieldProps("redirect_url")} className="form-control" />
                                                    {Form.touched.redirect_url && Form.errors.redirect_url ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.redirect_url}</div> : ''}
                                                </div> */}
                                                <div className='d-flex flex-wrap'>
                                                    {inputFields.map((field, index) => (
                                                        <div key={index} className="col-6">
                                                            <h5 className="">Highlights {index + 1}:
                                                                {inputFields.length > 1 && <button type="button" onClick={() => removeInputField(index)} className="fa fa-close btn btn-danger" style={{ float: 'right', marginBottom: '10px' }} ></button>}
                                                            </h5>
                                                            <input
                                                                type="text"
                                                                placeholder={`Enter Highlights ${index + 1}`}
                                                                value={field.value}
                                                                onChange={(e) => handleInputChanges(index, e)}
                                                                className={`form-control ${field.highlight ? "highlight" : ""
                                                                    }`}
                                                            />

                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="form-footer ">
                                                    <button type="button" onClick={addInputField} className="btn btn-primary">
                                                        Add
                                                    </button>
                                                </div><br /><br />
                                                {/* <button
                                                    aria-label="Close"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    type="button"
                                                    onClick={closeModal}
                                                ></button>  */}


                                                <div className='d-flex flex-wrap'>
                                                    {termsFields.map((field, index) => (

                                                        <div key={index} className="col-6">
                                                            <h5 >Terms & Condition {index + 1}:
                                                                {termsFields.length > 1 && <button type="button" onClick={() => removeTermsField(index)} className="fa fa-close btn btn-danger" style={{ float: 'right', marginBottom: '10px' }}>

                                                                </button>}</h5>
                                                            <input
                                                                type="text"
                                                                placeholder={`Enter T&C ${index + 1}`}
                                                                value={field.value}
                                                                onChange={(e) => handleTermsChanges(index, e)}
                                                                className={`form-control ${field.highlight ? "highlight" : ""
                                                                    }`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="form-footer ">
                                                    <button type="button" onClick={addTermsField} className="btn btn-primary"> Add</button>
                                                </div><br /><br />
                                                <label className="form-label">Image :</label>

                                                <div className="input-group file-browser mb-1">
                                                    <input type="file" className="form-control browse-file" placeholder="Upload your Banner"
                                                        onChange={(e) => image_select(e)} />
                                                    {/* <input type="file" className="form-control browse-file" placeholder="Upload your logo" />
                                                    <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChange={(e) => image_select(e)} style={{ display: 'none' }} /> </label> */}
                                                </div>
                                                {/* {Form.touched.image && Form.errors.image ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.image}</div> : ''} */}
                                                {imageUpload &&
                                                    <div><img src={URL.createObjectURL(imageUpload)} height={150} style={{ width: 'auto' }} alt="..." /></div>}</div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add Banner modal End */}

                    {/* Edit Banner Modal start */}

                    <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Edit Banner</h3>
                                    <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body application-modal">
                                    <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                        {FormEdit.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{FormEdit.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Title :</label>
                                                            <input type="text" placeholder='Enter Promotion Title' {...FormEdit.getFieldProps("title")} className="form-control" />
                                                            {FormEdit.touched.title && FormEdit.errors.title ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.title}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">Max Amount :</label>
                                                            <input type="number" placeholder='Enter Max Amount' {...FormEdit.getFieldProps("max_amount")} className="form-control" />
                                                            {FormEdit.touched.max_amount && FormEdit.errors.max_amount ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.max_amount}</div> : ''}
                                                        </div>
                                                        {/* <div className="col-6">
                                                            <label className="form-label">Highlights :</label>
                                                            <input type="text" placeholder='Enter Highlights' {...FormEdit.getFieldProps("highlights")} className="form-control" />
                                                            {FormEdit.touched.highlights && FormEdit.errors.highlights ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.highlights}</div> : ''}
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Discount Amount :</label>
                                                            <input type="number" placeholder='Enter Discount Amount' {...FormEdit.getFieldProps("discount_amount")} className="form-control" />
                                                            {FormEdit.touched.discount_amount && FormEdit.errors.discount_amount ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.discount_amount}</div> : ''}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="form-label">Discount Type :</label>
                                                            <select className="form-control" name="discount_type"
                                                                {...FormEdit.getFieldProps("discount_type")}
                                                                placeholder="Enter Discount Type">
                                                                <option value="null">Select Discount Type</option>
                                                                <option value="fixed" >Fixed</option>
                                                                <option value="percentage">Percentage</option>
                                                            </select>
                                                            {FormEdit.touched.discount_type && FormEdit.errors.discount_type ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.discount_type}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">

                                                        <div className="col-6">
                                                            <label className="form-label">Limit Per User :</label>
                                                            <input type="number" placeholder='Enter Avail Limit' {...FormEdit.getFieldProps("avail_limit")} className="form-control" />
                                                            {FormEdit.touched.avail_limit && FormEdit.errors.avail_limit ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.avail_limit}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">Merchant Name :</label>
                                                            <select className="form-control" name="merchant_id" placeholder="Select Merchant Name"
                                                                {...FormEdit.getFieldProps("merchant_id")} >
                                                                <option value="">--Select Merchant--</option>
                                                                {merchantdetail.length > 0 && merchantdetail.map((merchant, index) => {
                                                                    return (
                                                                        <option key={index} value={merchant.user_id}>{merchant.company_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {FormEdit.touched.merchant_id && FormEdit.errors.merchant_id ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.merchant_id}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">Description :</label>
                                                    <textarea placeholder='Enter Description' {...FormEdit.getFieldProps("description")} className="form-control" />
                                                    {FormEdit.touched.description && FormEdit.errors.description ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.description}</div> : ''}
                                                </div>
                                                {/* <div className="col-12">
                                                    <label className="form-label">T&C :</label>
                                                    <textarea placeholder='Enter T&C' {...FormEdit.getFieldProps("term_and_condition")} className="form-control" />
                                                    {FormEdit.touched.term_and_condition && FormEdit.errors.term_and_condition ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.term_and_condition}</div> : ''}
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Start Date:</label>
                                                            <input type="date" placeholder='Select Start Date' {...FormEdit.getFieldProps("start_date")} className="form-control" /* min={getCurrentDate()} */ />
                                                            {FormEdit.touched.start_date && FormEdit.errors.start_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.start_date}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">End Date:</label>
                                                            <input type="date" placeholder='Select End Date' {...FormEdit.getFieldProps("end_date")} className="form-control" min={getMinimumEndDates()} onBlur={validateEndDates} />
                                                            {FormEdit.touched.end_date && FormEdit.errors.end_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.end_date}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12">
                                                    <select className="form-control" name="promotion_type"
                                                        {...FormEdit.getFieldProps("promotion_type")}
                                                        placeholder="Enter Payment Mode"  >
                                                        <option value="null">Select Promotion Type</option>
                                                        <option value="referral" >Referral</option>
                                                        <option value="offer">Offer</option>
                                                    </select>
                                                    {FormEdit.touched.promotion_type && FormEdit.errors.promotion_type ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.promotion_type}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" placeholder='Enter Promotion URL' {...FormEdit.getFieldProps("redirect_url")} className="form-control" />
                                                    {FormEdit.touched.redirect_url && FormEdit.errors.redirect_url ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.redirect_url}</div> : ''}
                                                </div> */}
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        {editHeighlight.map((field, index) => (
                                                            <div key={index} className="col-6">
                                                                <h5 >Highlights {index + 1}:
                                                                    {editHeighlight.length > 1 && <button type="button" onClick={() => removeEditHeighlightField(index)} className="fa fa-close btn btn-danger" style={{ float: 'right', marginBottom: '10px' }}>

                                                                    </button>}
                                                                </h5>
                                                                <input
                                                                    type="text"
                                                                    placeholder={`Enter Highlights ${index + 1}`}
                                                                    value={field.value}
                                                                    onChange={(e) => handleHeighlightChanges(index, e)}
                                                                    className={`form-control ${field.highlight ? "highlight" : ""}`}
                                                                />
                                                            </div>
                                                        ))}
                                                        <div className="form-footer">
                                                            <button type="button" onClick={addHeighlightField} className="btn btn-primary">
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div><hr />
                                                <div className='col-md-12 mt-2'>
                                                    <div className='row'>
                                                        {edittermsandcondtionupdate?.length != 0 && edittermsandcondtionupdate?.map((field, index) => (
                                                            <div key={index} className="col-6">
                                                                <h5>Terms & Condition {index + 1}:
                                                                    {edittermsandcondtionupdate.length > 1 && <button type="button" onClick={() => removeEditTermsandcondtionField(index)} className="fa fa-close btn btn-danger" style={{ float: 'right', marginBottom: '10px' }}>

                                                                    </button>}</h5>
                                                                <input
                                                                    type="text"
                                                                    placeholder={`Enter T&C ${index + 1}`}
                                                                    value={field.value}
                                                                    onChange={(e) => handleTermsandcondtionChanges(index, e)}
                                                                    className={`form-control ${field.highlight ? "highlight" : ""}`}
                                                                />
                                                            </div>
                                                        ))}
                                                        <div className="form-footer mb-2">
                                                            <button type="button" onClick={addTermsandcondtionField} className="btn btn-primary">
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="form-label">Image :</label>
                                                <div className="input-group file-browser mb-2">

                                                    <input type="file" className="form-control browse-file" placeholder="Upload your Banner"
                                                        // {...FormEdit.getFieldProps("image")}
                                                        onChange={(e) => imageChange(e)} />
                                                </div>
                                                {!selectedImage ?

                                                    <img src={image ? image : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="/" height={150} style={{ width: 'auto' }} />
                                                    :
                                                    <img src={URL.createObjectURL(selectedImage)} height={150} style={{ width: 'auto' }} alt="..." />}


                                                {/* {FormEdit.touched.image && FormEdit.errors.image ?
                                                 <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.image}</div> : ''} */}
                                            </div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Edit Banner Modal End */}

                </div>

            }

        </>
    )
}

export default Managepromotion


