import React, { useEffect, useRef, useState } from 'react'
import { getApi, postApi } from '../service/adminService';
import PaginationComponent from '../../PaginationComponent';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Search from '../../components/Search/Search';

const Directusers = () => {

    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [loader, setloader] = useState(true)
    const [pagesCount, setpagesCount] = useState(1);
    const [search, setSearch] = useState('');
    const [pagesCheck, setpagesCheck] = useState(1);
    const [searchpage, setSearchpage] = useState('');
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [showAddNewCasesdirect, setshowAddNewCasesdirect] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalPagesCountEmployer, setTotalPagesCountEmployer] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [viewEmployer, setViewEmployer] = useState([])
    const tableRef = useRef(null);
    const [check, setCheck] = useState(false)
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [demo, setDemo] = useState('')
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [ValidPagination, setValidPagination] = useState(false)

    //    console.log(pagesCheck)
    const EmployeeData = async (token) => {
        // const response = await GetEmployee(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/get-employee-list", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        if (response.status == true) {
            setTimeout(() => {
                // setCheck(true)
                // console.log(response)
                setDataArray(response.data);
                setTotalPagesCount(response.AllEmployee);
                settotalItems(response.AllEmployee)
                setloader(false)
            }, 2000);
            setloader(true)
        }
    }
    const employer = JSON.parse(localStorage.getItem("admin"));
    const token = employer.admin_token;


    const getEmployeesData = async (token, pageNumber, filterVlu, employee) => {
        setDemo(employee)
        // console.log(demo)
        // const response = await GetEmployersdirect(token, pageNumber, filterVlu);
        const response = await getApi("/get-employer-list", { page_number: pageNumber, search: filterVlu });

        // console.log("EployeeViews", response)
        if (response.status == true) {
            setViewEmployer(response.data);
            setTotalPagesCountEmployer(response.AllEmployer);

        } else {
            console.log("get employees data response", response);
        }
    }
    const selfAssign = async (id) => {
        EmployeeData()
        // const updata = {
        //     "employer_id": "0",
        //     "customer_id": id,
        //     "company_name": "Self Employed"
        // }

        // const response = await selfAssignEmployee(token, updata)
        const response = await postApi("/assign-employer", { "employer_id": "0", "customer_id": id, "company_name": "Self Employed" })
        console.log(response)
        if (response.status == true) {
            toast.success("Assign to Self Employed Successfully");
            setCheck(false)
        } else {
            console.log(response.error)
        }
    }

    const AssignEmployer = async (company_name, user_id) => {
        // const updata = {
        //     "customer_id": demo,
        //     "company_name": company_name,
        //     "employer_id": user_id,
        // }
        // const response = await AssignEmployercheck(token, updata)
        const response = await postApi("/assign-employer", {
            "customer_id": demo,
            "company_name": company_name,
            "employer_id": user_id,
        })

        console.log(response)
        if (response.status == true) {
            toast.success("Assign to Employee Successfully");
            EmployeeData()
            setCheck(false)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        getEmployeesData(admin.admin_token, pagesCheck, searchpage, demo)
    }, [pagesCheck, searchpage, demo])


    useEffect(() => {
        EmployeeData(admin.admin_token, pagesCount, search);
        // getEmployeesData(admin.admin_token, pagesCheck, searchpage )
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // EmployeeData(admin.admin_token, pagesCount, search);
    }
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Direct Borrower {totalItems ? '(' + totalItems + ')' : ''}</h4>
                            </div>

                            {/* <AssignToLender show={show} handleShow={handleShow} handleClose={handleClose} /> */}

                            {/* <div className="page-rightheader">
                        <div className="btn-list">

                            <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={tableRef.current}>
                                <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                            </DownloadTableExcel>
                        </div>
                    </div> */}
                        </div>
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">All Status</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <select className="form-control mb-0" name="status" value={queryFilter.status} onChange={(e) => handleChangeQueryBuilder(e)}>
                                            <option>Select Status</option>
                                            <option value="Active Employer">Active Merchant</option>
                                            <option value="InActive Employer">InActive Merchant</option>
                                           
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Select Date</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel} initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input placeholder="Search By Date" className="form-control fc-datepicker hasDatepicker" type="text" defaultValue="" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Borrower {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                        {/* <th className="font-weight-bold">Limit {sortedBy =="total_limit" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('total_limit')}} ></i> :<i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('total_limit')}}></i>}</th> */}
                                                        <th className="font-weight-bold">Country {sortedBy == "country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }}></i>}</th>
                                                        <th className="font-weight-bold">Created At {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}></i>}</th>
                                                        <th className="font-weight-bold">Assign Employer </th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader /> </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                //  let id = option.id
                                                                let Capitalize = (option) => {
                                                                    return option.charAt(0).toUpperCase() + option.slice(1);
                                                                }

                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td>
                                                                            {/* <Link to={{pathname: `${prefix}/EmployeeDetails/${option.id}`, state: {id}}} > */}
                                                                            <span className="font-weight-normal1">{option.first_name ? option.first_name : ""}<br />{option.mobile_number}</span>
                                                                            {/* </Link> */}
                                                                        </td>
                                                                        {/* <td><span className="font-weight-normal1">{option.total_limit   }</span></td> */}
                                                                        <td><span className="font-weight-normal1">{option.country == null ? "--" : <span>{option.country}</span>}</span></td>
                                                                        <td><span className="font-weight-normal1">{/* <Moment format="YYYY-MM-DD h:MM A"> */}{TimeZone(option.created_at).timeZone}</span></td>
                                                                        <td><Link to="" className="view-pro" onClick={() => getEmployeesData(admin.admin_token, pagesCheck, searchpage, option.customer_id)}
                                                                            data-bs-target="#assign-rm" data-bs-toggle="modal" style={{ width: "120px" }}>Assign Employer</Link>
                                                                            {/* <Link to="" onClick={() => selfAssign(option.customer_id)} className="view-pro"> Self Employee</Link> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            }
            <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog" style={{ display: 'block !important' }}>
                <div className="modal-dialog" role="document" style={{ overflowY: 'initial !important' }}>
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Assign Employer</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                        <div className="modal-body application-modal" >
                            <div className="input-group" >
                                <input type="text" className="form-control" placeholder="Search By Keyword"
                                    value={searchpage} onChange={(e) => setSearchpage(e.target.value)} /* onKeyDown={getEmployeesData} */ />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div><hr />
                            <div className='container' style={{ height: '60vh', overflowY: 'auto' }}>
                                {viewEmployer.length > 0 ?
                                    <>
                                        {/* {viewEmployer.filter((user) => user?.company_name?.toLowerCase().includes(searchpage.toLocaleLowerCase())).map((option, i) => { */}
                                        {viewEmployer.length > 0 && viewEmployer.map((option, i) => {
                                            let serial_number = ((10 * (pagesCheck - 1)) + i + 1);
                                            return (
                                                <>
                                                    <div className='row' key={serial_number}>
                                                        <div className="col-sm-12">
                                                            <div className='card'>
                                                                <div style={{ float: 'left' }}>
                                                                    {option.logo !== null ?
                                                                        <img style={{ width: 50, height: 55, borderRadius: '8px' }} src={option.logo} alt='...' /> :
                                                                        <img style={{ width: 50, height: 55, borderRadius: '8px' }} src={process.env.PUBLIC_URL + "/assets/img/Fintranxect-Logo-1.png"} alt='...' />}
                                                                </div>
                                                                <div>
                                                                    <div> &nbsp;&nbsp;&nbsp;  {option.company_name} </div>
                                                                    <div> &nbsp;&nbsp;&nbsp;  {option.mobile_number} </div>
                                                                    <div style={{ textTransform: 'none' }}> &nbsp;&nbsp;&nbsp;  {option.email} <span className="view-pro" style={{ float: 'right', marginTop: "-20px", cursor: 'pointer' }} data-bs-dismiss="modal"
                                                                        onClick={() => AssignEmployer(option.company_name, option.user_id)}>Assign</span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}</> :

                                    <div colSpan={10} className='text-center'>No Data Available</div>
                                }

                                {viewEmployer.length > 0 ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCountEmployer}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCheck(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''} </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Directusers
