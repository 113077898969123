import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Loader from '../../../components/Loader/loader';
import { Gettime } from '../../../components/TimeZone/TimeZone';
import { GetAttendancebyID, getApi } from '../../service/adminService';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const Attendance = () => {
  // const history = useHistory();
  // const back = history.goBack()
  const [loader, setloader] = useState(true)
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [dataArray, setDataArray] = useState([]);
  const [search, setSearch] = useState('');
  const tableRef = useRef(null);
  const [input, setInput] = useState(true);
  const admin = JSON.parse(localStorage.getItem("admin"));
  const user_Name = (admin.user_name)
  const token = admin.admin_token
  const [dsaDetail, setdsaDetail] = useState(false);
  const [date, setDate] = useState("")
  const [year, setYear] = useState("")

  // const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
  // const base_url = process.env.REACT_APP_API_URL;
  const { id } = useParams()
  const navigate = useNavigate();

  const handleChangeQueryBuilder = (e) => {
    // console.log(e, "checkingssssss")
    setDate(e)
  }

  const handleChangeQueryBuilders = (e) => {
    // console.log(e, "checkingssssss")
    setYear(e)
  }

  const ViewGetAttendancebyID = async (token) => {
    // const response = await GetAttendancebyID(token, id, date, year);
    const response = await getApi(`/borrower-attendance/${id}`, { month: date, year: year });

    console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
    if (response.status == true) {
      setTimeout(() => {
        setDataArray(response.data);

        setloader(false)
      }, 2000);
      setloader(true)
    } else if (response.status === false) {
      setDataArray("");
      setloader(false)
      console.log("get employees data response", response);

    }
    else if (response.message === 'Session expired') {
      localStorage.removeItem("employer");
      navigate('/employer/login')
    } else {
      console.log("get employees data response", response);
    }
  }

  useEffect(() => {
    ViewGetAttendancebyID(token)
  }, [date, year])
  const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX

  return (
    <>

      {!dsaDetail &&
        <div className="main-container container-fluid px-0">
          <div className="page-header my-3 py-2">
            <div className="page-leftheader">
              <h6 className="page-title mb-0 text-primary">Borrower Attendance</h6>
            </div>
            <div className="page-rightheader">
              <div className="btn-list">
                <div className="btn-list">
                  <a href={exportfix + `/export-borrower-attendance/${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Select Month and Year</label>
                <div className="row g-xs">
                  <div className="wd-200 mg-b-30">
                    <div className="input-group">
                      <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                        <option value="">Select Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      &nbsp;
                      <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilders(e.target.value)} >
                        <option value="">Select Year</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              {/* <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                      <thead className="border-bottom-0 pt-3 pb-3">
                        <tr>
                          <th className="font-weight-bold">Status  </th>
                          <th className="font-weight-bold">In Time </th>
                          <th className="font-weight-bold">Out Time  </th>
                          <th className="font-weight-bold">Total Hours  </th>
                          <th className="font-weight-bold">Attendance Date  </th>
                        </tr>
                      </thead>
                      {loader == true ? <tbody>
                        <td />
                        <div>
                          <Loader />
                        </div> </tbody> :
                        <>
                          <tbody>
                            {dataArray.length > 0 && dataArray.map((option, i) => {

                              let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                              const { id, customer_id, attendance_date, status, in_time, out_time } = option;
                              const calculateTotalTime = (inTime, outTime) => {
                                if (!in_time || !out_time) {
                                  return "-";
                                }
                                const inTimeDate = new Date(`${attendance_date}  ${inTime}`);
                                const outTimeDate = new Date(`${attendance_date} ${outTime}`);
                                const totalTimeMs = outTimeDate - inTimeDate;
                                const hours = Math.floor(totalTimeMs / 3600000);
                                const minutes = Math.floor((totalTimeMs % 3600000) / 60000);
                                return `${hours} hours ${minutes} minutes`;
                              };
                              return (
                                <tr key={serial_num}>
                                  <td>{status ? status : "NA"}</td>
                                  <td>{option.in_time ? Gettime(in_time) : "-"}</td>
                                  <td>{option.out_time ? Gettime(out_time) : "-"}</td>
                                  <td>{calculateTotalTime(Gettime(option.in_time), Gettime(option.out_time))}</td>
                                  <td>{attendance_date ? attendance_date : "NA"}</td>
                                </tr>
                              )
                            })
                            } </tbody>
                          <tbody>
                            {dataArray.length == 0 &&
                              <tr>
                                <td colSpan={5} className='text-center'>No Data Available</td>
                              </tr>
                            }
                          </tbody> </>}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      }



    </>
  )
}

export default Attendance

