import React, { useEffect, useRef, useState } from 'react'
import { GetPermission, getApi, postApi } from '../../service/lenderService';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { Image } from 'antd';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';

const Thirdroctransaction = () => {
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const [search, setSearch] = useState('');
    const [loader, setloader] = useState(true)
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_LENDER_PRIFIX
    const navigate = useNavigate();
    const [multippleArray, setMultippleArray] = useState([]);
    const [totalAmoumt, setTotalAmount] = useState(0);
    const ref = useRef()
    const [disable, setDisable] = useState(false);
    const [imageUpload, setimageUpload] = useState(null)
    const [ValidPagination, setValidPagination] = useState(false)

    const Thirdroctransaction = async () => {
        // const response = await getThirdroctransaction(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/thirdroc-transfer-history`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setloader(false)
            }, 2000);

            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(prefix + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        }
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        // Thirdroctransaction(token)
    }

    useEffect(() => {
        Thirdroctransaction(token, search, pagesCount, sortedBy, orderBy)
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination])

    const copiedInfo = () => {
        toast.success("Transaction No. copied Successfully")
    }

    const selectTransactions = async (e, id, amount) => {
        setMultippleArray(id)
        setTotalAmount(amount)

    }

    const Form = useFormik({
        initialValues: {
            // merchantId: "",
            merchantTransctionId: "",
            payment_mode: '',
            comment: "",
            image: "",
            payment_status: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            comment: yup.string().required('Please Add a Comment'),
            payment_mode: yup.string().required('Please Select Payment Mode '),
            // payment_mode: yup.string().required('Please Select Payment Mode'),
            payment_status: yup.string().required('Please Select Status')
        }),

        onSubmit: async values => {
            // console.log(multippleArray, typeof (multippleArray), "??????????????????????##############3")
            const formData = new FormData();
            // formData.append("merchant_id", datas);
            formData.append("id", multippleArray);
            formData.append("payment_mode", Form.getFieldProps('payment_mode').value);
            formData.append("comment", Form.getFieldProps('comment').value);
            formData.append("image", imageUpload);
            // formData.append("txn_from", dataArray[0].txn_from)
            formData.append("payment_status", Form.getFieldProps('payment_status').value)
            // paytothirdrocUpdate(token, formData)
            postApi("/update-thirdroc-paid", formData, true).then((res) => {
                if (res.status == true) {
                    toast.success(res.message);
                    ref.current.click()
                    // window.location.reload(true)
                    // window.location.reload();
                    Thirdroctransaction(token)
                    setMultippleArray([])
                    setTotalAmount(0)
                    //   setIsChecked(false)
                    //  console.log(setIsChecked)
                }
            }).catch((err) => {

            })
        }
    })

    const handleInputChange = (event) => {
        Form.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_types = JSON.parse(lenderData)
    const user_type = user_types.user_type

    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);
    console.log(permission_data)

    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [thirdroc_paid_view, setthirdroc_paid_view] = useState(false)
    const [thirdroc_update_paid_txn, setthirdroc_update_paid_txn] = useState(false)
    const [thirdroc_paid_settlement, setthirdroc_paid_settlement] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "thirdroc_paid_view") {
                    setthirdroc_paid_view(true);
                }
                if (option.key == "thirdroc_update_paid_txn") {
                    setthirdroc_update_paid_txn(true);
                }
                if (option.key == "thirdroc_paid_settlement") {
                    setthirdroc_paid_settlement(true);
                }

            });
        }
    }, [role]);

    return (

        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                    <h4 className="page-title mb-0 text-primary ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a>Pay to ThirdRoc</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Paid</li>
                            </ol>
                        </nav>
                    </h4>

                    {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                 <button className="btn btn-primary" disabled>Sync</button>} */}
                </div>
                {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                <div className="page-rightheader">


                    {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                    <div className="btn-list">
                        <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}>
                            <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                        </DownloadTableExcel>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                    {/* <div className="form-group">
                    <label className="form-label">All Status</label>
                    <div className="row g-xs">
                        <div className="wd-200 mg-b-30">
                            <div className="input-group">
                                <select className="form-control mb-0" name='submit'   value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                                    <option>Select Status</option>
                                    <option value="all task" >All Status</option>
                                    <option value="Assigned"  >Assigned</option>
                                    <option value="Unassigned" >Unassigned</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                </div>
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>

                                            <th className="font-weight-bold">TXN Docs</th>
                                            {/*   <th className="font-weight-bold">Merchant Details
                                          {sortedBy =="first_name" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('first_name')}} ></i> :<i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('first_name')}}> </i>} 
                                          </th>*/}
                                            <th className="font-weight-bold">Transaction No. {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}> </i>} </th>
                                            {/* <th className="font-weight-bold">Company</th> */}
                                            <th className="font-weight-bold"> ThirdRoc Commission  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>}</th>
                                            <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}> </i>} </th>
                                            <th className="font-weight-bold">Payment Status  {sortedBy == "payment_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_status') }}> </i>}</th>
                                            <th className="font-weight-bold"> Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}> </i>} </th>
                                            <th className="font-weight-bold"> Date  {sortedBy == "downdated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                            {(thirdroc_paid_settlement || thirdroc_update_paid_txn || user_type == 'lenders') && <th className="font-weight-bold"> Action</th>}
                                        </tr>
                                    </thead>

                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <Loader />
                                    </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    const { id, txn_number, amount, payment_mode, status, txn_docs, comment, payment_status } = option;
                                                    // var num2 = parseFloat(option.updated_at).toFixed(2)
                                                    // var timezone = new Date(option.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });     

                                                    return (

                                                        <tr key={serial_num} >
                                                            {txn_docs != null ?

                                                                <td><span className="font-weight-normal1">
                                                                    <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={'https://thirdroc1.s3.ap-southeast-2.amazonaws.com/txndocs/' + txn_docs} alt="..." /></span></td>
                                                                :
                                                                <td><span className="font-weight-normal1" >

                                                                    <Image style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="..." /></span></td>
                                                            }


                                                            {/* <td>                                                    
                                                    <span className="font-weight-normal1">{option.merchant_name  ? merchant_name : ""}<br/>{merchant_mobile_number}</span></td> */}
                                                            <td><span className="font-weight-normal1">{option.txn_number ? txn_number : ""} </span>
                                                                <span>
                                                                    <CopyToClipboard text={txn_number} >
                                                                        <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td><span className="font-weight-normal1"> {option.amount ? "$" + amount : ""} </span></td>
                                                            <td><span className="font-weight-normal1"> {option.payment_mode ? payment_mode : ""} </span></td>
                                                            <td>{(option.payment_status == 'initiate') ? <span className="badge rounded-pill" style={{ background: "#ff8f00" }}>{payment_status}</span>
                                                                : <span> {option.payment_status == 'cancelled' ? <span className="badge rounded-pill " style={{ background: "#d44343" }}>{payment_status}</span>
                                                                    : <span className="badge rounded-pill" style={{ background: "green" }}>{payment_status}</span>}</span>}</td>
                                                            <td><span className="font-weight-normal1"> {option.comment !== null ? comment : "NA"} </span></td>
                                                            <td><span className="font-weight-normal1"> {option.updated_at ? TimeZone(option?.updated_at).timeZone : ""} </span></td>
                                                            {/* <td><span className="font-weight-normal1"> {option.status ?   status  : ""} </span></td>      */}
                                                            <td><span className="font-weight-normal1">
                                                                {(thirdroc_paid_settlement || user_type == 'lenders') && <Link to={{ pathname: prefix + `/employee_transaction/${option.id}`, state: { id } }} className="btn btn-primary me-3">View Settlement</Link>}
                                                                {(thirdroc_update_paid_txn || user_type == 'lenders') && <button className='btn btn-primary' onClick={(e) => selectTransactions(e, option.id, option.amount)}
                                                                    data-bs-target="#add_settlement" data-bs-toggle="modal" disabled={payment_status != 'initiate'}>Update</button>}
                                                            </span></td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Transaction Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
            {/* Reconciliation modal Start */}
            <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h3 className="modal-title">Update Transaction</h3>
                            <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <h6 style={{ color: 'green', marginTop: '10px', marginLeft: '20px' }}> {totalAmoumt ? `Total: ${"$" + totalAmoumt}` : ""}</h6>

                        <div className="modal-body application-modal">

                            <form onSubmit={Form.handleSubmit}>
                                {/* {Form.values.wrong_message ?
                                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                    : ''} */}
                                <div className="row">
                                    <div className="row g-xs">
                                        <div className="col-12">
                                            <select className="form-control" name="payment_mode"
                                                {...Form.getFieldProps("payment_mode")}
                                                placeholder="Enter Payment Mode"  >
                                                <option value="">Select One</option>
                                                <option value="cash" >Cash</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="bank transfer">Bank Transfer</option>
                                            </select>
                                            {Form.touched.payment_mode && Form.errors.payment_mode ?
                                                <div className="invalid-feedback mt-0 mb-2" style={{ display: "block" }}>{Form.errors.payment_mode}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <input type="text" /* onKeyUp={(e) => handleInputChange(e)} */ placeholder='comment' {...Form.getFieldProps("comment")} value={Form?.comment?.values} className="form-control" />
                                            {Form.touched.comment && Form.errors.comment ?
                                                <div className="invalid-feedback mt-0 mb-2" style={{ display: "block" }}>{Form.errors.comment}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <select className="form-control" name="payment_status"
                                                {...Form.getFieldProps("payment_status")}
                                                placeholder="Select Status Mode"  >
                                                <option value="">Select Payment Status</option>
                                                <option value="success" >Success</option>
                                                <option value="cancelled">Cancel</option>
                                            </select>
                                            {Form.touched.payment_status && Form.errors.payment_status ?
                                                <div className="invalid-feedback mt-0 mb-2" style={{ display: "block" }}>{Form.errors.payment_status}</div> : ''}
                                        </div>
                                        <div className="input-group file-browser mb-3">
                                            <input type="file" onKeyUp={(e) => handleInputChange(e)} className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                        </div>
                                        {/* {Form.touched.image && Form.errors.image ?
                                                 <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.image}</div> : ''} */}

                                    </div>
                                    <div className="form-footer mt-2">
                                        <button type="submit" className="btn btn-primary" aria-hidden="true" /* disabled={!disable ? true : false} */>
                                            Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Reconciliation modal End */}
        </div>
    )
}

export default Thirdroctransaction
