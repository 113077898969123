


import Header  from '../website/header';
import Footer  from '../website/footer';

const Api = () => {


    return (
        <>
        <Header/>
        <div className="intro-one">
            <div className="intro-one--contents">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                        <h1 className="display-3">API</h1>
                        <h1 className="display-5" style={{fontSize:" 1.8rem"}}>We empower <span><b>Fintechs, Banks, NBFCs </b></span> by providing them credit risk analysis of their customers via our API wrapped Machine Learning model.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-one--img"><img src="website/assets/img/fintech-top-img.svg" alt="fintech" /></div>
        </div>
        <section className="p-top-100 p-bottom-55">
            <div className="icon-boxes icon-box--six">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="la la-thumbs-up"></i></span></div>
                            <div className="box-content">
                                <p>Can be used for credit risk analysis in Personal, Business, Education, Instant Loans, BNPL offerings, Customer profiling for KYC &amp; other purposes</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="fa-brands fa-quinscape"></i></span></div>
                            <div className="box-content">
                                <p>Uses standard API based integration resulting in safe, quick integration without disrupting existing tech infrastructure.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="fa-solid fa-user"></i></span></div>
                            <div className="box-content">
                                <p>HR for extending credit to employees &amp; their profiling.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="fa-solid fa-money-bill-wave"></i></span></div>
                            <div className="box-content">
                                <p>Fintechs for assessing repayment capabilities of borrowers.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="la la-level-up"></i></span></div>
                            <div className="box-content">
                                <p>Banks/NBFCs for assessing repayment capabilities plus for customer risk profiling for existing customers.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon"><span className="icon-square-sm"><i className="la la-lightbulb-o"></i></span></div>
                            <div className="box-content">
                                <p>Retail for assessing credit extension to retailers, vendors, suppliers, distributors, customers alike.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer/>
        </>
    )
}

export default Api;