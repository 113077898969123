import Header from '../website/header';
import Footer from '../website/footer';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Findmore, TalktoUS } from './Service/webservice';
import { toast } from 'react-hot-toast';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Index = () => {
	const ref = useRef()
	const ref2 = useRef()
	const regex = /[A-Za-z]/;

	const form = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile_number: "",
			comment: "",
			//   company_name: "",
			//   company_number:"",
			wrong_message: ""
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			name: yup.string().required('Please Enter Full Name')/* .matches(regex, 'Please Enter Only Character Values') */,
			email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
			mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
			comment: yup.string().required('Please Enter Comment'),
			//   company_name: yup.string().required('Please Enter Company Name'),	
			//   company_number: yup.string().required('Please Enter Company No.'),	
		}),
		onSubmit: async (values, { resetForm }) => {
			console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
			const data = JSON.stringify({
				name: values.name,
				email: values.email,
				mobile_number: `${values.mobile_number}`,
				comment: values.comment,
				// company_name: values.company_name,
				// company_number: values.company_number
			})
			// console.log(data)
			const response = await TalktoUS(data);

			// console.log("#@#@#", response);
			resetForm({ values: '' });
			ref.current.click()
			ref2.current.click()
			if (response.status == false) {
				console.log(response.message);
				toast.error(response.message);
				ref.current.click()
				ref2.current.click()
			}
			if (response.status == true) {
				console.log(response.message);
				toast.success(response.message);
				// window.$('#modal-center').modal('hide')	 
				ref.current.click()
				ref2.current.click()
			} else {

				console.log("#@#@#1234", response);
			}

		}
	});

	const Addform = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile_number: "",
			comment: "",
			company_name: "",
			company_number: "",
			wrong_message: ""

		},
		enableReinitialize: true,
		validationSchema: yup.object({
			name: yup.string().required('Please Enter Full Name')/* .matches(regex, 'Please Enter Only Character Values') */,
			email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
			mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
			company_name: yup.string().required('Please Enter Company Name'),
			company_number: yup.string().required('Please Enter Company No.')
		}),

		onSubmit: async (values, { resetForm }) => {
			const data = JSON.stringify({
				name: values.name,
				email: values.email,
				mobile_number: `${values.mobile_number}`,
				company_name: values.company_name,
				company_number: values.company_number

			});

			const response = await Findmore(data)
			resetForm({ values: '' });
			if (response.status == true) {
				console.log('inside ', response);
				toast.success(response.message);

			} else if (response.status == false) {
				console.log('inside', response);
				toast.error(response.message);
			}
		}
	});
	return (
		<>
			<Header />
			<section>
				<div className="row no-gutter">
					<div className="col-md-12 col-lg-8">
						<section className="slider-section">
							<div id="carousel" className="carousel slide" data-ride="carousel">
								<div className="carousel-inner" role="listbox">
									<div className="carousel-item active" style={{ backgroundImage: "url('website/assets/img/banner1-Thirdroc.jpg')" }}>
										<div className="carousel-caption d-md-block">
											<div className="row d-flex align-items-center justify-content-center h100">
												<div className="col-md-6">
													<img src="website/assets/img/banner1-left.png" className="sliderimg" alt="" />
												</div>
												<div className="col-md-6">
													<h3>Grow your business with <span> ThirdRoc</span></h3>
													<p>Empowering Merchants to boost sales and attract new customers  with flexible payment options</p>
													<a className="talkus" data-toggle="modal" data-target="#modal-center1" href="/">Talk to us</a>
													<a className="signgup" /* data-toggle="modal" data-target="#modal-center" */ href="/merchant/login">Signup / Login</a>
												</div>
											</div>

										</div>
									</div>
									<div className="carousel-item" style={{ backgroundImage: "url('website/assets/img/banner2-Thirdroc.jpg')" }}>
										<div className="carousel-caption d-md-block">
											<div className="row d-flex align-items-center justify-content-center h100">
												<div className="col-md-6">
													<img src="website/assets/img/banner2-left.png" className="sliderimg1" alt="" />
												</div>
												<div className="col-md-6">
													<h3>Attract and retain good employees with <span> ThirdRoc </span></h3>
													<p>Give your staff the financial control they want, and they’ll give you loyalty and long-lasting employment</p>
													<a className="talkus" data-toggle="modal" data-target="#modal-center" href="/">Talk to us</a>
													<a className="signgup" /* data-toggle="modal" data-target="#modal-center" */ href="/employer/login">Signup / Login</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a href="#carousel" className="carousel-control-prev" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only"></span>
								</a>
								<a href="#carousel" className="carousel-control-next" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only"></span>
								</a>
							</div>
						</section>
					</div>

					<div className="col-md-12 col-lg-4">
						<form onSubmit={Addform.handleSubmit}>
							<div className="NewFromBox row">
								<div className="col-md-12">
									<h3>Find out more</h3>
								</div>
								<div className="form-group form-group-mtb col-md-6">
									<input type="text" placeholder="Enter Your Name" className="form-control"  {...Addform.getFieldProps("name")} />
									{Addform.touched.name && Addform.errors.name ?
										<div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.name}</div> : ''}
								</div>
								<div className="form-group form-group-mtb col-md-6">
									<input type="email" name="email" placeholder="Enter Email" className="form-control" {...Addform.getFieldProps("email")} />
									{Addform.touched.email && Addform.errors.email ?
										<div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.email}</div> : ''}

								</div>
								<div className="form-group form-group-mtb col-md-6">
									<input type="number" name="mobile_number" placeholder="Enter Mobile Number" className="form-control" {...Addform.getFieldProps("mobile_number")} />
									{Addform.touched.mobile_number && Addform.errors.mobile_number ?
										<div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.mobile_number}</div> : ''}
								</div>
								<div className="form-group form-group-mtb col-md-6">
									<input type="number" name="company_number" placeholder="Enter Company No." className="form-control" {...Addform.getFieldProps("company_number")} />
									{Addform.touched.company_number && Addform.errors.company_number ?
										<div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.company_number}</div> : ''}
								</div>
								<div className="form-group form-group-mtb col-md-12">
									<input type="text" placeholder="Enter Company Name" className="form-control" {...Addform.getFieldProps("company_name")} />
									{Addform.touched.company_name && Addform.errors.company_name ?
										<div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.company_name}</div> : ''}
								</div>
								<div className="col-md-12">
									<button type="submit" className="talkus" >Enroll</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
			<section className="threebox-sec">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-6 col-xs-12 ">
							<article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
								<div className="themetechmount-post-item">
									<div className="themetechmount-post-item-inner">
										<div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
											<img src="website/assets/img/ewa.png" className="wp-post-image" alt="" />
										</div>
									</div>

									<div className="themetechmount-box-bottom-content">
										<div className="themetechmount-box-title"><h4>EWA</h4></div>
										<div className="themetechmount-box-desc">
											The Workwell program benefits employees by providing them with more financial stability , reducing the number of financial stressors that might negatively impact their work
										</div>
										<div className="themetechmount-serviceboxbox-readmore">
											<a href="/ewa">Read More {'>>'}</a>
										</div>
									</div>
								</div>
							</article>
						</div>

						<div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-6 col-xs-12 ">
							<article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
								<div className="themetechmount-post-item">
									<div className="themetechmount-post-item-inner">
										<div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
											<img src="website/assets/img/bnplimg.jpg" className="wp-post-image" alt="" />
										</div>
									</div>

									<div className="themetechmount-box-bottom-content">
										<div className="themetechmount-box-title"><h4>BNPL</h4></div>
										<div className="themetechmount-box-desc">
											Buy Now Pay Later, helping Enterprises & their Employees ease out their purchases by availing no interest unsecured credit with easy repayments via our network of merchants.
										</div>
										<div className="themetechmount-serviceboxbox-readmore">
											<a href="/bnpl">Read More {'>>'}</a>
										</div>
									</div>
								</div>
							</article>
						</div>

						<div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-6 col-xs-12 ">
							<article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
								<div className="themetechmount-post-item">
									<div className="themetechmount-post-item-inner">
										<div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
											<img src="website/assets/img/apiimg.jpg" className="wp-post-image" alt="" />
										</div>
									</div>

									<div className="themetechmount-box-bottom-content">
										<div className="themetechmount-box-title"><h4>APIs</h4></div>
										<div className="themetechmount-box-desc">
											APIs, enabling Lenders, Fintechs, Enterprises to seamlessly utilize our Machine Learning intelligent credit risk analysis for their borrowers. <br></br>
										</div>
										<div className="themetechmount-serviceboxbox-readmore">
											<a href="/api">Read More {'>>'}</a>
										</div>
									</div>
								</div>
							</article>
						</div>
						<div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-6 col-xs-12 ">
							<article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
								<div className="themetechmount-post-item">
									<div className="themetechmount-post-item-inner">
										<div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
											{/* <a href="#"> */}
											<img src="website/assets/img/digital-autoimg.jpg" className="wp-post-image" alt="" />
											{/* </a> */}
										</div>
									</div>

									<div className="themetechmount-box-bottom-content">
										<div className="themetechmount-box-title"><h4>
											{/* <a href="#"> */}
											Digital Lending</h4></div>
										<div className="themetechmount-box-desc">
											Digital Lending, offering end-to-end web & mobile apps based integration providing fully automated dataflow pipeline of borrower's credit journey with lender.
										</div>
										<div className="themetechmount-serviceboxbox-readmore">
											<a href="/digital-lending">Read More {'>>'}</a>
										</div>
									</div>
								</div>
							</article>
						</div>


					</div>
				</div>
			</section>
			{/*  Merchant Modal Start */}
			<div className="modal fade" id="modal-center1" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modal-centerLabel">Talk to us</h5>
							<button ref={ref2} type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form onSubmit={form.handleSubmit}>
							{form.values.wrong_message ?
								<div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
								: ''}
							<div className="modal-body">
								<div className="formbox row">
									<div className="form-group form-group-mtb col-md-6">
										<input type="text"  {...form.getFieldProps("name")} placeholder="Enter Your Name" className="form-control" />
										{form.touched.name && form.errors.name ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.name}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-6">
										<input type="text" {...form.getFieldProps("email")} placeholder="Enter Email ID" className="form-control" />
										{form.touched.email && form.errors.email ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} placeholder="Enter Phone Number" className="form-control" />

										{form.touched.mobile_number && form.errors.mobile_number ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="text" name="comment" {...form.getFieldProps("comment")} placeholder="Enter Comment" className="form-control" />

										{form.touched.comment && form.errors.comment ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.comment}</div> : ''}
									</div>
									<div className="col-md-12">
										<button type="submit" className="btn btn-primary"> Send</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<button className="btn btn-primary" data-dismiss="modal"> Close</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{/*  Merchant Modal End */}

			{/*  Employer Modal Start */}
			<div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modal-centerLabel">Talk to us</h5>
							<button ref={ref} type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form onSubmit={form.handleSubmit}>
							{form.values.wrong_message ?
								<div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
								: ''}
							<div className="modal-body">
								<div className="formbox row">
									<div className="form-group form-group-mtb col-md-6">
										<input type="text"  {...form.getFieldProps("name")} placeholder="Enter Your Name" className="form-control" />
										{form.touched.name && form.errors.name ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.name}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-6">
										<input type="text" {...form.getFieldProps("email")} placeholder="Enter Email ID" className="form-control" />
										{form.touched.email && form.errors.email ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} placeholder="Enter Phone Number" className="form-control" />

										{form.touched.mobile_number && form.errors.mobile_number ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="text" name="comment" {...form.getFieldProps("comment")} placeholder="Enter Comment" className="form-control" />

										{form.touched.comment && form.errors.comment ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.comment}</div> : ''}
									</div>
									<div className="col-md-12">
										<button type="submit" className="btn btn-primary"> Send</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<button className="btn btn-primary" data-dismiss="modal"> Close</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{/*  Employer Modal End */}

			<section className="midel-newsec">
				<div className="row align-items-center justify-content-right">
					<div className="col-md-12 col-lg-6">
						<div className="blue-midel-box">
							<h1>Why ThirdRoc</h1>
							<div className="lispoint">
								<span>1.</span>
								<div className="rigcontent">
									<h4>Wide network of Merchants, Lenders, Enterprises</h4>
									<p>We have multiple types of Lenders, merchants, enterprises in our network & each day our network is growing to deliver better options to the involved stakeholders.</p>
								</div>
							</div>
							<div className="lispoint">
								<span>2.</span>
								<div className="rigcontent">
									<h4>Tech & Data Science based platform</h4>
									<p>Our cloud based end-to-end technology platform is backed by trained machine learning models.</p>
								</div>
							</div>
							<div className="lispoint">
								<span>3.</span>
								<div className="rigcontent">
									<h4>Quick Deployment</h4>
									<p>We have ready web & mobile apps based platform for end-to-end digital lending capabilities for lenders while we also have ready APIs to integrate quickly with existing systems.</p>
								</div>
							</div>
							<div className="lispoint">
								<span>4.</span>
								<div className="rigcontent">
									<h4>Reduce bad debts</h4>
									<p>Our machine learning models have been trained on a large set of data points with a varied set of lenders, merchants, enterprises to deliver higher accuracy predictions.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>



			<section className="shape-heaxgaonbg">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-md-12">
							<h2>We support</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="greenshape-bg-shape">
				<div className="container">
					<div className="greenshape-bg">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-4">
								<p>Enterprises offering BNPL (Buy Now Pay Later), loan/contingency opportunities to their employees</p>
							</div>
							<div className="col-md-4">
								<p>Businesses offering BNPL (Buy Now Pay Later) to their vendors, suppliers, distributors, customers, retailers- whole supply chain</p>
							</div>
							<div className="col-md-4">
								<p>B2B eCommerce, App aggregators (food etc.) for providing business loans to vendors, suppliers, merchants, employees</p>
							</div>
							<div className="col-md-4">
								<p>Retailers/Supermarkets offering BNPL to capture new consumers, increase sales from existing consumers</p>
							</div>
							<div className="col-md-4">
								<p>Banks/NBFCs mitigating risk in lending to new consumers, increasing revenue via our APIs integration and via our digital lending platform</p>
							</div>
							<div className="col-md-4">
								<p>Fintechs (offering lending, customer profiling etc.) using our API based services for mitigating consumer risk</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default Index;