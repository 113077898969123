import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { deleteApi, getApi, postApi } from '../service/adminService';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Swal from 'sweetalert2';
import Search from '../../components/Search/Search';

const Cashback = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [Count, setCount] = useState();
    const tableRef = useRef();
    const ref = useRef()
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [merchantdetail, setMerchantDetail] = useState([])
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [ValidPagination, setValidPagination] = useState(false)

    const getCashbackdetail = async (token) => {
        // const response = await GetCashbackdetail(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/main-bulk-cashback-txn", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                setCount(response.data.count);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getCashbackdetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination]);

    // const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
        return `${year}-${month}-${day}`;
    }

    const validateEndDate = () => {
        const startDate = new Date(Form.values.start_date);
        const endDate = new Date(Form.values.end_date);
        if (startDate > endDate) {
            Form.setFieldError("end_date", "End date must be greater than start date");
        } else {
            Form.setFieldError("end_date", "");
        }
    }

    function getMinimumEndDate() {
        const startDate = new Date(Form.values.start_date);
        const minimumEndDate = new Date(startDate);
        minimumEndDate.setDate(startDate.getDate() + 1);

        const year = minimumEndDate.getFullYear();
        let month = minimumEndDate.getMonth() + 1;
        let day = minimumEndDate.getDate();

        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    }
    const Form = useFormik({
        initialValues: {
            title: "",
            amount: "",
            description: "",
            start_date: "",
            end_date: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Please Add Title')/* .max(10, "title must contain max 40 Characters") */,
            amount: yup.string().required('Please Add Cashback Amount'),
            description: yup.string().required('Please Add Description'),
            start_date: yup.string().required('Please Select Start Date'),
            end_date: yup.string().required('Please Select End Date'),

        }),

        onSubmit: async (values, { resetForm }) => {
            // const data = JSON.stringify({
            //     title: values.title,
            //     amount: values.amount,
            //     description: values.description,
            //     start_date: values.start_date,
            //     end_date: values.end_date,

            // })

            /* AddCashback(admin.admin_token, data) */
            postApi("/add-cashback-to-all", {
                title: values.title,
                amount: values.amount,
                description: values.description,
                start_date: values.start_date,
                end_date: values.end_date,
            }).then((res) => {
                console.log(res)
                if (res.status == true) {
                    const Sendnotification = async () => {
                        // const body = JSON.stringify({
                        //     title: values.title,
                        //     body: values.description
                        // });
                        // 
                        // const res = await sendnotification(admin.admin_token, body)
                        const res = await postApi(`/banner-notification`, {
                            title: values.title,
                            body: values.description
                        })

                        console.log(res)

                    }
                    Sendnotification()
                    toast.success(res.message);
                    getCashbackdetail(admin.admin_token);
                    ref.current.click()
                    resetForm({ values: "" })
                }
            }).catch((err) => {

            })
        }
    })

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const response = await deleteCashback(admin.admin_token, data)
                const response = await deleteApi(`/delete-bulk-cashback-txn/${data}`)

                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'User Account has been deleted Successfully.',
                        'success'
                    )
                    //   setState(!state)
                    getCashbackdetail(admin.admin_token);

                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage Cash back  {Count ? "(" + Count + ")" : ""}</h4>
                            </div>
                            <div className='page-rightheader'>
                                <button className='btn btn-primary' data-bs-target="#add_settlement"
                                    data-bs-toggle="modal"> Add Cash back</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap"/*  ref={tableRef} */>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Title  {sortedBy == "title" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }}></i>}</th>
                                                        <th className="font-weight-bold">Description  {sortedBy == "description" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('description') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('description') }}></i>}</th>
                                                        <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Start Date  {sortedBy == "start_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('start_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('start_date') }}></i>}</th>
                                                        <th className="font-weight-bold">End Date  {sortedBy == "end_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('end_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('end_date') }}></i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Action</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { title, amount, start_date, end_date, description, created_at } = option;
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1"> {option?.title ? title : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.description ? description : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.amount ? "$" + amount : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.start_date ? start_date : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.end_date ? end_date : "-"} </span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(created_at).timeZone} </span></td>
                                                                        <td><Link className='btn btn-primary mr-2' to={{ pathname: `${prefix}/cashback_alldetail/${option.id}` }}>View Detail</Link>
                                                                            <button className='btn btn-danger ' onClick={() => { handleDelete(option.id) }} >Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>

                    {/* Add Banner modal start */}
                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Add CashBack</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body ">
                                    <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                        {Form.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Title:</label>
                                                            <input type="text" placeholder='Enter Title' /* minLength={60} */ {...Form.getFieldProps("title")} className="form-control" />
                                                            {Form.touched.title && Form.errors.title ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.title}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label"> Amount :</label>
                                                            <input type="number" placeholder='Enter Amount' {...Form.getFieldProps("amount")} className="form-control" />
                                                            {Form.touched.amount && Form.errors.amount ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.amount}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <label className="form-label">Description:</label>
                                                        <input type="text" placeholder='Enter Description' /* minLength={60} */ {...Form.getFieldProps("description")} className="form-control" />
                                                        {Form.touched.description && Form.errors.description ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.description}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="form-label">Start Date:</label>
                                                            <input type="date" placeholder='Select Start Date' {...Form.getFieldProps("start_date")} className="form-control" min={getCurrentDate()} />
                                                            {Form.touched.start_date && Form.errors.start_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.start_date}</div> : ''}
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">End Date:</label>
                                                            <input type="date" placeholder='Select End Date' {...Form.getFieldProps("end_date")} className="form-control" min={getMinimumEndDate()} onBlur={validateEndDate} />
                                                            {Form.touched.end_date && Form.errors.end_date ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.end_date}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Banner modal End */}
                </div>
            }
        </>
    )
}

export default Cashback