import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Loader from '../../../components/Loader/loader';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import PaginationComponent from '../../../PaginationComponent';
import { deleteApi, getApi } from '../../service/lenderService';


const Manageroles = () => {
  const [empty, setempty] = useState(false)
  const [dsaDetail, setdsaDetail] = useState(false);
  const [search, setSearch] = useState('');
  const [sortedBy, setSortedBy] = useState("id")
  const [orderBy, setOrderBy] = useState('desc')
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [dataArray, setDataArray] = useState([]);
  const [loader, setloader] = useState(true)
  const prefix = process.env.REACT_APP_LENDER_PRIFIX;
  const lender = JSON.parse(localStorage.getItem("lender"))
  const token = lender.lender_token
  const navigate = useNavigate();

  const getUsermanager = async (token) => {
    const response = await getApi(`/get-role-permission`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
    // const response = await GetUsermanager(token,pagesCount, search, sortedBy, orderBy);
    console.log(response)
    try {

      if (response.status == true) {
        setTimeout(() => {
          setDataArray(response.rows);
          setTotalPagesCount(response.count)
          setloader(false)

        }, 2000);
        setloader(true)
      } else if (response.message === 'Session expired') {
        localStorage.removeItem("lender");
        navigate('/lender/login')
      } else if (response.message == "Access denied! you're not allowed to access this tab") {
        toast.error(response.message)
        navigate('/lender/dashboard')
      } else if (response.data) {
        setempty(true)
        console.log("successfull test")
      } else {
        console.log("get employees data response", response);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUsermanager(token);
  }, [pagesCount, search, , sortedBy, orderBy]);

  const sortChange = (col) => {
    if (col === sortedBy) {
      setSortedBy(col);
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
      setSortedBy(col)
      setOrderBy("desc")
    }
  }

  const handleNavigate = (data) => {
    navigate(`${prefix}/edit_roles/` + data.id, { state: data })
  }

  const handleDelete = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteApi(`/delete-role-permission/${data}`)
        // const response = await deleteRole(token, data)
        if (response?.status) {
          Swal.fire(
            'Deleted!',
            'Your Role has been deleted.',
            'success'
          )
          getUsermanager(token);
        }
        else {
          toast.error("something went wrong")
        }

      }
    })
  }

  return (
    <>
      {!dsaDetail &&
        <div className="main-container container-fluid px-0">
          <div className="page-header my-3 py-2">
            <div className="page-leftheader">
              <h4 className="page-title mb-0 text-primary">Manage Role</h4>
            </div>
            <div className="page-rightheader">
              <div className="btn-list">
                {/* <button className='btn btn-primary btn-pill mt-3' 
                   >+ Add User</button> */}
                <Link to={prefix + "/add_role"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i>Add Role</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            {/*   <div className="form-group">
                      <label className="form-label">Loan Type</label>
                      <div className="row g-xs">
                          <div className="wd-200 mg-b-30">
                              <div className="input-group">
                                  <select className="form-control mb-0" name="loan_type"  value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)} >
                                      <option>Select Loan</option>
                                      <option value="1">BNPL</option>
                                      <option value="2">Digital Lending</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* <div className="row">
              <div className="col-sm-12 col-lg-12">
                  <div className="card">
                      <div className="card-header br-0">
                          <h3 className="card-title">EMPLOYEE REPORT</h3>
                      </div>
                  </div>
              </div>
          </div> */}


          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                      <thead className="border-bottom-0 pt-3 pb-3">
                        <tr>
                          {/* <th className="font-weight-bold">Employee Name {sortedBy == "employee_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }}></i>}</th>
                          <th className="font-weight-bold">Mobile No. {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>}</th>
                          <th className="font-weight-bold">Email ID  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                          <th className="font-weight-bold">Address  {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }}></i>}</th> */}
                          <th className="font-weight-bold">Role {sortedBy == "title" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('title') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('title') }}></i>}</th>
                          <th className="font-weight-bold">Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }}></i>}</th>

                          <th className="font-weight-bold">Action  </th>

                        </tr>
                      </thead>
                      {loader == true ? <tbody>
                        <td />
                        <div>
                          <Loader />
                        </div> </tbody> :
                        <>
                          <tbody>
                            {dataArray.length > 0 && dataArray.map((data, i) => {
                              let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                              const { id, title, created_at } = data;
                              return (
                                <tr key={serial_num}>
                                  <td>{title} </td>
                                  <td>{data.created_at ? TimeZone(created_at).timeZone : "NA"}</td>
                                  <td><button className="btn btn-outline-success" onClick={() => handleNavigate(data)}>Edit</button>&nbsp;&nbsp;
                                    <button className="btn btn-outline-danger " onClick={() => { handleDelete(id) }} type="button" >Delete</button></td>

                                </tr>
                              )
                            })
                            }
                          </tbody>

                          <tbody>
                            {dataArray.length == 0 &&
                              <tr>
                                <td colSpan={10} className='text-center'>No Data Available</td>
                              </tr>
                            }
                          </tbody>
                        </>}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {pagesCount > 0 && dataArray.length > 0 ?
              <>
                <div className="col-md-12">
                  <div className="card-body">
                    <PaginationComponent className="justify-content-center"
                      totalItems={totalPagesCount}
                      pageSize={10}
                      maxPaginationNumbers={3}
                      onSelect={(e) => setpagesCount(e)}
                    />
                  </div>
                </div>
              </>
              : ''}

          </div>
        </div>
      }
    </>
  )
}

export default Manageroles


