import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { LoanRiskAnalysis, getApi } from '../../service/lenderService';

const Riskanalysis = () => {
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const { id } = useParams()
    const lender = JSON.parse(localStorage.getItem("lender"));

    const viewLoanCasesData = async () => {
        const response = await getApi(`/user-loan-application/${id}`)

        if (response.status === true) {
            setEmployeeDetail(response.data.userData)

        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        viewLoanCasesData();
    }, []);

    const TotalOutgoing = (employeeDetail.rent + employeeDetail.electricity + employeeDetail?.bill_for_phone + employeeDetail?.bill_for_water
        + employeeDetail.house_keeping + employeeDetail.school_fees + employeeDetail.travelling + employeeDetail?.entertainment + employeeDetail?.hire_payments)

    const Surplus = (parseFloat(employeeDetail?.monthly_income) + parseFloat(employeeDetail?.other_income) - TotalOutgoing)
    const AddInsurance = ((employeeDetail?.require_loan_amount * 3 / 100) * 24 / 12)
    const Establishment = (employeeDetail?.require_loan_amount * 1 / 100)
    const totalSum = (
        parseFloat(employeeDetail?.require_loan_amount) +
        parseFloat(AddInsurance) +
        parseFloat(Establishment)
    );
    const interestflat = ((totalSum.toFixed(2) * 20 / 100) * 2)
    const TotalRepayable = (
        parseFloat(totalSum.toFixed(2)) +
        parseFloat(interestflat.toFixed(2))
    );

    const monthlyInstallment = ((TotalRepayable / 24).toFixed(2))

    let Employerfeesperinstall

    if (employeeDetail?.employerData?.length !== 0) {
        Employerfeesperinstall = ((monthlyInstallment * 3 / 100).toFixed(2))
    } else {
        Employerfeesperinstall = ((monthlyInstallment * 6 / 100).toFixed(2))
        console.log("second")

    };

    const SurplusAvailable = (
        parseFloat(Surplus.toFixed(2)) -
        monthlyInstallment -
        Employerfeesperinstall
    );

    function currencyFormat(num) {
        if (/-/g.test(num)) {
            return num?.toFixed(2).replace(/-/g, '-$')
        } else {
            return "$" + num?.toFixed(2)
        }
    }

    const netWeeklysurplus = parseFloat((SurplusAvailable * 12) / 52).toFixed(2);
    if (/-/g.test(netWeeklysurplus)) {
        var netWeeklysurpluss = netWeeklysurplus?.replace(/-/g, '-$');
    } /* else {
        var netWeeklysurpluss = "$" + netWeeklysurplus?.trim()
    }
 */
    const DebtIncomeRatio = parseFloat(monthlyInstallment / Surplus).toFixed(2)
    var new_str = DebtIncomeRatio

    const updateEmployeeData = async (e) => {
        await LoanRiskAnalysis(lender.lender_token, { [e.target.name]: e.target.value === "" ? "0" : e.target.value, user_loan_id: id }).then((res) => {
            if (res.status == true) {
                viewLoanCasesData();
            } else {

            }
        })
    }



    return (
        <div style={{ marginTop: "30px" }}>
            <h3>Income</h3>
            <div className="stepform-newdesign">
                <div className="row">
                    <div className="form-group col-md-3">
                        <label className="form-label">Require Loan Amount
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Require Loan Amount " name="require_loan_amount" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Other Income
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Other Income" name="other_income" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Total Monthly Income
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Total Monthly Income." name="monthly_income" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3>Living Cost</h3>
            <div className="stepform-newdesign">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label className="form-label">Rent
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Rent " name="rent" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Electricity
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Electricity" name="electricity" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Total Telephone Cost
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Total Telephone Cost." name="bill_for_phone" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Water
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Water." name="bill_for_water" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">House Keeping
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter House Keeping." name="house_keeping" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">School Fees
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter School Fees." name="school_fees" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Travelling/Petrol/Bus
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Travelling/Petrol/Bus." name="travelling" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label"> Entertainment/Video
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Entertainment/Video." name="entertainment" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Total Hire Purchase Payments
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control" placeholder="Enter Total Hire Purchase Payments." name="hire_payments" onBlur={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                <h3>Risk Analysis</h3>
                <div className="media-list p-0">
                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                <div className="w-70"> <label>Total Outgoing : </label> <span className="font-weight-normal1 fs-14">{currencyFormat(TotalOutgoing)}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-hand-holding-usd"></i> </div>
                                <div className="w-70"> <label>Surplus :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(Surplus)}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-amazon-pay"></i> </div>
                                <div className="w-70"> <label>New MFL Repayment :</label> <span className="font-weight-normal1 fs-14">{monthlyInstallment ? "$" + monthlyInstallment : "--"}</span> </div>
                            </div>
                        </div>
                    </div>
                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">

                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="fas fa-sort-amount-up" ></i> </div>
                                <div className="w-70"> <label>Surplus Available :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(SurplusAvailable)}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-sort-amount-up"></i> </div>
                                <div className="w-70"> <label>Net Weekly Surplus :</label> <span className="font-weight-normal1 fs-14">{netWeeklysurpluss}</span> </div>

                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-chart-pie"></i> </div>
                                <div className="w-70"> <label>DTI (Debt to Income Ratio) :</label> <span className="font-weight-normal1 fs-14">
                                    {new_str ? ((new_str * 100).toFixed(2) + "%") : "--"}</span>
                                    {(netWeeklysurplus >= 150 && new_str * 100 <= 50) ? (
                                        <span className='badge rounded-pill bg-success text-dark' style={{ width: '130px' }}>Very Low Risk</span>
                                    ) : (netWeeklysurplus < 150 && netWeeklysurplus > 0 && new_str * 100 <= 50) ? (
                                        <span className='badge rounded-pill bg-warning text-dark' style={{ width: '120px' }}>Cautionary</span>
                                    ) : (netWeeklysurplus >= 150 && new_str * 100 > 50 && new_str * 100 <= 55) ? (
                                        <span className='badge rounded-pill bg-warning text-dark' style={{ width: '120px' }}>Cautionary</span>
                                    ) : (
                                        <span className='badge rounded-pill bg-danger text-dark' style={{ width: '120px' }}>Unacceptable</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Riskanalysis 
