import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { getApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import PaginationComponent from '../../PaginationComponent';
import Search from '../../components/Search/Search';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const DeductionErrorlogs = () => {
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loading, setloading] = useState(false);
    const [profileData, setprofileData] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [totalCount, settotalCount] = useState();
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')

    const admin = JSON.parse(localStorage.getItem("admin"));


    const Getmonthlydeduction = async (token) => {
        // `/talk-to-us?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`
        // const response = await Getmonthlydeduction(token, pagesCount, search , sortedBy , orderBy);
        const response = await getApi("/ewa-monthly-deduction-failed-log", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                settotalCount(response.data.count);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        Getmonthlydeduction(admin.admin_token, pagesCount, search);
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    return (
        <>

            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                <h4 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a>Manage EWA Plan</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Deduction Error Logs {totalCount ? "(" + totalCount + ")" : ""}</li>
                                        </ol>
                                    </nav>
                                </h4>
                            </div>



                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    {/* <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Customer Detail  {sortedBy == "customer_id" && orderBy === "desc" ? <i classcustomer_id='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('name') }}></i>}</th>
                                                        <th className="font-weight-bold">EmployerDetail {sortedBy == "employer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_id') }}></i>}</th>
                                                        <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                                        <th className="font-weight-bold">Start Date {sortedBy == "from_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('from_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('from_date') }}></i>}</th>
                                                        <th className="font-weight-bold">End Date {sortedBy == "to_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('to_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('to_date') }}></i>}</th>
                                                        <th className="font-weight-bold">Deduction Date {sortedBy == "deduction_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('deduction_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('deduction_date') }}></i>}</th>
                                                        <th className="font-weight-bold">Created Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { customer_id, used_ewa_amount, charges, pay_cycle, from_date, comment, to_date, deduction_date, created_at } = option;
                                                                // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });  


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/employeedetails/${customer_id}`, state: { customer_id } }} >
                                                                            <span className="font-weight-normal1">
                                                                                {option?.customerData?.first_name || option?.customerData?.last_name ?
                                                                                    `${option.customerData.first_name} ${option.customerData?.last_name}` : "--"}
                                                                                <br />{option?.customerData?.mobile_number}
                                                                                <br /><span style={{ textTransform: 'none' }}>{option?.customerData?.work_email}</span>
                                                                            </span></Link>
                                                                        </td>
                                                                        <td><Link className="text-primary" to={prefix + `/employer_detail/${option.employer_id}`}>
                                                                            <span className="font-weight-normal1">{option?.employerData?.full_name ? option?.employerData?.full_name : "--"}
                                                                                <br />{option?.employerData?.mobile_number}</span></Link></td>
                                                                        <td><span className="font-weight-normal1 text-danger">{option?.comment ? comment : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.from_date ? from_date : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.to_date ? to_date : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.deduction_date ? deduction_date : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>



                </div>

            }
        </>
    )
}



export default DeductionErrorlogs