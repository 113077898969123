import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { GetPermission, getApi, postApi } from '../service/lenderService';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Image } from 'antd';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

const Manageloan = () => {
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(false);
    const [checkbox, setCheckbox] = useState(false)
    const [dataArray, setDataArray] = useState([]);
    const [data, setData] = useState([]);
    const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [refresh, setRefresh] = useState(true)
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("user_loan_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate();
    const [imageUpload, setimageUpload] = useState(null)
    const [userloanID, setuserloanID] = useState()
    const [username, setusername] = useState()
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)


    const ViewLoanCases = async (token) => {
        // const response = await GetLoanCasesDetail(token, search, pagesCount, sortedBy, orderBy)
        const response = await getApi("/get-loan-application", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })

        // console.log(response)
        if (response?.status == true) {
            setTimeout(() => {
                setDataArray(response?.data?.rows)
                setTotalPagesCount(response?.data?.count)

                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response?.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response?.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response?.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases(token, search, pagesCount)

    }, [search, pagesCount, refresh, sortedBy, orderBy])


    const getBorrowerdetail = async (id, name) => {
        console.log(name, "___________________")

        // const response = await GetBorrowerChatdetail(token, id)
        const response = await getApi(`/get-loan-chat/${id}`)
        setuserloanID(id)
        setusername(name)
        console.log(response.data)
        if (response.status == true) {
            // setTimeout(() => {
            setData(response.data)
            // setloader1(false)
            // }, 2000);
            // setloader1(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // ViewInvestment(token, search, pagesCount )
    }
    const copiedInfo = () => {
        toast.success("Account No. copied Successfully")
    }



    const selectTransactions = async (id, e) => {
        // settotalItems(true)
        if (!transactionPrimaryId.includes(id)) {
            setTransactionPrimaryId(oldArr => [...oldArr, id]);
            setCheckbox(true)
            settotalItems()

        } else {
            let x = transactionPrimaryId.shift(id)
            console.log(x);
            if (transactionPrimaryId.length == 0) {
                setCheckbox(false);
            }
        }
    }


    // const getStatus = async (token) => {
    //     const response = await GetLoanStatus(token);
    //     console.log(" data response", response);

    //     if (response.status == true) {
    //         setStatus(response.data.rows);
    //     } else {
    //         console.log("get Country data response", response);
    //     }
    // }

    // useEffect(() => {
    //     getStatus(token);
    // }, [])

    // useEffect(() => {
    //     FormEdit.setValues({
    //         nfc_site_name: nfc_site_name
    //     })
    // }, [nfc_site_name])

    // const FormEdit = useFormik({        
    //     enableReinitialize: true,
    //     validationSchema: yup.object({
    //         nfc_site_name: yup.string().required('Please Select Loan Status '),
    //     }),
    //     onSubmit: async values => {
    //         const data = JSON.stringify({
    //             "loan_status_id": values.nfc_site_name

    //         })

    //         const response = await EditLoanStatus(token, data, ID)
    //         if (response.status === true) {
    //             toast.success(response.message);
    //             tableRef.current.click()
    //             ViewLoanCases(token, search, pagesCount)
    //         } else {
    //             console.log('error', response);
    //             toast.error(response);
    //         }
    //     }
    // });

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_types = JSON.parse(lenderData)
    const user_type = user_types.user_type

    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);

    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [view_loan_application, setview_loan_application] = useState(false)
    const [add_loan_application, setadd_loan_application] = useState(false)
    const [manage_loan_application, setmanage_loan_application] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "view_loan_application") {
                    setview_loan_application(true);
                }
                if (option.key == "add_loan_application") {
                    setadd_loan_application(true);
                }
                if (option.key == "manage_loan_application") {
                    setmanage_loan_application(true);
                }

            });
        }
    }, [role]);

    // console.log(status, "status", nfc_site_name)
    const Form = useFormik({
        initialValues: {
            user_loan_id: "",
            message: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // message: yup.string().required('Please add a message'),
            // image: yup.string().required('Please Select Image')
        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("user_loan_id", userloanID);
            formData.append("message", Form.getFieldProps('message').value);
            formData.append("image", imageUpload);
            formData.append("time_zone", date_Format)

            console.log(formData)

            // updatechat(token, formData)
            postApi("/loan-chat-with-user", formData, true).then((res) => {
                console.log(res)
                if (res.status == true) {
                    resetForm({ values: '' });
                    getBorrowerdetail(userloanID)
                    setimageUpload({})
                    // ref.current.click()

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    })



    const handleImageRemove = () => {
        // Clear the image data from the state
        setimageUpload(null);
    };

    const handleDelete = (loanID, id) => {
        // console.log(loanID, id, "________________")
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = JSON.stringify({
                    "loan_status_id": id
                })

                // const response = await EditLoanStatus(token, data, loanID)
                const response = await postApi(`/update-loan-status/${loanID}`, {
                    loan_status_id: id
                })
                if (response?.status) {
                    Swal.fire(
                        'Approve!',
                        'Application Loan Status Updated Successfully.',
                        'success'
                    )
                    ViewLoanCases(token, search, pagesCount)
                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    const DisbursalModal = (id) => {
        navigate(prefix + `/disbursed_emi/${id}`)

    }

    const [Message, setMessage] = useState([]);

    // const noteID = ""
    const [IDs, setIDs] = useState()

    // const addNotes = (id) => {
    //     // setIDs = id
    // }

    // console.log(noteID)

    const GetNotesMessage = async (token, ids) => {
        // console.log(token, noteID)
        // const response = await getnotesmessage(token, ids)
        const response = await getApi(`/get-loan-notes/${ids}`)

        console.log(response, "319")
        if (response?.status == true) {
            setMessage(response.data)
        } else {
            console.log(response.message)
        }
    }


    // useEffect(() => {
    //     GetNotesMessage(token)
    // }, [])





    const FormEdit = useFormik({
        initialValues: {
            user_loan_id: "",
            note: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            note: yup.string().required('Please Add Notes'),


        }),
        onSubmit: async values => {
            // const data = JSON.stringify({
            //     user_loan_id: IDs,
            //     note: values.note,

            // })

            // const response = await addloannote(token, data)
            const response = await postApi("/add-loan-note", {
                user_loan_id: IDs,
                note: values.note,
            })

            if (response.status === true) {
                toast.success(response.message);
                FormEdit.resetForm()
                GetNotesMessage(token, IDs)

            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage Loan</h4>
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    {(user_type == 'lenders' || add_loan_application) && (<Link to="/lender/add_loan" className="btn btn-primary btn-pill mt-2"><i className="fa fa-plus me-2"></i> Add Loan</Link>)}
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1') }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Signature</th>
                                                        <th className="font-weight-bold">Agreement File</th>
                                                        <th className="font-weight-bold">Borrower Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Loan Type  {sortedBy == "loan_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }}> </i>}</th>
                                                        <th className="font-weight-bold">Loan Status  {sortedBy == "loan_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                        {(user_type == "lenders" || manage_loan_application) && <th className="font-weight-bold">Action </th>}
                                                        <th className="font-weight-bold">Comment Box</th>
                                                        <th className="font-weight-bold">Note</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { user_loan_id, loan_status, loan_type, last_name, first_name, mobile_number } = option;
                                                                return (
                                                                    <tr key={serial_num}>
                                                                        {option?.agreement_signature_1 !== null ? (
                                                                            <td>
                                                                                <Image
                                                                                    src={option.agreement_signature_1}
                                                                                    style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                                <Image
                                                                                    src={option.agreement_signature_2}
                                                                                    style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </td>) : <td>--</td>}
                                                                        {option?.agreement_doc_1 !== null ? (
                                                                            <td>
                                                                                <a href={option.agreement_doc_1} download>
                                                                                    <img
                                                                                        src="/assets/img/pdf.jpeg"
                                                                                        style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                        className="card-img-top m-1"
                                                                                        alt="PDF"
                                                                                    />
                                                                                </a>
                                                                                <a href={option.agreement_doc_2} download>
                                                                                    <img
                                                                                        src="/assets/img/pdf.jpeg"
                                                                                        style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                        className="card-img-top m-1"
                                                                                        alt="PDF"
                                                                                    />
                                                                                </a>
                                                                            </td>) : <td>--</td>}
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/case_detail/${user_loan_id}`, state: { user_loan_id } }}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}  {last_name ? last_name : ""}<br />{mobile_number}</span></Link></td>
                                                                        <td>{option.loan_type ? loan_type : "-"}</td>
                                                                        <td><span className="font-weight-normal1"> {option?.loan_status ? loan_status?.status_name : "- -"} </span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.createdAt).timeZone}</span></td>
                                                                        {/* {(user_type == "lenders" || manage_loan_application) && <td><span>  <button type="button" disabled={(option?.loan_status?.status_name === "Disbursal" || option?.loan_status?.status_name === "Rejected" || option?.loan_status?.status_name === "Closed")} className="btn btn-primary" data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.user_loan_id); setSite_Name(option?.loan_status?.status_id); getStatus(token); }}  >}Update Status</button> </span></td>} */}

                                                                        {(user_type == "lenders" || manage_loan_application) && <td><span>{
                                                                            (option?.loan_status?.status_name === "Open" &&
                                                                                <button className="btn btn-primary btn-sm"
                                                                                    onClick={() => { handleDelete(option.user_loan_id, "2") }} >Initial Review </button>) ||
                                                                            (option?.loan_status?.status_name === "Initial Review" &&
                                                                                (<span>
                                                                                    <button className="btn btn-primary btn-sm m-1"
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "3") }}>Underwriting </button>
                                                                                    <button className="btn btn-warning btn-sm"
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "1") }}>Open </button></span>)) ||
                                                                            (option?.loan_status?.status_name === "Underwriting" && (
                                                                                <span>
                                                                                    <button className="btn btn-success btn-sm m-1"
                                                                                        onClick={() => { DisbursalModal(option.user_loan_id) }}
                                                                                        /* onClick={() => { handleDelete(option.user_loan_id, "4") }} */>Approved </button>
                                                                                    <button className="btn btn-primary btn-sm m-1"
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "2") }}>Initial Review </button>

                                                                                    <button className="btn btn-danger btn-sm"
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "5") }}>Rejected </button></span>)) ||
                                                                            (option?.loan_status?.status_name === "Approved" && (
                                                                                <span><span> {option?.agreement_signature_2 === null ? <span className='text-danger mt-1'>Waiting for<br /> Signature &nbsp;&nbsp;&nbsp;</span> :
                                                                                    <button className="btn btn-primary btn-sm "
                                                                                        style={{ marginTop: "-15px", marginRight: '5px' }}
                                                                                        //DisbursalModal to={prefix + `/note/${user_loan_id}`}
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "6") }}
                                                                                    >Disbursal</button>}</span>
                                                                                    <button className="btn btn-danger btn-sm" style={{ marginTop: "-15px", marginRight: '5px' }}
                                                                                        onClick={() => { handleDelete(option.user_loan_id, "8") }}>Closed </button></span>)) ||
                                                                            (<span >
                                                                                {option?.loan_status?.status_name === "Disbursal" ? <span>Disbursed</span> : <span>{option?.loan_status?.status_name}</span>}</span>)}</span>
                                                                        </td>}
                                                                        <td><button type="button" onClick={() => getBorrowerdetail(option.user_loan_id, first_name)} className="btn btn-primary" data-bs-target="#chatBubble" data-bs-toggle="modal"><i className="las la-comments coseicon"></i></button></td>
                                                                        <td>
                                                                            <button className="btn btn-primary" type="button"
                                                                                onClick={() => { setIDs(option?.user_loan_id); GetNotesMessage(token, option?.user_loan_id) }}
                                                                                // onClick={addNotes(option.user_loan_id)}
                                                                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Note</button>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                            {/* Update Status Modal start */}
                            {/* <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content modal-content-demo">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Update Loan Status</h3>
                                            <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal"
                                                onClick={() => { setID(); setSite_Name(); }}
                                                type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                                <div className="row">
                                                    <div className="row g-xs">
                                                        <div className="col-12">
                                                            <select
                                                                className="form-control"
                                                                name="nfc_site_name"
                                                                {...FormEdit.getFieldProps("nfc_site_name")}
                                                            >
                                                                <option value="">Select Loan Status</option>
                                                                {status?.map((item, index) => {
                                                                    const selectedValue = FormEdit.getFieldProps("nfc_site_name").value;
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={item.status_id}
                                                                            disabled={
                                                                                (selectedValue === 1 && item?.status_id !== 2) ||
                                                                                (selectedValue === 2 && item?.status_id !== 1 && item?.status_id !== 3) ||
                                                                                (selectedValue === 3 && item?.status_id !== 2 && item?.status_id !== 4 && item?.status_id !== 5) ||
                                                                                (selectedValue === 4 && item?.status_id !== 8 && item?.status_id !== 6) ||
                                                                                (selectedValue === 6 && item?.status_id !== 4 && item?.status_id !== 8)
                                                                            } >{item.status_name}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {FormEdit.touched.nfc_site_name && FormEdit.errors.nfc_site_name ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.nfc_site_name}</div> : ''}
                                                        </div>
                                                        {FormEdit.getFieldProps("nfc_site_name").value === '6' &&
                                                            <form className="row" id='registerForm' onSubmit={FormEdit.handleSubmit}>
                                                                <div className="col">
                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Amount</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Amount" {...FormEdit.getFieldProps("amount")} />
                                                                                </div>
                                                                                {FormEdit.touched.amount && FormEdit.errors.amount ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.amount}</div> : ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">

                                                                        <div className="form-group">
                                                                            <label className="form-label">Total EMI</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Total EMI" {...FormEdit.getFieldProps("total_emi")} />
                                                                                </div>
                                                                                {FormEdit.touched.total_emi && FormEdit.errors.total_emi ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.total_emi}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Comment</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="Enter Comment" {...FormEdit.getFieldProps("comment")} />
                                                                                </div>
                                                                                {FormEdit.touched.comment && FormEdit.errors.comment ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.comment}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col">

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Monthly EMI Amount</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Deduction Amount Limit" {...FormEdit.getFieldProps("emi_monthly_amount")} />
                                                                                </div>
                                                                                {FormEdit.touched.emi_monthly_amount && FormEdit.errors.emi_monthly_amount ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.emi_monthly_amount}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="form-label">EMI Start Date</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="date" className="form-control" placeholder="Enter EMI Start Date" {...FormEdit.getFieldProps("emi_start_date")} />
                                                                                </div>
                                                                                {FormEdit.touched.emi_start_date && FormEdit.errors.emi_start_date ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.emi_start_date}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-5">
                                                                    <div className="col-md-12">
                                                                        <input type="submit" name="next" className="next action-button apply-now-btn ml-00 mt-1" value="Submit" />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        }
                                                    </div>
                                                    <div className="form-footer mt-2">
                                                        <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                            Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* Update Status Modal End */}

                            {/* Note Modal Start */}
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header">
                                    <h5 id="offcanvasRightLabel" className='text-primary'>Chat Activity</h5>
                                    {/* <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button">
                                                                                        <span aria-hidden="true">×</span></button> */}
                                    <button type="button" className="btn-close"
                                        data-bs-dismiss="offcanvas" aria-label="Close" aria-hidden="true">
                                        ×
                                    </button>
                                </div>
                                <div className="offcanvas-body">
                                    <form className="row " id='registerForm' onSubmit={FormEdit.handleSubmit} >
                                        <div className="card w-100 sideNoteBox">
                                            {Message.length > 0 && Message.map((option, index) => {
                                                return (
                                                    <div className="card-body" key={index}>
                                                        <h5 className="card-title mb-0">{option?.subLenderData?.full_name}</h5>
                                                        <p className="card-text">{option?.note}<br />
                                                            <span className='text-success'>{TimeZone(option?.created_at).timeZone}</span></p>
                                                        {/* <p className="card-text"></p> */}
                                                    </div>
                                                )
                                            })}
                                            {Message.length == 0 &&
                                                <div className="card-body">
                                                    <h5 className="card-title mb-0">No Data Available</h5>

                                                </div>}
                                        </div>

                                        <div className="addNoteBtnTxt">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12 pl-00">
                                                <div className="form-group mb-1">
                                                    <label className="form-label"></label>
                                                    <div className="row g-xs">
                                                        <div className="input-group pl-05">
                                                            <textarea placeholder='Add Notes' {...FormEdit.getFieldProps("note")} className="form-control formtestarea" />
                                                            {FormEdit.touched.note && FormEdit.errors.note ?

                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.note}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pl-00">
                                                <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1"> Save </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* Note Modal End */}



                            {/* Chat Bot Modal start */}
                            <div className="modal fade effect-scale show" id="chatBubble" aria-modal="true" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content modal-content-demo">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Applicant Name: {username ? username : "--"}</h3>
                                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                                <div className="col-md-12">
                                                    <div className="chat-bot">
                                                        <div className="messenger">
                                                            {/* <div className="timestamp">04:45 AM</div> */}
                                                            <div className="chatroom">
                                                                {data.length > 0 && data.map((option, index) => (
                                                                    <div key={index} className={option.sender === "USER" ? "msg msg-left" : "msg msg-right"}>
                                                                        <div className="bubble">
                                                                            {option?.image != null ? <Image src={option.image} style={{ width: '100px', height: '100px' }} alt="loading" /> : ""}
                                                                            <h6 className="name">{option.message ? option.message : ""}</h6>
                                                                        </div>
                                                                        <div className="timestamp">{TimeZone(option?.created_at).timeZone}</div>

                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="type-area">
                                                                <label for="fileInput" className="upload-label">
                                                                    <span className="fa-solid fa-paperclip upload-icon"></span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    onChange={(e) => setimageUpload(e.target.files[0])}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="typing"
                                                                    placeholder="Type Here..."
                                                                    {...Form.getFieldProps("message")}
                                                                />
                                                                <button type="submit" aria-hidden="true" className="sendBtnChat">
                                                                    <i className="las la-paper-plane"></i>
                                                                </button>
                                                            </div>
                                                            {/* {Form.touched.message && Form.errors.message ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.message}</div> : ''} */}


                                                            {/* {imageUpload && imageUpload.name ? (
                                                                <Image src={URL.createObjectURL(imageUpload)} className='m-2' style={{ maxWidth: '40%', height: '100px' }} alt="" />
                                                            ) : (
                                                                ""
                                                            )} */} </div>
                                                        {imageUpload && imageUpload.name ? (
                                                            <div className="selected-image">
                                                                <img src={URL.createObjectURL(imageUpload)} className='m-2' style={{ maxWidth: '100%', height: '100px' }} alt="" />
                                                                {/* <Link to="" className="upload__img-close"> */}
                                                                <i className="fa-solid fa-close fa-fw" onClick={handleImageRemove} style={{ cursor: "pointer" }}></i>
                                                                {/* </Link> */}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Chat Bot Modal End */}
                        </div>
                    </div>
                </div>

            }
        </>
    )

}
export default Manageloan


