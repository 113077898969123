import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PaginationComponent from '../../../PaginationComponent';
import { toast } from 'react-hot-toast';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import { GetPermission, getApi, postApi } from '../../service/employService';
import Loader from '../../../components/Loader/loader';
import moment from 'moment-timezone'
import { EXCEL_EMPLOYEE_ATTENDENCE_TEMPLATE } from '../../../attendence';
import { Switch } from 'antd';
import { Gettime } from '../../../components/TimeZone/TimeZone';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const EmployerAttendance = () => {
    let date_data = new Date
    let month1 = date_data.getMonth() + 1;
    if (month1 < 10) {
        month1 = "0" + month1
    }
    const [month, setmonth] = useState(month1)
    const [year, setyear] = useState(date_data.getFullYear())

    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [validate, setValidate] = useState(false)
    const [state, setstate] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [dataArray1, setDataArray1] = useState([]);
    let [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [deleteId, setdeleteId] = useState('');
    const [loader, setloader] = useState(true)
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [sortedBy, setSortedBy] = useState("status")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const gridRef = useRef();
    const [open, setOpen] = useState(false);
    // const [ID, setId] = useState({})
    // const [pay_cycle, setpaycycle] = useState()
    // const [deductioncode, setdeductioncode] = useState()
    // const [employeecode, setemployeecode] = useState()
    // const [deductionamount, setdeductionamount] = useState()
    const [excelFile, setExcelFile] = useState(null);
    const [fileToupload, setFileToUpload] = useState('');
    const [in_time, setIn_time] = useState(null)
    const [out_time, setOut_time] = useState(null)
    const [customer, setCustomer] = useState()
    const ref = useRef()
    const inputref = useRef()
    const navigate = useNavigate();
    const [updatedate, setUpdatedate] = useState()
    const current_date_format = new moment().format("YYYY-MM-DD")
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const [searchcheck, setsearchcheck] = useState(false)
    const [timevalue, settimevalue] = useState("")
    const [searchcheck1, setsearchcheck1] = useState(false)
    const [timevalue1, settimevalue1] = useState("")
    const [Departmentname, setDepartmentname] = useState('')
    const [Departmentname1, setDepartmentname1] = useState('')
    const handleShow = (customer_id) => {
        setShow(true);
        setdeleteId(customer_id)
    };

    // console.log(timevalue, "timevalue", timevalue1, "timevalue11111")
    // console.log("54654646546565654","idddd",ID, "paycycle" ,paycycle,"deductionamount",deductionamount, "employee",employeecode,"deductioncode",deductioncode)
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employer_name = employer.user_name;
    //  console.log(employer_name)

    // const getEmployeesData = async (token) => {
    //     const response = await GetEmployees(token, employerId, pagesCount, search, sortedBy, orderBy);
    //     console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
    //     if (response.status == true) {
    //         setTimeout(() => {
    //             setEmployee(response.data);
    //             setDataArray(response.data);
    //             setTotalPagesCount(response.allEmployeeCount)
    //             setloader(false)
    //         }, 2000);
    //         setloader(true)
    //         //setTotalPagesCount(response.data["count"])
    //     } else if (response.message === 'Session expired') {
    //         localStorage.removeItem("employer");
    //         navigate('/employer/login')
    //     } else {
    //         console.log("get employees data response", response);
    //     }
    // }
    const [date, setDate] = useState("");
    const onDateChange = (event) => {
        setDate(event.target.value);
    };


    const getEmployeesAttendance = async (token, data) => {
        if (data?.length) {
            search = data
        }
        // const response = await GetAttendancelist(token, pagesCount, search, sortedBy, orderBy, startDate, endDate, date_Format, Departmentname);
        const response = await getApi(`/attendance-list`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, start_date: startDate, end_date: endDate, userTimezone: date_Format, department: Departmentname, department_id: Departmentname1 });

        // console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
        // console.log(date_Format,"time_zone: date_Format")
        try {
            if (response.status == true) {
                settimevalue("")
                settimevalue1("")
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    if (response.data.rows.length > 0) {
                        setUpdatedate(response.data.rows[0].attendance_date)
                    }
                    setloader(false)
                }, 2000);
                setloader(true)
                // console.log(dataArray.length)
                //setTotalPagesCount(response.data["count"])
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                // console.log("get employees data response", response);
                // toast.error(response.message)
                // if (response.message == "Access denied! you're not allowed to access this tab") {
                //     navigate('/employer/dashboard')
                // }
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            // console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error.message);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const [ewa_attendance_view, setewa_attendance_view] = useState(false)
    const [ewa_mark_attendance, setewa_mark_attendance] = useState(false)
    const [ewa_in_out_time, setewa_in_out_time] = useState(false)
    const [ewa_bulk_upload_attendance, setewa_bulk_upload_attendance] = useState(false)
    const [ewa_view_attendance, setewa_view_attendance] = useState(false)
    useEffect(() => {
        if (role) {
            // console.log(role, "role")
            const options = role;
            // console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                // console.log(option, "option")
                if (option.key == "ewa_attendance_view") {
                    setewa_attendance_view(true);
                }
                if (option.key == "ewa_mark_attendance") {
                    setewa_mark_attendance(true);
                }
                if (option.key == "ewa_in_out_time") {
                    setewa_in_out_time(true);
                }
                if (option.key == "ewa_bulk_upload_attendance") {
                    setewa_bulk_upload_attendance(true);
                }
                if (option.key == "ewa_view_attendance") {
                    setewa_view_attendance(true);
                }
            });
        }
    }, [role]);

    const onBtnExport = useCallback(() => {
        gridRef.dataArray.exportDataAsCsv();
    }, []);


    useEffect(() => {
        // setpagesCount(1);
        if (localStorage.getItem("search_result")?.length < 1) {
            getEmployeesAttendance(employer.employer_token);
        }
    }, [pagesCount, search, sortedBy, orderBy, date, startDate, endDate, Departmentname, Departmentname1]);
    // console.log({pagesCount,search, sortedBy, orderBy, date})
    useEffect(() => {
        if (localStorage.getItem("search_result")?.length > 0) {
            // alert("hiiiiii")
            setSearch(localStorage.getItem("search_result"))
            getEmployeesAttendance(employer.employer_token, localStorage.getItem("search_result"))
        }
    }, [pagesCount, search, sortedBy, orderBy, date, startDate, endDate, Departmentname])



    const headers = [
        { label: "Employee Details", key: "name" },
        { label: "Age", key: "age" },
        { label: "Salary", key: "annual_salary" },
        { label: "Address", key: "postal_address" }
    ];

    const csvReport = {
        data: dataArray,
        headers: headers,
        filename: 'Employee_Detail_Report.csv'
    };
    const downloadTemplate = (fileN) => {

        const template = EXCEL_EMPLOYEE_ATTENDENCE_TEMPLATE;
        let sliceSize = 1024;
        let byteCharacters = atob(template);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        saveAs(
            new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
            fileN
        );

    }

    const readExcel = (file) => {
        // let selectedFile = e.target.files[0];
        if (file) {

            setFileToUpload(file)
            uploadExcelForm.setFieldValue('isExcelFile', true)

            // uploadEmployees
            const promise = new Promise((resolve, reject) => {
                // console.log('file.type', file.type);
                if (file) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = (e) => {
                        const bufferArray = e.target.result;
                        // setExcelFileError(null);
                        const workBook = XLSX.read(bufferArray, { type: 'buffer' });
                        const workSheetName = workBook.SheetNames[0];
                        const workSheet = workBook.Sheets[workSheetName];
                        const data = XLSX.utils.sheet_to_json(workSheet);
                        // const data2 = XLSX.utils.s
                        // setExcelFile(e.target.result)
                        resolve(data)


                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                }
            })
            promise.then((d) => {
                setExcelFile(d)
                // console.log('json data ', d);

            })

        } else {
            console.log('please select your file');
            // setExcelFileError('please select only excelfile')
        }
    }



    const uploadExcelForm = useFormik({
        initialValues: {
            // employer_id: '',
            isExcelFile: false,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // employer_id: yup.string().required('Please enter Amount'),
            // isExcelFile: yup.boolean().oneOf([true], ""),

        }),

        onSubmit: async (values) => {
            // alert(fileToupload)
            // console.log(fileToupload, "************%%%((((((((((")

            //   console.log("loaderrrrrrrrrrrr")


            if (excelFile.length == 0) {
                setOpen(true)
            }
            // console.log(excelFile.length)

            if (fileToupload) {
                const formData = new FormData();
                formData.append("file", fileToupload);

                const employer_id = JSON.parse(localStorage.getItem("employer"));
                const response = await postApi("/attendance-upload", formData, true);

                setOpen(false)

                if (response.status == true) {
                    toast.success(response.message);
                    uploadExcelForm.setFieldValue("isExcelFile", "")

                    inputref.current.value = ""
                    uploadExcelForm.resetForm()

                    ref.current.click()
                    getEmployeesAttendance(employer.employer_token);
                    setFileToUpload("")
                } else {
                    setFileToUpload("")
                    inputref.current.value = ""
                    toast.error(response.message);
                }



                // if (response.message == "Attendance file is empty") {
                //     // setOpen(false)
                //     toast.error("Data is not Available")
                //     uploadExcelForm.setFieldValue("isExcelFile", "")
                //     inputref.current.value = ""
                //     uploadExcelForm.resetForm()
                //     // window.$('#modal-center').modal('hide')	
                //     ref.current.click()
                //     getEmployeesAttendance(employer.employer_token);
                //     setFileToUpload("")
                // }
                // else if (response.status == true) {
                //     toast.success(`${response.data.insert.length > 0 ? response.data.insert.length + " " + "Insert Successfully" : ""} ${response.data.notFound.length > 0 ? response.data.notFound.length + " " + "Entries Not Found" : ""} ${response.data.update.length > 0 ? response.data.update.length + " " + "Updated Successfully" : ""} `)
                //     uploadExcelForm.setFieldValue("isExcelFile", "")
                //     inputref.current.value = ""
                //     uploadExcelForm.resetForm()
                //     ref.current.click()
                //     getEmployeesAttendance(employer.employer_token);
                //     setFileToUpload("")
                // } else {
                //     setFileToUpload("")
                //     inputref.current.value = ""
                //     toast.error(response.message);
                // }

            }

        },
    })
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getEmployeesData(employer.employer_token);
    }

    // const refreshbutton = () => {
    //     console.log("dfkjhgkfj");
    //     getEmployeesData(employer.employer_token);
    // }

    const [toggle, setToggle] = useState(true)
    const Registration = async (id, e) => {
        // let data = {
        //     "id": id,
        //     "status": e == true ? '1' : '0',
        // }
        try {
            // const response = await changeStatus(employer.employer_token, id, date_Format)
            const response = await getApi(`/mark-attendance/${id}`, { time_zone: date_Format })
            console.log('changeStatus', response)
            // if(response.status == true){
            //     toast.success(response.message)
            // }
            //   changeStatusToast();
        } catch (error) {
            console.log(error)
        }
    }

    // console.log(Departmentname, "")
    const addAttendance = (user_ID) => {
        // console.log(user_ID, "_____________")
    }


    const Intime = (event, ID, setIn_time, setCustomer) => {
        // console.log(event, ID, setIn_time)
        setIn_time(event)
        setCustomer(ID)
    }

    const OutTime = (event, ID, setOut_time, setCustomer, intime) => {
        // console.log(intime)
        // const parsedTime = intime
        // let newH = parsedTime.hours()
        // let newM = parsedTime.minutes()
        // if (newH < 10) {
        //     newH = `0${newH}`
        // }
        // if (newM < 10) {
        //     newM = `0${newM}`
        // }
        setIn_time(intime)
        // console.log(`${newH}:${newM}`)
        setOut_time(event)
        setCustomer(ID)
    }


    const Time_Schedule = async (customer) => {
        console.log(customer, current_date_format)
        // const data = {
        //     customer_id: customer.customer_id,
        //     in_time: in_time,
        //     out_time: out_time,
        //     current_date: current_date_format,
        //     time_zone: date_Format
        // }
        // const response = await Time_Schedule_Update(employer.employer_token, data)
        console.log(customer, "_____________________")
        const response = await postApi("/update-attendance-v2", {
            customer_id: customer.customer_id,
            in_time: in_time,
            out_time: out_time,
            current_date: customer.attendance_date || current_date_format,
            time_zone: date_Format
        })

        // console.log(response)
        if (response.status == true) {

            setIn_time("")
            setOut_time("")
            toast.success(response.message)
            getEmployeesAttendance(employer.employer_token);
        } else if (response.status == false) {
            toast.error(response.message)
            getEmployeesAttendance(employer.employer_token);
        }
    }
    const handletime = (customer_id, attendance_date) => {
        setstate({ customer_id, attendance_date, in_time, out_time })
        // console.log(attendance_date, "testing by lokendra")
    }


    useEffect(() => {
        if (Object.keys(state)?.length > 0) {
            Time_Schedule(state)

        }
    }, [state])
    //     const Getsearch = JSON.parse(localStorage.getItem("search"));

    //    console.log(Getsearch)

    // const handleCallback = (event, picker) => {
    //     picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    //     setstartDate(picker.startDate.format('MM/DD/YYYY'))
    //     setendDate(picker.endDate.format('MM/DD/YYYY'))
    // }
    const handleCallback = (event, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        // Calculate the difference in days
        const diffInDays = picker.endDate.diff(picker.startDate, 'days');

        if (diffInDays > 31) {
            // If the selected range is more than 31 days, adjust the end date
            const newEndDate = picker.startDate.clone().add(31, 'days');
            picker.setEndDate(newEndDate);
            picker.element.val(startDate + ' - ' + newEndDate.format('YYYY-MM-DD'));
            setstartDate(startDate);
            setendDate(newEndDate.format('YYYY-MM-DD'));
        } else {
            picker.element.val(startDate + ' - ' + endDate);
            setstartDate(startDate);
            setendDate(endDate);
        }
    };

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const [nfcsitename, setnfcsitename] = useState([])
    const GetNFC = async (token) => {
        try {
            const response = await getApi("/get-nfc-list", { search: "", page_number: "", sortby: "", orderby: "" });
            // const response = await GetNFCDetail(token, search , pagesCount ,sortedBy , orderBy);
            if (response.status == true) {
                setnfcsitename(response.data);
            }
            // else if (response.message == "Access denied! you're not eligible for NFC") {
            //     toast.error(response.message)
            //     navigate('/employer/dashboard')
            // } 
            else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetNFC();
    }, []);

    const GetDepartment = async (token) => {
        try {
            // const response = await GetDepartmentDetail(token, search, pagesCount, sortedBy, orderBy);
            const response = await getApi("/get-nfc-site-list");
            console.log('response', response);
            if (response.status == true) {
                setDataArray1(response.data);

            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    // useEffect(() => {
    //     GetDepartment(employer.employer_token);
    // }, [search, pagesCount, sortedBy, orderBy]);
    useEffect(() => {
        GetDepartment();
    }, []);
    // console.log("Date Selection", search, "__________________")

    const getMinDate = (attendance_date) => {
        const currentDate = attendance_date ? new Date(attendance_date) : new Date(); // Convert to Date if attendance_date is a string, use current date if not available
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = '00';
        const minutes = '00';

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">EWA Attendance</h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {/* <button type="submit" className="btn btn-outline-primary mt-3" onClick={refreshbutton()}> Refresh</button> */}
                                {/* <button type="submit" className="btn btn-outline-primary mt-3" onClick={() => downloadTemplate('employee-attendence-template.xlsx')}>  */}
                                <a href={exportfix + `/attendance-sample-export?username=${employer_name}&time_zone=${date_Format}`} className="btn btn-primary btn-pill mt-3" download >
                                    <i className="fa fa-download me-2"></i>Sample Download</a>
                                {(ewa_bulk_upload_attendance || user_type == "employer") && (<button type="button" data-toggle="modal" data-target="#modal-center"
                                    className="btn btn-primary btn-pill mt-3"><i className="fa fa-plus me-2"></i> Upload Attendance </button>)}
                                <a href={exportfix + `/attendance-export?start_date=${startDate ? startDate : current_date_format}&end_date=${endDate ? endDate : current_date_format}&username=${employer_name}&time_zone=${date_Format}`} className="btn btn-primary btn-pill mt-3" style={{ float: 'right' }} download ><i className="fa fa-download me-2"></i> Export Attendance</a>

                                {/* <Link to={prefix + "/add_employee"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                                {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Export Excel</CSVLink>    */}
                                {/* <a href={exportfix +`/export-employee/${employer.user_name} `} className="btn btn-outline-primary mt-3" download ><i className="fa fa-download me-2"></i> Import Excel</a> */}
                            </div>
                        </div>

                        <div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered emplyoyemodal" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modal-centerLabel">Upload Attendance</h5>
                                        {/* <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Templete</button> */}

                                    </div>
                                    <div className="modal-body">
                                        <div className="formbox row">
                                            {/* <div className="form-group form-group-mtb col-md-5 mt-2">
                                                <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Employee Templete</button>
                                            </div> */}
                                            <form className="" onSubmit={uploadExcelForm.handleSubmit}  >
                                                {uploadExcelForm.values.wrong_message ?
                                                    <div className="invalid-feedback " style={{ display: "block", textAlign: "center" }}>{uploadExcelForm.values.wrong_message}</div>
                                                    : ''}
                                                {open == true ? <div style={{ marginLeft: '100px'  /*, marginTop: '200px' */ }}  >
                                                    <Loader /> </div> :
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            {/* <div className="uploader">
                                                            </div> */}
                                                            <div>
                                                                <div className="page-rightheader" style={{ marginLeft: '10px' }}>
                                                                    <div className="btn-list ">
                                                                        <button className="btn btn-outline-primary uploadbmodal">
                                                                            <label htmlFor="formFile">
                                                                                <i className="fa fa-upload fa-beat me-2"></i>
                                                                            </label>
                                                                            <input ref={inputref} cstyle={{ marginLeft: '10px' }} type="file" id='formFile' onChange={(e) => {
                                                                                const file = e.target.files[0]; readExcel(file);
                                                                            }} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="modalboxEmployee">
                                                                    <button type="submit" className="btn btn-primary  w-md mb-0 mt-3 mb-0" > Save and Update</button>
                                                                    <button ref={ref} type="button" className="close mt-3" data-dismiss="modal" aria-label="Close"> Close </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                       
														<div className="form-group">
															<input type="date" min="2001-12-31" className="form-control" placeholder="Enter Date Of Birth" />
															
														 </div>
													</div> */}
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Date</label>
                                <div className="row g-xs">
                                    <div className="input-group" >
                                        <DateRangePicker
                                            onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                            initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input
                                                placeholder="Search By Date"
                                                className="form-control fc-datepicker hasDatepicker"
                                                type="text" defaultValue=""
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Site Name</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname} onChange={(e) => setDepartmentname(e.target.value)} >
                                                <option value="">Select Site Name</option>
                                                {nfcsitename.map(nfcdata => (<option key={nfcdata.id} value={nfcdata.site_name}>{nfcdata.site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Department</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname1} onChange={(e) => setDepartmentname1(e.target.value)} >
                                                <option value="">Select Department</option>
                                                {dataArray1.map(nfcdata => (<option key={nfcdata.site_id} value={nfcdata.site_id}>{nfcdata.nfc_site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        {localStorage.getItem("search_result") ?
                                            <input type="text" defaultValue={localStorage.getItem("search_result")}
                                                className="form-control" placeholder="Search By Keyword"
                                                value={search} onChange={(e) => { setSearch(e.target.value) }} /> :
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setsearchcheck(true); setsearchcheck1(true) }} />}
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <a href={exportfix + `/attendance-export?start_date=${startDate ? startDate : current_date_format}&end_date=${endDate ? endDate : current_date_format}&username=${employer_name}&time_zone=${date_Format}`} className="btn btn-primary btn-pill mt-4" style={{ float: 'right' }} download ><i className="fa fa-download me-2"></i> Export Attendance</a>

                        </div> */}
                    </div>

                    {/* <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="card">
                                <div className="card-header br-0">
                                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>}</th>)}
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th className="font-weight-bold">In Time  {sortedBy == "in_time" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }}></i>}</th>)}
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th />)}
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th className="font-weight-bold">Out Time  {sortedBy == "out_time" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_time') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_time') }}></i>}</th>)}
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th />)}
                                                    {(user_type == "employer" || ewa_mark_attendance) && (<th className="font-weight-bold">Total Hours  {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>)}
                                                    <th className="font-weight-bold">Date {sortedBy == "attendance_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('attendance_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('attendance_date') }}></i>}</th>
                                                    <th className="font-weight-bold">Employee Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Auto Checkout Status  {sortedBy == "auto_check_out" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }}></i>}</th>
                                                    <th className="font-weight-bold">Check In From  {sortedBy == "in_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }}></i>}</th>
                                                    <th className="font-weight-bold">Check Out From  {sortedBy == "out_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_from') }}></i>}</th>
                                                    <th className="font-weight-bold">In Time GPS Location  {sortedBy == "gps_location" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }}></i>}</th>
                                                    <th className="font-weight-bold">Out Time GPS Location  {sortedBy == "out_gps_location" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_gps_location') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('out_gps_location') }}></i>}</th>
                                                    <th className="font-weight-bold">Site Name  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Department  {sortedBy == "department_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Time Zone  {sortedBy == "time_zone" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }}></i>}</th>
                                                    {(ewa_view_attendance || user_type == "employer") && (<th className="font-weight-bold">Action</th>)}

                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /> <td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, i) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                            const { in_from, out_from, site_name, customer_id, in_time, auto_check_out, out_time, out_gps_location, attendance_date, status, employee_code, gps_location, mobile_number, first_name, last_name, time_zone, department_name } = option;
                                                            // console.log(i , "checkingi" , serial_num,)                                                        

                                                            var timeZonesIN = moment.utc(option.in_time).format("HH:mm")
                                                            // var timeZonesOUT = moment.utc(option.out_time).format("HH:mm")
                                                            var timeZonesOUT = moment.utc(option.out_time).format("YYYY-MM-DDTHH:mm");
                                                            const calculateTotalTime = (inTime, outTime) => {
                                                                if (!in_time || !out_time) {
                                                                    return "-";
                                                                }
                                                                // const inTimeDate = new Date(`${attendance_date}  ${inTime}`);
                                                                // const outTimeDate = new Date(`${attendance_date} ${outTime}`);
                                                                // const totalTimeMs = outTimeDate - inTimeDate;
                                                                // const hours = Math.floor(totalTimeMs / 3600000);
                                                                // const minutes = Math.floor((totalTimeMs % 3600000) / 60000);
                                                                // return `${hours} hours ${minutes} minutes`;
                                                                const inTimeDate = moment(inTime)
                                                                const outTimeDate = moment(outTime)

                                                                // Get the difference in minutes
                                                                const diffInMinutes = outTimeDate.diff(inTimeDate, 'minutes');

                                                                // Calculate hours and remaining minutes
                                                                const hours = Math.floor(diffInMinutes / 60);
                                                                const remainingMinutes = diffInMinutes % 60;
                                                                return `${hours} hours ${remainingMinutes} minutes`;
                                                            };
                                                            // var timeZones = moment.tz(option.in_time, option.time_zone).format('HH:mm');
                                                            // const splitdata = timeZones.split()
                                                            // console.log("Moment", timeZones)
                                                            return (
                                                                <tr key={serial_num}>
                                                                    {(ewa_mark_attendance || user_type == "employer") && (
                                                                        <td>
                                                                            {status == 'P' &&
                                                                                <div>
                                                                                    <Switch tabIndex={serial_num} checkedChildren="Present"
                                                                                        onClick={(e) => Registration(customer_id, e)} unCheckedChildren="Absent"
                                                                                        defaultChecked={true} onChange={(e) => setToggle(e)} />
                                                                                </div>
                                                                            }
                                                                            {(status == null || status == 'A') &&
                                                                                <div /* className="col-auto ms-auto d-print-none" */>
                                                                                    <Switch tabIndex={serial_num} checkedChildren="Present" onClick={(e) => Registration(customer_id, e)}
                                                                                        unCheckedChildren="Absent" onChange={(e) => setToggle(e)} />
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    )}
                                                                    {searchcheck && timevalue == ""
                                                                        ? <>
                                                                            {(user_type == "employer" || ewa_mark_attendance) && (
                                                                                <td>
                                                                                    <div className="cs-form">
                                                                                        <input
                                                                                            tabIndex={serial_num}
                                                                                            type="time"
                                                                                            className="form-control"
                                                                                            value={timeZonesIN}
                                                                                            onBlur={(e) => { Intime(e.target.value, customer_id, setIn_time, setCustomer, attendance_date); handletime(customer_id, attendance_date); console.log("value"); }}
                                                                                            onClick={() => { setsearchcheck(false) }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            )}
                                                                        </>
                                                                        : <>
                                                                            {(user_type == "employer" || ewa_mark_attendance) && (
                                                                                <td>
                                                                                    <div className="cs-form">
                                                                                        <input
                                                                                            tabIndex={serial_num}
                                                                                            type="time"
                                                                                            className="form-control"
                                                                                            defaultValue={timeZonesIN ? timeZonesIN : ""}
                                                                                            onBlur={(e) => { Intime(e.target.value, customer_id, setIn_time, setCustomer, attendance_date); handletime(customer_id, attendance_date); setsearchcheck(true); console.log("defaultvalue"); }}
                                                                                            onChange={(e) => { settimevalue(e.target.value); }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            )}
                                                                        </>
                                                                    }


                                                                    <td>
                                                                        {option.in_time != null
                                                                            ? <i className="fa fa-check-circle-o " style={{ fontSize: "15px", color: "#3bc391" }} />
                                                                            : "-"
                                                                        }
                                                                    </td>
                                                                    {searchcheck1 && timevalue1 == ""
                                                                        ? <>
                                                                            {(user_type == "employer" || ewa_mark_attendance) && (
                                                                                <td>
                                                                                    <div className="cs-form">
                                                                                        {option.in_time != null
                                                                                            ? <input
                                                                                                tabIndex={serial_num}
                                                                                                // type='time'
                                                                                                type="datetime-local"
                                                                                                className="form-control"
                                                                                                min={getMinDate(attendance_date)}
                                                                                                value={timeZonesOUT}
                                                                                                onBlur={(e) => {
                                                                                                    OutTime(e.target.value, customer_id, setOut_time, setCustomer, timeZonesIN);
                                                                                                    handletime(customer_id, attendance_date);
                                                                                                }}
                                                                                                onClick={() => { setsearchcheck1(false) }}
                                                                                            />
                                                                                            : <input
                                                                                                tabIndex={serial_num}
                                                                                                // type='time'
                                                                                                type="datetime-local"
                                                                                                className="form-control"
                                                                                                value={Gettime(option.out_time)}
                                                                                                disabled
                                                                                                min={getMinDate(attendance_date)}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                            }
                                                                        </>
                                                                        : <>
                                                                            {(user_type == "employer" || ewa_mark_attendance) && (<td><div className="cs-form">{option.in_time != null
                                                                                ? <input
                                                                                    tabIndex={serial_num}
                                                                                    // type='time'
                                                                                    type="datetime-local"
                                                                                    className="form-control"
                                                                                    min={getMinDate(attendance_date)}
                                                                                    defaultValue={timeZonesOUT}
                                                                                    onBlur={(e) => { OutTime(e.target.value, customer_id, setOut_time, setCustomer, timeZonesIN); handletime(customer_id, attendance_date);; setsearchcheck1(true) }}
                                                                                    onChange={(e) => { settimevalue1(e.target.value); }}
                                                                                />
                                                                                : <input
                                                                                    tabIndex={serial_num}
                                                                                    // type='time'
                                                                                    type="datetime-local"
                                                                                    className="form-control"
                                                                                    value={Gettime(option.out_time)}
                                                                                    disabled
                                                                                    min={getMinDate(attendance_date)}
                                                                                />
                                                                            }
                                                                            </div>
                                                                            </td>
                                                                            )}
                                                                        </>
                                                                    }
                                                                    {(user_type == "employer" || ewa_mark_attendance) && (<td>{option.out_time != null ? <i className="fa fa-check-circle-o " style={{ fontSize: "15px", color: "#3bc391" }} /> : "-"}</td>)}
                                                                    {(user_type == "employer" || ewa_mark_attendance) && (<td>{calculateTotalTime(option.in_time, option.out_time)}</td>)}
                                                                    {(user_type == "employer" || ewa_mark_attendance) && (<td>{attendance_date ? attendance_date : "-"}</td>)}
                                                                    <td><Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} >{first_name ? first_name : "-"}  {last_name ? last_name : ""}<br />{option?.mobile_number && mobile_number}<br />{employee_code ? employee_code : "-"}</Link></td>
                                                                    <td>{auto_check_out == '1' ? <span className='text-danger'>YES </span> : <span className='text-success'>NO</span>}</td>
                                                                    <td><span className="content_fix" style={{ fontSize: 'smaller' }}>{option?.in_from != null ? in_from : "--"}</span></td>
                                                                    <td><span className="content_fix" style={{ fontSize: 'smaller' }}>{option?.out_from != null ? out_from : "--"}</span></td>
                                                                    <td><span className="content_fix">{option?.gps_location ? gps_location : "--"}</span></td>
                                                                    <td><span className="content_fix">{option?.out_gps_location ? out_gps_location : "--"}</span></td>
                                                                    <td>{option?.site_name ? site_name : "--"}</td>
                                                                    <td>{option?.department_name ? department_name : "--"}</td>
                                                                    <td>{option?.time_zone ? time_zone : "--"}</td>
                                                                    {/* <td>{status == "P" ? <i className="fa fa-check-circle-o " style={{ fontSize: "30px", color: "#3bc391" }}></i>:"-"}</td> */}
                                                                    {(ewa_view_attendance || user_type == "employer") && (<td><Link className='btn btn-outline-primary' to={{ pathname: `${prefix}/employees_attendance/${customer_id}`, state: { customer_id } }} onClick={() => { localStorage.setItem("search_result", search) }}  >View All Attendance </Link></td>)}
                                                                </tr>)
                                                        })
                                                        } </tbody>

                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            }
            {/* Edit Employee Modal Start */}
            {/* <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog" style={{ display: 'block !important' }}>
                <div className="modal-dialog" role="document" style={{ overflowY: 'initial !important' }}>
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Edit Employee</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button" onClick={emptycheck}><span aria-hidden="true">×</span></button>
                        </div>
                        <form className="" id='registerForm' onSubmit={EditForm.handleSubmit} >
                            <div className="modal-body application-modal" >
                                <div className="form-group">
                                    <label className="form-label">Employee Code</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                {...EditForm.getFieldProps("employee_code")}
                                            />
                                        </div>
                                        {EditForm.touched.employee_code && EditForm.errors.employee_code ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.employee_code}</div> : ''}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Deduction Code</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" 
                                                {...EditForm.getFieldProps("deduction_code")}
                                            />
                                        </div>
                                        {EditForm.touched.deduction_code && EditForm.errors.deduction_code ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.deduction_code}</div> : ''}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Deduction Amount Limit</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                {...EditForm.getFieldProps("deduction_amount_limit")}
                                            />
                                        </div>
                                        {EditForm.touched.deduction_amount_limit && EditForm.errors.deduction_amount_limit ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.deduction_amount_limit}</div> : ''}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Pay Cycle</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="paycycle" 
                                                    onChange={(e) => setpaycycle(e.target.value)} 
                                                    {...EditForm.getFieldProps("pay_cycle")} >
                                                    <option value='weekly'>Weekly</option>
                                                    <option value='Fortnightly'>Fortnightly</option>
                                                    <option value='monthly'>Monthly</option>
                                                </select>
                                            </div>
                                            {EditForm.touched.pay_cycle && EditForm.errors.pay_cycle ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.pay_cycle}</div> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 ">
                                    <div className="row" style={{ display: 'flex' }}>
                                        <div className="">
                                            <button type="submit" className='btn btn-primary '  >Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
            {/* Edit Employee Modal End */}


            {/* Delete Employee Modal Start */}
            <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Delete Employee</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                        <div className="modal-body application-modal">
                            <div className="row ">
                                <div className="col-lg-12 col-xl-12 ">
                                    <div className="">
                                        <div className="main-content-body main-content-body-contacts">
                                            <div className="main-contact-info-body">
                                                <div className="media-list p-0">
                                                    <div>
                                                        <div className="media py-4 mt-0 d-flex justify-content-center">
                                                            <div className="media-body ">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="w-70"><span className="font-weight-normal1 fs-14">Do you want to delete this employee?</span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            {/* <div className="d-flex">
                                                                <button className='btn btn-primary'>cancel</button>
                                                            </div> */}
                                                            <div className="d-flex">
                                                                <button className='btn btn-primary' data-bs-dismiss="modal" /* onClick={deleteEmployees} */>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Employee Modal End */}

        </>
    )
}

export default EmployerAttendance