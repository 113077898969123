import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { Breadcrumbsecond } from '../../components';

const LenderDetail = (props) => {
    const [loading, setloading] = useState(false);
    const [loader, setloader] = useState(true)
    const [detail, setDetail] = useState([]);
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const { id } = useParams();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const navigate = useNavigate()
    const token = admin.admin_token;

    const getDetail = async () => {
        setloader(true)
        // const response = await GetLenderDetail(token, id)
        const response = await getApi(`/get-lender/${id}`)
        console.log('response getDetail', response);
        if (response.data) {
            setDetail(response.data);
            setloader(false)
        } else {
            setloader(false)
            props.toast.error(response.message);
        }

    }

    const Navigation = () => {
        navigate(-1)
    }
    useEffect(() => {
        getDetail(admin.admin_token, id);
    }, []);

    return (
        <>
            {loader == true ?
                <div style={{ marginLeft: '400px', marginTop: '200px' }}  >
                    <Loader /> </div> :
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="modal-header w-100">
                            {/* <div className="page-leftheader">
                                <h6 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to={prefix + '/merchant'}>Merchant</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Details </li>
                                        </ol>
                                    </nav>
                                </h6>
                            </div> */}
                            <div className="page-leftheader">
                                <Breadcrumbsecond order={{ first: { name: "Lender", path: `${prefix + '/lender'}` }, second: { name: "Lender Details" } }} />
                            </div>
                            <button className='btn btn-primary' onClick={Navigation} > Back</button>
                        </div>
                        <div className="modal-body application-modal">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="">
                                        <div className="main-content-body main-content-body-contacts">
                                            <div className="main-contact-info-header bg-contacthead">
                                                <div className="media">
                                                    <div className="media-body text-white ml-0">
                                                        <h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
                                                        <p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-contact-info-body">
                                                <div className="media-list p-0">
                                                    <div className="media py-4 mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">

                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14"> ${detail ? detail.mini_loan_range : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">${detail ? detail.max_loan_range : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure + "Months" : ''} </span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure + "Months" : ''} </span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-calendar-alt fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Created Date</label> <span className="font-weight-normal1 fs-14">{detail && detail ? moment(detail.created_at).format('YYYY-MM-DD HH:MM A') : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-calendar-alt fa-fw"></i> </div>
                                                                <div className="w-70"> <label>Last Update</label> <span className="font-weight-normal1 fs-14">{detail && detail ? moment(detail.updated_at).format('YYYY-MM-DD HH:MM A') : ''}</span> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LenderDetail

