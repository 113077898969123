import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { postApi } from '../admin/service/adminService';
// import { postApi } from './service/adminService';

const Otp = () => {
    const navigate = useNavigate();
    const [forVerify, setforVerify] = useState(false)
    const location = useLocation().search;
    const data = new URLSearchParams(location).get('mob_no')
    const form = useFormik({
        initialValues: {
            mobile_number: "",
            otp: "",
            forVerify: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            otp: yup.string().required('Please Enter OTP'),
        }),
        onSubmit: async (values, { resetForm }) => {

            try {
                const response = await postApi('/verify-cus-otp', {
                    mobile_number: data,
                    otp: values.otp,
                    forVerify: true,
                });
                if (response.status == true) {
                    resetForm({ values: "" })
                    toast.success(response.message);
                    navigate(`/agreement_signature?mob_no=${data}`)
                }
                else {
                    toast.error(response.message);
                }
            } catch (err) {
                console.log("🚀 ~ file: login.js:28 ~ Login ~ err:", err)
                toast.error(err.message);
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (forVerify === false) {
                    const response = await postApi('/verify-cus-otp', {
                        "mobile_number": data,
                    });
                    if (response.status === true) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                }
            } catch (error) {
                console.error("Error during API call:", error);
            }
        };

        fetchData();
    }, [forVerify]);


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-single bg-back">
                    <div className="container">
                        <div className="row">
                            <div className="col mx-auto">
                                <div className="row justify-content-center">
                                    <div className="col-xl-7 col-lg-12">
                                        <div className="mb-5 br-7 text-center"><Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img" alt="thirdroc" /></Link></div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-7 col-lg-12">
                                        <div className="row p-0 m-0 bg-white br-7">
                                            <div className="col-lg-6 p-0" style={{ backgroundColor: "#413aa4" }}>
                                                <div className="text-justified text-white p-5 register-1 overflow-hidden">
                                                    <div className="custom-content">
                                                        <div >
                                                            <div className="fs-22 mb-4 font-weight-bold text-white">Welcome Back To Thirdroc !</div>
                                                            <div className="mb-2 text-white-50"><i className="fa-solid fa-circle-dot fa-fw" /> Access all information related to your loan</div>
                                                            <div className="mb-2 text-white-50"><i className="fa-solid fa-circle-dot fa-fw" /> View Latest Offers</div>
                                                            <div className="mb-2 text-white-50"><i className="fa-solid fa-circle-dot fa-fw" /> Get a new loan</div>
                                                            <div className="mb-2 text-white-50"><i className="fa-solid fa-circle-dot fa-fw" /> Pay EMI and Overdues</div>
                                                            <div className="mb-2 text-white-50"><i className="fa-solid fa-circle-dot fa-fw" /> Create a service request</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-6 p-0 mx-auto">
                                                <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                    <div className="card-body">
                                                        <div className="text-center mb-3">
                                                            <h3 className="mb-2">OTP Verification</h3>
                                                            <Link to="/" >Hello There !</Link>
                                                        </div>
                                                        <form className="mt-5" onSubmit={form.handleSubmit}>
                                                            <div className="input-group mb-4">
                                                                <div className="input-group-text"> <i className="fa-solid fa-user fa-fw" /> </div>
                                                                <input type="number" className="form-control" name="otp" {...form.getFieldProps("otp")} placeholder="Please Enter otp " />
                                                                {form.touched.otp && form.errors.otp ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.otp}</div> : ''}
                                                            </div>
                                                            <div className="form-group text-center mb-3 mt-3"> <button type="submit" className="btn btn-primary btn-lg w-100 br-7">Submit</button> </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Otp