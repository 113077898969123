import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast from 'react-hot-toast';
import { Breadcrumbsecond } from '../../../components';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getApi, postApi } from '../../service/lenderService';
import { Image } from 'antd';
import moment from 'moment';

const ViewInvestment = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [Count, setCount] = useState("");
    const [ValidPagination, setValidPagination] = useState(false)
    const lender = JSON.parse(localStorage.getItem("lender"));
    const [imageUpload, setimageUpload] = useState(null)
    const ref = useRef()
    const navigate = useNavigate()

    const Getinvestment = async (token) => {
        // const response = await GetEwaEarningDetails(token, search, pagesCount, sortedBy, orderBy)
        const response = await getApi("/get-investment", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                settotalItems(response.data.amountData)
                setCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)

        } else if (response.message === 'Session expired') {
            localStorage.removeItem("lender");
            navigate('/lender/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else if (response.status == false) {
            console.log(response)
        }
    }
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)

    useEffect(() => {
        Getinvestment(lender.lender_token);
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination]);

    const addSettlementForm = useFormik({
        initialValues: {
            lender_note: '',
            amount: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            amount: yup.string().required('Please add amount'),
            lender_note: yup.string().required('Please add note '),
        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("amount", addSettlementForm.getFieldProps('amount').value);
            formData.append("lender_note", addSettlementForm.getFieldProps('lender_note').value);
            formData.append("image", imageUpload);
            formData.append("time_zone", date_Format)
            // AddSettlement(formData)
            postApi("/add-lender-investment", formData, true).then((res) => {
                if (res.status == true) {
                    toast.success(res.message);
                    Getinvestment(lender.lender_token);
                    ref.current.click()
                    addSettlementForm.resetForm()
                    setimageUpload(null)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    });

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const copiedInfo = () => {
        toast.success("Copied")
    }


    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <Breadcrumbsecond order={{ first: { name: "Manage Investment" }, second: { name: "View Investment " } }} />
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    <button className='btn btn-primary' data-bs-target="#add_settlement"
                                        data-bs-toggle="modal"  >+ Add Pledge</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Pledged Amount</p>
                                        <h4 className="mb-1 font-weight-bold">
                                            {typeof totalItems?.pledgedAmount === 'string'
                                                ? "$" + parseFloat(totalItems.pledgedAmount).toFixed(2)
                                                : typeof totalItems?.pledgedAmount === 'number'
                                                    ? "$" + totalItems.pledgedAmount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Received Amount</p>
                                        <h4 className="mb-1 font-weight-bold">
                                            {typeof totalItems?.receivedAmount === 'string'
                                                ? "$" + parseFloat(totalItems.receivedAmount).toFixed(2)
                                                : typeof totalItems?.receivedAmount === 'number'
                                                    ? "$" + totalItems.receivedAmount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Earning</p>
                                        <h4 className="mb-1 font-weight-bold">{typeof totalItems?.investedAmount === 'string'
                                            ? "$" + parseFloat(totalItems.investedAmount).toFixed(2)
                                            : typeof totalItems?.investedAmount === 'number'
                                                ? "$" + totalItems.investedAmount.toFixed(2)
                                                : "0"}
                                            {/* {totalAmounts?.total_merchant_commission ? "$" + totalAmounts.total_merchant_commission : 0} */}
                                            {/* {typeof totalAmounts?.total_merchant_commission === 'string'
                                                ? "$" + parseFloat(totalAmounts.total_merchant_commission).toFixed(2)
                                                : typeof totalAmounts?.total_merchant_commission === 'number'
                                                    ? "$" + totalAmounts.total_merchant_commission.toFixed(2)
                                                    : "0"} */}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        {/* <th className="font-weight-bold">Admin's Doc </th> */}
                                                        <th className="font-weight-bold">Lender's Doc </th>
                                                        <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">ROI  {sortedBy == "roi" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }}></i>}</th>

                                                        <th className="font-weight-bold">Lender Note  {sortedBy == "lender_note" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_note') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_note') }}></i>}</th>
                                                        {/* <th className="font-weight-bold">Admin Note  {sortedBy == "admin_note" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('admin_note') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('admin_note') }}></i>}</th> */}
                                                        <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                        <th className="font-weight-bold">Invest Date  {sortedBy == "invest_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('invest_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('invest_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Earning  {sortedBy == "invest_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('invest_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('invest_at') }}></i>}</th>


                                                        <th className="font-weight-bold">Create Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Updated Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const {
                                                                    amount, admin_note, lender_note, created_at, updated_at, status, roi, invest_at } = option;
                                                                // var timezone = new Date(option.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });

                                                                const amount1 = amount * (1 + (roi / 100)) * 1;
                                                                const compoundInterest = amount1 - amount;


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {/* <td>
                                                                            <span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.admin_image != null ? option.admin_image : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                            </span>
                                                                        </td> */}
                                                                        <td>
                                                                            <span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.lender_image != null ? option.lender_image : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                            </span>
                                                                        </td>
                                                                        <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{roi && status == "RECEIVED" ? "" + roi + "%" : "--"}</span></td>

                                                                        <td><span className="font-weight-normal1 ">{lender_note ? lender_note : "--"}</span></td>
                                                                        {/* <td><span className="font-weight-normal1 ">{admin_note ? admin_note : "--"}</span></td> */}
                                                                        <td><span className={status == "PLEDGED" ? "font-weight-normal1 badge bg-warning" : status == "RECEIVED" ? "font-weight-normal1 badge bg-success" : status == "FAILED" ? "font-weight-normal1 badge bg-danger" : "font-weight-normal1"}  >{status ? status : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{invest_at ? TimeZone(invest_at).timeZone : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{compoundInterest?.toFixed(2) && status == "RECEIVED" ? "$" + compoundInterest?.toFixed(2) : "--"}</span></td>

                                                                        <td><span className="font-weight-normal1 ">{TimeZone(created_at).timeZone}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{TimeZone(updated_at).timeZone}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Add Pledge</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body application-modal">

                                    <form className="mt-1 row" onSubmit={addSettlementForm.handleSubmit}>
                                        {addSettlementForm.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    <input type="number" placeholder='Enter Amount' {...addSettlementForm.getFieldProps("amount")} className="form-control" />
                                                    {addSettlementForm.touched.amount && addSettlementForm.errors.amount ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.amount}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" placeholder='Enter Note' {...addSettlementForm.getFieldProps("lender_note")} className="form-control" />
                                                    {addSettlementForm.touched.lender_note && addSettlementForm.errors.lender_note ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.lender_note}</div> : ''}
                                                </div>
                                                <div className="input-group file-browser mb-2">
                                                    <input type="file" className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                                </div>
                                            </div>
                                            <div className="form-footer mt-0">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true"  >
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default ViewInvestment
