import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { GetCountry, getApi, postApi } from '../../service/employService';
import ImageViewer from 'react-simple-image-viewer';
import { UseAppContext } from '../../../Context/AppContext';
import Loader from '../../../components/Loader/loader';

const EditProfile = (props) => {
  const [profileData, setprofileData] = useState({});
  const [country, setCountry] = useState([]);
  const navigate = useNavigate();
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
  const [loader, setloader] = useState(true)
  // const user_id = useParams();
  const employer = JSON.parse(localStorage.getItem("employer"));
  const [uploadBr, setUploadBr] = useState({ 'pancard_image': 0, 'logo': 0, 'gstproof_image': 0, 'business_address_proof': 0, 'bank_statement': 0, 'itr_docs': 0 });
  let HeaderLogo = UseAppContext()
  const getProfile = async (token) => {
    // const response = await GetProfile(token);
    const response = await getApi("/get-profile");

    if (response.status == true) {
      setTimeout(() => {
        setprofileData(response.data);
        setloader(false)
      }, 2000);

    } else if (response.message === 'Session expired') {
      localStorage.removeItem("employer");
      navigate('/employer/login')
    } else {
      console.log("get employees data response", response);
    }
  }

  useEffect(() => {
    getProfile(employer.employer_token)
  }, [])
  const getCountry = async () => {
    const response = await GetCountry();
    if (response.status == true) {
      setCountry(response.data);
    } else {
      console.log("get Country data response", response);
    }
  }

  useEffect(() => {
    getCountry();
  }, [])

  // useEffect(() => {
  //   EditForm.setValues({
  //     // user_id: profileData.user_id,
  //     full_name: profileData?.full_name,
  //     company_name: profileData?.company_name,
  //     email: profileData?.email,
  //     address: profileData?.address,
  //     mobile_number: profileData?.mobile_number,
  //     gst_number: profileData?.gst_number,
  //     website: profileData?.website,
  //     pay_cycle: profileData?.pay_cycle,
  //     // platform_fee: profileData?.platform_fee,
  //     country: profileData?.country,
  //     logo: profileData?.logo
  //   })
  // }, [profileData])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const EditForm = useFormik({
    initialValues: {
      full_name: profileData?.full_name,
      company_name: profileData?.company_name,
      email: profileData?.email,
      address: profileData?.address,
      // mobile_number: profileData?.mobile_number,
      gst_number: profileData?.gst_number,
      website: profileData?.website,
      pay_cycle: profileData?.pay_cycle,
      country: profileData?.country,
      logo: profileData?.logo,
      logo_true: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      company_name: yup.string().required('Please enter company name'),
      full_name: yup.string().required('Please enter your name'),
      address: yup.string().required('Please enter address'),
      email: yup.string().email("Invalid email address format").required('Please enter valid email address'),
      //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
      // mobile_number: yup.string().required('Please enter mobile number').min(7, "Mobile number must contain 7 number").max(15, "Mobile number contain Max Upto 15 number").matches(phoneRegExp, 'Please enter only number values'),
      // platform_fee: yup.string().required('Please Enter Platform Fee'),
      // website: yup.string().required('Please enter valid website'),
    }),
    onSubmit: async values => {

      // let data = JSON.stringify(values);
      // const data = JSON.stringify({
      //   company_name: `${values.company_name}`,
      //   full_name: `${values.full_name}`,
      //   address: `${values.address}`,
      //   email: `${values.email}`,
      //   gst_number: `${values.gst_number}`,
      //   website: `${values.website}`,
      //   mobile_number: `${values.mobile_number}`,
      //   country: `${values.country}`,
      //   logo:`${values.logo}`
      // });

      // const data = JSON.stringify({
      //   company_name: `${values.company_name}`,
      //   full_name: `${values.full_name}`,
      //   address: `${values.address}`,
      //   email: `${values.email}`,
      //   gst_number: `${values.gst_number}`,
      //   website: `${values.website}`,
      //   mobile_number: `${values.mobile_number}`,
      //   country: `${values.country}`,
      //   logo:`${values.logo}`
      // });

      // const response = await UpdateProfile(employer.employer_token, JSON.stringify(values))
      const response = await postApi("/edit_profile", {

        full_name: values.full_name,
        company_name: values.company_name,
        email: values.email,
        address: values.address,
        // mobile_number: values.mobile_number,
        gst_number: values.gst_number,
        website: values.website,
        pay_cycle: values.pay_cycle,
        country: values.country,
        logo: values.logo
      })

      if (response.status === true) {
        setprofileData(response.data);
        HeaderLogo.HeaderLogoChange()
        toast.success(response.message);
        navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/profile');
      } else {
        console.log('error', response);
        toast.error(response);
      }
    }
  });
  const checkURL = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  const [images, setimages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = (index, field_path, image_path) => {
    let data = {};
    let url = field_path.split(',');
    if (checkURL(S3_BUCKET_URL + '' + image_path + '' + url[index])) {
      data.push(S3_BUCKET_URL + '' + image_path + '' + url[index])
    }
    if (data.length) {
      setimages(data, setCurrentImage(0, setIsViewerOpen(true)))
    }
  }
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const removeImage = (e, index, path, co_index = 0) => {
    e.preventDefault()
    var array;
    array = EditForm.values[path].split(',')
    array.splice(index, 1)
    if (array == '') {
      EditForm.setFieldValue(path + '_true', false);
    }
    EditForm.setFieldValue(path, array.join(','));
  }
  const updateAndSaveEmployerRecord = async (e, path,) => {
    console.log("e", e, "path", path);
    if (e.target.files.length) {

      const formData = new FormData();
      formData.append('logo', e.target.files[0])
      const response = await postApi("/upload-logo", formData, true);

      // const response = await UploadLogo(employer.employer_token, formData);
      sessionStorage.setItem("employerlogo", response.Data)
      HeaderLogo?.HeaderLogoChange()


      if (response.status) {
        EditForm.setFieldValue(path, EditForm.values[path] ? EditForm.values[path] + ',' + response.Data : response.Data)
        EditForm.setFieldValue(path + '_true', true);
      } else {
        console.log(response);
      }
    }
    if (e.target.value || e.target.type == 'file') {
      EditForm.setFieldValue(path + '_true', false);
      if (e.target.type == 'file') {
        EditForm.validateForm().then((res) => {
          if (res[path] == undefined) {
            EditForm.setFieldValue(path + '_true', true);
          }
        })
      }
    } else {
      if (e.target.type == 'file') {
      } else {
        EditForm.setFieldValue(path, e.target.value);
      }
      EditForm.setFieldValue(path + '_true', false);

    }
  }
  let logoLength = EditForm?.values?.logo?.split(",").length
  if (logoLength > 1) {
    EditForm.values.logo = EditForm.values.logo.slice(EditForm.values.logo.lastIndexOf(",") + 1)
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {loader == true ?
        <div style={{ marginLeft: '400px', marginTop: '200px' }}  >
          <Loader />
        </div> :
        <div className="main-container container-fluid px-0">
          <div className="page-header">
            <div className="page-leftheader">

              <h4 className="page-title mb-0 text-primary">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/employer/profile" >Employer Profile</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                  </ol>
                </nav>
              </h4>
            </div>
          </div>
          <form className="row" id='registerForm' onSubmit={EditForm.handleSubmit}>
            <div className="row">

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Employer Name</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter employer name" {...EditForm.getFieldProps("company_name")} />
                    </div>
                    {EditForm.touched.company_name && EditForm.errors.company_name ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_name}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Name Of Contact Person</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" name='full_name' placeholder="Enter name" {...EditForm.getFieldProps("full_name")} />
                    </div>
                    {EditForm.touched.full_name && EditForm.errors.full_name ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Country</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
                        <option value="">Select Country</option>
                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                      </select>
                    </div>
                    {EditForm.touched.country && EditForm.errors.country ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Mobile Number</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter Mobile number" {...EditForm.getFieldProps("mobile_number")} />
                    </div>
                    {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                  </div>
                </div>
              </div> */}

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Address</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter address" {...EditForm.getFieldProps("address")} />
                    </div>
                    {EditForm.touched.address && EditForm.errors.address ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.address}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Pay Cycle</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <select className="form-control mb-0" name="pay_cycle" /* value={paycycle} onKeyUp={() => setValidate(true)} */
                        /*  onChange={(e) => setpaycycle(e.target.value)} */ /* onSelect={() => setValidate(true)} */
                        {...EditForm.getFieldProps("pay_cycle")} >
                        {/* <option>Select Pay Cycle</option> */}
                        <option value='Weekly'>Weekly</option>
                        <option value='Fortnightly'>Fortnightly</option>
                        <option value='Monthly'>Monthly</option>
                      </select>
                    </div>
                    {EditForm.touched.country && EditForm.errors.country ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter email" {...EditForm.getFieldProps("email")} />
                    </div>
                    {EditForm.touched.email && EditForm.errors.email ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Website</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter website" {...EditForm.getFieldProps("website")} />
                    </div>
                    {EditForm.touched.website && EditForm.errors.website ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Platform Fee</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Enter Platform Fee" {...EditForm.getFieldProps("platform_fee")} />
                  </div>
                  {EditForm.touched.platform_fee && EditForm.errors.platform_fee ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.platform_fee}</div> : ''}
                </div>
              </div>
            </div> */}
              <div className="col-xl-9 col-lg-6 col-md-6 col-xm-12">
                <label className="form-label">Logo</label>
                <div className="upload__box">
                  <div className="upload__btn-box">
                    <label className="upload__btn">
                      <i
                        className="fa-solid fa-arrow-up-from-bracket fa-fw"></i>
                      <p>Upload images <span className="addmore-plus"><i
                        className="fa-solid fa-plus fa-fw"></i></span>
                        <small
                          style={{ display: "block", color: "#a5a5a5" }}>(JPG,
                          PNG only)</small>
                      </p>
                      <input type="file" accept=".jpg, .png"  /* multiple */
                        name="logo" /* onChange={(e) => imageUpload(e, 'logo', 'employer-logo-')} */
                        // onBlur={(e) => updateAndSaveEmployerRecord(e, 'logo')}
                        onChange={(e) => updateAndSaveEmployerRecord(e, 'logo')}
                        data-max_length="1"
                        className="upload__inputfile" />
                      {/* <input type="file" accept=".jpg, .png" multiple
																name="cheque_image"

																data-max_length="20"
																className="upload__inputfile" /> */}

                    </label>
                  </div>
                </div>
                {EditForm.values.logo && EditForm.values.logo.split(',') && EditForm.values.logo.split(',').length > 0 ?

                  <>
                    <ul className="imgpreview-newbx">
                      {uploadBr['logo'] > 0 ?
                        <div className="progress-imgupload">
                          <div className="progress-value" style={{ width: uploadBr['logo'] + '%' }}><span>{uploadBr['logo']}%</span></div>
                        </div>
                        : ''}
                      {EditForm.values.logo && EditForm.values.logo.split(',').map((option, index) => {
                        return (
                          <li key={index} >

                            {checkURL(S3_BUCKET_URL + 'employer-logo-' + option) ?
                              <>
                                <img src={option} alt="" onClick={() => openImageViewer(index, EditForm.values.logo, 'employer-logo-')} />
                                <Link to="" onClick={(e) => { removeImage(e, index, 'logo') }} className="upload__img-close" ><i className="fa-solid fa-close fa-fw"></i></Link>
                                <p>{option}</p>
                              </>
                              :
                              <>
                                <Link to="" onClick={(e) => { removeImage(e, index, 'logo') }} className="upload__img-close" ><i className="fa-solid fa-close fa-fw"></i></Link>
                                <p>{option}</p>
                              </>
                            }
                          </li>
                        )
                      })}
                    </ul>
                  </>
                  : ''}

              </div>
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1">Submit</button>
              </div>
            </div>
          </form>
        </div>}
      {isViewerOpen && images && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  )
}
export default EditProfile;