import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Breadcrumbsecond } from '../../../components';
import Loader from '../../../components/Loader/loader';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import { getApi } from '../../service/employService';
import PaginationComponent from '../../../PaginationComponent';
import CopyToClipboard from 'react-copy-to-clipboard';
import Limitfilter from '../../../components/Limitfilter/Limitfilter';
import Search from '../../../components/Search/Search';

const EwaTransactionByID = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [Entrieslimit, setEntrieslimit] = useState(10)
    const [ValidPagination, setValidPagination] = useState(false)
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    // const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();
    // const admin = JSON.parse(localStorage.getItem("admin"));
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX

    const SettlementBYidDetail = async (token) => {
        try {
            const response = await getApi(`/ewa-employee-all-txn-detail/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit });

            console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
            if (response.data) {

                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)


            } else {
                console.log("get merchant data response", response);
            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }

    }
    useEffect(() => {
        SettlementBYidDetail()
    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    const copiedInfo = () => {
        toast.success("Copied")
    }


    // const [location, setLocation] = useState(null);

    // useEffect(() => {
    //     const successHandler = (position) => {
    //         const { latitude, longitude } = position.coords;
    //         setLocation({ latitude, longitude });
    //     };

    //     const errorHandler = (error) => {
    //         console.error('Error getting location:', error.message);
    //     };

    //     // Check if the Geolocation API is available in the browser
    //     if ('geolocation' in navigator) {
    //         // Get the current location
    //         navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    //     } else {
    //         console.error('Geolocation is not supported by your browser');
    //     }
    // }, []);

    // console.log(location, "__________________________-")

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbsecond order={{ first: { name: "EWA Repayments", path: `${prefix}/paytothirdroc` }, second: { name: "Transaction Details" } }} />
                </div>
                <div style={{ marginLeft: '450px', marginTop: '25px' }}>
                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-ewa-settlement/${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div> */}

                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN ID. {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }}></i>} </th>
                                            <th className="font-weight-bold">Paid Amount {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }}></i>}</th>
                                            <th className="font-weight-bold">ROI  {sortedBy == "thirdroc_roi" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_roi') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_roi') }}></i>}</th>
                                            {/* <th className="font-weight-bold">Merchant Commission  {sortedBy == "mrch_commission" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }}></i>} </th> */}
                                            {/* <th className="font-weight-bold">Merchant Settled Amount  {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_settled_amount') }}></i>} </th> */}
                                            {/* <th className="font-weight-bold">Thirdroc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }}></i>} </th> */}
                                            <th className="font-weight-bold">Paid Date   {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                            <th className="font-weight-bold">Status </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { txn_id, thirdroc_income, paid_amount, merchant_settled_amount, mrch_commission, thirdroc_roi, created_at, updated_at, status } = data;
                                                    return (
                                                        <tr key={serial_num}>
                                                            <td>{txn_id ? txn_id : "NA"}
                                                                <span><CopyToClipboard text={txn_id} ><button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button></CopyToClipboard></span></td>
                                                            <td>{paid_amount ? "$" + paid_amount : "NA"}</td>
                                                            <td>{thirdroc_roi ? thirdroc_roi + "%" : "NA"}</td>
                                                            {/* <td>{mrch_commission ? "$" + mrch_commission : "NA"}</td> */}
                                                            {/* <td>{merchant_settled_amount ? "$" + merchant_settled_amount : "NA"}</td> */}
                                                            {/* <td>{thirdroc_income ? "$" + thirdroc_income : "NA"}</td> */}
                                                            <td>{TimeZone(created_at).timeZone}</td>
                                                            <td><span className="font-weight-normal1"></span>{status}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={Entrieslimit}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default EwaTransactionByID