import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Breadcrumbs, Breadcrumbsecond } from '../../../components';
import { getApi, postApi } from '../../service/lenderService';
import { Image } from 'antd';
import Moment from 'react-moment';
import Applicantemi from '../../Loan/CasesDetails/Applicantemi';
import Applicationlogs from '../../Loan/CasesDetails/Applicationlogs';
import Riskanalysis from '../../Loan/CasesDetails/Riskanalysis';

const EmployeeDetail = () => {
    const [bank, setBank] = useState([])
    const [employ, setEmploy] = useState([])
    const [income, setIncome] = useState([])
    const [loan, setLoan] = useState([])
    const [pay, setPay] = useState([])
    const [photo, setPhoto] = useState([])
    const [signature, setSignature] = useState([])
    const [tin, setTin] = useState([])
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const { id, type } = useParams();
    const location = useLocation();
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const lender = JSON.parse(localStorage.getItem("lender"));
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalAmount, settotalAmount] = useState();
    const [totalInterest, settotalInterest] = useState();
    const [Emivalidate, setEmivalidate] = useState(false)

    useEffect(() => {
        GetEmployeeData(id);
    }, [id]);


    const GetEmployeeData = async (id) => {
        try {
            if (location.pathname == `/lender/employee_detail/${id}/New` || location.pathname == `/lender/employee_detail/${id}/Existing`) {

                // const response = await GetEmployee(token, id);
                const response = await postApi(`/get-employee-num`, { mobile_number: id });
                if (response.status == true) {
                    setEmployeeDetail(response.data);
                    setBank(response.document.BANK_STATEMENTS)
                    setEmploy(response.document.EMPLOYMENT_LETTER)
                    setIncome(response.document.INCOME)
                    setLoan(response.document.LOAN_STATEMENTS)
                    setPay(response.document.PAYSLIPS)
                    setPhoto(response.document.PHOTO_ID)
                    setSignature(response.document.SIGNATURE)
                    setTin(response.document.TIN_LETTER)
                } else {
                    console.log("get employees data response", response);
                }
            } else {
                const response = await getApi(`/user-loan-application/${id}`)
                if (response.status === true) {
                    setEmployeeDetail(response.data.userData)
                    setBank(response?.data?.docsData.BANK_STATEMENTS)
                    setEmploy(response?.data?.docsData.EMPLOYMENT_LETTER)
                    setIncome(response?.data?.docsData.INCOME)
                    setLoan(response?.data?.docsData.LOAN_STATEMENTS)
                    setPay(response?.data?.docsData.PAYSLIPS)
                    setPhoto(response?.data?.docsData.PHOTO_ID)
                    setSignature(response?.data?.docsData.SIGNATURE)
                    setTin(response?.data?.docsData.TIN_LETTER)
                } else {
                    console.log(response)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    function calculateAge(dob) {
        if (!dob) {
            return '';
        }

        const today = new Date();
        const birthDate = new Date(dob);

        let age = today.getFullYear() - birthDate.getFullYear();

        const currentMonth = today.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDate = today.getDate();
        const birthDay = birthDate.getDate();

        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate < birthDay)) {
            age--;
        }
        return age.toString();
    }

    const dob = employeeDetail ? employeeDetail?.age : '--';
    const age = calculateAge(dob);

    const GetEmployeeEMIs = async (id) => {
        try {
            if (location.pathname == `/lender/employee_detail/${id}/New` || location.pathname == `/lender/employee_detail/${id}/Existing`) {
                setEmivalidate(true)
                const response = await getApi(`/get-emi-byId/${id}`);
                if (response.status == true) {
                    setDataArray(response.data);
                    setTotalPagesCount(response.totleEmi)
                    settotalAmount(response.totalAmount)
                    settotalInterest(response.totalInterest)
                } else {
                    console.log("get employees data response", response);
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        GetEmployeeEMIs(id);
    }, []);

    var num3 = parseFloat(totalInterest).toFixed(2)
    const TotalOutgoing = (employeeDetail.rent + employeeDetail.electricity + employeeDetail.bill_for_phone + employeeDetail.bill_for_water
        + employeeDetail.house_keeping + employeeDetail.school_fees + employeeDetail.travelling + employeeDetail.entertainment)

    const Surplus = (employeeDetail.monthly_income - TotalOutgoing)

    const newMFLRepayment = (totalAmount)

    const SurplusAvailable = (Surplus - newMFLRepayment)

    function currencyFormat(num) {
        // return  num.toFixed(2).replace(/-/g, '-$')       
        if (/-/g.test(num)) {
            return num?.toFixed(2).replace(/-/g, '-$')
        } else {
            return "$" + num?.toFixed(2)
        }
    }

    const netWeeklysurplus = parseFloat((SurplusAvailable * 12) / 52).toFixed(2);
    var netWeeklysurpluss
    if (/-/g.test(netWeeklysurplus)) {
        netWeeklysurpluss = netWeeklysurplus?.replace(/-/g, '-$');

    } else {
        netWeeklysurpluss = "$" + netWeeklysurplus?.trim()
    }


    const DebtIncomeRatio = parseFloat(newMFLRepayment / Surplus).toFixed(2)
    var new_str = DebtIncomeRatio.replace(/-/g, '-$');


    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-3">
                    <div className="page-leftheader tabsbtm">
                        {/* <div className="modal-header w-100" > */}
                        {/* <h3 className="page-title mb-0 text-primary">Borrower <i className='fas fa-arrow-right'/> Details</h3> */}
                        {Emivalidate ? <Breadcrumbs order={{ first: { name: "Borrower" }, second: { name: type == "Existing" ? "Existing Borrower" : "New Borrower", path: type == "Existing" ? `${prefix}/employee` : `${prefix}/newborrower` }, third: { name: "Details" } }} />
                            :
                            <Breadcrumbsecond order={{ first: { name: "Manage Loan", path: `${prefix}/manage_loan` }, second: { name: "Details" } }} />}
                    </div>
                    {/* <Link to={prefix + '/employee'}  >
                        <i className='fas fa-arrow-left' /> Back</Link>  */}
                    {/* </div> */}
                    <div style={{ width: "100%" }}>

                        <div className="col-md-12">
                            <Tabs
                                defaultActiveKey="personal"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="personal" title="Personal"  >
                                    {/* <StepOne /> */}
                                    <div className="main-container container-fluid px-0">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <h6>Borrower Information</h6>
                                                                <div className='btn_group' style={{}}>
                                                                </div>
                                                                <div className="main-contact-info-body">
                                                                    <div className="media-list p-0">

                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                                                    <div className="w-70"> <label>First Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.first_name : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                                                    <div className="w-70"> <label>Middle Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.middle_name}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-tie"></i> </div>
                                                                                    <div className="w-70"> <label>Last Name:</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.last_name : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                    <div className="w-70"> <label>Personal Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail ? employeeDetail?.personal_email : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-adn"></i> </div>
                                                                                    <div className="w-70"> <label>Age :</label> <span className="font-weight-normal1 fs-14">{age ? age + " Years " : "--"}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                    <div className="w-70"> <label>Work Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail ? employeeDetail?.work_email : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                                    <div className="w-70"> <label>Postal Address :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.postal_address
                                                                                        : ''} <br /> {employeeDetail ? employeeDetail?.postal_period + "s" : ''}
                                                                                    </span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-address-card"></i></div>
                                                                                    <div className="w-70">
                                                                                        <label>Previous Address :</label>
                                                                                        <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.previus_address
                                                                                            : ''}<br /> {employeeDetail ? employeeDetail?.previous_period + "s" : ''}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                                                    <div className="w-70">
                                                                                        <label>Residential Address :</label>
                                                                                        <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.residental_address : ''}
                                                                                            <br /> {employeeDetail ? employeeDetail?.period + "s" : ''}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                                                    <div className="w-70"> <label>Telephone(H) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.telephone_home : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone-alt"></i> </div>
                                                                                    <div className="w-70"> <label>Telephone(W) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.telephone_work : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Marrital Status :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.martial_status : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-file"></i> </div>
                                                                                    <div className="w-70"> <label>Tin :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.tin
                                                                                        : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>FNPF: </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.fnpf : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-shield"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Employer :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.previus_employer : ''} <br />
                                                                                        {employeeDetail ? employeeDetail?.previous_employer_period + "s" : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">

                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-address-book"></i> </div>
                                                                                    <div className="w-70"> <label>Previous Office Address :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.previus_office_address : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-piggy-bank"></i> </div>
                                                                                    <div className="w-70"> <label>Bank Name :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.bank_name : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Branch :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.branch : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>Account Name :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.account_name : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-house-user"></i> </div>
                                                                                    <div className="w-70"> <label>Account No. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.account_number : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-birthday-cake"></i> </div>
                                                                                    <div className="w-70"> <label>DOB. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.date_of_birth ? employeeDetail?.date_of_birth : '--'}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                                                    <div className="w-70"> <label>No of Dependent :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.dependent_no : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-house-user"></i> </div>
                                                                                    <div className="w-70"> <label>Occupation. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.occupation : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-user-check"></i> </div>
                                                                                    <div className="w-70"> <label>Current Employer :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.current_employer : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dolly-flatbed"></i> </div>
                                                                                    <div className="w-70"> <label>Mortgage Balance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.mortgage_balance : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-house-user"></i> </div>
                                                                                    <div className="w-70"> <label>Mortgaged. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.mortgaged : ''}</span> </div>
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-warehouse"></i> </div>
                                                                                    <div className="w-70"> <label>Notliving_house :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.notliving_house : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1" date_of_birth> <i className="fas fa-home	"></i> </div>
                                                                                    <div className="w-70"> <label>Own House Estimated Value :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.house_est_value : ''}</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Income"  >
                                    {/* <Expenses /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h3>Income</h3>
                                        <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                            <div className="media-list p-0">
                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                                            <div className="w-70"> <label>Annual Salary : </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.annual_salary : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Other Income : </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.other_income : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Total Monthly Income:</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.monthly_income : ''}</span> </div>
                                                        </div>
                                                        {/* <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Default Credit Account :</label> <span className="font-weight-normal1 fs-14">
                                                            ${employeeDetail ? employeeDetail?.default_credit_account : ''}</span> </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="assest" title="Assets">
                                    {/* <Assest /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h3>Assets</h3>
                                        <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house"></i> </div>
                                                            <div className="w-70"> <label>House Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.house_value : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-car"></i> </div>
                                                            <div className="w-70"> <label>Car Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.car_value : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-landmark"></i> </div>
                                                            <div className="w-70"> <label>Land Value :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.land_value : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Life Policy :
                                                            </label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.life_policy : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-credit-card"></i> </div>
                                                            <div className="w-70"> <label>Debtors :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? employeeDetail?.debtors : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                            <div className="w-70">
                                                                <label>Personal Effects :</label>
                                                                <span className="font-weight-normal1 fs-14"> {employeeDetail ? "$" + employeeDetail?.personal_effects : ''} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                                            <div className="w-70"> <label>Available Cash :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.cash_on_hand : ''} </span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i></div>
                                                            <div className="w-70">
                                                                <label>Cash At Bank :</label>
                                                                <span className="font-weight-normal1 fs-14">{employeeDetail ? "$" + employeeDetail?.cash_at_bank : ''}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-toolbox"></i> </div>
                                                            <div className="w-70"> <label>Total Asset :</label> <span className="font-weight-normal1 fs-14"> ${employeeDetail ? employeeDetail?.total_asset : ''} </span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="liabilities" title="Liabilities">
                                    {/* <Liabilities /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h3>Liabilities</h3>
                                        <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                            <div className="media-list p-0">
                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                            <div className="w-70"> <label>Mortgage Loan Balance : </label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.mortgage_loan_balnce : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                                            <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.life_insurance : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Home Loan :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.home_loan : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                                            <div className="w-70"> <label>Other Loan Payments :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.otherloan_balance : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-location-arrow"></i> </div>
                                                            <div className="w-70"> <label>Total Liabilities :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.total_outgoings : ''}</span> </div>

                                                        </div>
                                                        <div className="d-flex">
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="monthly" title="Monthly Living Cost">
                                    {/* <Monthly /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h3>Living Cost</h3>

                                        <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home"></i> </div>
                                                            <div className="w-70"> <label>Rent : </label> <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.rent : ''}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-lightbulb"></i> </div>
                                                            <div className="w-70"> <label>Electricity : </label> <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.electricity : ''}</b></span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                            <div className="w-70"> <label>Telephone Cost :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.bill_for_phone : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-water"></i> </div>
                                                            <div className="w-70"> <label>Water :
                                                            </label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.bill_for_water : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                            <div className="w-70"> <label>House Keeping :</label> <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.house_keeping : ''}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-book-reader"></i> </div>
                                                            <div className="w-70">
                                                                <label>School Fees :</label>
                                                                <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.school_fees : ''}</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-gas-pump"></i> </div>
                                                            <div className="w-70"> <label>Travelling/Petrol/Bus :</label> <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.travelling : ''}</b></span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-tv"></i></div>
                                                            <div className="w-70">
                                                                <label>Entertainment/Video :</label>
                                                                <span className="font-weight-normal1 fs-14"><b>${employeeDetail ? employeeDetail?.entertainment : ''}</b>  </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="material-icons mt-1">&#xe8a1; </i></div>
                                                            <div className="w-70"> <label>Total Hire Purchase Payments :</label> <span className="font-weight-normal1 fs-14">${employeeDetail ? employeeDetail?.hire_payments : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="documents" title="Documents">
                                    {/* <Documents /> */}
                                    <div style={{ marginTop: "30px" }}>
                                        <h6>Documents</h6>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }}>
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Tin Number</h3>
                                                            </div>
                                                            {tin.map((Tin, index) => {
                                                                return (
                                                                    <>
                                                                        {(Tin?.file_type === '.pdf' || Tin?.file_type === '.docx') ? (
                                                                            <a href={Tin.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Tin ? Tin.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                /* data-bs-target="#applicaiton-report1" data-bs-toggle="modal"
                                                onClick={() => setpaymentImage(Tin.doc_file)} */ />
                                                                        )}
                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report1" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Tin</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Signature</h3>
                                                            </div>
                                                            {signature.map((Signature, index) => {
                                                                return (
                                                                    <>
                                                                        {Signature?.file_type === '.pdf' || Signature?.file_type === '.docx' ? (
                                                                            <a href={Signature.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Signature ? Signature.doc_file : ''}
                                                                                // onClick={() => setpaymentImage(Signature.doc_file)}
                                                                                //     data-bs-target="#applicaiton-report2" data-bs-toggle="modal"
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }}
                                                                                className="card-img-top m-1" alt="..." />
                                                                        )}
                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report2" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Signature</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Photo ID</h3>
                                                            </div>
                                                            {photo.map((Photo, index) => {
                                                                return (
                                                                    <>
                                                                        {(Photo?.file_type === '.pdf' || Photo?.file_type === '.docx') ? (
                                                                            <a href={Photo.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Photo ? Photo.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                /* data-bs-target="#applicaiton-report3" data-bs-toggle="modal"
                                                onClick={() => setpaymentImage(Photo.doc_file)} */ />
                                                                        )}
                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report3" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Photo ID</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card" style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Pay Slip</h3>
                                                            </div>
                                                            {pay.map((Pay, index) => {
                                                                return (
                                                                    <>
                                                                        {(Pay?.file_type === '.pdf' || Pay?.file_type === '.docx') ? (
                                                                            <a href={Pay.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Pay ? Pay.doc_file : ''}
                                                                                // onClick={() => setpaymentImage(Pay.doc_file)}
                                                                                //     data-bs-target="#applicaiton-report4" data-bs-toggle="modal"
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}
                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report4" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Pay Slip</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Income Statement</h3>
                                                            </div>
                                                            {income.map((Income, index) => {
                                                                return (
                                                                    <>
                                                                        {(Income?.file_type === ('.pdf' || Income?.file_type === '.docx')) ? (
                                                                            <a href={Income.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Income ? Income.doc_file : ''}
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                                            />)}
                                                                        {/*  data-bs-target="#applicaiton-report5" data-bs-toggle="modal"
                                                onClick={() => setpaymentImage(Income.doc_file)}   */}

                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report5" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Income Statement</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Loan Statement</h3>
                                                            </div>
                                                            {loan.map((Loan, index) => {
                                                                return (
                                                                    <>
                                                                        {(Loan?.file_type === ('.pdf' || Loan?.file_type === '.docx')) ? (
                                                                            <a href={Loan.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Loan ? Loan.doc_file : ''}
                                                                                // onClick={() => setpaymentImage(Loan.doc_file)}
                                                                                //     data-bs-target="#applicaiton-report6" data-bs-toggle="modal"
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}

                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report6" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Loan Statement</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                                    <div className="col">
                                                        <div className="card  " style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Bank Statement</h3>
                                                            </div>
                                                            {bank.map((Bank, index) => {
                                                                return (
                                                                    <>
                                                                        {(Bank?.file_type === '.pdf' || Bank?.file_type === '.docx') ? (
                                                                            <a href={Bank.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Bank ? Bank.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                /* data-bs-target="#applicaiton-report7" data-bs-toggle="modal"
                                                onClick={() => setpaymentImage(Bank.doc_file)} */ />
                                                                        )}
                                                                        {/* <div className="modal fade effect-fall show" id="applicaiton-report7" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-sm" role="document">
                                                <div className="modal-content modal-content-demo">
                                                    <div className="modal-header"> <h6 className="modal-title">Bank Statement</h6>
                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                    <div className="modal-body application-modal" >
                                                        <img src={paymentImage} alt="..."
                                                            style={{ height: "80%", width: "80%" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card" style={{ border: "1px solid silver" }} >
                                                            <div className="card-body" style={{ background: '#4239a4' }}>
                                                                <h3 className="card-title text-light ">Employment Letter</h3>
                                                            </div>
                                                            {employ.map((Employ, index) => {
                                                                return (
                                                                    <>
                                                                        {(Employ?.file_type === '.pdf' || Employ?.file_type === '.docx') ? (
                                                                            <a href={Employ.doc_file} download>
                                                                                <img
                                                                                    src="/assets/img/pdf.jpeg"
                                                                                    style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="PDF"
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <Image src={Employ ? Employ.doc_file : ''}
                                                                                // onClick={() => setpaymentImage(Employ.doc_file)}
                                                                                //     data-bs-target="#applicaiton-report8" data-bs-toggle="modal"
                                                                                style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                                        )}

                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="emiDetais" title="EMI Details">
                                    {/* <EmiDetails /> */}
                                    {Emivalidate ?
                                        <div style={{ marginTop: "30px" }}>
                                            <div className="page-header mb-3">
                                                <div className="page-leftheader">
                                                    <h5>EMI Details</h5>
                                                </div>
                                                <div className="page-rightheader">
                                                    <h5>{totalAmount !== "0" ? "Total Amount : $" + totalAmount : ""}
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <span>{totalInterest !== "0" ? "Total Interest :  $" + num3 : ""}</span></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body p-0">
                                                            <div className="table-responsive">
                                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Sr No.</th>
                                                                            <th className="font-weight-bold">EMI Date</th>
                                                                            <th className="font-weight-bold">Borrower</th>
                                                                            <th className="font-weight-bold">Amount</th>
                                                                            <th className="font-weight-bold">Late Charge</th>
                                                                            <th className="font-weight-bold">Interest of Days</th>
                                                                            <th className="font-weight-bold">Interest</th>
                                                                            <th className="font-weight-bold">Status</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                            const { first_name, last_name, amount, status, late_charges, mobile_number, interest_of_days, due_date } = option;
                                                                            // var Capitalize = (option) => {
                                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                            // }
                                                                            var num2 = parseFloat(option.interest).toFixed(2)
                                                                            return (
                                                                                <tr key={serial_num} >
                                                                                    <td><span className="font-weight-normal1">{index + 1}</span></td>
                                                                                    <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD ">{due_date}</Moment></span></td>
                                                                                    <td><span className="font-weight-normal1">
                                                                                        {first_name ? first_name : ""}  {last_name ? last_name : ""}<br />{mobile_number}
                                                                                    </span></td>
                                                                                    <td><span className="font-weight-normal1">{option.amount ? "$" + amount : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{available_limit}</span></td>   */}
                                                                                    <td><span className="font-weight-normal1">{option.late_charges ? "$" + late_charges : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{total_paid_amount}</span></td> */}
                                                                                    <td><span className="font-weight-normal1">{option.interest_of_days ? interest_of_days + " Days " : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option.interest ? "$" + num2 : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{status ? status : ''}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    <tbody>
                                                                        {dataArray.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No EMI's Data Available</td>
                                                                            </tr>}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}

                                            </div>
                                        </div> : <Applicantemi />}
                                </Tab>
                                {!Emivalidate && <Tab eventKey="Applicationlogs" title="Activity Logs">
                                    <Applicationlogs />
                                </Tab>}
                                <Tab eventKey="RiskAnalysis " title="Risk Analysis ">
                                    {/* <RiskAnalysis /> */}
                                    {Emivalidate ?
                                        <div style={{ marginTop: "30px" }}>
                                            <h5>Risk Analysis</h5>
                                            <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                                <div className="media-list p-0">
                                                    <div className="media py-4 mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                <div className="w-70"> <label>Total Outgoing : </label> <span className="font-weight-normal1 fs-14">{currencyFormat(TotalOutgoing)}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-hand-holding-usd"></i> </div>
                                                                <div className="w-70"> <label>Surplus :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(Surplus)}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-amazon-pay"></i> </div>
                                                                <div className="w-70"> <label>New MFL Repayment :</label> <span className="font-weight-normal1 fs-14">{"$" + newMFLRepayment}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="fas fa-sort-amount-up" ></i> </div>
                                                                <div className="w-70"> <label>Surplus Available :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(SurplusAvailable)}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-sort-amount-up"></i> </div>
                                                                <div className="w-70"> <label>Net Weekly Surplus :</label> <span className="font-weight-normal1 fs-14">{netWeeklysurpluss}</span> </div>

                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-chart-pie"></i> </div>
                                                                <div className="w-70"> <label>DTI (Debt to Income Ratio) :</label> <span className="font-weight-normal1 fs-14">
                                                                    {currencyFormat(Number(new_str))}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <Riskanalysis />}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EmployeeDetail