import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getApi } from '../service/lenderService';
import Loader from '../../components/Loader/loader';


const Dashboard = (props) => {
	const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
	const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
	const lender = JSON.parse(localStorage.getItem("lender"));
	const [totalBorrowers, setTotalBorrowers] = useState('');
	const [totalDues, setTotalDues] = useState('');
	const [totalEmisPaid, setTotalEmisPaid] = useState('');
	const [totalEmis, setTotalEmis] = useState('');
	const [totalTransactions, setTotalTransactions] = useState('');
	const [totaldisbursedamount, setTotaldisbursedamount] = useState("")
	const [merchantdue, setmerchantdue] = useState("")
	const [MerchantPaidAmount, setMerchantPaidAmount] = useState("")
	const [ThirdrocDueAmount, setThirdrocDueAmount] = useState("")
	const [totaldisbursedused, setTotaldisbursedused] = useState("")
	const [ThirdrocPaidAmount, setThirdrocPaidAmount] = useState("")
	const [existingBorrowers, setexistingBorrowers] = useState('')
	const [totalPaidEmiAmount, settotalPaidEmiAmount] = useState("")
	const [totalDueEmiAmount, settotalDueEmiAmount] = useState("")
	const [totalReceivedProfitAmount, settotalReceivedProfitAmount] = useState("")
	const [totalUpcommintProfitAmount, settotalUpcommintProfitAmount] = useState("")
	const [totaldis, setTotaldis] = useState('')
	const [totalpending, setTotalPending] = useState('')
	const [loader, setloader] = useState(true)
	const navigate = useNavigate()

	const getDashBoard = async () => {

		try {
			const response = await getApi(`/lender-dashboard`)

			if (response.status == true) {
				setTimeout(() => {
					settotalUpcommintProfitAmount(response.data.totalUpcommintProfitAmount)
					settotalReceivedProfitAmount(response.data.totalReceivedProfitAmount)
					settotalDueEmiAmount(response.data.totalDueEmiAmount)
					settotalPaidEmiAmount(response.data.totalPaidEmiAmount)
					setThirdrocPaidAmount(response.data.ThirdrocPaidAmount)
					setMerchantPaidAmount(response.data.MerchantPaidAmount)
					setThirdrocDueAmount(response.data.ThirdrocDueAmount)
					setmerchantdue(response.data.MerchantDueAmount)
					setTotalBorrowers(response.data.newBorrowers)
					setTotalDues(response.data.totalDueEmiCount)
					setTotalEmisPaid(response.data.totalPaidEmiCount)
					setexistingBorrowers(response.data.existingBorrowers)
					setTotalEmis(response.EmiListsCount)
					setTotalTransactions(response.lenderTransctions)
					setTotaldis(response.data.totalDisbursedAmount)
					setTotalPending(response.EmiListsCount)
					setTotaldisbursedamount(response.data.totalDisbursedRemainingAmount)
					setTotaldisbursedused(response.data.totalDisbursedUsedAmount)
					// setTotaldues(response.TotalDues )
					setloader(false)
				}, 2000);



			} else if (response.message == 'Session expired') {
				localStorage.removeItem("lender");
				navigate('/lender/login')


			} else {
				console.log("get employees data response", response);
				console.log(response.error)

			}

		} catch (error) {
			console.log("🚀 ~ getDashBoard Catch block: ", error)
		}
	}
	useEffect(() => {
		getDashBoard(token)
	}, [])


	return (
		<>
			{loader == true ? <div style={{ marginLeft: '400px', marginTop: '200px' }}  >   <Loader /></div> :
				<div className="main-container container-fluid px-0">
					<div className="page-header">
						<div className="page-leftheader">
							<h4 className="page-title mb-0 text-primary">Dashboard</h4>
						</div>
					</div>
					<div className="row">
					</div>


					<div className="row lenderDashboardCards">
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">New Borrower</p>
									<h2 className="mb-1 font-weight-bold">{totalBorrowers ? totalBorrowers : 0}</h2>
									{(totalBorrowers !== 0 && totalBorrowers !== null) && (<span className="mb-1"><Link to={'/lender/newborrower'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Existing Borrowers</p>
									<h2 className="mb-1 font-weight-bold">{existingBorrowers ? existingBorrowers : 0}</h2>
									{(existingBorrowers !== 0 && existingBorrowers !== null) && (<span className="mb-1"><Link to={'/lender/employee'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						{/* <div className="col-xl-3 col-lg-6 col-md-12" >
						<div className="card">
							<div className="card-body">
								<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
								<p className="fs-13 mb-1">Total Paid EMIs</p>
								<h2 className="mb-1 font-weight-bold">{totalEmisPaid? totalEmisPaid :0}</h2>
								<span className="mb-1"><Link to={'/lender/emitransaction'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
							</div>
						</div>
					</div> */}

						{/* 				
					<div className="col-xl-3 col-lg-6 col-md-12">
						<div className="card">
							<div className="card-body">
								<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
								<p className="fs-13 mb-1">Total Due</p>
								<h2 className="mb-1 font-weight-bold">{totalDues?totalDues:0}</h2>
								<span className="mb-1"><Link className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
							</div>
						</div>
					</div> */}
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Earned ROI</p>
									<h2 className="mb-1 font-weight-bold" style={{ color: "#00c18d" }}> {totalReceivedProfitAmount ? "$" + totalReceivedProfitAmount : 0}   </h2>
									{/* <span className="mb-1"><Link to={'/lender/emi'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span> */}
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1"> Expected ROI</p>
									<h2 className="mb-1 font-weight-bold" style={{ color: "#00c18d" }}> {totalUpcommintProfitAmount ? "$" + totalUpcommintProfitAmount : 0}   </h2>
									{/* <span className="mb-1"><Link to={'/lender/emi'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span> */}
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1"> Due Repayment Amount</p>
									<h2 className="mb-1 font-weight-bold"> {totalDueEmiAmount ? "$" + totalDueEmiAmount : 0}   </h2>
									{(totalDueEmiAmount !== 0 && totalDueEmiAmount !== null) && (
										<span className="mb-1">
											<Link to={'/lender/due_emi'} className="text-azure">
												<i className="fa fa-eye me-1"></i> View Detail
											</Link>
										</span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Paid Repayment Amount</p>
									<h2 className="mb-1 font-weight-bold"> {totalPaidEmiAmount ? "$" + totalPaidEmiAmount : 0}   </h2>
									{(totalPaidEmiAmount !== 0 && totalPaidEmiAmount !== null) && (<span className="mb-1"><Link to={'/lender/emitransaction'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Credit Limit Extended</p>
									<h2 className="mb-1 font-weight-bold">{totaldis ? "$" + totaldis : 0}</h2>
									<span className="mb-1">
										{/* <Link to={'/lender/transactions'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link> */}
									</span>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1"> Total Unused Credit Limit</p>
									<h2 className="mb-1 font-weight-bold">{totaldisbursedamount ? "$" + totaldisbursedamount : 0}</h2>
									{/* <span className="mb-1"><Link  className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span> */}
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									{/* height: 8.5rem; */}
									<svg className="lender-dashboard card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Used Credit Limit</p>
									<h2 className="mb-1 font-weight-bold">{totaldisbursedused ? "$" + totaldisbursedused : 0}</h2>
									{/* <span className="mb-1"><Link  className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span> */}
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="lender-dashboard card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Amount Due to Merchants</p>
									<h2 className="mb-1 font-weight-bold">{merchantdue ? "$" + merchantdue : 0}</h2>
									{(merchantdue !== 0 && merchantdue !== null) && (<span className="mb-1"><Link to={'/lender/pendingsettlement'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className=" lender-dashboard card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Amount Paid to Merchants</p>
									<h2 className="mb-1 font-weight-bold">{MerchantPaidAmount ? "$" + MerchantPaidAmount : 0}</h2>
									{(MerchantPaidAmount !== 0 && MerchantPaidAmount !== null) && (<span className="mb-1"><Link to={'/lender/merchanttransaction'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="lender-dashboard card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Amount Due to Thirdroc</p>
									<h2 className="mb-1 font-weight-bold">{ThirdrocDueAmount ? "$" + ThirdrocDueAmount : 0}</h2>
									{(ThirdrocDueAmount !== 0 && ThirdrocDueAmount !== null) && (<span className="mb-1"><Link to={'/lender/thirdrocsettlement'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div style={{ height: '8.5rem' }} className="card">
								<div className="card-body">
									<svg className="lender-dashboard card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Amount Paid to Thirdroc</p>
									<h2 className="mb-1 font-weight-bold">{ThirdrocPaidAmount ? "$" + ThirdrocPaidAmount : 0}</h2>
									{(ThirdrocPaidAmount !== 0 && ThirdrocPaidAmount !== null) && (<span className="mb-1"><Link to={'/lender/thirdroctransaction'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default Dashboard;