import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../components'
import Loader from '../../components/Loader/loader'
import PaginationComponent from '../../PaginationComponent'
import { useNavigate, useParams } from 'react-router-dom'
import { postApi } from '../service/adminService'
import { TimeZone } from '../../components/TimeZone/TimeZone'
import { toast } from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Image } from 'antd'

const Viewreconciliation = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const navigate = useNavigate();
    const admin = JSON.parse(localStorage.getItem("admin"));


    const getReconciliation = async (token) => {
        // const response = await GetReconciliation(token, search, pagesCount, sortedBy, orderBy);
        const response = await postApi(`/get-reconciliation`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.data) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)


        } else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        getReconciliation(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    {/* <Breadcrumbs order={{ first: { name: "Reconciliation" }}} /> */}
                    <Breadcrumbs order={{ first: { name: "Manage EWA" }, second: { name: "Reconciliation" } }} />
                </div>


                {/* <div className="page-rightheader">
                    <div className="btn-list">                      
                        <a href={exportfix + `/export-ewa-settlement/${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div> */}

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN Docs  </th>
                                            <th className="font-weight-bold">TXN No.  {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>}</th>
                                            <th className="font-weight-bold">Merchant Detail {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}></i>}</th>
                                            {/* <th className="font-weight-bold"> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th> */}
                                            {/* <th className="font-weight-bold">ROI {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th> */}
                                            <th className="font-weight-bold">Paid Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                            <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>}</th>
                                            <th className="font-weight-bold">Comment {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                            <th className="font-weight-bold">TXN Date {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                            {/* <th className="font-weight-bold">Action</th> */}
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { logo, txn_docs, merchant_name, merchant_mobile_number, txn_number, payment_mode, comment, updated_at, amount } = option;

                                                    return (
                                                        <tr key={serial_num}>
                                                            {option.txn_docs != null ?
                                                                <td><span className="font-weight-normal1">
                                                                    <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.txn_docs} alt='' /></span></td>
                                                                : <td><span className="font-weight-normal1" >
                                                                    <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></td>}
                                                            <td><span className="font-weight-normal1 ">{txn_number ? txn_number : "NA"}</span>
                                                                <span>
                                                                    <CopyToClipboard text={txn_number} >
                                                                        <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span></td>
                                                            <td><span className="font-weight-normal1">{merchant_name ? merchant_name : "NA"}<br />
                                                                {merchant_mobile_number ? merchant_mobile_number : ""}</span></td>
                                                            {/* <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{amount ? + amount + "%" : "NA"}</span></td> */}
                                                            <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                            <td><span className="font-weight-normal1">{payment_mode ? payment_mode : "NA"}</span></td>
                                                            <td><span className="font-weight-normal1"
                                                                style={{
                                                                    maxWidth: "200px",
                                                                    display: "inline-block",
                                                                    whiteSpace: "break-spaces"
                                                                }}>{comment ? comment : "NA"}</span></td>
                                                            <td><span className="font-weight-normal1">{option.updated_at ? TimeZone(option.created_at).timeZone : "NA"}</span></td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default Viewreconciliation
