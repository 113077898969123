import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Header from './layout/header'
import Footer from './layout/footer'
import Profile from './user/profile/profile';
import EditProfile from './user/profile/editProfile';
import ChangePassword from './user/profile/changePassword';
import AddEmployer from './user/addEditEmployee/addEmployee';
import Employee from './user/ManageEmployee/Manage/epmloyee';
import UploadEmployess from './user/addEditEmployee/uploadEmployees';
import { UseAppContext } from '../Context/AppContext';
// import Repayments from './user/repayments';
import AddEmployee from './user/addEditEmployee/addEmployee';
import Settlement from './user/EmployeeSettlement/settlement';
import EmployeeEmi from './user/EmployeeSettlement/employeeEmi';
import PaytoThirdroc from './user/EWA/PaytoThirdroc';
import Paidtrasaction from './user/EWA/paidtransaction';
import Paidtransaction from './user/EWA/paidtransaction';
import Ewapaidhistory from './user/EWA/EWApaidhistory';
import EmployerAttendance from './user/emplyerAttendance/employeesAttendance';
import AttendancebyID from './user/emplyerAttendance/AttendancebyID';
import AddNFC from './user/NFC/AddNFC';
import AddNFCEmployees from './user/NFC/AddNFCEmployees';
import Attendancelogs from './user/emplyerAttendance/Attendancelogs';
import Manageuser from './user/UserRole/manageuser';
import Managerole from './user/UserRole/managerole';
import Addruser from './user/UserRole/Addruser';
import Addrole from './user/UserRole/Addrole';
import Editrole from './user/UserRole/Editrole';
import Edituser from './user/UserRole/Edituser';
import ViewNFC from './user/NFC/viewNFC';
// import ViewDepartment from './user/NFC/viewNFCsites';
import ViewNFCsites from './user/ManageEmployee/Manage/viewNFCsites';
import ManageParentrole from './user/Parentrole/manageParentrole';
import AddParentrole from './user/Parentrole/Addparentrole';
import Editparentrole from './user/Parentrole/Editparentrole';
// import Signature from '../website/Signature';
import BnplTransaction from './user/Repayment/BnplTransaction';
import BnplTransactionByID from './user/Repayment/BnplTransactionByID';
import EwaTransactionByID from './user/Repayment/EwaTransactionByID';
import Signature from '../website/Signature';
import RosterEfficiencyReport from './user/ManageEmployee/Reports/RosterEfficiencyReport';
import Schdular from './user/ManageEmployee/Schdular';
import Jobtypelocation from './user/ManageEmployee/Manage/Jobtypelocation';
import Dashboard from './user/Dashboard/dashboard';
import PaySlip from './user/ManageEmployee/Manage/PaySlip';
import BulkGeneratePayslip from './user/ManageEmployee/Manage/bulkGeneratePayslip';
import GetpaySlipbyID from './user/ManageEmployee/Manage/GetpaySlipbyID';
import Schedule from './user/ManageEmployee/Schedule';
import AssignJobType from './user/ManageEmployee/Manage/AssignJobType';
import LeavesRequest from './user/ManageEmployee/Approval/LeavesRequest';
import Timesheet from './user/ManageEmployee/Approval/Timesheet';
// import MyComponent from './TrashFolder/MyComponent';
import Attendance from './user/ManageEmployee/Reports/Attendance';
import Viewemployeedetail from './user/ManageEmployee/Manage/viewemployeedetail';
import Employeeshift from './user/ManageEmployee/Manage/Employeeshift';
import ManageLeave from './user/ManageEmployee/Manage/ManageLeave';
import Compliance from './user/ManageEmployee/Manage/Compliance';
// import Payroll from './user/ManageEmployee/Payroll/Payroll';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployerBusinessType } from '../Redux/modalerrorReducer';

const Index = (props) => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const { id } = useParams();
    const { header } = UseAppContext();
    const { customer_id } = useParams()
    const [locationName, setlocationName] = useState(prefix + '/dashboard');
    const location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        setlocationName(location.pathname)
    }, [location.pathname])

    const employer = JSON.parse(localStorage.getItem("employer"));

    const employerBusinessType = useSelector(state => state.ModalError.EmployerBusinessType);

    console.log("🚀 ~ Index ~ employerBusinessType:", employerBusinessType)


    useEffect(() => {
        if (employerBusinessType?.length == 0) {
            dispatch(setEmployerBusinessType(employer.data?.business_type));
        }
    }, [])

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-main">
                    <Header prefix={prefix} />
                    <div className={header ? "app_content_res app-content main-content" : "app-content main-content"}>
                        <div className="side-app">
                            {
                                locationName == prefix + "/dashboard" ? <Dashboard />
                                    : locationName == prefix + "/profile" ? <Profile />
                                        : locationName == prefix + "/profile" ? <Profile />
                                            : locationName == prefix + `/editProfile` ? <EditProfile />
                                                : locationName == prefix + `/changePassword/${id}` ? <ChangePassword />
                                                    : locationName == prefix + "/employees" ? <Employee />
                                                        : locationName == prefix + "/employees_attendance" ? <EmployerAttendance />
                                                            : locationName == prefix + "/add_employee" || locationName == prefix + `/edit_employee/${id}` ? <AddEmployee />
                                                                : locationName == prefix + `/upload_employees` ? <UploadEmployess />
                                                                    : locationName == prefix + `/viewemployee_detail/${id}` ? <Viewemployeedetail />
                                                                        : locationName == prefix + `/bnpl_repayment` ? <BnplTransaction />
                                                                            : locationName == prefix + `/settlement` ? <Settlement />
                                                                                : locationName == prefix + `/employeeEmi/${id}` ? <EmployeeEmi />
                                                                                    : locationName == prefix + `/paytothirdroc` ? <PaytoThirdroc />
                                                                                        : locationName == prefix + `/paidtransaction` ? <Paidtransaction />
                                                                                            : locationName == prefix + `/ewa_paidhistory/${id}` ? <Ewapaidhistory />
                                                                                                : locationName == prefix + `/employees_attendance/${id}` ? <AttendancebyID />
                                                                                                    : locationName == prefix + `/add_nfc` ? <AddNFC />
                                                                                                        : locationName == prefix + `/add_nfc_employees` ? <AddNFCEmployees />
                                                                                                            : locationName == prefix + `/attendancelogs/${id}/${customer_id}` ? <Attendancelogs />
                                                                                                                : locationName == prefix + `/addruser` ? <Addruser />
                                                                                                                    : locationName == prefix + `/manageuser` ? <Manageuser />
                                                                                                                        : locationName == prefix + `/managerole` ? <Managerole />
                                                                                                                            : locationName == prefix + `/addrole` ? <Addrole />
                                                                                                                                : locationName == prefix + `/editrole/${id}` ? <Editrole />
                                                                                                                                    : locationName == prefix + `/edituser/${id}` ? <Edituser />
                                                                                                                                        : locationName == prefix + `/view_nfc` ? <ViewNFC />
                                                                                                                                            : locationName == prefix + `/department` ? <ViewNFCsites />
                                                                                                                                                : locationName == prefix + `/manage_role` ? <ManageParentrole />
                                                                                                                                                    : locationName == prefix + `/add_role` ? <AddParentrole />
                                                                                                                                                        : locationName == prefix + `/edit_role/${id}` ? <Editparentrole />
                                                                                                                                                            : locationName == prefix + `/signature/${id}` ? <Signature />
                                                                                                                                                                : locationName == prefix + `/bnpl_transaction_by_id/${id}` ? <BnplTransactionByID />
                                                                                                                                                                    : locationName == prefix + `/ewa_transaction_by_id/${id}` ? <EwaTransactionByID />
                                                                                                                                                                        : locationName == prefix + `/employee_shift` ? <Employeeshift />
                                                                                                                                                                            : locationName == prefix + `/scheduler` ? employerBusinessType == "Manufacturing" ? <Schedule /> : <Schdular />
                                                                                                                                                                                : locationName == prefix + `/roster_efficiency_report` ? <RosterEfficiencyReport />
                                                                                                                                                                                    : locationName == prefix + `/jobtype&location` ? <Jobtypelocation />
                                                                                                                                                                                        : locationName == prefix + `/manage_Leave` ? <ManageLeave />
                                                                                                                                                                                            : locationName == prefix + `/pay_slip/${id}` ? <PaySlip />
                                                                                                                                                                                                : locationName == prefix + `/bulk_generate_payslip` ? <BulkGeneratePayslip />
                                                                                                                                                                                                    : locationName == prefix + `/payslip_by_id/${id}` ? <GetpaySlipbyID />
                                                                                                                                                                                                        // : locationName == prefix + `/schedule` ? <Schedule />
                                                                                                                                                                                                        : locationName == prefix + `/assign_job_type/${id}` ? <AssignJobType />
                                                                                                                                                                                                            : locationName == prefix + `/leaves_request` ? <LeavesRequest />
                                                                                                                                                                                                                : locationName == prefix + `/timesheet` ? <Timesheet />
                                                                                                                                                                                                                    : locationName == prefix + `/compliance` ? <Compliance />
                                                                                                                                                                                                                        : locationName == prefix + `/attendance` ? <Attendance />
                                                                                                                                                                                                                            // : locationName == prefix + `/MyComponent` ? <MyComponent />
                                                                                                                                                                                                                            //     : locationName == prefix + `/payroll` ? <Payroll />
                                                                                                                                                                                                                            : ""}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )



}

export default Index