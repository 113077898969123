import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import PaginationComponent from '../../../PaginationComponent';
import { GetPermission, getApi, postApi } from '../../../service/employService'
import { toast } from 'react-hot-toast';
import Loader from '../../../../components/Loader/loader';
import { Breadcrumbsecond } from '../../../../components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Grid, RotatingLines } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';


const RosterEfficiencyReport = () => {
    const [csvfileName, setCvsFileName] = useState('')
    const [data, setData] = useState([])
    // const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [totalCount, setTotalCount] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [apiTotalData, setApiTotalData] = useState({});
    const [getshiftname, setGetshiftname] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedShift, setSelectedShift] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [analyserModal, setAnalyserModal] = useState(false)
    // const [sortedBy, setSortedBy] = useState("updatedAt")
    // const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const gridRef = useRef();
    const navigate = useNavigate();
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [remainingDetail, setRemainingDetail] = useState()
    const [viewAIdata, setViewAIData] = useState([])
    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    const employer = JSON.parse(localStorage.getItem("employer"));
    const employerId = employer.user_id;
    const user_type = employer.user_type
    const permissions = permission_data?.permissions;

    if (permissions) {
        var role = JSON.parse(permissions);
    }

    const getEmployeesData = async (token) => {
        const response = await getApi("/roster-report",
            { start_date: startDate, end_date: endDate, selectedShift: selectedShift, selectedLocation: selectedLocation, search: search })
        console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
        try {
            if (response.status == true) {
                setTimeout(() => {
                    setTotalCount(response.count);
                    setDataArray(response.data);
                    setApiTotalData(response.totalData);
                    setCvsFileName(response.totalData.csvFileName)

                    setTotalPagesCount(response.allEmployeeCount)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    const [employee_view, setemployee_view] = useState(false)
    const [employee_upload_bulk, setemployee_upload_bulk] = useState(false)
    const [employee_add, setemployee_add] = useState(false)
    const [employee_edit, setemployee_edit] = useState(false)
    const [employee_delete, setemployee_delete] = useState(false)

    useEffect(() => {
        if (role) {
            // console.log(role, "role")
            const options = role;
            // console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                // console.log(option, "option")
                if (option.key == "employee_view") {
                    setemployee_view(true);
                }
                if (option.key == "employee_upload_bulk") {
                    setemployee_upload_bulk(true);
                }
                if (option.key == "employee_add") {
                    setemployee_add(true);
                }
                if (option.key == "employee_edit") {
                    setemployee_edit(true);
                }
                if (option.key == "employee_delete") {
                    setemployee_delete(true);
                }
            });
        }
    }, [role]);


    useEffect(() => {
        // setpagesCount(1);
        getEmployeesData(employer.employer_token);
    }, [pagesCount, search, startDate, endDate, selectedShift, selectedLocation]);

    const GetTypeLocation = async () => {
        try {

            const response = await getApi("/get-job-location");
            // const response = await getApi("/get-country");

            console.log('response', response);
            if (response.status) {
                setData(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetTypeLocation();
    }, []);

    const GetAllShift = async () => {
        try {
            const response = await getApi("/get-all-shift");
            if (response.status) {
                setGetshiftname(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetAllShift();
    }, []);



    const handleCallback = (event, picker) => {
        const newStartDate = picker.startDate.format('YYYY-MM-DD');
        const newEndDate = picker.endDate.format('YYYY-MM-DD');

        picker.element.val(newStartDate + ' - ' + newEndDate);

        setstartDate(newStartDate)
        setendDate(newEndDate)
    }

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const [spinnerStatus, setSpinnerStatus] = useState(false)
    const AddForm = useFormik({
        initialValues: {
            prompt: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // prompt: yup.string().required('Please Enter Prompt here'),

        }),

        onSubmit: async (values, { resetForm }) => {
            setSpinnerStatus(true)
            let requestData = {};
            let isContinue = false

            if (viewAIdata.length == 0) {

                requestData = {
                    "id": csvfileName,
                    "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                    "prompt": values.prompt,
                    "bucket_name": "thirdroc-openai-chat-files",
                    "file_key": csvfileName
                };

                setViewAIData([
                    ...viewAIdata,
                    { isUser: false, message: 'How can I help you today?' },
                    { isUser: true, message: values.prompt }
                ])

            } else {
                isContinue = true

                requestData = {
                    "id": csvfileName,
                    "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                    "assistant_id": remainingDetail?.assistant_id,
                    "file_id": remainingDetail?.file_id,
                    "thread_id": remainingDetail?.thread_id,
                    "prompt": values.prompt
                };

                setViewAIData([
                    ...viewAIdata,
                    { isUser: true, message: values.prompt }
                ])
            }

            axios.request({
                method: "POST",
                url: !isContinue ? 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/start' : 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/continue',
                data: requestData,
                headers: {
                    "x-api-key": 'chUkpBamc36taj1oHYQkP8i6PMPe3S1Y3tD9MwfI'
                },
            })

                // response()
                .then((response) => {
                    setTimeout(() => {
                        UpdateChat(values.prompt)

                    }, 10000);
                    // resolve(response.data);
                    resetForm({ values: '' });
                    console.log(response.data);



                }).catch((error) => {
                    console.error(error);
                    // if (error.response) {
                    //     resolve(error.response.data);
                    // } else {
                    //     reject(error);
                    // }


                    // })
                })
            // }
        }

    });

    const UpdateChat = async (userLatestMsg) => {
        try {
            console.log("🚀 ~ UpdateChat ~ viewAIdata:", viewAIdata)

            const response = await postApi('/chat-response', {
                "csvFileName": csvfileName
            })
            setSpinnerStatus(false)
            if (response?.status !== false) {

                const modifiedResponse = response?.mainResponse?.responses.map((data) => {
                    return { isUser: false, message: data }
                })

                if (viewAIdata.length == 0) {
                    setViewAIData([
                        ...viewAIdata,
                        { isUser: false, message: 'How can I help you today?' },
                        { isUser: true, message: userLatestMsg },
                        ...modifiedResponse
                    ])
                    setRemainingDetail(response?.mainResponse)
                }
                else {

                    setViewAIData([
                        ...viewAIdata,
                        { isUser: true, message: userLatestMsg },
                        ...modifiedResponse
                    ])
                    setRemainingDetail(response?.mainResponse)
                }

            } else {
                setSpinnerStatus(true)
                setTimeout(() => {
                    UpdateChat(userLatestMsg)

                }, 15000);
            }
        } catch (error) {
            console.log(error)
        }

    }


    const HandleStopAIChat = async () => {
        AddForm.setFieldValue('prompt', "")
        setViewAIData([])

        axios.request({
            method: "POST",
            url: 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/stop',
            data: {
                "id": csvfileName,
                "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                "assistant_id": remainingDetail?.assistant_id,
                "file_id": remainingDetail?.file_id,
                "thread_id": remainingDetail?.thread_id,
                "bucket_name": "thirdroc-openai-chat-files",
                "file_key": csvfileName
            },
            headers: {
                "x-api-key": 'chUkpBamc36taj1oHYQkP8i6PMPe3S1Y3tD9MwfI'
            },
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        })


    }

    return (
        <>
            {!dsaDetail &&

                <div className="main-container container-fluid px-0">

                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "WFM / Reports" }, second: { name: "Roster Efficiency Report" } }} />
                            </h4>
                        </div>
                        <div className="page-rightheader rosterEfficiency">
                            <div className="btn-list">
                                {/* <Link
                                    className="btn btn-primary btn-pill mt-3" download >
                                    <i className="fa fa-download me-2"></i>
                                    Export Data
                                </Link> */}

                                {/* <button className="btn btn-primary btn-pill mt-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"> */}
                                <button
                                    className="btn btn-primary btn-pill mt-3"
                                    onClick={() => setAnalyserModal(true)}
                                >
                                    AI Analyser
                                </button>
                                {analyserModal &&
                                    <>
                                        <div class="modal offcanvas offcanvas-start show" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ visibility: 'visible' }}>
                                            <div className="offcanvas-header">
                                                <h5 id="offcanvasExampleLabel" className='text-primary'>AI Analyser</h5>
                                                {viewAIdata.length == 0
                                                    ? <button
                                                        className="btn btn-sm btn-primary btn-pill"
                                                        onClick={() => {
                                                            setAnalyserModal(false)
                                                        }}
                                                        disabled={spinnerStatus}
                                                    >
                                                        Close
                                                    </button>
                                                    :
                                                    <button
                                                        className="btn btn-sm btn-danger btn-pill"
                                                        onClick={() => {
                                                            setAnalyserModal(false)
                                                            HandleStopAIChat()
                                                        }}
                                                        disabled={spinnerStatus}
                                                    >
                                                        End Chat
                                                    </button>
                                                }
                                            </div>
                                            <div className="offcanvas-body" style={{ padding: '1rem 0rem' }}>
                                                <div className="w-100 sideNoteBoxforAI">
                                                    {viewAIdata?.length == 0 &&
                                                        <div className='d-flex justify-content-start'>
                                                            <h6 className='mx-2' style={{ fontSize: '12px', padding: '5px 4px', backgroundColor: '#e8e8e8', borderRadius: '8px', textAlign: 'start', width: '75%' }}>How can I help you today?</h6>
                                                        </div>
                                                    }

                                                    {viewAIdata && viewAIdata?.length > 0 && viewAIdata.map((item, index) => {
                                                        return (
                                                            <div key={index} className={item.isUser ? 'd-flex justify-content-end' : 'd-flex justify-content-start'}>
                                                                <h6 className='mx-2' style={item.isUser ? { fontSize: '12px', padding: '5px 4px', backgroundColor: 'rgb(188 228 255)', borderRadius: '8px', textAlign: 'start', width: '75%' } : { fontSize: '12px', padding: '5px 4px', backgroundColor: '#e8e8e8', borderRadius: '8px', textAlign: 'start', width: '75%' }}>{item.message}</h6>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="addNoteBtnTxt">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12 pl-00">
                                                        <form onSubmit={AddForm.handleSubmit}>
                                                            <div className="form-group mb-1">

                                                                <label className="form-label"></label>
                                                                <div className="row g-xs justify-content-end">
                                                                    <div className="input-group">
                                                                        <textarea
                                                                            placeholder='Ask something about...'
                                                                            className="form-control formtestarea"
                                                                            {...AddForm.getFieldProps('prompt')}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter' && AddForm.values.prompt.replace(/\s/g, "").length > 0) {
                                                                                    e.preventDefault();
                                                                                    AddForm.handleSubmit();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {spinnerStatus ?
                                                                        <div className='d-flex justify-content-end me-2'>
                                                                            <Loader status={spinnerStatus} />
                                                                        </div>
                                                                        :
                                                                        <button type="submit" className="btn btn-primary mb-6  mb-1 mt-1"
                                                                            disabled={AddForm.values.prompt.replace(/\s/g, "").length == 0}>Ask
                                                                            <i class="fa-solid fa-wand-magic-sparkles"></i>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Date</label>
                                <div className="row g-xs">
                                    <div className="input-group" >
                                        <DateRangePicker
                                            onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                            initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input
                                                placeholder="Search By Date"
                                                className="form-control fc-datepicker hasDatepicker"
                                                type="text" defaultValue=""
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Shift Name</label>
                                <div className="row g-xs">
                                    {/* <div className="wd-200 mg-b-30"> */}
                                    <div className="input-group">
                                        <select
                                            className="form-control mb-0"
                                            name="loan_type"
                                            onChange={(e) => setSelectedShift(e.target.value)}
                                        >
                                            <option value={selectedShift}>Select Site Name</option>
                                            {getshiftname.map(listItems => (
                                                <option key={listItems.id} value={listItems.id}>{listItems.shift_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Shift Location</label>
                                <div className="row g-xs">
                                    {/* <div className="wd-200 mg-b-30"> */}
                                    <div className="input-group">
                                        <select
                                            className="form-control mb-0"
                                            name="loan_type"
                                            onChange={(e) => setSelectedLocation(e.target.value)}
                                        >
                                            <option value={selectedLocation}>Select Shift Location</option>
                                            {data.length > 0 && data.map(shiftLocation => (
                                                <option key={shiftLocation.id} value={shiftLocation.id}>{shiftLocation.job_location}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12 d-flex justify-content-end align-items-center">
                            {/* <button
                                className='btn btn-dark'
                                onClick={() => {
                                    setSearch('')
                                    setSelectedLocation('')
                                    setSelectedShift('')
                                    setstartDate('')
                                    setendDate('')
                                }}
                            >Clear All Filters
                            </button> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Detail
                                                        {/* {sortedBy == "name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }}></i>} */}
                                                    </th>
                                                    <th className="font-weight-bold">Planned /<br /> Actual Hrs
                                                        {/* {sortedBy == "department_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }}></i>} */}
                                                    </th>
                                                    {/* <th className="font-weight-bold">Actual Working Hrs  </th> */}
                                                    <th className="font-weight-bold">Max NT / <br /> Actual NT  </th>
                                                    <th className="font-weight-bold">Max TH / <br /> Actual TH  </th>
                                                    <th className="font-weight-bold">Max DT / <br /> Actual DT  </th>
                                                    <th className="font-weight-bold">Max MEAL / <br /> Actual MEAL</th>
                                                    {/* <th className="font-weight-bold">Max MEAL / <br /> Actual MEAL  </th> */}
                                                    <th className="font-weight-bold">Expected Employee Cost / <br />Actual Employee Cost</th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader

                                                    /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        <tr style={{ fontSize: '20px' }}>

                                                            <td><span className="font-weight-normal1 fw-bold">Total Count: {totalCount}</span></td>
                                                            <td><span className="font-weight-normal1 fw-bold">{parseFloat(apiTotalData?.expectedHrs).toFixed(2)} Hours / <br />{parseFloat(apiTotalData?.actualHrs).toFixed(2)} Hour</span></td>
                                                            {/* <td><span className="font-weight-normal1 fw-bold text-primary">30.2 Hour</span></td> */}
                                                            <td>
                                                                <span className="font-weight-normal1 fw-bold">
                                                                    ${parseFloat(apiTotalData?.expectedNT).toFixed(2)} /
                                                                    <br />
                                                                    ${parseFloat(apiTotalData?.actualNT).toFixed(2)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1 fw-bold">
                                                                    ${parseFloat(apiTotalData?.expectedTH).toFixed(2)} /
                                                                    <br />
                                                                    ${parseFloat(apiTotalData?.actualTH).toFixed(2)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1 fw-bold">
                                                                    ${parseFloat(apiTotalData?.expectedDT).toFixed(2)} /
                                                                    <br />
                                                                    ${parseFloat(apiTotalData?.actualDT).toFixed(2)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1 fw-bold">
                                                                    $0.00 /
                                                                    <br />
                                                                    ${apiTotalData?.actualMeal ? parseFloat(apiTotalData?.actualMeal).toFixed(2) : '0.00'}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1 fw-bold text-success">
                                                                    ${apiTotalData?.expectedEmpCost ? parseFloat(apiTotalData?.expectedEmpCost).toFixed(2) : '0.00'} /
                                                                    <br />
                                                                    ${apiTotalData?.actualEmpCost ? parseFloat(apiTotalData?.actualEmpCost).toFixed(2) : '0.00'}
                                                                </span>
                                                            </td>
                                                            <hr />
                                                        </tr>
                                                        {dataArray.length > 0 &&
                                                            dataArray.map((e, i) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                                const { NT_total_hrs, NT_per_hrs_wages, nt_amount, TH_total_hrs, TH_per_hrs_wages, th_amount, DT_total_hrs, DT_per_hrs_wages, dt_amount, MEAL_total_hrs, meal_amount, exp_emp_cost, actual_emp_cost, expected_working_hours, acctual_working_hours, mobile_number, first_name, last_name } = e;

                                                                return (
                                                                    <tr key={serial_num}>
                                                                        <td>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}&nbsp;{e?.last_name ? last_name : ""}<br />
                                                                                {mobile_number}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1 text-primary">
                                                                                {e?.expected_working_hours ? expected_working_hours + " Hours" : "0.00 Hours"} / <br /> {e?.acctual_working_hours ? acctual_working_hours + " Hours" : "0.00 Hours"}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                {e?.NT_total_hrs ? "$" + parseFloat(NT_total_hrs * NT_per_hrs_wages).toFixed(2) : "$0.00"} / <br />
                                                                                {e?.nt_amount ? "$" + nt_amount : "$0.00"}
                                                                                {/* {e?.NT_total_hrs ? NT_total_hrs + " Hours" : "--"} */}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                {e?.TH_total_hrs ? "$" + parseFloat(TH_total_hrs * TH_per_hrs_wages).toFixed(2) : "$0.00"} / <br />
                                                                                {e?.th_amount ? "$" + th_amount : "$0.00"}
                                                                                {/* {e?.TH_total_hrs ? TH_total_hrs + " Hours" : "--"} */}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                {e?.DT_total_hrs ? "$" + parseFloat(DT_total_hrs * DT_per_hrs_wages).toFixed(2) : "$0.00"} / <br />
                                                                                {e?.dt_amount ? "$" + dt_amount : "$0.00"}
                                                                                {/* {e?.DT_total_hrs ? DT_total_hrs + " Hours" : "--"} */}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                $0.00 /
                                                                                <br />
                                                                                {e?.meal_amount ? "$" + meal_amount : "$0.00"}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="font-weight-normal1 text-success">
                                                                                {e?.exp_emp_cost ? "$" + exp_emp_cost : "$0.00"} / <br />
                                                                                {e?.actual_emp_cost !== 'NaN' && e?.actual_emp_cost !== null ? "$" + actual_emp_cost : "$0.00"}
                                                                                {/* {e?.actual_emp_cost ? "$" + actual_emp_cost : ""} */}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        } </tbody>
                                                    <tbody>{dataArray.length == 0 && <tr><td colSpan={10} className='text-center'>No Data Available</td></tr>}</tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''} */}

                    </div>
                </div >
            }


        </>
    )
}


export default RosterEfficiencyReport;
