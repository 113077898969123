import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Service from '../../adminService';
import toast, { Toaster } from 'react-hot-toast';
import { GetCountry } from '../../employer/service/employService';
import { EditLenderDetail, postApi } from '../service/adminService';
import { Image } from 'antd';



const api = new Service();

const EditLender = (props) => {

  const navigate = useNavigate();
  const { user_id } = useParams();
  const [country, setCountry] = useState([]);
  const ids = useLocation();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const token = admin.admin_token

  console.log(ids)
  const profileData = ids.state
  const [image, setImage] = useState(profileData?.logo)
  const getCountry = async () => {
    const response = await GetCountry();
    if (response.status == true) {
      setCountry(response.data);
    } else {
      console.log("get Country data response", response);
    }
  }

  const [selectedImage, setSelectedImage] = useState();
  const imageChange = (e) => {
    setImage(e.target.files[0])
    setSelectedImage(e.target.files[0])
    EditForm.setFieldValue('logo', e.target.files[0].name)
  };

  useEffect(() => {
    getCountry();
  }, [])


  useEffect(() => {

  }, [profileData])

  const S3_url = process.env.REACT_APP_S3_BUCKET_URL + "logo/"
  console.log(S3_url)

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
  const EditForm = useFormik({

    initialValues: {
      // bank_id: profileData.bank_id,
      company_name: profileData.company_name,
      full_name: profileData.full_name,
      country: profileData.country,
      email: profileData.email,
      address: profileData.address,
      mobile_number: profileData.mobile_number,
      gst_number: profileData.gst_number,
      website: profileData.website,
      mini_rate_of_intrest: profileData.mini_rate_of_intrest,
      max_rate_of_intrest: profileData.max_rate_of_intrest,
      mini_loan_range: profileData.mini_loan_range,
      max_loan_range: profileData.max_loan_range,
      mini_tenure: profileData.mini_tenure,
      max_tenure: profileData.max_tenure,
      logo: image?.name,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      company_name: yup.string().required('Please Enter company name'),
      full_name: yup.string().required('Please Enter Your Name'),
      address: yup.string().required('Please Enter Address'),
      email: yup.string().email("Invalid Email Address Format").required('Please Enter Valid Email Address'),
      //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
      mobile_number: yup.string().required('Please Enter Mobile Number').min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Must Contain 15 Number").matches(phoneRegExp, 'Please Enter Only Number Values'),
      gst_number: yup.string().required('Please Enter Valid GST Number'),
      website: yup.string().required('Please Enter Valid Website'),
      mini_rate_of_intrest: yup.number().typeError('Please Enter a Valid Rate Of Intrest').required('Please Enter Minimum Rate Of Intrest'),
      max_rate_of_intrest: yup.number().typeError('Please Enter a Valid Rate Of Intrest').required('Please Enter Maximum Rate Of Intrest'),
      mini_loan_range: yup.number().typeError('Please Enter a Valid Loan Range').required('Please Enter Minimum Loan Range'),
      max_loan_range: yup.number().typeError('Please Enter a Valid Loan Range').required('Please Enter Maximum Loan Range'),
      mini_tenure: yup.number().typeError('Please Enter a Valid Tenure').required('Please Enter Minimum Tenure'),
      max_tenure: yup.number().typeError('Please Enter a Valid Tenure').required('Please Enter Maximum Tenure '),
    }),
    onSubmit: async values => {
      const formData = new FormData();
      formData.append("bank_id", profileData.bank_id);
      formData.append("company_name", EditForm.getFieldProps('company_name').value);
      formData.append("full_name", EditForm.getFieldProps('full_name').value);
      formData.append("country", EditForm.getFieldProps('country').value);
      formData.append("mobile_number", EditForm.getFieldProps('mobile_number').value);
      formData.append("address", EditForm.getFieldProps('address').value);
      formData.append("website", EditForm.getFieldProps('website').value);
      formData.append("email", EditForm.getFieldProps('email').value);
      formData.append("gst_number", EditForm.getFieldProps('gst_number').value);
      formData.append("mini_rate_of_intrest", EditForm.getFieldProps('mini_rate_of_intrest').value);
      formData.append("max_rate_of_intrest", EditForm.getFieldProps('max_rate_of_intrest').value);
      formData.append("mini_loan_range", EditForm.getFieldProps('mini_loan_range').value);
      formData.append("max_loan_range", EditForm.getFieldProps('max_loan_range').value);
      formData.append("mini_tenure", EditForm.getFieldProps('mini_tenure').value);
      formData.append("max_tenure", EditForm.getFieldProps('max_tenure').value);
      formData.append("logo", image);

      // const response = await EditLenderDetail(token, formData, profileData.user_id)
      const response = await postApi(`/edit-lender/${profileData.user_id}`, formData, true)

      if (response.status === true) {
        toast.success(response.message);
        navigate(prefix + '/lender');
      } else {
        console.log('error', response);
        toast.error(response);
      }

    }
  });
  // console.log( S3_url + selectedImage.name)
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      <div className="main-container container-fluid px-0">
        <div className="page-header">
          <div className="page-leftheader">
            <h4 className="page-title mb-0 text-primary">Update Lender's Profile</h4>
          </div>
        </div>
        <form className="mt-5 row" id='registerForm' onSubmit={EditForm.handleSubmit}>
          <div className="row">

            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Company Name</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter company name" {...EditForm.getFieldProps("company_name")} />
                  </div>
                  {EditForm.touched.company_name && EditForm.errors.company_name ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_name}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Your Name</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter Your Name" {...EditForm.getFieldProps("full_name")} />
                  </div>
                  {EditForm.touched.full_name && EditForm.errors.full_name ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Email</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="email" className="form-control" placeholder="Enter Email" {...EditForm.getFieldProps("email")} />
                  </div>
                  {EditForm.touched.email && EditForm.errors.email ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Country</label>
                <div className="row g-xs">
                  <div className="input-group">

                    <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
                      <option value="">Select Country</option>
                      {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                    </select>
                  </div>
                  {EditForm.touched.country && EditForm.errors.country ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Mobile number</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter Mobile number" {...EditForm.getFieldProps("mobile_number")} />
                  </div>
                  {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Address</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter address" {...EditForm.getFieldProps("address")} />
                  </div>
                  {EditForm.touched.address && EditForm.errors.address ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.address}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Gst Number</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter gst_number" {...EditForm.getFieldProps("gst_number")} />
                  </div>
                  {EditForm.touched.gst_number && EditForm.errors.gst_number ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.gst_number}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Website</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Enter website" {...EditForm.getFieldProps("website")} />
                  </div>
                  {EditForm.touched.website && EditForm.errors.website ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Maximum Rate of Interest</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="max_rate_of_intrest"{...EditForm.getFieldProps("max_rate_of_intrest")} className="form-control" placeholder="Enter maximum rate of interest" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Minimum Rate of Interest</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="mini_rate_of_intrest"{...EditForm.getFieldProps("mini_rate_of_intrest")} className="form-control" placeholder="Enter minimum rate of interest" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Maximum Loan Range</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="max_loan_range"{...EditForm.getFieldProps("max_loan_range")} className="form-control" placeholder="Enter maximum loan range" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Minimum Loan Range</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="mini_loan_range"{...EditForm.getFieldProps("mini_loan_range")} className="form-control" placeholder="Enter email" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Maximum Tenure(Months)</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="max_tenure"{...EditForm.getFieldProps("max_tenure")} className="form-control" placeholder="Enter maximum tenure" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Minimum Tenure(Months)</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" name="mini_tenure"{...EditForm.getFieldProps("mini_tenure")} className="form-control" placeholder="Enter minimum tenure" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Logo</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control browse-file" placeholder="Upload your logo" />
                    <label className="input-group-text btn btn-primary"> Browse   {/* <p onChange={imageChange}> */}<input type="file" className="file-browserinput" /* filename={image} */ onChange={(e) => imageChange(e)} style={{ display: 'none' }} multiple /> </label>
                  </div>
                </div>




                {!selectedImage ? <ul className="imgpreview-newbx">
                  <li className="registerboximg">
                    <Image src={image ? image : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="/" /* style={{ width: 'auto' }} */ /> </li>
                </ul> :
                  <ul className="imgpreview-newbx">
                    <li className="registerboximg">
                      <Image
                        src={URL.createObjectURL(selectedImage)}
                        /* src={S3_url + selectedImage.name} */ /* style={{ width: 'auto' }} */
                        alt="..."
                      /> </li>
                  </ul>}
              </div>
            </div>

            <div className="col-md-12">
              <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1">Submit</button>
            </div>


          </div>
        </form>




      </div>






    </>
  )
}
export default EditLender;
