import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { deleteApi, getApi, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import { TimeZone } from '../../../../components/TimeZone/TimeZone';
import PaginationComponent from '../../../../PaginationComponent';
import Swal from 'sweetalert2';

const ViewNFCsites = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate()
    const employer = JSON.parse(localStorage.getItem("employer"))
    const token = employer.employer_token
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("site_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [nfc_site_name, setSite_Name] = useState()
    const [ID, setID] = useState()
    const tableRef = useRef();

    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX


    const GetDepartment = async (token) => {
        try {
            // const response = await GetDepartmentDetail(token, search, pagesCount, sortedBy, orderBy);
            const response = await getApi("/get-nfc-site-list", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

            console.log('response', response);
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data);
                    setTotalPagesCount(response.count)
                    //setTotalPagesCount(response.data["count"])
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetDepartment(employer.employer_token);
    }, [search, pagesCount, sortedBy, orderBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        // getAllCityData(admin.admin_token, pagesCount, search);
    }


    const form = useFormik({
        initialValues: {
            // nfc_id: "",
            nfc_site_name: "",
            // location: "",
            // other: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // nfc_id: yup.string().required('Please Enter NFC ID'),
            // nfc_site_name: yup.string().required('Please Enter Department Name'),
            nfc_site_name: yup.string()
                .matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character')
                .test('noOnlySpaces', 'Title cannot be empty or contain only spaces', value => {
                    return value && value.trim() !== ''; // Add a check for value existence
                })
                .required('Please Enter EWA Plan Title')
            // location: yup.string().required('Please Enter Other Details'),
            // other: yup.string().required('Please Enter Other Details')
        }),
        onSubmit: async (values, { resetForm }) => {
            // const data = JSON.stringify({
            //     nfc_site_name: values.nfc_site_name,
            //     // other: values.other,
            //     // location: values.location
            // })

            // const response = await addDepartmentdetails(token, data)
            const response = await postApi("/add-nfc-site", { nfc_site_name: values.nfc_site_name, })

            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                resetForm({ values: '' });
                // navigate(prefix + '/view_department')
                GetDepartment(employer.employer_token);


            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
                resetForm({ values: '' });
            }
        }
    })

    const handleDelete = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const response = await deleteDeparment(employer.employer_token, id)
                const response = await deleteApi(`/delete-nfc-site/${id}`)

                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'User Account has been deleted Successfully.',
                        'success'
                    )
                    GetDepartment(employer.employer_token);
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    useEffect(() => {
        // FormEdit.setValues({            
        //         "site_id": ID,
        //         "nfc_site_name": nfc_site_name,
        //         "location": "",
        //         "other": ""
        //        })     

    }, [])

    const FormEdit = useFormik({
        initialValues: {
            nfc_site_name: nfc_site_name,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // nfc_site_name: yup.string().required('Please Enter Site Name'),
            nfc_site_name: yup.string()
                .matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character')
                .test('noOnlySpaces', 'Title cannot be empty or contain only spaces', value => {
                    return value && value.trim() !== ''; // Add a check for value existence
                })
                .required('Please Enter EWA Plan Title')

        }),
        onSubmit: async values => {
            const data = JSON.stringify({
                "site_id": ID,
                "nfc_site_name": values.nfc_site_name
                // const formData = new FormData();
                // formData.append("nfc_site_name", FormEdit.getFieldProps('nfc_site_name').value);
            })

            // const response = await EditNFCSites(employer.employer_token, data)
            const response = await postApi(`/edit-nfc-site`, {
                site_id: ID,
                nfc_site_name: values.nfc_site_name
            })

            if (response.status === true) {
                toast.success(response.message);
                tableRef.current.click()
                GetDepartment(employer.employer_token);
            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbsecond order={{ first: { name: "Employee" }, second: { name: "Department List" } }} />
                        </h4>
                    </div>
                </div>
                {/* <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Department Name</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Department Name" {...form.getFieldProps("nfc_site_name")} />
                                        <button type="submit" className="btn btn-primary ">Add</button>
                                    </div>
                                    {form.touched.nfc_site_name && form.errors.nfc_site_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.nfc_site_name}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </form> */}
            </div>
            <div className="main-container container-fluid px-0">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" >
                        <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                            <div className="row">

                                <div className="form-group">
                                    <label className="form-label">Department Name</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Department Name" {...form.getFieldProps("nfc_site_name")} />
                                            <button type="submit" className="btn btn-primary ">Add</button>
                                        </div>
                                        {form.touched.nfc_site_name && form.errors.nfc_site_name ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.nfc_site_name}</div> : ''}
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                {/* <th className="font-weight-bold">NFC ID  {sortedBy == "nfc_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }}> </i>}</th> */}
                                                <th className="font-weight-bold">Department Name  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }}> </i>}</th>
                                                <th className="font-weight-bold">Updated Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                                <th className="font-weight-bold">Action </th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader />
                                            </div> </tbody> : <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    return (
                                                        <tr key={serial_num} >
                                                            <td><span className="font-weight-normal1">{option.nfc_site_name ? option.nfc_site_name : "-"}</span></td>
                                                            <td><span className="font-weight-normal1">{TimeZone(option.updated_at).timeZone}</span></td>
                                                            <td>
                                                                <button type="button" className="btn btn-md btn-outline-primary me-1 fas fa-edit"
                                                                    data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.site_id); setSite_Name(option.nfc_site_name) }}  >{/* <i className="fas fa-edit " /> */}</button> {/* &nbsp;&nbsp; */}
                                                                <button type="button" className="btn btn-md btn-outline-danger me-1 fa fa-trash" onClick={() => { handleDelete(option.site_id) }} ></button></td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                </div>


                {/* Edit Banner Modal start */}
                <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content modal-content-demo">
                            <div className="modal-header">
                                <h3 className="modal-title">Edit Department</h3>
                                <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                    aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body application-modal">
                                <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                    {FormEdit.values.wrong_message ?
                                        <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{FormEdit.values.wrong_message}</div>
                                        : ''}
                                    <div className="row">
                                        <div className="row g-xs">
                                            <div className="col-12">
                                                <input type="text" placeholder='Enter Site Name' {...FormEdit.getFieldProps("nfc_site_name")} className="form-control" />
                                                {FormEdit.touched.nfc_site_name && FormEdit.errors.nfc_site_name ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.nfc_site_name}</div> : ''}
                                            </div>
                                        </div>
                                        <div className="form-footer mt-2">
                                            <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Banner Modal End */}
            </div>
        </>
    )
}

export default ViewNFCsites;
