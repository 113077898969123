import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { postApi, posttoken } from '../service/employService';
import { error } from 'jquery';
import axios from 'axios';
// import { loadStripe } from '@stripe/stripe-js';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';


//Lender Login API
const Signup = () => {
  const navigate = useNavigate();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const [hideResetForm, sethideResetForm] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('plan');


  const postOrderPricePreview = async () => {
    try {

      const url = 'https://www.wixapis.com/pricing-plans/v2/plans/public/query';
      const headers = {
        Authorization: 'VWeQylPxxsP7FAgQMHc7IyIiZXvBYxFXLxtO0Ynwj24.eyJpbnN0YW5jZUlkIjoiZDdkZGIzMmUtNjFhNC00ZTExLWFmYWUtMmI2OTdkMTY5MzdhIiwiYXBwRGVmSWQiOiIyMmJlZjM0NS0zYzViLTRjMTgtYjc4Mi03NGQ0MDg1MTEyZmYiLCJtZXRhU2l0ZUlkIjoiZDdkZGIzMmUtNjFhNC00ZTExLWFmYWUtMmI2OTdkMTY5MzdhIiwic2lnbkRhdGUiOiIyMDI0LTA0LTAyVDA2OjM3OjI4LjkzN1oiLCJkZW1vTW9kZSI6ZmFsc2UsImFpZCI6ImE2YjRkMjliLWEyOWYtNDFjZC1iM2E1LTA3YWY1MTVhNWUyYyIsInNpdGVPd25lcklkIjoiNzAwNmE4MTMtZjI4ZC00ZDgzLWI1OGUtZjBhYjhhMDg4NTI4In0'
      };
      const body = {
        "query": {
          "filter": {
            "id": {
              "$eq": "44a808a4-c47e-4b68-9774-993767a86197"
            }
          }
        }
      }

      const response = await axios.post(url, body, { headers });

      // Handle response
      console.log('Response:', response);
    } catch (error) {
      // Handle error
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    postOrderPricePreview()

  }, [])


  // console.log(customerId, "customerId")
  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmedPassword: "",
      captcha: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
      password: yup.string().required('Please Enter Password'),
      // confirm_password: yup.string().required('Please Enter Confirm Password'),
      confirmedPassword: yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
        .oneOf([yup.ref("password"), null], "Password Must Be Same"),
      captcha: yup.string().required('Please Enter Captcha'),

    }),
    onSubmit: async values => {

      if (status == true) {

        const response = await postApi("/employer-registration-v2", {
          email: values.email,
          password: values.password,
          plan: customerId
        })

        if (response.status == false) {
          toast.error(response.message);
        }
        if (response.status == true) {

          // stripetool(response.token)

          if (response.status) {
            toast.success(response.message);
            let employer = {
              user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
              employer_token: response.token,
              email: response.email,
              user_name: response.user_name,
              is_nfc_active: response.is_nfc_active,
              plan: response.plan,
              business_type: response?.business_type,
              payment_status: response.payment_status,
              basic_details_update: response.basic_details_update
            }

            localStorage.setItem('employer', JSON.stringify(employer));
            navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/dashboard')
          } else {
            toast.error(response.message);
            localStorage.removeItem('employer');
            console.log("#@#@#1234", error);
          }
        }
      }
    }
  });

  // const stripetool = async (token) => {
  //   const stripe = await loadStripe("pk_test_51O9itQBRGXsPADTNSCAfqn2aPfvQP6H2Lx1w7gu8NgYP49lwWvY4elpVDnCmCOw7FBDaRWfQwyn0Ki1QHKrVKxpU008q3PNH3E");

  //   const body = {
  //     plan: 'Starter'
  //   }

  //   const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone);

  //   const headers = {
  //     "Content-type": "Application/json",
  //     "Authorization": `Bearer ${token}`,
  //     "Timezone": Timezone
  //   };

  //   try {
  //     const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/stripe-check-out", {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(body)
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch session from API');
  //     }

  //     const session = await response.json();

  //     console.log(session);

  //     const result = await stripe.redirectToCheckout({ sessionId: session.id });
  //     console.log(result, "_____________");
  //     return result;
  //   } catch (error) {
  //     console.error('Error:', error);
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //     stripetool("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJsaXZ0NDFicWtneWdraWpuNm9nIiwidXNlcl9pZCI6OCwiaWF0IjoxNzExNjMzOTQxLCJleHAiOjE3MTQyMjU5NDF9.rIFsCBTX_UHKwf78VBWtVQOLm2Y1uhkCKJMfGEmT0rQ")
  //   },[])

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfP, setValuesConfP] = React.useState({
    password: "",
    showConfPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfPassword = () => {
    setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
  };
  const eyeBtnStyle = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
  }

  const eyeBtnStyle1 = {
    position: 'absolute',
    marginLeft: '262px',
    marginTop: '16px',
  }

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  // const doSubmit = () => {
  //   const userCaptchaValue = document.getElementById('user_captcha_input').value;

  //   if (validateCaptcha(userCaptchaValue)) {
  //     alert('Captcha Matched');
  //   } else {
  //     alert('Captcha Does Not Match');
  //     userCaptchaValue()
  //   }
  // };
  const [status, setStatus] = useState(false)
  const doSubmit = () => {
    const userCaptchaInput = document.getElementById('user_captcha_input');
    const userCaptchaValue = userCaptchaInput.value;

    if (validateCaptcha(userCaptchaValue)) {
      userCaptchaInput.value = '';
      toast.success("Captcha Matched Successfully")
      setStatus(true)
    } else {
      toast.error("Please Enter Correct Captcha Value")
      userCaptchaInput.value = '';
      form.setFieldValue('captcha', "")
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page">
        <div className="page-single bg-back">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-12">
                    <div className="mb-5 br-7 text-center"><Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img loginlogo" alt="logo" /></Link></div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-12">
                    <div className="row p-0 m-0 bg-white br-7">
                      <div className="col-lg-6 p-0">
                        <div className="text-justified text-white p-5 register-1 overflow-hidden">
                          <div className="custom-content">
                            <div className="">
                              <div className="fs-22 mb-4 font-weight-bold text-white">Welcome Back To ThirdRoc!</div>
                              {/* <h4>Order Summary</h4>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Starter        $4.00</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Duration: 3 Months</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Free trail: 7 Days</div> */}
                              {/* <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimal Documentation.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Quick Disbursal.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Loan Amount.</div> */}


                              {/* <h6 className="mt-5">Total Todays :  $0.00</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> After the free trial, you will be charged $4.00 monthly for 3 months.</div> */}
                              {/* <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimum KYC</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Furnishes your Working Capital Requirement, Manage operational cost, growth & expansion of Business</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {hideResetForm &&
                        <div className="col-md-8 col-lg-6 p-0 mx-auto">
                          <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                            <div className="card-body">
                              <div className="text-center mb-3">
                                <h3 className="mb-2">Signup As Employer</h3>
                              </div>
                              <form className="mt-5" onSubmit={form.handleSubmit}>
                                {form.values.wrong_message ?
                                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
                                  : ''}
                                <div className="input-group mb-4">
                                  <div className="input-group-text"> <i className="fa-solid fa-envelope fa-fw" /> </div>
                                  <input type="email" className="form-control" name="email" {...form.getFieldProps("email")} placeholder="Enter Email ID " />
                                  {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                                </div>
                                <div className="input-group mb-4">
                                  <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                  <input className="form-control" name="password" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} placeholder="Enter password" id="password-field" />
                                  {/* <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes r20"></span> */}
                                  {!values.showPassword ?
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                    :
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                  }
                                  {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                                </div>

                                <div className="input-group">
                                  <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                  <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                  {!valuesConfP.showConfPassword ?
                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                    :
                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                  }
                                  {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                                </div>
                                {!status &&
                                  <div className=' mb-3 mt-3 '>
                                    <LoadCanvasTemplate />
                                    <div className="col mt-3">
                                      <div>
                                        <input className='form-control' type="text"  {...form.getFieldProps("captcha")}
                                          placeholder="Enter Captcha Value" id="user_captcha_input" /* name="user_captcha_input" */ ></input></div>
                                    </div>

                                    <div className="col mt-3">
                                      <div>
                                        <button class="form-group text-center mb-3 mt-3 btn btn-primary btn-lg w-100 br-7" onClick={() => doSubmit()}
                                          disabled={!form.values.captcha}>Verify</button></div>
                                    </div>
                                    {form.touched.captcha && form.errors.captcha ?
                                      <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.captcha}</div> : ''}
                                  </div>}

                                {/* <div className='text-center mb-3 mt-3'> */}
                                {/* <Link to="" data-bs-target="#forgotpassword" data-bs-toggle="modal" >FORGOT PASSWORD</Link> */}
                                {/* <a href="/lender/registration" style={{textAlign:"right"}}>SIGNUP</a> */}
                                {/* </div> */}
                                {status && <div className="form-group text-center mb-3 mt-3"> <button type="submit" className="btn btn-primary btn-lg w-100 br-7">Sign Up</button> </div>}
                                {/* <div className='text-center mb-3 mt-3'>
                                  <a href="/lender/registration">SIGNUP</a>
                                </div> */}
                                <div className="form-group mt-3">Do you have account?<Link to={customerId ? "/employer/login?customer=" + customerId : "/employer/login"}
                                  className="font-weight-bold fs-6"> Login</Link></div>

                                {/* <div className="form-group mt-3">Don't have account? <Link to={customerId ? "/employer/registration?customer=" + customerId : "/employer/registration"}
                                  className="font-weight-bold"> Register</Link></div> */}


                                <div className="form-group fs-12 text-center"> By logging in, you agree to the following Credit report
                                  {/* <Link to="" className="font-weight-bold">Terms &amp; Conditions</Link> and have read and
                                  acknowledge our <Link to="" className="font-weight-bold">Privacy
                                    &amp; Services.</Link>  */}
                                  <a target="blank"
                                    href={'/terms-of-use'}
                                  /* href={config.PRODUCTION_MODE == 1 ? config.DOMAIN + '/terms-of-use' : config.DEV_DOMAIN + '/terms-of-use'}  */ alt="Terms of use"> &nbsp;Terms & Conditions </a> &nbsp;and have read and
                                  acknowledge our&nbsp;<a rel="noreferrer"
                                    href={'/privacy-policy'}
                                  /* href={config.PRODUCTION_MODE == 1 ? config.DOMAIN + '/privacy-policy' : config.DEV_DOMAIN + '/privacy-policy'} */ alt="Privacy Policy" target="_blank"> Privacy Policy </a>

                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Signup