import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { getApi, postApi } from '../../service/lenderService';
import { Breadcrumbsecond } from '../../../components';

const Editusers = () => {
    const [dataArray, setDataArray] = useState([]);
    const ids = useLocation();
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const navigate = useNavigate()
    const lender = JSON.parse(localStorage.getItem("lender"))
    const token = lender.lender_token
    const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const storeData = ids.state

    //   console.log(storeData)
    // useEffect(() => {
    //     EditForm.setValues({
    //         user_id: storeData.user_id,
    //         full_name: storeData?.full_name,
    //         // country: storeData.country,
    //         role_id: storeData?.role?.id,
    //         mobile_number: storeData?.mobile_number,
    //         email: storeData?.email

    //     })

    // }, [storeData])
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const EditForm = useFormik({
        initialValues: {
            user_id: storeData.user_id,
            full_name: storeData?.full_name,
            // country: storeData.country,
            role_id: storeData?.role?.id,
            mobile_number: storeData?.mobile_number,
            email: storeData?.email

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            full_name: yup.string().required('Please Enter Name'),
            role_id: yup.string().required('Please Enter Role'),
            mobile_number: yup.string().required('Please Enter Mobile Number').min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number").matches(phoneRegExp, 'Please Enter Only Number Values'),
            email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url'),

        }),
        onSubmit: async values => {
            // let data = JSON.stringify(values);

            // const response = await UpdateManageUser(token, data);
            const response = await postApi("/update-sub-users", {
                user_id: storeData.user_id,
                full_name: values.full_name,
                role_id: values.role_id,
                mobile_number: values.mobile_number,
                email: values.email
            });
            console.log(response)
            if (response.status == true) {
                navigate(prefix + '/manage_user')

                toast.success(response.message)
            } else {
                toast.error(response.message)
                console.log("get Country data response", response);
            }
        }
    });
    const handleChangeQueryBuilder = e => {
        const { name, value } = e.target;
        EditForm.setValues({
            ...EditForm.values,
            [name]: value.trim()
        })
    };

    const getUsermanager = async () => {
        // const response = await GetUsermanagers(token);
        const response = await getApi("/get-role-permission");
        console.log(response)
        try {
            if (response.status == true) {
                setDataArray(response.rows);

            } else if (response.message === 'Session expired') {
                localStorage.removeItem("lender");
                navigate('/lender/login')
            } else {
                console.log("get lender data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        // setpagesCount(1);
        getUsermanager(token);
    }, []);

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">
                        {/* <nav aria-label="breadcrumb">
                         <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/profile" >Merchant Profile</Link></li>
                     <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                       </ol>
                    </nav> */}

                        <Breadcrumbsecond order={{ first: { name: "Manage User", path: `${prefix}/manage_user` }, second: { name: "Edit User" } }} />

                    </h4>


                </div>

            </div>
            <form className="row" id='registerForm' onSubmit={EditForm.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">User Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...EditForm.getFieldProps("full_name")} />
                                </div>
                                {EditForm.touched.full_name && EditForm.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Role</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    {/* <select className="form-control mb-0" name="role_id" {...EditForm.getFieldProps("role_id")} onChange={(e) => handleChangeQueryBuilder(e)}> */}
                                    <select className="form-control mb-0" name="role" {...EditForm.getFieldProps("role_id")} onChange={(e) => handleChangeQueryBuilder(e)}>
                                        <option value=''>Select Roles</option>
                                        {dataArray?.map((e) => {
                                            return (
                                                <option key={e.id} value={e.id}>{e.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                {EditForm.touched.role_id && EditForm.errors.role_id ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.role_id}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...EditForm.getFieldProps("mobile_number")} />
                                </div>
                                {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...EditForm.getFieldProps("email")} />
                                </div>
                                {EditForm.touched.email && EditForm.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>
    )
}

export default Editusers
