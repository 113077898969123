import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { addSettlement, getApi, postApi } from '../service/adminService';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import { Oval } from 'react-loader-spinner';

import PaginationComponent from '../../PaginationComponent';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { Image } from 'antd';
import { toast } from 'react-hot-toast';

const Settlement = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const tableRef = useRef(null);
    const merchant = JSON.parse(localStorage.getItem("admin"));
    const [data, setData] = useState([])
    const [settlements, setSettlements] = useState([])
    // const [copied, setCopied] = useState(false);
    const [value, setValue] = useState('');
    const [search, setSearch] = useState('');
    const [dataArray, setDataArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)

    const [detail, setDetail] = useState([]);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const transction = async (token, pagesCount, search) => {
        // const response = await GetSettlement(token, pagesCount, search, sortedBy, orderBy);
        const response = await postApi("/get-settlements", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        // console.log(response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data)
                setTotalPagesCount(response.totalTransctions)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response);
        }
    }

    const addSettlements = async (data) => {
        console.log('data addSettlements', data);
        // const response = await addSettlement(data);
        const response = await postApi("/add-settlment", { merchatTransctionId: data });
        if (response.status) {
            setData(response.data)
            transction(merchant.admin_token)
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        transction(merchant.admin_token, pagesCount, search)

    }, [search, pagesCount, sortedBy, orderBy])

    const selectSettlement = (id) => {
        // settlements.push(id)
        // setSettlements(settlements.idArr.push(id))
        setSettlements(oldArr => [...oldArr, id]);

    }
    // useEffect(()=>{

    //     console.log('settlements',settlements);
    // },[settlements])
    const addSettlementByIds = () => {
        addSettlements(settlements)
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // transction(merchant.admin_token, pagesCount, search );
    }

    const getDetail = async (token, id) => {
        // const response = await GetMerchantrDetail(token, id);
        const response = await getApi(`/get-merchant/${id}`);
        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.status) {
            setDetail(response.data);
        } else {
            console.log("get merchant data response", response);
        }
    }

    const setCopied = () => {
        toast.success("Copied")
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">Settlement</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="card">
                            {/* <div className="card-header br-0 d-flex justify-content-between">
                                <h3 className="card-title"> Settlement Reports</h3>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Loan Type</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <select className="form-control mb-0" name="loan_type" /* value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)} */>
                                            <option>Select Loan</option>
                                            <option value="1">BNPL</option>
                                            <option value="2">Digital Lending</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword/Name/Transactions ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold"> logo</th>
                                                <th className="font-weight-bold">Merchant  {sortedBy == "marchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('marchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('marchant_name') }}></i>} </th>
                                                <th className="font-weight-bold">Paid Amount   {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>} </th>
                                                <th className="font-weight-bold">TXN Id   {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>} </th>
                                                <th className="font-weight-bold">Mode of TXN  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>} </th>
                                                <th className="font-weight-bold">Comment    {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                                <th className="font-weight-bold">TXN Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>} </th>
                                                {/* <th className="font-weight-bold">STATUS</th> */}
                                            </tr>
                                        </thead>

                                        {loader == true ? <tbody>
                                            <td /><td /><td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader /> </div> </tbody> :
                                            <>
                                                <tbody>
                                                    {dataArray && dataArray.map((option, index) => {
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        let user_id = option?.user_id;
                                                        //  let Capitalize = (option) => {
                                                        //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                        //     }
                                                        // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });


                                                        // var NewText = option.logo.replace("merchant-logo-", "logo/");
                                                        // console.log(NewText)
                                                        return (
                                                            <tr key={index} >

                                                                {/* <td><input type="checkbox" name="id" value={option.id} onClick={()=>selectSettlement(option.id)}/></td> */}
                                                                {/* <td><span className="font-weight-normal1">{serial_num}</span></td> */}
                                                                {/* <td><div style={{ width: 50, height: 50, borderRadius: '8px' }}>
														<span className="font-weight-normal1">
															<Image  src={option.logo != null ? NewText : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'}  alt=''/>
														</span></div>
													</td> */}
                                                                <td>
                                                                    <Link to="" onClick={() => getDetail(admin.admin_token, option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" >
                                                                        {/* <Link to={{pathname: `${prefix}/EmployeeDetails/${option.user_id}`, state: {user_id}}} > */}
                                                                        <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{option.marchant_name ? option.marchant_name : ""}<br /> {option.mobile_number}</span>
                                                                    </Link>
                                                                </td>
                                                                <td><span className="font-weight-normal1">{option.amount ? "$" + option.amount : "NA"}</span>
                                                                </td>
                                                                <td><span className="font-weight-normal1">{option.txn_number ? option.txn_number : "NA"}</span>
                                                                    <span>
                                                                        <CopyToClipboard text={option.txn_number} onCopy={() => setCopied(true)}>
                                                                            <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                                        </CopyToClipboard>
                                                                    </span>
                                                                </td>
                                                                <td><span className="font-weight-normal1">{option.payment_mode}</span></td>
                                                                <td><span className="font-weight-normal1">{option.comment}</span></td>
                                                                {/* <td><span className="font-weight-normal1">{option.title}</span></td> */}
                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                {/* <td><span className="font-weight-normal1">{option.status}</span></td> */}

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                </div>
                <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content modal-content-demo">
                            <div className="modal-header"> <h6 className="modal-title">Merchant Information</h6>
                                <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                            <div className="modal-body application-modal">

                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="">
                                            <div className="main-content-body main-content-body-contacts">
                                                {/* <h6>Marchant Information</h6> */}
                                                <div className="main-contact-info-header bg-contacthead pb15">
                                                    <div className="media">
                                                        <div className="media-body text-white ml-0">
                                                            <h4 className="text-white">Company Name: {detail && detail.company_name ? detail.company_name : ''}</h4>
                                                            <p className="mb-1">Person Name: {detail && detail ? detail.full_name : ''}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                </div>
                                                <div className="main-contact-info-body">
                                                    <div className="media-list p-0">
                                                        <div className="media py-4 mt-0">
                                                            <div className="media-body">
                                                                <div className="d-flex">
                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                    <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mobile_number : ''}</span> </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                    <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.email : ''}</span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="media py-4 border-top mt-0">
                                                            <div className="media-body">
                                                                <div className="d-flex">
                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                    <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.gst_number : ''}</span> </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                    <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.address : ''}</span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="media py-4 border-top mt-0">
                                                            <div className="media-body">
                                                                <div className="d-flex">
                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                    <div className="w-70"> <label>Country:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.country : ''}</span> </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settlement