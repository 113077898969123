import React, { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { postApi } from '../../../service/employService';

const JobTypeAssignModal = ({ assignJobTypeModal, setAssignJobTypeModal, employeeList, jobTypeAndChild }) => {

    const [employeeArray, setEmployeeArray] = useState([]);

    useEffect(() => {
        setEmployeeArray(employeeList)
    }, [employeeList]);

    const handleBulkJobTypeAssign = async () => {
        // console.log("***************************", employeeArray)

        const response = await postApi('/bulk-job-type-assign-v2', { employeeArray })

        if (response.status == true) {
            toast.success(response.message);
            setAssignJobTypeModal(false)

            // navigate('/employer/schedule');

        } else {
            console.log('inside abhi', response);
            toast.error(response.message);
        }
    }

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
    
    const filteredEmployees = employeeArray.filter((employee) => {
      const searchTermLowercase = searchTerm.toLowerCase();
      return (
        employee.first_name?.toLowerCase().includes(searchTermLowercase) ||
        employee.last_name?.toLowerCase().includes(searchTermLowercase) ||
        employee.mobile_number?.includes(searchTermLowercase)
      );
    });


    return (
        <>
            {assignJobTypeModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Assign Job Type</h5>


                                    
                                <input type="text" className="col-md-4 form-control" placeholder="Search By Employee Details..." value={searchTerm}
      onChange={handleSearch} />
                                {/* <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span> */}








                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" /> */}
                                </div>
                                <div className="modal-body">


                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Detail  </th>
                                                    <th className="font-weight-bold">JOB TYPE  </th>
                                                    <th className="font-weight-bold">SUB JOB TYPE  </th>
                                                    <th className="font-weight-bold">L2 JOB TYPE  </th>
                                                </tr>
                                            </thead>
                                            <>
                                                <tbody>
                                                    {/* employeeArray?.length > 0 && */ filteredEmployees?.map((option, index) => {
                                                        return (
                                                            <tr key={option.customer_id} >
                                                                <td>
                                                                    <span className="font-weight-normal1">{option.first_name ? option.first_name : "--"} {option.last_name && option.last_name}</span> <br />
                                                                    <span className="font-weight-normal1">{option?.mobile_number ? option?.mobile_number : "--"}</span>
                                                                </td>
                                                                <td>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <select
                                                                            className="form-control mb-0"
                                                                            name="job_id"
                                                                            value={option.job_id}
                                                                            onChange={(e) => {
                                                                                const updatedArray = employeeArray.map((data => {
                                                                                    if (data.customer_id === option.customer_id) {
                                                                                        data.job_id = e.target.value
                                                                                        data.sub_job_id = ""
                                                                                        data.sub_l2_job_id = ""
                                                                                    }
                                                                                    return data
                                                                                }))
                                                                                setEmployeeArray(updatedArray)
                                                                            }}
                                                                        >
                                                                            <option value="">Select Job Type</option>
                                                                            {jobTypeAndChild?.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.job_type}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <select
                                                                            className="form-control mb-0"
                                                                            name="sub_job_id"
                                                                            value={option.sub_job_id}
                                                                            onChange={(e) => {
                                                                                const updatedArray = employeeArray.map((data => {
                                                                                    if (data.customer_id === option.customer_id) {
                                                                                        data.sub_job_id = e.target.value
                                                                                        data.sub_l2_job_id = ""
                                                                                    }
                                                                                    return data
                                                                                }))

                                                                                setEmployeeArray(updatedArray)
                                                                            }}
                                                                        >
                                                                            <option value="">Select Job Type</option>
                                                                            {jobTypeAndChild?.filter((item) => item.id == option.job_id)[0]?.subJobType?.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data?.sub_job_type}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <select
                                                                            className="form-control mb-0"
                                                                            name="sub_job_id"
                                                                            value={option.sub_l2_job_id}
                                                                            onChange={(e) => {
                                                                                const updatedArray = employeeArray.map((data => {
                                                                                    if (data.customer_id === option.customer_id) {
                                                                                        data.sub_l2_job_id = e.target.value
                                                                                    }
                                                                                    return data
                                                                                }))

                                                                                setEmployeeArray(updatedArray)
                                                                            }}
                                                                        >
                                                                            <option value="">Select Job Type</option>
                                                                            {jobTypeAndChild?.filter((item) => item.id == option.job_id)[0]?.subJobType?.filter((i2) => i2.id == option.sub_job_id)[0]?.subL2JobType?.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data?.sub_l2_job_type}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tbody>
                                                    {filteredEmployees?.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody></>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" onClick={() => setAssignJobTypeModal(false)}>Close</button>
                                    <button type="button" className="btn btn-primary" onClick={() => handleBulkJobTypeAssign()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-backdrop show'></div>
                </>
            }
        </>
    );
}

export default JobTypeAssignModal;
