import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
// import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { getApi, postApi } from '../../service/lenderService';
import Loader from '../../../components/Loader/loader';
// import PaginationComponent from '../../../PaginationComponent';
import Swal from 'sweetalert2';
import { TimeZone } from '../../../components/TimeZone/TimeZone';


const Applicantemi = () => {
    // const [copied, setCopied] = useState(false);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    // const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    // const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("user_loan_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate();
    const { id } = useParams();

    const viewApplicantEMI = async (token) => {
        // const response = await ViewApplicantEMI(token, id, search, pagesCount, sortedBy, orderBy)
        const response = await getApi(`/get-loan-emi-data/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        // console.log(response, "_____________")
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data)
                // setTotalPagesCount(response.data.count)

                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        viewApplicantEMI(token, search, pagesCount)

    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const handleDelete = (id, user_loan_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Received!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let data = JSON.stringify({
                //     "id": id,
                //     "user_loan_id": user_loan_id,
                // })

                // const response = await updateloanstatus(token, data)
                const response = await postApi("/update-emi", {
                    id: id,
                    user_loan_id: user_loan_id,
                })

                if (response?.status) {
                    Swal.fire(
                        'Approve!',
                        'Application Loan EMI has been Received Successfully.',
                        'success'
                    )
                    viewApplicantEMI(token, search, pagesCount)
                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">EMIs Detail </h4>
                            </div>
                            <div className="page-rightheader">
                                {/* <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                {/* <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">EMI docs  </th>
                                                        <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>}</th>
                                                        <th className="font-weight-bold">Late Charges  {sortedBy == "late_charges" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }}> </i>}</th>
                                                        <th className="font-weight-bold">EMI No.  {sortedBy == "emi_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }}> </i>}</th>
                                                        <th className="font-weight-bold">Paid Amount {sortedBy == "total_paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }}> </i>}</th>
                                                        <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Due Date  {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>}</th>
                                                        <th className="font-weight-bold">Paid Date  {sortedBy == "paid_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }}> </i>}</th>
                                                        <th className="font-weight-bold">Notify</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { emi_docs, amount, late_charges, due_date, emi_number, total_paid_amount, status, paid_date } = option;
                                                                // var num2 = parseFloat(option.interest_rate).toFixed(2)
                                                                // var Capitalize = (option) => {
                                                                //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                //     }
                                                                // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });  


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {emi_docs != null ?
                                                                            <td><span className="font-weight-normal1"><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={emi_docs} alt='...' /></span></td> :
                                                                            <td><span className="font-weight-normal1" ><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='...' /></span></td>}
                                                                        <td><span className="font-weight-normal1">{option?.amount ? "$" + amount : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.late_charges ? "$" + late_charges : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.emi_number ? emi_number : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.total_paid_amount ? "$" + total_paid_amount : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.status ? status : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.due_date).timeZone}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.paid_date ? paid_date : "--"}</span></td>
                                                                        <td>{option.status == "PENDING" ? <Link className='btn btn-primary' onClick={() => { handleDelete(option.id, option.user_loan_id) }} >Received</Link> : "--"}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}

                        </div>
                    </div>
                </div>

            }
        </>
    )

}

export default Applicantemi


