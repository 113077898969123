import { Link, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { GetCountry, getApi, postApi } from '../../service/lenderService';
import ImageViewer from 'react-simple-image-viewer';
import { UseAppContext } from '../../../Context/AppContext';
import Loader from '../../../components/Loader/loader';
import { Image } from 'antd';

const EditProfile = (props) => {
  const [profileData, setprofileData] = useState({});
  const [country, setCountry] = useState([]);
  const navigate = useNavigate();
  const [loader, setloader] = useState(true)
  const [handleImage, setHandleImage] = useState(false)
  const [uploadBr, setUploadBr] = useState({ 'pancard_image': 0, 'logo': 0, 'gstproof_image': 0, 'business_address_proof': 0, 'bank_statement': 0, 'itr_docs': 0 });

  const checkURL = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  let HeaderLogo = UseAppContext()
  const lender = JSON.parse(localStorage.getItem("lender"));

  const getProfile = async (token) => {
    const response = await getApi("/get-profile");
    // const response = await GetProfile(token);
    if (response.status == true) {
      setTimeout(() => {
        setprofileData(response.data);
        setloader(false)
      }, 2000);

    } else if (response.message == 'Session expired') {
      localStorage.removeItem('lender');
      navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
    } else {
      console.log("get employees data response", response);
    }
  }
  const getCountry = async () => {
    const response = await GetCountry();
    if (response.status == true) {
      setCountry(response.data);
    } else {
      console.log("get Country data response", response);
    }
  }

  useEffect(() => {
    getCountry()
    getProfile(lender.lender_token);
  }, [])
  const token = lender.lender_token

  // useEffect(() => {
  //   EditForm.setValues({
  //     user_id: profileData.user_id,
  //     company_name: profileData.company_name,
  //     full_name: profileData.full_name,
  //     country: profileData.country,
  //     address: profileData.address,
  //     mobile_number: profileData.mobile_number,
  //     gst_number: profileData.gst_number,
  //     website: profileData.website,
  //     email: profileData.email,
  //     mini_rate_of_intrest: profileData.mini_rate_of_intrest,
  //     max_rate_of_intrest: profileData.max_rate_of_intrest,
  //     mini_loan_range: profileData.mini_loan_range,
  //     max_loan_range: profileData.max_loan_range,
  //     mini_tenure: profileData.mini_tenure,
  //     max_tenure: profileData.max_tenure,
  //     logo: profileData.logo,
  //   })
  // }, [profileData])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const re = /^[0-9\b]+$/;
  const res = /^[0-9\b]+$/;
  const EditForm = useFormik({

    initialValues: {
      user_id: profileData.user_id,
      company_name: profileData.company_name,
      full_name: profileData.full_name,
      country: profileData.country,
      address: profileData.address,
      mobile_number: profileData.mobile_number,
      gst_number: profileData.gst_number,
      website: profileData.website,
      email: profileData.email,
      mini_rate_of_intrest: profileData.mini_rate_of_intrest,
      max_rate_of_intrest: profileData.max_rate_of_intrest,
      mini_loan_range: profileData.mini_loan_range,
      max_loan_range: profileData.max_loan_range,
      mini_tenure: profileData.mini_tenure,
      max_tenure: profileData.max_tenure,
      logo: profileData.logo,
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      company_name: yup.string().required('Please enter company name'),
      full_name: yup.string().required('Please enter your name'),
      address: yup.string().required('Please enter address'),
      country: yup.string().required('Please enter country'),
      //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
      // mobile_number: yup.string().required('Please enter mobile number').min(7, "Mobile number must contain 7 number").max(14, "Mobile number max contain 14 number").matches(phoneRegExp, 'Please enter only number values'),
      gst_number: yup.string().required('Please enter valid GST number'),
      website: yup.string().required('Please enter valid website'),
      email: yup.string().required('Please enter valid email'),
      mini_rate_of_intrest: yup.number().typeError('Please enter a valid rate of intrest').required('Please enter minimum rate of intrest'),
      max_rate_of_intrest: yup.number().typeError('Please enter a valid rate of intrest').required('Please Enter Maximum Rate Of Interest').moreThan(yup.ref('mini_rate_of_intrest'), "Max Rate Of Intrest Should Be Greater Than Min Rate Of Interest"),
      mini_loan_range: yup.number().typeError('Please enter a valid loan range').required('Please enter minimum loan range'),
      max_loan_range: yup.number().typeError('Please enter a valid loan range').required('Please Enter Maximum Loan Range Amount').typeError('Please Enter Valid Loan Range Amount').moreThan(yup.ref("mini_loan_range"), "Max Loan Range Should Be Greater Than Min Loan Range"),
      mini_tenure: yup.string().typeError('Please enter a valid tenure').required('Please enter minimum tenure').matches(re, 'Please enter only valid month count').max(2, `Min Tenure Max Contain 2 Number`),
      // max_tenure: yup.string().typeError('Please enter a valid tenure').required('Please enter maximum tenure ').matches(res, 'Please enter only valid month count'),
      max_tenure: yup
        .number()
        .typeError('Please Enter a valid number for Maximum Tenure')
        .required('Please Enter Maximum Tenure')
        .moreThan(yup.ref('mini_tenure'), 'Max Tenure Should Be Greater Than Min Tenure')
        .test('len', 'Max Tenure Max Contain 2 Number', val => val && val.toString().length <= 2)
    }),
    onSubmit: async values => {

      console.log(values.logo)
      values.logo = values.logo.slice(values.logo.lastIndexOf(",") + 1)

      const response = await postApi("/edit_profile", {
        user_id: profileData.user_id,
        company_name: values.company_name,
        full_name: values.full_name,
        country: values.country,
        address: values.address,
        // mobile_number: values.mobile_number,
        gst_number: values.gst_number,
        website: values.website,
        email: values.email,
        mini_rate_of_intrest: values.mini_rate_of_intrest,
        max_rate_of_intrest: values.max_rate_of_intrest,
        mini_loan_range: values.mini_loan_range,
        max_loan_range: values.max_loan_range,
        mini_tenure: values.mini_tenure,
        max_tenure: values.max_tenure,
        logo: values.logo,
      });
      if (response.status === true) {
        setprofileData(response.data);
        HeaderLogo?.HeaderLogoChange()
        toast.success(response.message);
        navigate(process.env.REACT_APP_LENDER_PRIFIX + '/profile')
      } else {
        console.log('inside abhi', response);
        toast.error(response.message);
      }
    }
  });
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL


  const imageUpload = async (e, path) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('logo', e.target.files[0])
      const response = await postApi("/upload-logo", formData, true);
      setHandleImage(true)
      // const response = await UploadLogo(token, formData);
      HeaderLogo?.HeaderLogoChange()
      console.log({ response })
      if (response.status) {
        EditForm.setFieldValue(path, EditForm.values[path] ? EditForm.values[path] + ',' + response.Data : response.Data)
        EditForm.setFieldValue(path + '_true', true);
      } else {
        console.log(response);
      }
    }
  }
  const removeImage = (e, index, path, co_index = 0) => {
    e.preventDefault()
    var array;
    array = EditForm.values[path].split(',')
    array.splice(index, 1)
    if (array == '') {
      EditForm.setFieldValue(path + '_true', false);
    }
    EditForm.setFieldValue(path, array.join(','));
  }
  const updateAndSaveMerchantRecord = (e, path) => {
    if (e.target.value || e.target.type == 'file') {
      EditForm.setFieldValue(path + '_true', false);
      if (e.target.type == 'file') {
        EditForm.validateForm().then((res) => {
          if (res[path] == undefined) {
            EditForm.setFieldValue(path + '_true', true);
          }
        })
      }
    } else {
      if (e.target.type == 'file') {
      } else {
        EditForm.setFieldValue(path, e.target.value);
      }
      EditForm.setFieldValue(path + '_true', false);

    }
  }

  let logoLength = EditForm?.values?.logo?.split(",").length
  if (logoLength > 1) {
    EditForm.values.logo = EditForm.values.logo.slice(EditForm.values.logo.lastIndexOf(",") + 1)
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {loader == true ? <div style={{ marginLeft: '400px', marginTop: '200px' }}><Loader /></div> :
        <div className="main-container container-fluid px-0">
          <div className="page-header">
            <div className="page-leftheader">
              {/* <h4 className="page-title mb-0 text-primary">Update Lender Profile</h4> */}
              <h4 className="page-title mb-0 text-primary">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/lender/profile" >Lender Profile</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                  </ol>
                </nav>
              </h4>
            </div>
          </div>
          <form className=" row" id='registerForm' onSubmit={EditForm.handleSubmit}>
            <div className="row">

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Company Name</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter Company Name" {...EditForm.getFieldProps("company_name")} />
                    </div>
                    {EditForm.touched.company_name && EditForm.errors.company_name ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_name}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Name Of Contact Person</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter name" {...EditForm.getFieldProps("full_name")} />
                    </div>
                    {EditForm.touched.full_name && EditForm.errors.full_name ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Country</label>
                  <div className="row g-xs">
                    <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
                      <option value="">Select Country</option>
                      {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                    </select>
                    {EditForm.touched.country && EditForm.errors.country ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Mobile Number</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" className="form-control" placeholder="Enter Mobile number"
                        onWheel={(e) => e.target.blur()}
                        {...EditForm.getFieldProps("mobile_number")} />
                    </div>
                    {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                  </div>
                </div>
              </div> */}

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Address</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter Address" {...EditForm.getFieldProps("address")} />
                    </div>
                    {EditForm.touched.address && EditForm.errors.address ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.address}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">GST Number</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter GST Number" {...EditForm.getFieldProps("gst_number")} />
                    </div>
                    {EditForm.touched.gst_number && EditForm.errors.gst_number ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.gst_number}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Website</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter Website" {...EditForm.getFieldProps("website")} />
                    </div>
                    {EditForm.touched.website && EditForm.errors.website ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Enter Email" {...EditForm.getFieldProps("email")} />
                    </div>
                    {EditForm.touched.email && EditForm.errors.email ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Minimum Rate Of Interest</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} min="0" step="any" name="mini_rate_of_intrest" {...EditForm.getFieldProps("mini_rate_of_intrest")} className="form-control" placeholder="Enter Minimum Rate Of Interest" />
                    </div>
                    {EditForm.touched.mini_rate_of_intrest && EditForm.errors.mini_rate_of_intrest ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mini_rate_of_intrest}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Maximum Rate Of Interest</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} min="0" step="any" name="max_rate_of_intrest" {...EditForm.getFieldProps("max_rate_of_intrest")} className="form-control" placeholder="Enter Maximum Rate Of Interest" />
                    </div>
                    {EditForm.touched.max_rate_of_intrest && EditForm.errors.max_rate_of_intrest ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.max_rate_of_intrest}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Minimum Loan Range</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} name="mini_loan_range"{...EditForm.getFieldProps("mini_loan_range")} className="form-control" placeholder="Enter Manimum Loan Range" />
                    </div>
                    {EditForm.touched.mini_loan_range && EditForm.errors.mini_loan_range ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mini_loan_range}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Maximum Loan Range</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} name="max_loan_range"{...EditForm.getFieldProps("max_loan_range")} className="form-control" placeholder="Enter Maximum Loan Range" />
                    </div>
                    {EditForm.touched.max_loan_range && EditForm.errors.max_loan_range ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.max_loan_range}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Minimum Tenure(Months)</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} name="mini_tenure"{...EditForm.getFieldProps("mini_tenure")} className="form-control" placeholder="Enter Minimum Tenure" />
                    </div>
                    {EditForm.touched.mini_tenure && EditForm.errors.mini_tenure ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mini_tenure}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                <div className="form-group">
                  <label className="form-label">Maximum Tenure(Months)</label>
                  <div className="row g-xs">
                    <div className="input-group">
                      <input type="number" onWheel={(e) => e.target.blur()} name="max_tenure"{...EditForm.getFieldProps("max_tenure")} className="form-control" placeholder="Enter maximum Tenure" />
                    </div>
                    {EditForm.touched.max_tenure && EditForm.errors.max_tenure ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.max_tenure}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                <label className="form-label">Logo</label>
                <div className="upload__box">
                  <div className="upload__btn-box">
                    <label className="upload__btn">
                      <i
                        className="fa-solid fa-arrow-up-from-bracket fa-fw"></i>
                      <p>Upload images <span className="addmore-plus"><i
                        className="fa-solid fa-plus fa-fw"></i></span>
                        <small
                          style={{ display: "block", color: "#a5a5a5" }}>(JPG,
                          PNG only)</small>
                      </p>
                      <input type="file" accept=".jpg, .png" multiple name="logo" onChange={(e) => imageUpload(e, 'logo')}
                        onBlur={(e) => updateAndSaveMerchantRecord(e, 'logo')}
                        data-max_length="20"
                        className="upload__inputfile" />


                    </label>
                  </div>
                </div>
                {/* {console.log(EditForm.values.logo,"zzzzzzzz")} */}
                {EditForm.values.logo && EditForm.values.logo.split(',') && EditForm.values.logo.split(',').length > 0 ?

                  <>
                    <ul className="imgpreview-newbx">
                      {uploadBr['logo'] > 0 ?
                        <div className="progress-imgupload">
                          <div className="progress-value" style={{ width: uploadBr['logo'] + '%' }}><span>{uploadBr['logo']}%</span></div>
                        </div>
                        : ''}
                      {EditForm.values.logo && EditForm.values.logo.split(',').map((option, index) => {
                        return (
                          <li key={index} >

                            {checkURL(S3_BUCKET_URL + 'lender-logo-' + option) ?
                              <>
                                <Image src={S3_BUCKET_URL + 'logo/' + option} alt="" /* onClick={() => openImageViewer(index, EditForm.values.logo, 'lender-logo/')} */ />
                                <Link to="" onClick={(e) => { removeImage(e, index, 'logo') }} className="upload__img-close" ><i className="fa-solid fa-close fa-fw"></i></Link>
                                <p>{option}</p>
                              </>
                              :
                              <>
                                <Link to="" onClick={(e) => { removeImage(e, index, 'logo') }} className="upload__img-close" ><i className="fa-solid fa-close fa-fw"></i></Link>
                                <p>{option}</p>
                              </>
                            }
                          </li>
                        )
                      })}
                    </ul>
                  </>
                  : ''}

              </div>
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Submit</button>
              </div>
            </div>
          </form>
        </div>
      }
      {/* {isViewerOpen && images && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )} */}
    </>
  )
}
export default EditProfile;
