import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getApi } from '../../service/employService';
import Loader from '../../../components/Loader/loader';
import PaymentDueModal from './paymentDueModal';
import BussinessModal from './BussinessModal';

const Dashboard = () => {
	const employer = JSON.parse(localStorage.getItem("employer"));
	const [totalEmployee, setTotalEmployee] = useState('');
	const [totalBnplServiceUsers, settotalBnplServiceUsers] = useState("")
	const [totalBnplPaidAmount, settotalBnplPaidAmount] = useState("")
	const [totalBnplDuesAmount, settotalBnplDuesAmount] = useState("")
	const [totalBnplUpcommingDuesAmount, settotalBnplUpcommingDuesAmount] = useState("")
	const [loader, setloader] = useState(true)
	const [restrictModal, setRestrictModal] = useState(employer?.payment_status)
	const [BasicDetailsUpdate, setBasicDetailsUpdate] = useState(employer?.basic_details_update)
	const navigate = useNavigate()
	// const location = useLocation();
	// const queryParams = new URLSearchParams(location.search);
	// const QueryPaymentStatus = queryParams.get('payment');

	// useEffect(() => {
	// 	if (QueryPaymentStatus === "ZW1wbG95ZWVfcmVnaXN0cmF0aW9uX3BheW1lbnRfc3VjY2Vzc2Z1bGxfZm9yX3RoaXJkcm9j") {
	// 		setOpenModal(true)
	// 	}
	// }, [QueryPaymentStatus]);

	const getDashBoard = async () => {
		try {
			const response = await getApi(`/dashboard-employer`);
			if (response.status == true) {
				setTimeout(() => {
					setTotalEmployee(response.message.totalEmployee)
					settotalBnplServiceUsers(response.message.totalBnplServiceUsers)
					settotalBnplPaidAmount(response.message.totalBnplPaidAmount)
					settotalBnplDuesAmount(response.message.totalBnplDuesAmount)
					settotalBnplUpcommingDuesAmount(response.message.totalBnplUpcommingDuesAmount)
					setloader(false)
				}, 2000);

				// Calling other USE effects functions after successful calling dashbord API
				if (response.isPaymentExpired) {
					setRestrictModal(false)
				}
				else {
					setRestrictModal(true)
				}

			} else if (response.message == 'Session expired') {
				localStorage.removeItem("employer");
				navigate('/employer/login')
			} else {
				console.log("get employees data response", response);
			}
		} catch (error) {
			if (error.message == 'Session expired') {
				localStorage.removeItem("employer");
				navigate('/employer/login')
			}
			console.log(error)
		}
	}

	const GeValidTillDate = async () => {
		try {
			const response = await getApi('/get-valid-till')
			if (response.status) {
				const localStorageEmployer = { ...employer, plan_expire_on: response.valid_till }
				localStorage.setItem("employer", JSON.stringify(localStorageEmployer))

			} else {
				console.log(response)
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		GeValidTillDate()
		getDashBoard(employer.employer_token)
	}, [])

	// const updatetoken = async () => {

	// 	const response = await getApi("/update-payment-status")

	// 	if (response.status == false) {
	// 		toast.error(response.message);
	// 	}
	// 	if (response.status == true) {
	// 		if (response.status) {
	// 			let employer = {
	// 				user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
	// 				employer_token: response.token,
	// 				email: response.email,
	// 				user_name: response.user_name,
	// 				is_nfc_active: response.is_nfc_active,
	// 				plan: response.plan,
	// 				payment_status: response.payment_status,
	// 				basic_details_update: response.basic_details_update
	// 			}
	// 			localStorage.setItem('employer', JSON.stringify(employer));
	// 			console.log('Set restrcit modal', response.payment_status);
	// 			setRestrictModal(response.payment_status)
	// 			setBasicDetailsUpdate(response.basic_details_update)
	// 		} else if (response.message === 'Payment due') {
	// 			setRestrictModal(false)
	// 		} else {
	// 			toast.error(response.message);
	// 			localStorage.removeItem('employer');
	// 		}
	// 	}

	// }

	// useEffect(() => {
	// 	// console.log(QueryPaymentStatus, "-------------------", QueryPaymentStatus == 'ZW1wbG95ZWVfcmVnaXN0cmF0aW9uX3BheW1lbnRfc3VjY2Vzc2Z1bGxfZm9yX3RoaXJkcm9j')
	// 	if (QueryPaymentStatus == 'ZW1wbG95ZWVfcmVnaXN0cmF0aW9uX3BheW1lbnRfc3VjY2Vzc2Z1bGxfZm9yX3RoaXJkcm9j') {
	// 		updatetoken()
	// 	}
	// }, [])

	return (
		<>
			{loader == true && <div className="modal-backdrop fade show"></div>}
			{loader == true ? <div style={{ marginLeft: '400px', marginTop: '200px' }}><Loader /></div> :
				<div className="main-container container-fluid px-0">
					<div className="page-header pt-2">
						<div className="page-leftheader">
							<h4 className="page-title mb-0 text-primary">Dashboard</h4>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Employees</p>
									<h2 className="mb-1 font-weight-bold">{totalEmployee ? totalEmployee : 0}</h2>
									{(totalEmployee !== 0 && totalEmployee !== null) && (<span className="mb-1"><Link to={'/employer/employees'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1"> Active Service Employee</p>
									<h2 className="mb-1 font-weight-bold">{totalBnplServiceUsers ? totalBnplServiceUsers : 0}</h2>
									{(totalBnplServiceUsers !== 0 && totalBnplServiceUsers !== null) && (<span className="mb-1"><Link to={'/employer/employees'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Paid Amount</p>
									<h2 className="mb-1 font-weight-bold" style={{ color: "#00c18d" }}>{totalBnplPaidAmount ? "$" + totalBnplPaidAmount : 0}</h2>
									{(totalBnplPaidAmount !== 0 && totalBnplPaidAmount !== null) && (<span className="mb-1"><Link to={'/employer/settlement'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Due Amount</p>
									<h2 className="mb-1 font-weight-bold">{totalBnplDuesAmount ? "$" + totalBnplDuesAmount : 0}</h2>
									{(totalBnplDuesAmount !== 0 && totalBnplDuesAmount !== null) && (<span className="mb-1"><Link to={'/employer/bnpl_repayment'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1"> Upcoming Due Amount</p>
									<h2 className="mb-1 font-weight-bold">{totalBnplUpcommingDuesAmount ? "$" + totalBnplUpcommingDuesAmount : 0}</h2>
									{/* <span className="mb-1"><Link to={'/employer/employees'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span> */}
								</div>
							</div>
						</div>
					</div>

					{/************************** Modal for Plan date expired ***********************************/}

					{!restrictModal && <PaymentDueModal restrictModal={restrictModal} />}
					{!BasicDetailsUpdate && restrictModal && <BussinessModal BasicDetailsUpdates={BasicDetailsUpdate} restrictModals={restrictModal} />}

				</div >
			}
		</>
	)
}

export default Dashboard;