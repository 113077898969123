import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getApi } from '../service/adminService'
import Loader from '../../components/Loader/loader'
import { TimeZone } from '../../components/TimeZone/TimeZone'
import PaginationComponent from '../../PaginationComponent'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Limitfilter from '../../components/Limitfilter/Limitfilter'
import Search from '../../components/Search/Search'
import moment from 'moment/moment'

const Employeealltxn = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Entrieslimit, setEntrieslimit] = useState("10")
    const [dataArray, setDataArray] = useState([]);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [recordCount, setRecordCount] = useState("")
    const [ValidPagination, setValidPagination] = useState(false)

    const getEmployeeTransactionDetail = async (token) => {
        // const response = await GetEmployeeallTXNDetail(token, search, pagesCount, sortedBy, orderBy ,date_Format);
        const response = await getApi('/borrower-transaction', { search: search, page_number: pagesCount, sortby: sortedBy, orderBy: orderBy, start_date: startDate, end_date: endDate, time_zone: date_Format, limit: Entrieslimit });

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count)
                setRecordCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        getEmployeeTransactionDetail(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy, startDate, endDate, Entrieslimit])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX

    const handleCallback = (event, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        // Calculate the difference in days
        const diffInDays = picker.endDate.diff(picker.startDate, 'days');

        if (diffInDays > 31) {
            const newEndDate = picker.startDate.clone().add(31, 'days');
            picker.setEndDate(newEndDate);
            picker.element.val(startDate + ' - ' + newEndDate.format('YYYY-MM-DD'));
            setstartDate(startDate);
            setendDate(newEndDate.format('YYYY-MM-DD'));
        } else {
            picker.element.val(startDate + ' - ' + endDate);
            setstartDate(startDate);
            setendDate(endDate);
        }
    };

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    // console.log(startDate, endDate, "start Date", "end date")

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <h6 className="page-title mb-0 text-primary">Borrower TXN Detail {recordCount ? `(${recordCount})` : ""}</h6>
                </div>
                <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-borrower-transactions?time_zone=${date_Format}&start_date=${startDate}&end_date=${endDate}`} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>
            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}

                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Select Date</label>
                        <div className="row g-xs">
                            <div className="input-group" >
                                <DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                    initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                    <input
                                        placeholder="Search By Date"
                                        className="form-control fc-datepicker hasDatepicker"
                                        type="text" defaultValue=""
                                        style={{ cursor: 'pointer' }}
                                    />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN ID
                                                {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                            <th className="font-weight-bold">Customer Info {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>} </th>
                                            <th className="font-weight-bold">Merchant Info {sortedBy == "merchant_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_id') }}></i>} </th>
                                            <th className="font-weight-bold">Txn Amount <span data-bs-toggle="tooltip" data-bs-placement="top" title="Including Txn Charges">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>   {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }}></i>} </th>
                                            <th className="font-weight-bold">Order Value <span data-bs-toggle="tooltip" data-bs-placement="top" title="Actual Order Value">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>   {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }}></i>} </th>
                                            <th className="font-weight-bold">Settled Amount <span data-bs-toggle="tooltip" data-bs-placement="top" title="Actual Merchant Settled Amount">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>  {sortedBy == "settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('settled_amount') }}></i>}</th>
                                            <th className="font-weight-bold">Merchant Comm. <span data-bs-toggle="tooltip" data-bs-placement="top" title="Actual Profit Comm from Merchant Side">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>  {sortedBy == "mrch_commission" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }}></i>}</th>
                                            <th className="font-weight-bold">Lender Comm. {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }}></i>}</th>
                                            <th className="font-weight-bold">Thirdroc Inc.  <span data-bs-toggle="tooltip" data-bs-placement="top" title="Total Profit Amount Including TXn Chanrges">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }}></i>}</th>
                                            <th className="font-weight-bold">TXN Charge  {sortedBy == "txn_charge" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_charge') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_charge') }}></i>}</th>

                                            {/*  <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                                <i className='mx-1 fa-solid fa-circle-info'></i>
                                            </span>  */}
                                            {/* <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }}></i>}</th> */}
                                            <th className="font-weight-bold">TXN From  {sortedBy == "txn_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_from') }}></i>} </th>
                                            {/* <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} </th> */}
                                            <th className="font-weight-bold">TXN Date   {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { txn_id, paid_amount, settled_amount, merchant_id, comment, txn_from, status, updated_at, customer_id } = data;

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td><span className="font-weight-normal1"> {txn_id ? txn_id : "-"} </span>
                                                                <span><CopyToClipboard text={txn_id} ><button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button></CopyToClipboard></span></td>
                                                            <td><Link to={{ pathname: `${prefix}/employeedetails/${customer_id}`, state: { customer_id } }} ><span className='font-weight-normall text-primary'>{data.customerTxnDetail ? data.customerTxnDetail.full_name : "NA"}<br />{data?.customerTxnDetail && data?.customerTxnDetail?.mobile_number}</span></Link></td>
                                                            {/* <td>
                                                                {merchant_id === null ? <span className='text-success'>Transfer Request<br /> {data?.ewaSummary?.status}</span> : <Link className="text-primary" to={prefix + `/merchantdetail/${data.merchant_id}`}>{data.merchantTxnDetail?.company_name ? data.merchantTxnDetail?.company_name : "-"}<br />{data?.merchantTxnDetail && data?.merchantTxnDetail?.mobile_number}</Link>}
                                                            </td> */}
                                                            <td><span className='font-weight-normal1'>
                                                                {merchant_id === null ?
                                                                    <span className="text-success" style={{ fontSize: "medium" }}>{data.ewaSummary?.status}</span> : <Link className="text-primary" to={prefix + `/merchantdetail/${data.merchant_id}`}>{data.merchantTxnDetail?.company_name ? data.merchantTxnDetail?.company_name : "-"}<br />{data?.merchantTxnDetail && data?.merchantTxnDetail?.mobile_number}</Link>}
                                                            </span></td>
                                                            <td>
                                                                <span className='font-weight-normal1'>
                                                                    {(data?.walletSummary != null) ? (
                                                                        <span>${data?.walletSummary?.paid_amount}</span>
                                                                    ) : (data?.bnplSummary != null) ? (
                                                                        <span >${data?.bnplSummary?.amount}</span>
                                                                    ) : (data?.ewaSummary != null) && (
                                                                        <span >${data?.ewaSummary?.paid_amount}</span>
                                                                    )}
                                                                </span>
                                                            </td>

                                                            {/* <td>{data.ewaSummary?.paid_amount ? "$" + data.ewaSummary?.paid_amount : "-"}</td> */}
                                                            <td>{data.paid_amount ? "$" + paid_amount : "$0.00"}</td>
                                                            {/* <td className='text-primary'>{data.settled_amount ? "$" + settled_amount : "-"}</td> */}
                                                            {/* <td className='text-primary'>{!data?.bnplSummary ? (data?.ewaSummary?.amount ? "$" + (data?.ewaSummary?.amount - data?.ewaSummary?.mrch_commission) : "-") :
                                                                ("$" + data?.bnplSummary?.merchant_settled_amount)}</td> */}
                                                            <td className='text-primary'>
                                                                {merchant_id === null ? (settled_amount) :

                                                                    <span>
                                                                        {!data?.bnplSummary ? (
                                                                            data?.ewaSummary?.amount
                                                                                ? "$" + parseFloat((data?.ewaSummary?.amount - data?.ewaSummary?.mrch_commission).toFixed(2))
                                                                                : <span>{data?.settled_amount && "$" + settled_amount}</span>
                                                                        ) : (
                                                                            "$" + parseFloat(data?.bnplSummary?.merchant_settled_amount).toFixed(2)
                                                                        )}</span>}
                                                            </td>
                                                            {data.walletSummary != null ? <td>{data?.walletSummary ? "$" + data.walletSummary?.mrch_commission : "$0.00"}</td> :
                                                                <td>{data.ewaSummary == null ? (data.bnplSummary?.mrch_commission ? "$" + data.bnplSummary?.mrch_commission : "$0.00") : (data.ewaSummary?.mrch_commission ? "$" + data.ewaSummary?.mrch_commission : "$0.00")}</td>
                                                            }
                                                            <td>{data.ewaSummary == null ? (data.bnplSummary?.lender_commission_amount ? "$" + data.bnplSummary?.lender_commission_amount : "$0.00") : "$0.00"}</td>

                                                            {data.walletSummary != null ? <td className='text-success'>{data?.walletSummary ? "$" + data.walletSummary?.thirdroc_income : "$0.00"}</td> :
                                                                <td className='text-success'>{data.ewaSummary == null ? (data.bnplSummary?.thirdroc_income ? "$" + data.bnplSummary?.thirdroc_income : "$0.00") : (data.ewaSummary?.thirdroc_income ? "$" + data.ewaSummary?.thirdroc_income : "$0.00")}</td>}

                                                            {data.walletSummary != null ? <td>{data?.walletSummary ? "$" + data.walletSummary?.txn_charge : "$0.00"}</td> :
                                                                <td>{data.bnplSummary == null ? "$" + data.ewaSummary?.txn_charge : "$0.00"}</td>}
                                                            {/* <td>{data.comment ? comment : "$0.00"txn_charge}</td> */}
                                                            <td>{data.txn_from ? txn_from : "$0.00"}</td>
                                                            {/* <td>{data.status ? status : "NA"}</td> */}
                                                            <td>{TimeZone(updated_at).timeZone}</td>

                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={Entrieslimit}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={Entrieslimit}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}



export default Employeealltxn
