import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postApi } from '../../service/lenderService';


const Disbursedemi = () => {
    const id = useParams();
    const navigate = useNavigate();

    const AddForm = useFormik({
        initialValues: {
            // user_loan_id: id,
            loan_status_id: "4",
            amount: "",
            total_emi: "",
            emi_monthly_amount: "",
            emi_start_date: "",
            comment: "",
            lender_roi: "",
            image: ""


        },
        enableReinitialize: true,
        validationSchema: yup.object({
            amount: yup.string().required('Please Enter Amount'),
            total_emi: yup.string().required('Please Enter Total EMIs'),
            emi_monthly_amount: yup.string().required('Please Enter EMI Monthly Amount'),
            lender_roi: yup.string().required('Please Enter Rate of Interest'),
            comment: yup.string().required('Please Enter Comment'),
            emi_start_date: yup.string().required('Please Select EMI Due Date'),

        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("loan_status_id", "4");
            formData.append("amount", AddForm.getFieldProps('amount').value);
            formData.append("total_emi", AddForm.getFieldProps('total_emi').value);
            formData.append("emi_monthly_amount", AddForm.getFieldProps('emi_monthly_amount').value);
            formData.append("lender_roi", AddForm.getFieldProps('lender_roi').value);
            formData.append("emi_start_date", AddForm.getFieldProps('emi_start_date').value);
            formData.append("comment", AddForm.getFieldProps('comment').value);
            formData.append("image", logoValue);
            // const lender = JSON.parse(localStorage.getItem("lender"))            
            const response = await postApi(`/update-loan-status/${id.id}`, formData, true)
            // const response = await EditLoanStatus1(lender.lender_token, formData, id.id)

            if (response.status) {
                console.log('inside abhi', response);
                // toast.success(response.message);
                toast.success("Notice Added Successfully");
                navigate(process.env.REACT_APP_LENDER_PRIFIX + '/manage_loan');
                resetForm({ values: "" });


            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });
    const [logoValue, setDocValue] = useState({})

    const uploadLogoFunc = async (e) => {
        const file = e.target.files[0];
        AddForm.setValues({
            ...AddForm.values,
            image: file
        })
        setDocValue(file);
    }

    const imageUploads = (e) => {
        console.log("event pancard", e);
        const file = e.target.files[0];
        AddForm.setValues({
            ...AddForm.values,
            image: file
        })
    }


    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Add Employee</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/lender/manage_loan" >Manage Loan</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add EMI detail</li>
                                </ol>
                            </nav>
                        </h4>
                    </div>
                </div>
                <form className="row" id='registerForm' onSubmit={AddForm.handleSubmit}>
                    <div className="col">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Disbursed Amount</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Amount" {...AddForm.getFieldProps("amount")} />
                                    </div>
                                    {AddForm.touched.amount && AddForm.errors.amount ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.amount}</div> : ''}
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">

                            <div className="form-group">
                                <label className="form-label">Total EMI</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Total EMI" {...AddForm.getFieldProps("total_emi")} />
                                    </div>
                                    {AddForm.touched.total_emi && AddForm.errors.total_emi ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.total_emi}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Comment</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Comment" {...AddForm.getFieldProps("comment")} />
                                    </div>
                                    {AddForm.touched.comment && AddForm.errors.comment ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.comment}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Rate of Interest</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Rate of Interest" {...AddForm.getFieldProps("lender_roi")} />
                                    </div>
                                    {AddForm.touched.lender_roi && AddForm.errors.lender_roi ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.lender_roi}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">

                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Monthly EMI Amount</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Deduction Amount Limit" {...AddForm.getFieldProps("emi_monthly_amount")} />
                                    </div>
                                    {AddForm.touched.emi_monthly_amount && AddForm.errors.emi_monthly_amount ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.emi_monthly_amount}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">EMI Start Date</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="date" className="form-control" placeholder="Enter EMI Start Date" {...AddForm.getFieldProps("emi_start_date")} />
                                    </div>
                                    {AddForm.touched.emi_start_date && AddForm.errors.emi_start_date ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.emi_start_date}</div> : ''}
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Receipt </label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control browse-file" name={logoValue} placeholder="Upload your logo" disabled />
                                            <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc} onChange={(e) => imageUploads(e)} style={{ display: 'none' }} multiple /> </label>
                                            {AddForm.touched.image && AddForm.errors.image ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.image}</div> : ''}
                                        </div>
                                    </div>

                                    {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                                        <li className="registerboximg">
                                            <img src={URL.createObjectURL(logoValue)} alt="" />
                                            <Link to="" onClick={() => setDocValue({})} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                                            <p>img preview name here</p>
                                        </li>
                                    </ul> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="page-rightheader">
                                    <div className="btn-list">
                                        <button className="btn btn-outline-primary"><label htmlFor="formFile">
                                        <i className="fa fa-upload fa-beat me-2"></i>
                                    </label><input style={{ marginLeft: '10px' }} type="file" id='formFile' onChange={(e) => {
                                    const file = e.target.files[0]; readExcel(file);
                                }} /></button>
                                    </div>
                                </div> */}

                    <div className="mt-2 mb-5">
                        <div className="col-md-12">
                            <input type="submit" name="next" className="next action-button apply-now-btn ml-00 mt-1" value="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Disbursedemi