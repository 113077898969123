import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../service/employService';
import { Breadcrumbs, Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import { TimeZone } from '../../../../components/TimeZone/TimeZone';
import PaginationComponent from '../../../../PaginationComponent';

const GetpaySlipbyID = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("site_id")
    const [orderBy, setOrderBy] = useState('desc')
    const { id } = useParams()
    const [employeeData, setEmployeeData] = useState("")

    console.log(id)

    const GetPayslip = async () => {
        try {
            const response = await getApi(`/get-all-payslip/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

            console.log('response', response);
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setEmployeeData(response.data.customerDetail)
                    //setTotalPagesCount(response.data["count"])
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetPayslip();
    }, [search, pagesCount, sortedBy, orderBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbs order={{ first: { name: "Employee" }, second: { name: "Employee List", path: '/employer/employees' }, third: { name: "Get Payslip" } }} />
                        </h4>
                    </div>
                </div>
            </div>
            <div className="main-container container-fluid px-0">
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {employeeData && (
                            <div className="card border-dark mb-3" style={{ backgroundColor: '#cbd1e7' }}>
                                <div className="card-body d-flex">
                                    <div className="me-2">
                                        <p className="m-0 fw-bold text-wrap">Name</p>
                                        <p className="m-0 fw-bold text-wrap">Email</p>
                                        <p className="m-0 fw-bold text-wrap">Mobile No</p>
                                    </div>
                                    <div>
                                        <p className="m-0 text-wrap">
                                            : {employeeData?.first_name ? employeeData?.first_name : "--"}{" "}
                                            {employeeData?.last_name}
                                        </p>
                                        <p className="m-0 text-wrap">
                                            : {employeeData?.email ? employeeData?.email : "--"}
                                        </p>
                                        <p className="m-0 text-wrap">
                                            : {employeeData?.mobile_number ? employeeData?.mobile_number : "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                {/* <th className="font-weight-bold">NFC ID  {sortedBy == "nfc_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }}> </i>}</th> */}
                                                <th className="font-weight-bold">Month  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }}> </i>}</th>
                                                <th className="font-weight-bold">Created Date </th>
                                                <th className="font-weight-bold">Updated Date </th>
                                                <th className="font-weight-bold">Doc File  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader />
                                            </div> </tbody> : <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    return (
                                                        <tr key={serial_num} >
                                                            <td><span className="font-weight-normal1">{option.month ? option.month : "-"}</span></td>
                                                            <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                            <td><span className="font-weight-normal1">{TimeZone(option.updated_at).timeZone}</span></td>
                                                            <td><span className="font-weight-normal1">
                                                                <a href={option.payslip_doc} download > <img
                                                                    src="/assets/img/pdf.jpeg"
                                                                    style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                    className="card-img-top m-1"
                                                                    alt="PDF"
                                                                />
                                                                </a></span></td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                </div>
            </div>
        </>
    )
}

export default GetpaySlipbyID;